import moment from 'moment';
import { AccountingType, FixbooksAction } from './types';

export const accountingTypeToColor: Record<AccountingType, string> = {
  Cash: '#c0ffb9',
  Accrual: '#f8adff',
  Both: '#ffe3b9',
  Different: '#e5e5e5',
};

export const accountingTypeToText: Record<AccountingType, string> = {
  Cash: 'Cash',
  Accrual: 'Accrual',
  Both: 'Both Cash and Accrual',
  Different: 'Different Values',
};

export const timeInMsToFriendlyTime = (ms: number) => moment.unix(ms / 1000).format('DD/MM/yyyy');

export const getEmptyAction = (): FixbooksAction => ({
  accountContext: '',
  accountRole: '',
  amount: 0,
  sign: 'credit',
  description: '',
});
