import axios from 'axios';
import { SQSQueueMetrics } from '../types/SQSQueueMetrics';

let url = '';
export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchSQSQueueMetrics = async (): Promise<SQSQueueMetrics[]> => {
  if (!url.length) {
    return [];
  }
  const result = await axios.get<SQSQueueMetrics[]>(`${url}/duty/sqs/queue-metrics`);
  return result.data;
};
