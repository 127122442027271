import _ from 'lodash';
import { LenderActionType, LoanLender } from '../types';

export const unlistedLender: LoanLender = {
  id: '256e105d-a0c5-4bf0-8e00-af34f40782ba',
  name: 'Other',
  requiredActions: [
    { type: LenderActionType.uploadAnAmortizationSchedule, payload: { withAskLender: true } },
    { type: LenderActionType.confirmInstructions },
  ],
};

const unSortedLenders: LoanLender[] = [
  {
    id: '86735dce-80ff-41a2-9681-d4a11d05cef2',
    name: 'Amazon Lending',
    logoUrl: 'https://logos.ntropy.com/amazon.com',
    requiredActions: [
      {
        type: LenderActionType.assignRole,
        payload: {
          instructionsLink:
            'https://intercom.help/finaloopapp/en/articles/6725534-how-to-connect-amazon-lending-to-your-books',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: 'c3ec835b-a9c8-431a-852f-ae4e4de122b9',
    name: 'PayPal Working Capital',
    logoUrl: 'https://logos.ntropy.com/paypal.com',
    requiredActions: [
      {
        type: LenderActionType.uploadAgreement,
      },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: {
          link: 'https://intercom.help/finaloopapp/en/articles/6725448-paypal-working-capital-what-info-do-i-need',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: '7bae2064-77bf-4bfa-a595-0e3f3676015c',
    name: 'PayPal Business Loans',
    logoUrl: 'https://logos.ntropy.com/paypal.com',
    requiredActions: [
      {
        type: LenderActionType.uploadAgreement,
      },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: {
          link: 'https://intercom.help/finaloopapp/en/articles/6725454-paypal-business-loans-what-info-do-i-need',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: 'f8afb310-723d-4bd2-9e24-8ca55d9605af',
    name: 'PayPal Credit',
    logoUrl: 'https://logos.ntropy.com/paypal.com',
    requiredActions: [
      {
        type: LenderActionType.uploadBillingCycles,
      },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: { link: 'https://intercom.help/finaloopapp/en/articles/6778226-paypal-credit-what-info-do-i-need' },
      },
      { type: LenderActionType.confirmInstructions },
    ],
  },
  {
    id: '91db9ba3-a6de-478f-aaab-f573a0fb7a03',
    name: 'Stripe Capital',
    logoUrl: 'https://logos.ntropy.com/stripe.com',
    requiredActions: [
      {
        type: LenderActionType.uploadAgreement,
      },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: { link: 'https://intercom.help/finaloopapp/en/articles/6725549-stripe-capital-what-info-do-i-need' },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: '78791f1d-a0a4-4b43-8639-3022dfdf53e6',
    name: 'Shopify Capital',
    logoUrl: 'https://logos.ntropy.com/shopify.co.uk',
    requiredActions: [
      { type: LenderActionType.uploadAnAmortizationSchedule },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: { link: 'https://intercom.help/finaloopapp/en/articles/6723711-shopify-capital-what-info-do-i-need' },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: '119445a2-dd92-4958-89fe-712c7385cde0',
    name: 'Clearco',
    logoUrl: 'https://logos.ntropy.com/clearco.com',
    requiredActions: [{ type: LenderActionType.connectIntegration, payload: { integrationType: 'Clearco' } }],
  },
  {
    id: '393057e3-65bd-432b-8c78-a94ce0de65ef',
    name: 'Ampla',
    logoUrl: 'https://logos.ntropy.com/getampla.com',
    requiredActions: [
      {
        type: LenderActionType.assignRole,
        payload: {
          instructionsLink: 'https://intercom.help/finaloopapp/en/articles/6723694-how-to-connect-ampla-to-your-books',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: '29670b10-e6c2-4c59-af0a-db0e06d12be7',
    name: 'Settle',
    logoUrl: 'https://logos.ntropy.com/settle.com',
    requiredActions: [
      {
        type: LenderActionType.assignRole,
        payload: {
          instructionsLink: 'https://intercom.help/finaloopapp/en/articles/6721970-how-to-connect-settle-to-your-books',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: '3f2e1356-c2fe-43be-9751-26f58411113b',
    name: 'Payability',
    logoUrl: 'https://logos.ntropy.com/payability.com',
    requiredActions: [
      { type: LenderActionType.uploadAgreement, payload: { fileTypeDisplayName: 'required documents' } },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: {
          link: 'https://intercom.help/finaloopapp/en/articles/6851769-payability-what-info-do-i-need',
        },
      },
      { type: LenderActionType.confirmInstructions },
    ],
  },
  {
    id: 'bf06d09c-7a80-48ca-b24b-6fd29471a1f3',
    name: 'Wayflyer',
    requiredActions: [
      { type: LenderActionType.uploadAnAmortizationSchedule, payload: { withAskLender: true } },
      { type: LenderActionType.confirmInstructions },
    ],
  },
  {
    id: '5e5dabee-8cd0-41f0-a013-d11a95e798b4',
    name: 'Marcus by Goldman Sachs',
    logoUrl: 'https://logos.ntropy.com/goldmansachs.com',
    requiredActions: [
      {
        type: LenderActionType.uploadAgreement,
      },
      {
        type: LenderActionType.seeAgreementInstructions,
        payload: {
          link: 'https://intercom.help/finaloopapp/en/articles/6723729-marcus-by-goldman-what-info-do-i-need',
        },
      },
      {
        type: LenderActionType.confirmInstructions,
      },
    ],
  },
  {
    id: 'eb60c259-beea-406a-b8f7-f216c4c55399',
    name: 'PPP',
    requiredActions: [{ type: LenderActionType.uploadAgreement }],
  },
  {
    id: 'a175e4b9-9123-43ae-9d89-4fbc588321fe',
    name: 'SBA',
    requiredActions: [{ type: LenderActionType.uploadAgreement }],
  },
];

const sortedLenders = _.sortBy(unSortedLenders, (_) => _.name);
sortedLenders.unshift(unlistedLender);
export const lenders = sortedLenders;
