import { useContext, useEffect } from 'react';
import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { Company } from '../../../types/Company';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { gqlClient } from '../../../services/FinaloopGql';

export type UseListCompaniesState = FetchHookState<Company[]>;

export const useListCompaniesName = () => {
  const { currentEnv } = useContext(environmentContext);

  const { isError, isFetching, data, refetch } = useQuery(
    'get-company-names',
    async () => {
      return (
        await gqlClient.query({
          listCompanies: {
            id: true,
            name: true,
          },
        })
      ).listCompanies.map((company) => ({ id: company.id, name: company.name || 'N/A' }));
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  useEffect(() => {
    refetch();
  }, [refetch, currentEnv]);

  return { data, isError, isLoading: isFetching };
};
