const formatterWithFractions = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const formatCurrency = (value: number | string) => {
  const formatter = formatterWithFractions;

  let target = value;

  if (isNaN(Number(value))) {
    target = 0;
  }

  if (typeof target === 'string') {
    target = parseFloat(value as string);
  }

  return formatter.format(target);
};
