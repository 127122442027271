import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import { BusinessEventsTableView } from './BusinessEventTableViewV2';
import { useFetchBusinessEventsForMetric } from '../common/hooks/useFetchBusinessEventsForMetric';
import { usePagination } from '../common/hooks/usePaginationV2';
import { AccountFragments } from '../../types/AccountFragments';

const Loader = styled(LinearProgress)`
  width: 100%;
`;

const TableLoader = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Fetching business events
      </Typography>
      <Loader />
    </>
  );
};

interface BusinessEventsV2TableProps {
  companyId?: string;
  metricName?: string;
  accountFragments?: AccountFragments;
  eventType?: string;
  startTime?: number | null;
  endTime?: number | null;
  openingBalance?: number;
  onEventTimeClick: (eventTime: Date) => void;
  showAmountsInOriginalCurrency: boolean;
}

const createDescCumulativeSumFunc = (openingBalance: number) => (current: number) => (openingBalance -= current);
enum SortDirection {
  Asc,
  Desc,
}
export const BusinessEventsTableV2 = ({
  companyId,
  accountFragments,
  eventType,
  startTime,
  endTime,
  openingBalance = 0,
  onEventTimeClick,
  showAmountsInOriginalCurrency,
}: BusinessEventsV2TableProps) => {
  const [page, setNextPageKey] = useState(1);
  const { data, loading, error } = useFetchBusinessEventsForMetric({
    companyId,
    accountFragments,
    eventType,
    startTime: startTime ?? undefined,
    endTime: endTime ?? undefined,
    page,
  });

  const { appendPage, currentPage, nextPage, previousPage, missingPage, hasPrevious, clearPages } = usePagination();

  useEffect(() => clearPages(), [clearPages, companyId, accountFragments, startTime, endTime, eventType]);
  useEffect(() => {
    if (data) {
      appendPage(data);
    }
  }, [data, appendPage]);

  const sortDirection = SortDirection.Desc;

  const sorter = useCallback((a: any, b: any) => b.eventTime - a.eventTime, [sortDirection]);
  useEffect(() => {
    if (missingPage) {
      setNextPageKey(page + 1);
    }
  }, [missingPage, currentPage?.lastKey]);
  const businessEvents = useMemo(() => {
    if (!currentPage || !currentPage.length) {
      return;
    }
    const sorted = currentPage.sort(sorter);
    if (!openingBalance) {
      return sorted;
    }

    const amountKeyName = showAmountsInOriginalCurrency ? 'amountInOriginalCurrency' : 'amount';

    const balanceCalculator = createDescCumulativeSumFunc(openingBalance + sorted[0].action[amountKeyName] || 0);

    return sorted.map((event: any) => ({ ...event, currentBalance: balanceCalculator(event.action[amountKeyName]) }));
  }, [currentPage, sorter, openingBalance, sortDirection]);

  if (loading) {
    return <TableLoader />;
  }
  if (!businessEvents || !companyId) {
    return null;
  }
  if (!businessEvents.length) {
    return (
      <Typography variant="h5" gutterBottom>
        No relevant business events for {JSON.stringify(accountFragments)}
      </Typography>
    );
  }

  return (
    <>
      <Button onClick={() => nextPage()} variant="contained">
        Next Page
      </Button>
      <Button disabled={!hasPrevious} onClick={() => previousPage()} variant="contained">
        Previous Page
      </Button>
      <BusinessEventsTableView
        onEventTimeTitleClick={() => {}}
        businessEvents={businessEvents}
        onEventTimeClick={onEventTimeClick}
        showAmountsInOriginalCurrency={showAmountsInOriginalCurrency}
        companyId={companyId}
      />
    </>
  );
};
