import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuid } from 'uuid';
import { createBusinessEventModel } from '../../../common/utils/createBusinessEventModel';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { createEvent, deleteFixbooksEvent, getEvents } from '../services/fixbooks-api';
import { FixbooksEventRequest } from '../types';

const GET_FIXBOOKS_EVENTS_QUERY_NAME = 'get-fixbooks-events';

export const useGetFixbooksEvents = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { selectedCompany } = useSelectedCompany();

  const { isError, isFetching, data: fixbooksEvents } = useQuery(
    [GET_FIXBOOKS_EVENTS_QUERY_NAME, selectedCompany?.id],
    async () => {
      if (!selectedCompany) return [];
      const token = await getAccessTokenSilently();
      return await getEvents(token, env, selectedCompany.id);
    },
    {
      refetchInterval: 10000,
    },
  );

  return { fixbooksEvents, isError, isLoading: isFetching };
};

export const usePostFixbooksEvent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { selectedCompany } = useSelectedCompany();

  const queryClient = useQueryClient();

  const { isLoading, isError, mutateAsync } = useMutation(
    'post-fixbooks-event',
    async ({ request }: { request: FixbooksEventRequest }) => {
      if (!selectedCompany) throw new Error(`can't post event, no company was selected`);

      const eventId = uuid();
      const editedRequest: FixbooksEventRequest = {
        ...request,
        classificationTagId: request.classificationTagId,
        eventId,
        context: request.context?.trim().replace(/\s+/g, ' ') || undefined,
        companyId: selectedCompany.id,
      };

      const event = createBusinessEventModel(
        selectedCompany.id,
        'finaloop-adjustment',
        eventId,
        'fixbooks',
        editedRequest,
        editedRequest.eventTimeMs,
      );

      console.log('sending event!!!!!!!', event);

      await createEvent(await getAccessTokenSilently(), env, event);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_FIXBOOKS_EVENTS_QUERY_NAME);
      },
    },
  );

  return { isError, isLoading, postFixbooksEvent: mutateAsync };
};

export const useDeleteFixbooksEvent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { selectedCompany } = useSelectedCompany();

  const queryClient = useQueryClient();

  const { isLoading: isDeletingFixbooksEvent, isError: hasErrorDeletingFixbooksEvent, mutateAsync } = useMutation(
    'delete-fixbooks-event',
    async ({ eventId }: { eventId: string }) => {
      if (!selectedCompany) throw new Error(`can't delete event, no company was selected`);
      const authToken = await getAccessTokenSilently();
      return await deleteFixbooksEvent({ authToken, env, eventId, companyId: selectedCompany.id });
    },
    {
      onSuccess: () => {
        queryClient.fetchQuery(GET_FIXBOOKS_EVENTS_QUERY_NAME);
      },
    },
  );

  return { isDeletingFixbooksEvent, hasErrorDeletingFixbooksEvent, deleteFixbooksEvent: mutateAsync };
};

export const useFixbooksApi = () => {
  return {
    useGetFixbooksEvents,
    usePostFixbooksEvent,
    useDeleteFixbooksEvent,
  };
};
