import { useContext } from 'react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const GET_COMPANY_BILLS = 'get-company-bills';

export const useGetCompanyBills = (companyId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const services = useContext(environmentContext);

  return useQuery(GET_COMPANY_BILLS, () => services.BillsApiClient.getBills(companyId, getAccessTokenSilently));
};
