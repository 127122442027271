import styled from '@emotion/styled';
import {
  AnyMission,
  BankStatementsPdfUploadMission,
  MissionStatus,
  MissionType,
} from '@finaloop/mission-types';
import { Button, Divider } from '@mui/material';
import _, { startCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useMissionsApiClient } from '../../../common/hooks/useMissionsApiClient';
import { useSelectedCompany } from '../../../common/hooks/useSelectedCompany';
import { timeInMsToFriendlyTime, timeInMsToMonthName } from '../../shared';

export type ExistingPdfMissionsProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  h5 {
    color: gray;
    font-size: 12px;
  }
`;

const MissionsByStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  h2 {
    font-size: 13px;
    color: gray;
    display: flex;
    gap: 5px;
    overflow-wrap: anywhere;
    flex-shrink: 0;
  }
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissionDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MissionRenderer = ({
  mission,
}: {
  mission: BankStatementsPdfUploadMission;
}) => {
  const { useEditMission } = useMissionsApiClient();
  const {
    editMission,
    isError: hasErrorClosingTheMission,
    isLoading: isClosingTheMission,
    isSuccess: isSucceededClosingTheMission,
  } = useEditMission(mission.id);

  const onCloseMissionClicked = useCallback(
    async (mission: AnyMission) => {
      const editedMission: AnyMission = {
        ...mission,
        status: MissionStatus.ClosedByFinaloopTeam,
      };

      await editMission({ mission: editedMission });
    },
    [editMission]
  );

  return (
    <MissionContainer data-mission-id={mission.id}>
      <MissionDetails>
        <Texts>
          <h5>{mission.id}</h5>
          <h2>
            <u>creation time:</u>
            {timeInMsToFriendlyTime(mission.createdAtMs)}
          </h2>
          {mission.createdBy && (
            <h2>
              <u>created by:</u>
              {mission.createdBy}
            </h2>
          )}
        </Texts>
        <label>
          {`Gap from `}
          <b>{timeInMsToMonthName(mission.payload.fromTimeInMs)}</b>
          <br />
          {` to `}
          <b>{timeInMsToMonthName(mission.payload.toTimeInMs)}</b>
        </label>
        <label>
          {mission.payload.account.mask}{' '}
          {mission.payload.account.name || mission.payload.account.displayName}
        </label>
        {mission.resolution && (
          <>
            <Divider sx={{ margin: '10px' }} />
            <Texts>
              <h2>
                <u>resolution:</u>
                {timeInMsToFriendlyTime(mission.resolution?.resolvedAtMs)}
              </h2>
              <>user uploaded PDF files</>
            </Texts>
          </>
        )}
      </MissionDetails>
      {mission.status === MissionStatus.Open && (
        <>
          <Button
            disabled={isClosingTheMission}
            onClick={() => onCloseMissionClicked(mission)}>
            Close mission
          </Button>
          {isClosingTheMission && <label>closing the mission...</label>}
          {hasErrorClosingTheMission && (
            <label>failed to close the mission</label>
          )}
          {isSucceededClosingTheMission && <label>mission closed</label>}
        </>
      )}
    </MissionContainer>
  );
};

const MissionsByStatus = ({
  status,
  missions,
}: {
  status: MissionStatus;
  missions: BankStatementsPdfUploadMission[];
}) => {
  return (
    <MissionsByStatusContainer>
      <u>{startCase(status)} Missions:</u>
      <MissionsContainer>
        {missions.map((mission, index) => (
          <MissionRenderer mission={mission} key={index} />
        ))}
      </MissionsContainer>
    </MissionsByStatusContainer>
  );
};

export const ExistingPdfMissions = ({}: ExistingPdfMissionsProps): JSX.Element => {
  const { useCompanyMissions } = useMissionsApiClient();
  const { selectedCompany } = useSelectedCompany();
  const {
    missions,
    isLoading: isLoadingPdfMissions,
    isError: hasErrorLoadingPdfMissions,
  } = useCompanyMissions<BankStatementsPdfUploadMission>({
    type: MissionType.BankStatementsPdfUpload,
    companyId: selectedCompany?.id,
  });

  const missionsToRender = useMemo(() => {
    if (!missions?.length) return [];
    return missions
      .filter((_) => _.type === MissionType.BankStatementsPdfUpload)
      .sort((_) => _.createdAtMs || 0);
  }, [missions]);

  const missionStatusToMissions = useMemo(
    () => _.groupBy(missionsToRender, (mission) => mission.status),
    [missions]
  );
  console.log({ missionStatusToMissions });

  return (
    <Container>
      <h1>
        All Company Bulk Bank Statements Missions:{' '}
        {hasErrorLoadingPdfMissions ? (
          <>Failed to get company missions</>
        ) : isLoadingPdfMissions ? (
          <>Loading...</>
        ) : (
          !missions?.length && <>No missions</>
        )}
      </h1>
      {Object.keys(missionStatusToMissions).map((status, index) => (
        <MissionsByStatus
          status={status as MissionStatus}
          missions={missionStatusToMissions[status]}
          key={index}
        />
      ))}
    </Container>
  );
};
