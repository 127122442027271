import styled from '@emotion/styled';
import {
  BankStatementsPdfUploadPayload,
  MissionType,
} from '@finaloop/mission-types';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { useState } from 'react';
import { Company } from '../../../../services/FinaloopGql';
import { Selector } from '../../../common/Selector';
import { useMissionsApiClient } from '../../../common/hooks/useMissionsApiClient';
import { useSelectedCompany } from '../../../common/hooks/useSelectedCompany';
import { timeInMsToMonthName } from '../../shared';
import { Bank } from '../../types';
import { useCompanyBanks } from '../../useCompanyBanks';

export type BanksListProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

const Summery = styled.div`
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;
`;

const SuccessText = styled.label`
  color: green;
`;

const accountTypeToMissionAccountType: Record<string, 'bank' | 'credit'> = {
  ['Depository']: 'bank',
  ['CreditCard']: 'credit',
};

export const NewPdfMission = ({}: BanksListProps): JSX.Element | null => {
  const {
    companyBanks,
    isError: hasErrorLoadingBanks,
    isLoading: isLoadingBanks,
  } = useCompanyBanks();
  const { selectedCompany } = useSelectedCompany();
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>();

  return (
    <Container>
      <h1>New:</h1>
      {hasErrorLoadingBanks ? (
        <>{`Something went wrong getting ${
          selectedCompany!.name
        } bank accounts`}</>
      ) : (
        <>
          {isLoadingBanks && <>Loading accounts...</>}
          {!isLoadingBanks && !companyBanks?.length ? (
            <>Company has no banks connected</>
          ) : (
            <>
              {!!companyBanks?.length && (
                <Selector
                  title='Select bank account'
                  data={companyBanks!}
                  onSelect={(_) => setSelectedBank(_)}
                  labelExtractor={(_) => _.fullName}
                  disabled={isLoadingBanks}
                />
              )}
            </>
          )}
          <OnetimePdfMission
            company={selectedCompany!}
            selectedBank={selectedBank!}
          />
        </>
      )}
    </Container>
  );
};

export const OnetimePdfMission = ({
  selectedBank,
  company,
}: {
  selectedBank: Bank;
  company: Company;
}) => {
  const [
    selectedStartingMonth,
    setSelectedStartingMonth,
  ] = useState<Moment | null>(null);
  const [selectedEndingMonth, setSelectedEndingMonth] = useState<Moment | null>(
    null
  );

  const { useTriggerMission } = useMissionsApiClient();
  const { isError, isLoading, isSuccess, triggerMission } = useTriggerMission();

  const fromTimeInMs = selectedStartingMonth
    ?.clone()
    .startOf('month')
    .valueOf();
  const toTimeInMs = selectedEndingMonth
    ?.clone()
    .startOf('month')
    .valueOf();

  const onSaveClicked = async () => {
    const payload: BankStatementsPdfUploadPayload = {
      bankId: selectedBank.id,
      account: {
        displayName: selectedBank.displayName,
        name: selectedBank.name,
        type: accountTypeToMissionAccountType[selectedBank.type],
        mask: selectedBank.mask,
        institutionName: selectedBank.institutionName,
      },
      fromTimeInMs: selectedStartingMonth!
        .clone()
        .startOf('month')
        .valueOf(),
      toTimeInMs: selectedEndingMonth!
        .clone()
        .endOf('month')
        .valueOf(),
    };

    await triggerMission({
      type: MissionType.BankStatementsPdfUpload,
      companyId: company.id,
      payload,
    });
  };

  const canSave =
    !!selectedStartingMonth &&
    !!selectedEndingMonth &&
    !!selectedBank &&
    !isLoading;

  return (
    <InputsContainer>
      <DatePicker
        inputFormat='yyyy-MM'
        views={['year', 'month']}
        label='From'
        value={selectedStartingMonth}
        onChange={setSelectedStartingMonth}
        renderInput={(params: any) => <TextField {...params} helperText={null} />}
      />
      <DatePicker
        inputFormat='yyyy-MM'
        views={['year', 'month']}
        label='To'
        value={selectedEndingMonth}
        onChange={setSelectedEndingMonth}
        renderInput={(params: any) => <TextField {...params} helperText={null} />}
      />
      {canSave && (
        <Summery>
          <label>
            {`Mission will be created once, right now, asking for a PDF bank statements upload from `}
            {fromTimeInMs && <b>{timeInMsToMonthName(fromTimeInMs)}</b>}
            {` to `}
            {toTimeInMs && <b>{timeInMsToMonthName(toTimeInMs)}</b>}
            <br />
            {` For company: `}
            <b>{company.name}</b>
            {`, Bank: `}
            <b>{selectedBank?.fullName}</b>.
          </label>
        </Summery>
      )}
      <Button disabled={!canSave} onClick={onSaveClicked}>
        Create Mission
      </Button>
      {isError && <>Something went wrong.</>}
      {isLoading && <>Creating a new mission...</>}
      {isSuccess && (
        <SuccessText>Mission was created successfully!</SuccessText>
      )}
    </InputsContainer>
  );
};
