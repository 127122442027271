import styled from '@emotion/styled';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { Selector } from '../../../../common/Selector';
import { useSelectedCompany } from '../../../../common/hooks/useSelectedCompany';
import { Bank } from '../../../types';
import { useCompanyBanks } from '../../../useCompanyBanks';
import { OnetimeCsvMission } from './OneTimeCsvMission';
import { RecurringCsvMission } from './RecurringCsvMission';

export type BanksListProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type NewCsvMissionType = 'one-time' | 'recurring' | 'one-time-reconciliation';
const newCsvMissionTypes: NewCsvMissionType[] = [
  'one-time',
  'recurring',
  'one-time-reconciliation',
];

export const NewCSVMission = ({}: BanksListProps): JSX.Element | null => {
  const {
    companyBanks,
    isError: hasErrorLoadingBanks,
    isLoading: isLoadingBanks,
  } = useCompanyBanks();
  const { selectedCompany } = useSelectedCompany();
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>();
  const [selectedNewMissionType, setSelectedNewMissionType] = useState<
    NewCsvMissionType
  >('recurring');

  if (!selectedCompany) return <>First select company in the menu</>;

  return (
    <Container>
      <h1>New:</h1>
      <FormControl variant='standard'>
        <InputLabel>Select Type</InputLabel>
        <Select
          sx={{ width: '200px' }}
          value={selectedNewMissionType}
          onChange={(_) => setSelectedNewMissionType(_.target.value as any)}>
          {newCsvMissionTypes.map((newMissionType, index) => (
            <MenuItem key={index} value={newMissionType}>
              {newMissionType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {hasErrorLoadingBanks ? (
        <>{`Something went wrong getting ${
          selectedCompany!.name
        } bank accounts`}</>
      ) : (
        <>
          {isLoadingBanks && <>Loading accounts...</>}
          {!isLoadingBanks && !companyBanks?.length ? (
            <>Company has no banks connected</>
          ) : (
            <>
              {!!companyBanks?.length && (
                <Selector
                  title='Select bank account'
                  data={companyBanks!}
                  onSelect={(_) => setSelectedBank(_)}
                  labelExtractor={(_) => _.fullName}
                  disabled={isLoadingBanks}
                />
              )}
            </>
          )}
          {!!selectedBank || true ? (
            selectedNewMissionType === 'recurring' ? (
              <RecurringCsvMission
                company={selectedCompany!}
                selectedBank={selectedBank!}
              />
            ) : (
              <OnetimeCsvMission
                company={selectedCompany!}
                selectedBank={selectedBank!}
                selectedSubtype={selectedNewMissionType}
              />
            )
          ) : null}
        </>
      )}
    </Container>
  );
};
