import styled from '@emotion/styled';
import { IdentifiedBankConnectionPayload, MissionType, TransactionDirection } from '@finaloop/mission-types';
import { TextField, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useMissionsApiClient } from '../../common/hooks/useMissionsApiClient';
import { formatMoney } from '../../../common/utils/format-money';

const Container = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  gap: 10px;

  h1 {
    font-size: 20px;
  }

  h3 {
    font-size: 14px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const MissionPreviewContainer = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 15px;
`;

const Warning = styled.label`
  color: orange;
`;

export const IdentifiedBankConnectionMission = () => {
  const [transactionId, setTransactionId] = useState<string>('');
  const [missionPayload, setMissionPayload] = useState<IdentifiedBankConnectionPayload | undefined>();
  const [hasMoreMissionsForThisBank, setHasMoreMissionsForThisBank] = useState<boolean | undefined>();
  const [transactionCompanyId, setTransactionCompanyId] = useState<string | undefined>();
  const { useGetIdentifiedBankConnectionMissionPayload, useTriggerMission } = useMissionsApiClient();

  const {
    getPayload,
    isLoading: isGettingPayload,
    isError: hadErrorGettingPayload,
    error: errorGettingPayload,
  } = useGetIdentifiedBankConnectionMissionPayload();

  const {
    triggerMission,
    isLoading: isCreatingTheMission,
    isError: hadErrorCreatingTheMission,
    error: errorCreatingTheMission,
    isSuccess,
  } = useTriggerMission();

  const onGetMissionPreviewClicked = useCallback(async () => {
    if (!transactionId) return;

    setMissionPayload(undefined);
    setTransactionCompanyId(undefined);
    setHasMoreMissionsForThisBank(undefined);

    const { payload, companyId, hasMoreMissionsForThisBank } = await getPayload({ transactionId });
    setTransactionCompanyId(companyId);
    setMissionPayload(payload);
    setHasMoreMissionsForThisBank(hasMoreMissionsForThisBank);
  }, [getPayload, transactionId]);

  const approveAndTriggerMissionClicked = useCallback(async () => {
    await triggerMission({
      payload: missionPayload!,
      companyId: transactionCompanyId!,
      type: MissionType.IdentifiedBankConnection,
    });
  }, [triggerMission, missionPayload, transactionCompanyId]);

  return (
    <Container>
      <h1>Trigger a new identified bank connection mission</h1>
      <h3>Transaction in which we identified the account to connect</h3>
      <InputContainer>
        <TextField
          name="transaction-id"
          fullWidth
          label=""
          variant="outlined"
          onChange={(e) => setTransactionId(e.target.value)}
          value={transactionId}
        />
        <Button disabled={!transactionId || isGettingPayload} onClick={onGetMissionPreviewClicked}>
          get mission preview
        </Button>
        {hadErrorGettingPayload && <>Failed to get mission preview. {errorGettingPayload}</>}
      </InputContainer>
      {isGettingPayload ? (
        <>Loading...</>
      ) : (
        missionPayload && (
          <div>
            Mission Preview:
            {hasMoreMissionsForThisBank && (
              <>
                <br />
                Notice:{' '}
                <Warning>{`Similar mission for ${missionPayload.sourceAccount.institution.name} ${missionPayload.sourceAccount.account.mask} has already been triggered. Please make sure you don't trigger the same mission again.`}</Warning>
              </>
            )}
            <MissionPreviewContainer>{getDescriptionComponent(missionPayload)}</MissionPreviewContainer>
            <Button
              disabled={!missionPayload || isCreatingTheMission || isSuccess}
              onClick={approveAndTriggerMissionClicked}
            >
              {`Approve and trigger ${
                hasMoreMissionsForThisBank ? 'another mission for this bank account' : 'the mission'
              }`}
            </Button>
            {isCreatingTheMission && <>Creating the mission...</>}
            {hadErrorCreatingTheMission && (
              <>Something went wrong. The mission was not created. {errorCreatingTheMission}</>
            )}
            {isSuccess && <>Mission created successfully !</>}
          </div>
        )
      )}
    </Container>
  );
};

const getDescriptionComponent = (missionPayload: IdentifiedBankConnectionPayload) => {
  return (
    <div>
      <label>
        {`We’ve identified several cash transfers ${directionToFriendlyName(
          getOppositeTransactionDirection(missionPayload.identifiedAccount.transactionDirection),
        )} your ${missionPayload.sourceAccount.institution.name} account ${directionToFriendlyName(
          missionPayload.identifiedAccount.transactionDirection,
        )} an`}
        <b>{` unknown account.`}</b>
        {` Here is an example: `}
      </label>
      <div>
        {missionTimeToFriendlyTime(missionPayload.transaction.dateInMs)}
        &nbsp;
        {missionPayload.transaction.transactionDetails}
        {` - `}
        {formatMoney(missionPayload.transaction.amount.value, missionPayload.transaction.amount.currency)}
      </div>
    </div>
  );
};

const getOppositeTransactionDirection = (direction: TransactionDirection): TransactionDirection => {
  if (direction === TransactionDirection.fromAccount) return TransactionDirection.toAccount;
  return TransactionDirection.fromAccount;
};

const directionToFriendlyName = (direction: TransactionDirection): string => {
  switch (direction) {
    case TransactionDirection.fromAccount:
      return 'from';
    case TransactionDirection.toAccount:
      return 'to';
    default:
      return '';
  }
};

const dateInMsToString = (dateInMs: number): string => format(dateInMs, 'MM/dd/yyyy');
const missionTimeToFriendlyTime = (timeInMs: number | undefined) => (!timeInMs ? '' : dateInMsToString(timeInMs));
