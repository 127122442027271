import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from './hooks/useQuery';

const Container = styled.div`
  display: flex;
  width: 450;
`;

const Separator = styled.div`
  width: 1em;
`;

interface DateTimeRangePickerProps {
  onRangeSelect: (range: TimeRange) => void;
  initialTimeRange?: TimeRange;
  disabled: boolean;
}

export interface TimeRange {
  start: number | null;
  end: number | null;
}

export const DateTimeRangePicker = ({ onRangeSelect, disabled, initialTimeRange }: DateTimeRangePickerProps) => {
  const query = useQuery();
  const paramsStartTime = query.get('startTime') ? moment(Number(query.get('startTime'))) : null;
  const paramsEndTime = query.get('endTime') ? moment(Number(query.get('endTime'))) : null;

  const [start, setStartValue] = useState<Moment | null | undefined>(paramsStartTime);
  const [end, setEndValue] = useState<Moment | null | undefined>(paramsEndTime);
  const roundedStartTime = useMemo(() => (start ? start.startOf('minute') : null), [start]);
  const roundedEndTime = useMemo(() => (end ? end.startOf('minute') : null), [end]);

  useEffect(() => {
    if (!roundedStartTime || !roundedEndTime) {
      return;
    }
    const range = { start: roundedStartTime.toDate().getTime(), end: roundedEndTime.toDate().getTime() };
    onRangeSelect(range);
  }, [roundedStartTime, roundedEndTime, onRangeSelect]);

  useEffect(() => {
    if (!initialTimeRange || !initialTimeRange.start) {
      return;
    }
    setStartValue(startValue => {
      if (!initialTimeRange || !initialTimeRange.start) {
        return startValue;
      }
      if (startValue && startValue.toDate().getTime() === initialTimeRange.start) {
        return startValue;
      }
      return moment.unix(initialTimeRange.start / 1000);
    });
  }, [initialTimeRange]);
  useEffect(() => {
    if (!initialTimeRange || !initialTimeRange.end) {
      return;
    }
    setEndValue(endValue => {
      if (!initialTimeRange || !initialTimeRange.end) {
        return endValue;
      }
      if (endValue && endValue.toDate().getTime() === initialTimeRange.end) {
        return endValue;
      }
      return moment.unix(initialTimeRange.end / 1000);
    });
  }, [initialTimeRange]);

  return (
    <Container>
      <DatePicker
        disabled={disabled}
        renderInput={(props: any) => <TextField {...props} />}
        label="Start time"
        value={roundedStartTime}
        onChange={setStartValue}
      />
      <Separator />
      <DatePicker
        disabled={disabled}
        renderInput={(props: any) => <TextField {...props} />}
        label="End time"
        value={roundedEndTime}
        onChange={setEndValue}
      />
    </Container>
  );
};
