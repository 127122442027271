var Company_possibleTypes = ['Company'];
module.exports.isCompany = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompany"');
  return Company_possibleTypes.includes(obj.__typename);
};

var AmazonProductsValidation_possibleTypes = ['AmazonProductsValidation'];
module.exports.isAmazonProductsValidation = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonProductsValidation"');
  return AmazonProductsValidation_possibleTypes.includes(obj.__typename);
};

var Query_possibleTypes = ['Query'];
module.exports.isQuery = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"');
  return Query_possibleTypes.includes(obj.__typename);
};

var Mutation_possibleTypes = ['Mutation'];
module.exports.isMutation = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMutation"');
  return Mutation_possibleTypes.includes(obj.__typename);
};
