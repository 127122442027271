import { Close as NoIcon, Done as YesIcon } from '@mui/icons-material';
import { format as formatDate } from 'date-fns';
import ReactJson from 'react-json-view';
import { Company } from '../../services/FinaloopGql';

export const CompanyReadonlyInformation = ({
  company,
}: { company?: Company } = {}) => {
  if (company == null) {
    return <></>;
  }

  return (
    <div>
      <div>
        <strong>Company ID:</strong> {company?.id}
      </div>
      <div>
        <strong>Company Name:</strong> {company?.name}
      </div>
      <div>
        <strong>User Name:</strong> {company?.userName}
      </div>

      <div>
        <strong>Is Paying?</strong>{' '}
        {company?.isPaying ? <YesIcon /> : <NoIcon /> ?? 'Error'}
      </div>

      <div>
        <strong>Trial Start Time:</strong>{' '}
        {(company?.trialStartTimeIso &&
          formatDate(
            new Date(company?.trialStartTimeIso),
            'yyyy-MM-dd HH:mm:ss'
          )) ??
          'Error'}
      </div>
      <div>
        <strong>Phone Number:</strong> {company?.phoneNumber}
      </div>
      <div>
        <strong>Email:</strong> {company?.email}
      </div>
      <div>
        <strong>Manufacturer:</strong>{' '}
        {company?.isManufacturer ? <YesIcon /> : <NoIcon />}
      </div>
      <div>
        <strong>Selected Accounting Method:</strong>{' '}
        {company?.customerSelectedAccountingMethod
          ? company.customerSelectedAccountingMethod
          : 'Not Selected'}
      </div>

      {company?.address != null && (
        <div>
          <strong>Address:</strong> {transformCompanyAddress(company?.address)}
        </div>
      )}
      <div>
        <strong>General Information:</strong>
        <ReactJson collapsed src={company?.generalDetails || {}} />
      </div>
    </div>
  );
};

export const transformCompanyAddress = (
  address: Record<string, any> | undefined
): string | null => {
  if (address == null) return null;

  const cityStatePostal = [
    address.city,
    address.state,
    address.postalCode,
  ].join(', ');

  const fullAddressArr = [
    address.line1,
    address.line2,
    cityStatePostal,
    address.country,
  ].filter(Boolean);

  return fullAddressArr.join('\n');
};
