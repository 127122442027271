import axios, { AxiosRequestConfig } from 'axios';
import { AccountMetadataResult } from '../types/AccountMetadataResult';
import { AccountFragments } from '../types/AccountFragments';
import { isEmpty, isNil, omitBy } from 'lodash';

let metricsApiBaseUrl = '';

const getClient = (authToken: string, config?: AxiosRequestConfig) =>
  axios.create({
    baseURL: metricsApiBaseUrl,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    ...config,
  });

export const setUrl = (newUrl: string) => (metricsApiBaseUrl = newUrl);

export const listAccountMetaData = async (authToken: string): Promise<AccountMetadataResult[]> => {
  const accountMetaDataListResponse = await getClient(authToken).get(`${metricsApiBaseUrl}/account/metadata/list`);
  return accountMetaDataListResponse.data;
};

export const getAccountFragments = async (authToken: string, companyId: string): Promise<AccountFragments[]> => {
  const { data } = await getClient(authToken).get(`${metricsApiBaseUrl}/company/${companyId}/account-fragments`);
  return data;
};

export const getActionsSum = async (
  authToken: string,
  companyId: string,
  accountFragments: AccountFragments,
  useOriginalCurrency: boolean = false,
  startDate?: number,
  endDate?: number,
  eventType?: string,
): Promise<number> => {
  const { data } = await getClient(authToken).get(`${metricsApiBaseUrl}/company/${companyId}/actions/sum`, {
    params: {
      ...omitBy(accountFragments, (f) => isEmpty(f) || isNil(f)),
      useOriginalCurrency,
      startDate,
      endDate,
      eventType,
    },
  });
  return data;
};

export const fetchAffectingBusinessEventTypesV2 = async (
  authToken: string,
  companyId: string,
  accountFragments: AccountFragments,
  startDate?: number,
  endDate?: number,
) => {
  if (!metricsApiBaseUrl.length) return [];

  const { data } = await getClient(authToken).get(`${metricsApiBaseUrl}/company/${companyId}/actions/event-types`, {
    params: { ...omitBy(accountFragments, (f) => isEmpty(f) || isNil(f)), startDate, endDate },
  });

  return data;
};
