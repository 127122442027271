import { ClassificationTag, Merchant } from '../../services/FinaloopGql';

export type AccountingType = 'Cash' | 'Accrual' | 'Both' | 'Different';
export const AccountingTypes: AccountingType[] = ['Both', 'Cash', 'Accrual', 'Different'];

export type BusinessEvent = {
  businessEventId: string;
  rawEvent: FixbooksEventRequest;
  isSynced: boolean;
  tags: Record<string, any>;
};

export type FixbooksAction = {
  accountContext: string;
  accountRole: string;
  amount: number;
  sign: 'debit' | 'credit';
  description?: string;
  classificationTag?: ClassificationTag;
};

export type FixbooksEventRequest = {
  companyId: string;
  eventId: string;
  eventTimeMs: number;
  memo?: string;
  context?: string;
  merchant?: Pick<Merchant, 'id' | '__typename' | 'name'>;
  cashActions: FixbooksAction[];
  accrualActions: FixbooksAction[];
  classificationTagId?: string;
};
