import axios from 'axios';
import { Bill } from '../types/Bill';
import { BillPayment } from '../types/BillPayment';
import { BillToPaymentRelation } from '../types/BillToPaymentRelation';

let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export const getBills = async (
  companyId: string,
  getToken: () => Promise<string>,
): Promise<(Bill | BillPayment | BillToPaymentRelation)[] | undefined> => {
  const authToken = await getToken();
  if (!url.length) {
    throw new Error('Url is not set');
  }

  if (!authToken) {
    return;
  }

  const response = await axios.get<any>(`${url}/company/${companyId}/bills`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data;
};
