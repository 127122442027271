import styled from '@emotion/styled';
import { IdentifiedIntegrationConnectionMissionPayload, MissionType } from '@finaloop/mission-types';
import { TextField, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useMissionsApiClient } from '../../common/hooks/useMissionsApiClient';
import { capitalize } from 'lodash';

const Container = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  gap: 10px;

  h1 {
    font-size: 20px;
  }

  h3 {
    font-size: 14px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const MissionPreviewContainer = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 15px;
`;

export const IdentifiedIntegrationConnectionMission = () => {
  const [transactionId, setTransactionId] = useState<string>('');
  const [missionPayload, setMissionPayload] = useState<IdentifiedIntegrationConnectionMissionPayload | undefined>();
  const [transactionCompanyId, setTransactionCompanyId] = useState<string | undefined>();
  const { useGetIdentifiedIntegrationConnectionMissionPayload, useTriggerMission } = useMissionsApiClient();

  const {
    getPayload,
    isLoading: isGettingPayload,
    isError: hadErrorGettingPayload,
    error: errorGettingPayload,
  } = useGetIdentifiedIntegrationConnectionMissionPayload();

  const {
    triggerMission,
    isLoading: isCreatingTheMission,
    isError: hadErrorCreatingTheMission,
    isSuccess,
    error: errorCreatingTheMission,
  } = useTriggerMission();

  const onGetMissionPreviewClicked = useCallback(async () => {
    if (!transactionId) return;
    setMissionPayload(undefined);
    setTransactionCompanyId(undefined);
    const { payload, companyId } = await getPayload({ transactionId });
    setTransactionCompanyId(companyId);
    setMissionPayload(payload);
  }, [getPayload, transactionId]);

  const approveAndTriggerMissionClicked = useCallback(async () => {
    await triggerMission({
      payload: missionPayload!,
      companyId: transactionCompanyId!,
      type: MissionType.IdentifiedIntegrationConnection,
    });
  }, [triggerMission, missionPayload, transactionCompanyId]);

  return (
    <Container>
      <h1>Trigger a new identified integration connection mission</h1>
      <h3>Transaction in which we identified the integration to connect</h3>
      <InputContainer>
        <TextField
          name="transaction-id"
          fullWidth
          label=""
          variant="outlined"
          onChange={(e) => setTransactionId(e.target.value)}
          value={transactionId}
        />
        <Button disabled={!transactionId || isGettingPayload} onClick={onGetMissionPreviewClicked}>
          get mission preview
        </Button>
        {hadErrorGettingPayload && <>Failed to get mission preview. {errorGettingPayload}</>}
      </InputContainer>
      {isGettingPayload ? (
        <>Loading...</>
      ) : (
        missionPayload && (
          <div>
            Mission Preview:
            <MissionPreviewContainer>{getDescriptionComponent(missionPayload)}</MissionPreviewContainer>
            <Button
              disabled={!missionPayload || isCreatingTheMission || isSuccess}
              onClick={approveAndTriggerMissionClicked}
            >
              Approve and trigger the mission
            </Button>
            {isCreatingTheMission && <>Creating the mission...</>}
            {hadErrorCreatingTheMission && (
              <>Something went wrong. The mission was not created. {errorCreatingTheMission}</>
            )}
            {isSuccess && <>Mission created successfully !</>}
          </div>
        )
      )}
    </Container>
  );
};

const getDescriptionComponent = (missionPayload: IdentifiedIntegrationConnectionMissionPayload) => {
  const integrationType = capitalize(missionPayload.integrationType);
  const identifiedEvent = capitalize(missionPayload.identifiedEvent);

  return (
    <div>
      <label>
        {`We've identified some ${integrationType} ${identifiedEvent}s in your ${missionPayload.sourceAccount.institution.name} ${missionPayload.sourceAccount.mask} account.`}
        {`Please connect your ${integrationType} account to Finaloop, so your books will fully reflect all your business activity.`}
      </label>
    </div>
  );
};
