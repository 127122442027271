import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { useState } from 'react';
import { Company } from '../../../../../services/FinaloopGql';
import { useMissionsApiClient } from '../../../../common/hooks/useMissionsApiClient';
import { dateToNextMonthFirstDay } from '../../../shared';
import { Bank } from '../../../types';

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

const Summery = styled.div`
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;
`;

const SuccessText = styled.label`
  color: green;
`;

export const RecurringCsvMission = ({
  selectedBank,
  company,
}: {
  selectedBank: Bank;
  company: Company;
}) => {
  const [
    selectedStartingMonth,
    setSelectedStartingMonth,
  ] = useState<Moment | null>(null);

  const { useScheduleANewRecurringCsvMission } = useMissionsApiClient();
  const {
    isError: hasErrorSchedulingANewRecurringMission,
    isLoading: isSchedulingANewRecurringMission,
    isSuccess,
    schedule,
  } = useScheduleANewRecurringCsvMission();

  const onSaveClicked = () => {
    schedule({
      bankId: selectedBank!.id,
      companyId: company.id,
      initialMonthAtMs: selectedStartingMonth!.valueOf(),
    });
  };

  const canSave =
    !!selectedStartingMonth &&
    !!selectedBank &&
    !isSchedulingANewRecurringMission;

  return (
    <InputsContainer>
      <DatePicker
        inputFormat='yyyy-MM'
        views={['year', 'month']}
        label='Year and Month'
        value={selectedStartingMonth}
        onChange={setSelectedStartingMonth}
        renderInput={(params: any) => <TextField {...params} helperText={null} />}
      />
      {canSave && (
        <Summery>
          <label>
            {`Mission will be created automatically on the first day each month, asking for a CSV upload for the previous month.`}
            <br />
            {`For company: `}
            <b>{company.name}</b>
            {`, Bank: `}
            <b>{selectedBank?.fullName}</b>,
            <br />
            {`First month asking a CSV for: ${selectedStartingMonth?.format(
              'MM-yyyy'
            )}, will be created on ${dateToNextMonthFirstDay(
              selectedStartingMonth!
            )?.format('DD-MM-yyyy')}.`}
          </label>
        </Summery>
      )}
      <Button disabled={!canSave} onClick={onSaveClicked}>
        Schedule Recurring Missions
      </Button>
      {hasErrorSchedulingANewRecurringMission && <>Something went wrong.</>}
      {isSchedulingANewRecurringMission && <>Scheduling...</>}
      {isSuccess && (
        <SuccessText>Recurring Mission was created successfully!</SuccessText>
      )}
    </InputsContainer>
  );
};
