var UserProductData_possibleTypes = ['UserProductData'];
module.exports.isUserProductData = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserProductData"');
  return UserProductData_possibleTypes.includes(obj.__typename);
};

var Product_possibleTypes = ['ShopifyProduct'];
module.exports.isProduct = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProduct"');
  return Product_possibleTypes.includes(obj.__typename);
};

var ShopifyProduct_possibleTypes = ['ShopifyProduct'];
module.exports.isShopifyProduct = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyProduct"');
  return ShopifyProduct_possibleTypes.includes(obj.__typename);
};

var SoldProductInPeriod_possibleTypes = ['FinaloopSoldProductInPeriod', 'UserAdjustedSoldProductInPeriod'];
module.exports.isSoldProductInPeriod = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSoldProductInPeriod"');
  return SoldProductInPeriod_possibleTypes.includes(obj.__typename);
};

var FinaloopSoldProductInPeriod_possibleTypes = ['FinaloopSoldProductInPeriod'];
module.exports.isFinaloopSoldProductInPeriod = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFinaloopSoldProductInPeriod"');
  return FinaloopSoldProductInPeriod_possibleTypes.includes(obj.__typename);
};

var UserAdjustedSoldProductInPeriod_possibleTypes = ['UserAdjustedSoldProductInPeriod'];
module.exports.isUserAdjustedSoldProductInPeriod = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserAdjustedSoldProductInPeriod"');
  return UserAdjustedSoldProductInPeriod_possibleTypes.includes(obj.__typename);
};

var ProductSalesTimelineItem_possibleTypes = ['ProductSalesTimelineItem'];
module.exports.isProductSalesTimelineItem = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductSalesTimelineItem"');
  return ProductSalesTimelineItem_possibleTypes.includes(obj.__typename);
};

var ProductSalesTimeline_possibleTypes = ['ProductSalesTimeline'];
module.exports.isProductSalesTimeline = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductSalesTimeline"');
  return ProductSalesTimeline_possibleTypes.includes(obj.__typename);
};

var ValidationMismatchMetrics_possibleTypes = ['ValidationMismatchMetrics'];
module.exports.isValidationMismatchMetrics = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationMismatchMetrics"');
  return ValidationMismatchMetrics_possibleTypes.includes(obj.__typename);
};

var ValidationMismatchData_possibleTypes = ['ValidationMismatchData'];
module.exports.isValidationMismatchData = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationMismatchData"');
  return ValidationMismatchData_possibleTypes.includes(obj.__typename);
};

var ValidationDetails_possibleTypes = ['ValidationDetails'];
module.exports.isValidationDetails = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationDetails"');
  return ValidationDetails_possibleTypes.includes(obj.__typename);
};

var Company_possibleTypes = ['Company'];
module.exports.isCompany = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompany"');
  return Company_possibleTypes.includes(obj.__typename);
};

var Validation_possibleTypes = ['Validation'];
module.exports.isValidation = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidation"');
  return Validation_possibleTypes.includes(obj.__typename);
};

var Query_possibleTypes = ['Query'];
module.exports.isQuery = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"');
  return Query_possibleTypes.includes(obj.__typename);
};

var Mutation_possibleTypes = ['Mutation'];
module.exports.isMutation = function (obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMutation"');
  return Mutation_possibleTypes.includes(obj.__typename);
};
