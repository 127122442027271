import { useContext } from 'react';
import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { CompanyQuickbooksAccount } from '../../../types/CompanyQuickbooksAccount';
import { useQuery } from 'react-query';
export type UseGetCompanyQbAccounts = FetchHookState<CompanyQuickbooksAccount[]>;

export const GET_COMPANY_QB_ACCOUNTS_QUERY = 'get-company-qb-accounts';

export const useGetCompanyQbAccounts = (companyId: string) => {
  const services = useContext(environmentContext);
  return useQuery(GET_COMPANY_QB_ACCOUNTS_QUERY, () => services.CompanyQbAccountsApiClient.getAccounts(companyId));
};
