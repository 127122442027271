export enum LoanStatus {
  processing = 'processing',
  approved = 'approved',
  actionRequired = 'action required',
}

export enum LoanInitiator {
  finaloopTeam = 'finaloop-team',
  user = 'user',
}

export enum LoanDirection {
  taken = 'taken',
  given = 'given',
}

interface Loan {
  id: string;
  companyId: string;
  status: LoanStatus;
  lenderId: string;
  unlistedLenderName?: string;
  amount?: number;
  commencementDateMs?: number;
  comment: LoanComment | null;
  initiatedBy: LoanInitiator;
  createdAtMs: number;
  updatedAtMs: number;
  updatedBy: string;
  isDeleted?: boolean;
  includesRelatedParty?: boolean;
  loanDirection?: LoanDirection;
  isWithoutAgreements?: boolean;
}

export type LoanComment = {
  author: string;
  dateMs: number;
  text: string;
};

export enum LenderActionType {
  confirmInstructions = 'confirm instructions',
  seeAgreementInstructions = 'see agreement instructions',
  assignRole = 'assign role',
  uploadAgreement = 'upload agreement',
  connectIntegration = 'connect integration',
  uploadAnAmortizationSchedule = 'upload an amortization schedule',
  uploadBillingCycles = 'upload billing cycles',
}

export interface LenderAction<Type extends LenderActionType, Payload = any> {
  type: Type;
  payload?: Payload;
}

export type ConfirmInstructions = LenderAction<LenderActionType.confirmInstructions>;
export type SeeAgreementInstructions = LenderAction<LenderActionType.seeAgreementInstructions, { link: string }>;
export type AssignRole = LenderAction<LenderActionType.assignRole, { instructionsLink: string }>;
export type UploadAgreement = LenderAction<LenderActionType.uploadAgreement, { fileTypeDisplayName: string }>;
export type ConnectIntegration = LenderAction<LenderActionType.connectIntegration>;
export type UploadAnAmortizationSchedule = LenderAction<
  LenderActionType.uploadAnAmortizationSchedule,
  { withAskLender?: boolean }
>;
export type UploadBillingCycle = LenderAction<LenderActionType.uploadBillingCycles>;

export type RequiredAction =
  | ConfirmInstructions
  | SeeAgreementInstructions
  | AssignRole
  | UploadAgreement
  | ConnectIntegration
  | UploadAnAmortizationSchedule
  | UploadBillingCycle;

export interface LoanLender {
  id: string;
  name: string;
  logoUrl?: string;
  requiredActions: RequiredAction[];
}

export type LoanResponse = Loan & { agreementFileNames: string[]; companyName: string };
export type GetLoansResponse = { loans: LoanResponse[] };
export type UpsertLoanResponse = { loanId: string };

export type LoanToUpsert = Partial<Loan>;
