import styled from '@emotion/styled';
import { Button, Tooltip } from '@mui/material';
import moment from 'moment';
import { RecurringCsvMission } from '../../../../services/missionsApi';
import { useMissionsApiClient } from '../../../common/hooks/useMissionsApiClient';
import { useSelectedCompany } from '../../../common/hooks/useSelectedCompany';
import { dateToNextMonthFirstDay } from '../../shared';

export type ExistingCsvMissionsProps = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const MissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;
  width: 300px;

  h2 {
    font-size: 13px;
    color: gray;
    display: flex;
    gap: 5px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100px;
`;

const RecurringItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  label {
    width: 200px;
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const RecurringMission = (props: {
  recurringCsvMission: RecurringCsvMission;
}): JSX.Element => {
  const {
    bankAccount: { fullName },
    recurringMission: {
      initialMonthAtMs,
      createdBy,
      createdAtIso,
      isEnabled,
      bankId,
    },
  } = props.recurringCsvMission;
  const { useDeleteRecurringCsvMission } = useMissionsApiClient();
  const { selectedCompany } = useSelectedCompany();
  const {
    deleteRecurringCsvMission,
    isLoading,
    isSuccess,
  } = useDeleteRecurringCsvMission(selectedCompany?.id, bankId);

  return (
    <MissionContainer>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>Bank Name:</u>
          </h2>
        </TitleWrapper>
        <Tooltip title={fullName}>
          <label>{fullName}</label>
        </Tooltip>
      </RecurringItem>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>First Mission at:</u>
          </h2>
        </TitleWrapper>
        <label>
          {dateToNextMonthFirstDay(moment(initialMonthAtMs)).format(
            'DD-MM-yyyy'
          )}
        </label>
      </RecurringItem>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>Created By:</u>
          </h2>
        </TitleWrapper>
        <Tooltip title={createdBy}>
          <label>{createdBy}</label>
        </Tooltip>
      </RecurringItem>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>Created At:</u>
          </h2>
        </TitleWrapper>
        <Tooltip title={createdAtIso}>
          <label>{createdAtIso}</label>
        </Tooltip>
      </RecurringItem>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>is active:</u>
          </h2>
        </TitleWrapper>
        <Tooltip title={createdBy}>
          <label>{isEnabled?.toString?.()}</label>
        </Tooltip>
      </RecurringItem>
      <RecurringItem>
        <TitleWrapper>
          <h2>
            <u>bank id:</u>
          </h2>
        </TitleWrapper>
        <label>{bankId}</label>
      </RecurringItem>
      <Button
        disabled={isLoading || isSuccess}
        onClick={() => deleteRecurringCsvMission()}>
        {isSuccess ? 'Deleted' : 'Delete'}
      </Button>
    </MissionContainer>
  );
};

export const CurrentRecurringCsvMissions = ({}: ExistingCsvMissionsProps): JSX.Element => {
  const { useCompanyRecurringCsvMissions } = useMissionsApiClient();
  const { selectedCompany } = useSelectedCompany();

  const {
    recurringCsvMissions,
    isLoading: isLoadingRecurringCsvMissions,
    isError: hasErrorLoadingRecurringCsvMissions,
  } = useCompanyRecurringCsvMissions(selectedCompany?.id);

  const recurringMissionsToRender = recurringCsvMissions || [];

  return (
    <Container>
      <h1>
        Current Recurring CSV Missions:{' '}
        {hasErrorLoadingRecurringCsvMissions ? (
          <>Failed to get recurring missions</>
        ) : isLoadingRecurringCsvMissions ? (
          <>Loading...</>
        ) : (
          !recurringMissionsToRender.length && <>No recurring missions</>
        )}
      </h1>
      <MissionsContainer>
        {recurringMissionsToRender.map((recurringMission, index) => (
          <RecurringMission
            key={index}
            recurringCsvMission={recurringMission}
          />
        ))}
      </MissionsContainer>
    </Container>
  );
};
