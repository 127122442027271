import { getAuthToken } from '../../environmentContext/auth';
import { createClient } from './__generated__';
import { ulid } from 'ulid';

export * from './__generated__';

export const gqlClient = createClient({
  url: process.env.REACT_APP_GQL_FINALOOP_OVERRIDE || 'https://gql-internal.myfinaloop.com',
  batch: false,
  headers: async () => {
    const token = await getAuthToken();

    return {
      Authorization: `Bearer ${token}`,
      'x-correlation-id': ulid(),
      'x-request-initiator': 'backoffice-app',
    };
  },
});
