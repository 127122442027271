import { useContext, useEffect, useState } from 'react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { BalanceStatusForCompany } from '../../../types/BalanceStatusForCompany';
import { useAuth0 } from '@auth0/auth0-react';

export type UseFetchBalanceStatusForCompanyState = FetchHookState<BalanceStatusForCompany[]>;

const defaultState: UseFetchBalanceStatusForCompanyState = {
  data: [],
  loading: true,
};

export const useFetchBalanceStatuses = (integrationType?: string) => {
  const [fetchHookState, setFetchHookState] = useState<UseFetchBalanceStatusForCompanyState>(defaultState);
  const { getAccessTokenSilently } = useAuth0();

  const services = useContext(environmentContext);
  useEffect(() => {
    if (!integrationType) return;
    setFetchHookState(defaultState);
    getAccessTokenSilently().then(token => {
      let statusesPromise: Promise<BalanceStatusForCompany[]>;
      if (
        integrationType === 'shop_pay'
        // integrationType === "etsy" /* || integrationType === 'ebay'*/
      ) {
        statusesPromise = services.ValidationApiClient.fetchBalanceValidationStatusV2(integrationType, token);
      } else {
        statusesPromise = services.ValidationApiClient.fetchBalanceValidationStatus(integrationType, token);
      }
      return statusesPromise
        .then(data => {
          setFetchHookState({
            data,
            loading: false,
          });
        })
        .catch(error => {
          setFetchHookState({
            data: [],
            error,
            loading: false,
          });
        });
    });
  }, [services, integrationType, getAccessTokenSilently]);
  return fetchHookState;
};
