import axios from 'axios';
import { Company } from '../types/Company';

let url = '';

export const setUrl = (newUrl: string) => {
  url = newUrl;
};
interface ListCompaniesResponse {
  items: Company[];
  cursor?: string;
}

export const listCompanies = async () => {
  if (!url?.length) {
    return [];
  }
  const result = await axios.get<ListCompaniesResponse>(`${url}/companies`);
  return result.data.items;
};

export const fetchCompany = async (companyId: string): Promise<Company | undefined> => {
  if (!url.length) {
    return;
  }
  const result = await axios.get<Company>(`${url}/company/${companyId}`);
  return result.data;
};

export const updateCompany = async (
  companyId: string | undefined,
  updatedCompanyFields: Partial<Company>,
  getAccessTokenSilently: () => Promise<string>,
): Promise<Company | undefined> => {
  const authToken = await getAccessTokenSilently();

  if (!url.length || companyId == null) {
    return;
  }

  const notUpdatingAnyFields = Object.keys(updatedCompanyFields || {}).length === 0;
  if (notUpdatingAnyFields) {
    return;
  }

  const result = await axios.put<Company>(
    `${url}/company`,
    { ...updatedCompanyFields, id: companyId },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
  return result.data;
};
