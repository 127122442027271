import styled from '@emotion/styled';
import { Add, Delete } from '@mui/icons-material';
import { Badge, Fab, IconButton, Input, Tooltip } from '@mui/material';
import { SetStateAction, useCallback, useEffect } from 'react';
import { Selector } from '../../../common/Selector';
import { useAccountRoles } from '../../hooks/useAccountRoles';
import { AccountingType, FixbooksAction } from '../../types';
import { accountingTypeToColor, getEmptyAction } from '../../utils';
import { useAccountActionsValidation } from './useAccountActionsValidation';

type EventEditorProps = {
  actionsToEdit: FixbooksAction[];
  editActions: (edit: SetStateAction<FixbooksAction[]>) => void;
  onValidationChanged: (isValid: boolean) => void;
  type: AccountingType;
};

const FlexBase = styled.div`
  display: flex;
  gap: 10px;
`;

const HorizontalFlex = styled(FlexBase)`
  flex-direction: row;
  align-items: center;
`;

const InputsWrapper = styled(HorizontalFlex)`
  align-items: flex-end;
`;

const VerticalFlex = styled(FlexBase)`
  flex-direction: column;
`;

const Container = styled(VerticalFlex)`
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 10px;
`;

const ActionsWrapper = styled(VerticalFlex)``;

const TableWrapper = styled(VerticalFlex)``;

const RowsDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BaseTextWrapper = styled(HorizontalFlex)`
  word-break: break-word;
  width: 250px;
  flex-shrink: 0;
  margin-right: 10px;

  &:last-of-type {
    margin: 0;
  }
`;

const ActionWrapper = styled(BaseTextWrapper)`
  width: 100px;
`;

const RoleTextWrapper = styled(BaseTextWrapper)``;

const RoleSelectionItem = styled(HorizontalFlex)`
  padding: 10px;
  align-items: center;
`;

const SignTextWrapper = styled(BaseTextWrapper)`
  width: 100px;
`;

const DescriptionTextWrapper = styled(BaseTextWrapper)`
  flex: 1;
  width: 100%;
`;

const DetailTitles = styled(HorizontalFlex)`
  font-size: 13px;
  color: gray;
  align-items: center;
`;

export const EventEditor = (props: EventEditorProps): JSX.Element => {
  const { actionsToEdit, editActions, onValidationChanged, type } = props;
  const { roles } = useAccountRoles();

  const onAddRowClicked = useCallback(() => editActions(actions => [...actions, getEmptyAction()]), [editActions]);

  const onDeleteActionClicked = useCallback(
    (actionIndex: number) => {
      editActions(actions => actions.filter((_, index) => index !== actionIndex));
    },
    [editActions],
  );

  const onDebitOrCreditChanged = useCallback(
    (sign: 'credit' | 'debit', value: number, actionIndex: number) => {
      editActions(actions => {
        actions[actionIndex].amount = value;
        actions[actionIndex].sign = sign;
        return [...actions];
      });
    },
    [editActions],
  );

  const onSelectAccountRole = useCallback(
    (role: string, actionIndex: number) => {
      editActions(actions => {
        actions[actionIndex].accountRole = role;
        return [...actions];
      });
    },
    [editActions],
  );

  const onDescriptionChanged = useCallback(
    (description: string, actionIndex: number) => {
      editActions(actions => {
        actions[actionIndex].description = description;
        return [...actions];
      });
    },
    [editActions],
  );

  const { isValid, renderSummaryRow } = useAccountActionsValidation(actionsToEdit);

  useEffect(() => {
    onValidationChanged(isValid && actionsToEdit.length > 0 && !actionsToEdit.some(_ => !_.accountRole));
  }, [isValid, actionsToEdit]);

  return (
    <Container>
      <TableWrapper>
        <DetailTitles>
          <RoleTextWrapper>Role</RoleTextWrapper>
          <SignTextWrapper>Debit</SignTextWrapper>
          <SignTextWrapper>Credit</SignTextWrapper>
          <DescriptionTextWrapper>Description</DescriptionTextWrapper>
          {actionsToEdit.length > 2 && <ActionWrapper>Delete Row</ActionWrapper>}
        </DetailTitles>
        <ActionsWrapper>
          {actionsToEdit.map((action, actionIndex) => (
            <InputsWrapper key={actionIndex}>
              <RoleTextWrapper>
                <Selector
                  value={action.accountRole}
                  initialValue={action.accountRole || ''}
                  data={roles}
                  onRenderOption={(props, option) => (
                    <RoleSelectionItem {...(props as any)}>{option}</RoleSelectionItem>
                  )}
                  onSelect={_ => onSelectAccountRole(_, actionIndex)}
                />
              </RoleTextWrapper>
              <SignTextWrapper>
                <Input
                  type={'number'}
                  placeholder={'0.00'}
                  value={action.sign === 'debit' ? action.amount : 0}
                  onChange={args =>
                    onDebitOrCreditChanged('debit', parseFloat(parseFloat(args.target.value).toFixed(2)), actionIndex)
                  }
                />
              </SignTextWrapper>
              <SignTextWrapper>
                <Input
                  type={'number'}
                  placeholder={'0.00'}
                  value={action.sign === 'credit' ? action.amount : 0}
                  onChange={args =>
                    onDebitOrCreditChanged('credit', parseFloat(parseFloat(args.target.value).toFixed(2)), actionIndex)
                  }
                />
              </SignTextWrapper>
              <DescriptionTextWrapper>
                <Input
                  sx={{ width: '100%' }}
                  placeholder={'description'}
                  value={action.description || ''}
                  onChange={args => onDescriptionChanged(args.target.value, actionIndex)}
                />
              </DescriptionTextWrapper>
              <ActionWrapper>
                {actionsToEdit.length > 2 && (
                  <Tooltip title="Delete" arrow>
                    <IconButton onClick={() => onDeleteActionClicked(actionIndex)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </ActionWrapper>
            </InputsWrapper>
          ))}
          <RowsDetails>
            <HorizontalFlex>
              Accounting Type:
              <Badge sx={{ backgroundColor: accountingTypeToColor[type], padding: '5px 10px', borderRadius: '5px' }}>
                <label> {type === 'Both' ? 'Both Cash and Accrual' : type}</label>
              </Badge>
              {renderSummaryRow()}
            </HorizontalFlex>
            <Tooltip title="Add row" arrow>
              <Fab color="primary" aria-label="add" onClick={onAddRowClicked} size="small">
                <Add />
              </Fab>
            </Tooltip>
          </RowsDetails>
        </ActionsWrapper>
      </TableWrapper>
    </Container>
  );
};
