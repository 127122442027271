import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { AccountingType, BusinessEvent } from '../types';

export const getEventAccountingType = (event: BusinessEvent): AccountingType => {
  const { cashActions, accrualActions } = event.rawEvent;

  const areIdentical = isEqual(cashActions, accrualActions);
  if (areIdentical) return 'Both';

  if (cashActions?.length < 1) return 'Accrual';
  if (accrualActions?.length < 1) return 'Cash';
  return 'Different';
};

export const useAccountingTypeFinder = () => {
  const getAccountingType = useCallback(getEventAccountingType, []);

  return { getAccountingType };
};
