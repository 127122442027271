import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { MetricsValidationStatusForCompany, ValidationStatus } from '../../types/MetricsValidationStatusForCompany';
import { useFetchMetricsValidationStatus } from '../common/hooks/useFetchMetricsStatus';
import ReactJson from 'react-json-view';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState, useMemo } from 'react';
import { keyBy } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useTriggerCompanyValidationMutation } from '../common/hooks/useTriggerComapnyValidation';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { styled } from '@mui/material';
import { useListCompaniesName } from '../common/hooks/useListCompaniesName';
import { Company } from '../../services/FinaloopGql';

const ToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'black',
    backgroundColor: 'LightGrey',
  },
});
interface ReportsTableProps {
  reports: MetricsValidationStatusForCompany[];
  companies: Required<Pick<Company, 'id' | 'name'>>[];
  triggerCompanyValidation: UseMutationResult<
    never[] | AxiosResponse<any, any>,
    unknown,
    CompanyIntegrationInfo,
    unknown
  >;
}

interface CompanyIntegrationInfo {
  companyId: string;
  integrationAccountId: string;
}

interface ReportTableRowProps {
  report: MetricsValidationStatusForCompany;
  companyName: string;
  rowIndex: number;
  triggerCompanyValidation: UseMutationResult<
    never[] | AxiosResponse<any, any>,
    unknown,
    CompanyIntegrationInfo,
    unknown
  >;
}

enum Version {
  V1 = 'v1',
  V2 = 'v2',
}

const ReportsTableRow = ({ report, companyName, rowIndex, triggerCompanyValidation }: ReportTableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{rowIndex}</TableCell>
        <TableCell component="th" scope="row">
          {companyName}
          {report.errMsg != null && (
            <>
              <br />
              <span style={{ fontSize: 12, color: 'red' }}>{report.errMsg}</span>
            </>
          )}
        </TableCell>
        <TableCell>{report.companyId}</TableCell>
        <TableCell>{report.integrationAccountId}</TableCell>
        <TableCell>{report.createdAt}</TableCell>
        <TableCell>{report.endTime}</TableCell>
        <TableCell>
          {report.status === ValidationStatus.SUCCESS ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : (
            <ErrorOutlineOutlinedIcon color="error" />
          )}
        </TableCell>
        <TableCell>{report.currentValues?.shopifyValues?.net}</TableCell>
        <TableCell>{report.currentValues?.finaloopValues?.net}</TableCell>
        <TableCell>{report.currentValues?.gap}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              triggerCompanyValidation.mutate({
                companyId: report.companyId,
                integrationAccountId: report.integrationAccountId,
              })
            }
          >
            <RefreshIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <ReactJson
              collapsed={1}
              src={{
                currentValues: report.currentValues,
                firstGapValues: report.firstGapValues,
                salesChannelByStatus: report.salesChannelByStatus,
              }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ReportsTable = ({ reports, companies, triggerCompanyValidation }: ReportsTableProps) => {
  const [sorter, setSorter] = useState(() => (a: any, b: any) => a.companyName?.localeCompare(b.companyName));
  const [sortDirection, setSortDirection] = useState(true);
  const [currentVersion, setCurrentVersion] = useState(Version.V1);

  const companyById = useMemo(() => {
    if (!companies) {
      return {};
    }
    return keyBy(companies, 'id');
  }, [companies]);

  const getNumberSorterFunc = (extractProperty: (a: any) => number) => {
    return (a: any, b: any) => ((extractProperty(a) ?? -Infinity) > (extractProperty(b) ?? -Infinity) ? 1 : -1);
  };

  const getStringSorterFunc = (extractProperty: (a: any) => string) => {
    return (a: any, b: any) => (extractProperty(a) || '').localeCompare(extractProperty(b) || '');
  };

  const sortedReports = useMemo(() => {
    console.info('currentVersion', currentVersion);
    const filteredReport = reports.filter((report) => !report.version || report.version === currentVersion);
    if (!filteredReport) {
      return [];
    }
    const extendedReports = filteredReport.map((report) => ({
      ...report,
      companyName: companyById[report.companyId]?.name,
    }));
    const sortReportsInit = extendedReports.sort(sorter);
    return sortDirection ? sortReportsInit : sortReportsInit.reverse();
  }, [reports, sorter, sortDirection, companyById, currentVersion]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={currentVersion}
        exclusive
        onChange={(event, newVersion) => {
          console.info('newVersion', newVersion);
          setCurrentVersion(newVersion);
        }}
        aria-label="Platform"
      >
        <ToggleButton value={Version.V1}>{Version.V1}</ToggleButton>
        <ToggleButton value={Version.V2}>{Version.V2}</ToggleButton>
      </ToggleButtonGroup>
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>#</TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getStringSorterFunc((a: any) => a.companyName));
                }}
              >
                Company
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getStringSorterFunc((a: any) => a.companyId));
                }}
              >
                CompanyId
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getStringSorterFunc((a: any) => a.integrationAccountId));
                }}
              >
                Shop Name
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getStringSorterFunc((a: any) => a.createdAt));
                }}
              >
                Created At
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getStringSorterFunc((a: any) => a.endTime));
                }}
              >
                End Time
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => (a: any, b: any) => a.status.localeCompare(b.status));
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getNumberSorterFunc((a) => a.currentValues?.shopifyValues?.net));
                }}
              >
                Shopify Net
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getNumberSorterFunc((a) => a.currentValues?.finaloopValues?.net));
                }}
              >
                Finaloop Net
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSortDirection(!sortDirection);
                  setSorter(() => getNumberSorterFunc((a) => a.currentValues?.gap));
                }}
              >
                Gap
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedReports.map((report, index) => {
              const company = companyById[report.companyId];
              if (!company) return null;

              return (
                <ReportsTableRow
                  triggerCompanyValidation={triggerCompanyValidation}
                  rowIndex={index}
                  key={`${report.companyId}-${report.integrationAccountId}`}
                  report={report}
                  companyName={company.name}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const MetricsValidation = () => {
  const { integrationType } = useParams();
  const listCompanies = useListCompaniesName();

  const metricsValidationStatus = useFetchMetricsValidationStatus(integrationType);
  const triggerCompanyValidation = useTriggerCompanyValidationMutation();
  if (listCompanies.isLoading || metricsValidationStatus.loading) {
    return <>Loading...</>;
  }

  return (
    <ReportsTable
      reports={metricsValidationStatus.data}
      companies={listCompanies.data || []}
      triggerCompanyValidation={triggerCompanyValidation}
    />
  );
};
