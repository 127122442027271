import { AnyMission, IdentifiedBankConnectionPayload, MissionType } from '@finaloop/mission-types';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

const getUrl = (env: string) => {
  const urlSuffix = env === 'prod' ? '' : `-${env}`;
  return `https://api3${urlSuffix}.finaloop.com`;
};

type BaseParams = {
  authToken: string;
  env: string;
};

const getClient = ({
  env,
  authToken,
  config,
}: BaseParams & {
  config?: AxiosRequestConfig;
}) =>
  axios.create({
    baseURL: getUrl(env),
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    ...config,
  });

export type GetPayloadParams = BaseParams & {
  transactionId: string;
};

export type MissionPayload = { payload: any; companyId: string };

export const getIdentifiedBankConnectionMissionPayload = ({
  authToken,
  env,
  transactionId,
}: GetPayloadParams): Promise<MissionPayload & { hasMoreMissionsForThisBank: boolean }> => {
  return getMissionPayload(
    '/missions/identified-bank-connection-mission-payload-creator',
    authToken,
    env,
    transactionId,
  );
};

export const getIdentifiedIntegrationConnectionMissionPayload = ({
  authToken,
  env,
  transactionId,
}: GetPayloadParams): Promise<MissionPayload> => {
  return getMissionPayload(
    '/missions/identified-integration-connection-mission-payload-creator',
    authToken,
    env,
    transactionId,
  );
};

const getMissionPayload = async <T = MissionPayload>(
  url: string,
  authToken: string,
  env: string,
  transactionId: string,
): Promise<T> => {
  const body = { transactionId };
  const { data } = await axiosRequestWithAxiosErrorCatch(() => getClient({ authToken, env }).post<T>(url, body));
  return data;
};

export type TriggerMissionParams = BaseParams & {
  type: MissionType;
  companyId: string;
  payload: any;
  createdBy: string;
};

export const triggerMission = async ({
  authToken,
  env,
  ...rest
}: TriggerMissionParams): Promise<IdentifiedBankConnectionPayload> => {
  const { data } = await axiosRequestWithAxiosErrorCatch(() => {
    console.log('creating a new mission', rest);
    return getClient({ authToken, env }).post<IdentifiedBankConnectionPayload>('/missions', rest);
  });
  return data;
};

const axiosRequestWithAxiosErrorCatch = async <T = any>(action: () => Promise<T>): Promise<T> => {
  try {
    return await action();
  } catch (error) {
    const axiosError = error as AxiosError;
    if (!axiosError.isAxiosError) throw error;
    throw axiosError.response?.data;
  }
};

export type GetCompanyMissionsParams = BaseParams & {
  type?: MissionType;
  companyId: string;
};

export type CompanyMissions<T> = { missions: T[] };

export const getCompanyMissions = async <T>({
  authToken,
  env,
  type,
  companyId,
}: GetCompanyMissionsParams): Promise<CompanyMissions<T>> => {
  const { data } = await axiosRequestWithAxiosErrorCatch(() =>
    getClient({ authToken, env }).get<CompanyMissions<T>>('/missions', {
      params: { type, companyId },
    }),
  );

  return data;
};

export type NewRecurringCsvMissionParams = BaseParams & {
  bankId: string;
  initialMonthAtMs: number;
  companyId: string;
  createdBy: string;
};

export const newRecurringCsvMission = async ({
  authToken,
  env,
  bankId,
  initialMonthAtMs,
  companyId,
  createdBy,
}: NewRecurringCsvMissionParams): Promise<void> => {
  await axiosRequestWithAxiosErrorCatch(() =>
    getClient({ authToken, env }).post<IdentifiedBankConnectionPayload>('/missions/recurring/csv', {
      companyId,
      bankId,
      initialMonthAtMs,
      createdBy,
    }),
  );
};

export type RecurringCsvMission = {
  recurringMission: {
    initialMonthAtMs: number;
    createdBy: string;
    isEnabled: boolean;
    bankId: string;
    createdAtIso: string;
  };
  bankAccount: {
    fullName: string;
  };
};
export type GetRecurringCsvMissionParams = BaseParams & {
  companyId: string;
};
export const getRecurringCsvMissions = async ({
  authToken,
  env,
  companyId,
}: GetRecurringCsvMissionParams): Promise<RecurringCsvMission[]> => {
  return await axiosRequestWithAxiosErrorCatch<RecurringCsvMission[]>(async () => {
    const { data } = await getClient({ authToken, env }).get<RecurringCsvMission[]>('/missions/recurring/csv', {
      params: { companyId },
    });

    return data;
  });
};

export type EditMissionParams = BaseParams & {
  mission: AnyMission;
  updatedBy: string;
};

export const editMission = async ({ authToken, env, mission, updatedBy }: EditMissionParams): Promise<void> => {
  await axiosRequestWithAxiosErrorCatch(() =>
    getClient({ authToken, env }).put('/missions', { ...mission, updatedBy }),
  );
};

export type DeleteMissionParams = BaseParams & {
  missionId: string;
};

export const deleteMission = async ({ authToken, env, missionId }: DeleteMissionParams): Promise<void> => {
  await axiosRequestWithAxiosErrorCatch(() => getClient({ authToken, env }).delete(`/missions/${missionId}`));
};

export const deleteRecurringCsvMission = async ({
  authToken,
  env,
  bankId,
  companyId,
}: BaseParams & { bankId: string; companyId: string }): Promise<void> => {
  await axiosRequestWithAxiosErrorCatch(() =>
    getClient({ authToken, env }).delete(`/missions/recurring/csv/${companyId}/${bankId}`),
  );
};

export const createBalanceBankStatementMission = async ({
  authToken,
  env,
  companyId,
  type,
  fromDateString,
  toDateString,
}: BaseParams & {
  companyId: string;
  type: string;
  fromDateString: string;
  toDateString: string;
}): Promise<void> => {
  const body = {
    companyId,
    type,
    fromDateString,
    toDateString,
  };
  await axiosRequestWithAxiosErrorCatch(() =>
    getClient({ authToken, env }).post(`/missions/balance-bank-statement`, body),
  );
};
