import { useGetCompanyBills } from '../common/hooks/useGetCompanyBills';
import { fromCents, toCents } from '../common/money';
import { BillBalanceTable } from './BillBalanceTable';
import { Loader } from './Loader';
import { formatCurrency } from '../common/formatCurrency';
import { getBillsBalanceForDate } from './getBillsBalanceForDate';

export const CompanyBillsBalanceView = ({ companyId, date }: { companyId: string; date: number }) => {
  const { data, isLoading } = useGetCompanyBills(companyId);

  if (isLoading || data === undefined)
    return (
      <div className="py-5">
        <Loader variant="big" />
      </div>
    );

  if (data.length === 0) {
    return (
      <div className="mt-8">
        <h1 className="bg-gray-50 px-6 py-3 text-left font-medium text-gray-500 tracking-wider w-full">
          This company doesn't have bills in our system yet.
        </h1>
      </div>
    );
  }

  const items = getBillsBalanceForDate(data, date);
  const balanceDue = fromCents(items.reduce((pv, cv) => pv + toCents(cv.amount), 0));

  return (
    <div className="mt-8">
      <h1 className="bg-gray-50 px-6 py-3 text-left font-medium text-gray-500 tracking-wider w-full">
        Balance: {formatCurrency(balanceDue)}
      </h1>
      <BillBalanceTable data={items} />
    </div>
  );
};
