import axios from 'axios';

import { encode } from './apiClientUtils';
import { BusinessEventParams } from '../types/BusinessEventParams';
import { AccountFragments } from '../types/AccountFragments';

let url = '';

export interface FetchBusinessEventsParams {
  authToken?: string;
  companyId: string;
  accountFragments?: AccountFragments;
  eventType?: string;
  startKey?: string;
  pageSize?: number;
  page?: number;
  startTime?: number;
  endTime?: number;
}
export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchBusinessEventDetails = async (businessEventId: string, authToken: string) => {
  if (!url.length) {
    return;
  }
  const businessEventDetailsResponse = await axios.get(`${url}/business-events/${businessEventId}/inspect`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return businessEventDetailsResponse.data;
};

export const fetchBusinessEvents = async (params: FetchBusinessEventsParams) => {
  if (params.accountFragments) {
    return await fetchBusinessEventsByAccountFragments(params);
  }
  return [];
};

export const fetchBusinessEventsByAccountFragments = async ({
  authToken,
  startTime,
  endTime,
  companyId,
  eventType,
  accountFragments,
  pageSize,
  page,
}: FetchBusinessEventsParams) => {
  if (!accountFragments) {
    throw new Error('accountFragments is required');
  }
  const query = new URL(`${url}/business-actions`);
  Object.keys(accountFragments).forEach((key) => {
    const typedKey = key as keyof AccountFragments;
    if (typeof accountFragments[typedKey] === 'string') {
      query.searchParams.append(key, (accountFragments[typedKey] as string) || '');
    } else if (typeof accountFragments[typedKey] === 'object') {
      query.searchParams.append(key, accountFragments[typedKey] ? JSON.stringify(accountFragments[typedKey]) : '');
    }
  });

  query.searchParams.append('companyId', companyId);
  query.searchParams.append('eventType', eventType || '');
  query.searchParams.append('startDate', startTime?.toString() || '');
  query.searchParams.append('endDate', endTime?.toString() || '');
  query.searchParams.append('pageSize', pageSize?.toString() || '');
  query.searchParams.append('page', page?.toString() || '');
  const emptyParamsToDelete: string[] = [];
  for (const [key, value] of query.searchParams.entries()) {
    if (!value) {
      emptyParamsToDelete.push(key);
    }
  }
  emptyParamsToDelete.forEach((key) => query.searchParams.delete(key));

  const businessEventsResponse = await axios.get(query.toString(), {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return businessEventsResponse.data.map((be: any) => {
    return {
      ...be,
      type: be.eventType,
      source: be.integrationType,
    };
  });
};

export const fetchBusinessEventsByFinaloopSubjectId = async (finaloopSubjectId: string, authToken: string) => {
  const businessEventsResponse = await axios.get(`${url}/business-events/finaloop-subject/${finaloopSubjectId}/list`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return businessEventsResponse.data;
};
