import { useCallback, useContext, useState } from 'react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useAuth0 } from '@auth0/auth0-react';

export type useFetchCompanyOnboardingState = FetchHookState<any>;

const defaultState: useFetchCompanyOnboardingState = {
  data: {},
  loading: false,
};

export const useCompanyOnboardingStateClient = (companyId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [fetchHookState, setFetchHookState] = useState<useFetchCompanyOnboardingState>(defaultState);
  const services = useContext(environmentContext);

  const fetchOnboardingStateForCompany = useCallback(() => {
    if (!companyId) {
      throw new Error('missing company ID');
    }
    setFetchHookState(defaultState);
    return services.CompanyOnboardingStateClient.getOnboardingStateForCompany(companyId, getAccessTokenSilently).then(
      (data: any) => setFetchHookState({ data, loading: false }),
    );
  }, [companyId, services, getAccessTokenSilently]);

  const updateOnboardingStateForCompany = useCallback(
    (newState: any) => {
      if (!companyId) {
        throw new Error('missing company ID');
      }
      setFetchHookState((state) => ({ ...state, loading: true }));
      return services.CompanyOnboardingStateClient.setOnboardingStateForCompany(
        companyId,
        newState,
        getAccessTokenSilently,
      ).finally(() => setFetchHookState((state) => ({ ...state, loading: false })));
    },
    [companyId, services, getAccessTokenSilently],
  );

  return { ...fetchHookState, fetchOnboardingStateForCompany, updateOnboardingStateForCompany };
};
