import { useContext, useEffect, useState } from 'react';
import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { AccountMetadataResult } from '../../../types/AccountMetadataResult';
import { useAuth0 } from '@auth0/auth0-react';

export type UseListAccountMetaData = FetchHookState<AccountMetadataResult[]>;

const defaultState: UseListAccountMetaData = {
  data: [],
  loading: true,
};

export const useListAccountMetaData = (): UseListAccountMetaData => {
  const [fetchHookState, setFetchHookState] = useState<UseListAccountMetaData>(defaultState);
  const services = useContext(environmentContext);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setFetchHookState(defaultState);
    getAccessTokenSilently()
      .then((token) => services.MetricsApiClient.listAccountMetaData(token))
      .then((data) => setFetchHookState({ data, loading: false }))
      .catch((error) => setFetchHookState({ data: [], error, loading: false }));
  }, [services, getAccessTokenSilently]);

  return fetchHookState;
};
