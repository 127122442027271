import { Selector } from '../common/Selector';
import { AccountFragments } from '../../types/AccountFragments';
import { camelCase } from 'lodash';
interface MetricFragmentSelectProps {
  companyId?: string;
  fragmentName: keyof AccountFragments;
  onSelected: any;
  accountFragmentsList: AccountFragments[];
  isLoading: boolean;
}

export const MetricFragmentSelect = ({
  companyId,
  fragmentName,
  onSelected,
  accountFragmentsList,
  isLoading,
}: MetricFragmentSelectProps) => {
  const currentFragmentPossibleValues = accountFragmentsList.map((fragment) => fragment[fragmentName]).filter(Boolean);

  return (
    <Selector
      disabled={!companyId || !currentFragmentPossibleValues.length}
      data={currentFragmentPossibleValues}
      title={camelCase(fragmentName)}
      onSelect={onSelected}
      labelExtractor={(e) => {
        return e || '';
      }}
      loading={isLoading}
    />
  );
};
