import styled from '@emotion/styled';
import { Button, Divider, Switch } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { AddANewLoan } from './AddANewLoan';
import { LoanComponent } from './LoanComponent';
import { useLoans } from '../hooks/useLoans';
import { LoanStatus } from '../types';
import { lenders } from '../services/lenders-provider';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
`;

const LoansByStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 16px;
    font-weight: bold;
  }
`;

const LoansContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 300px;
  align-items: center;
  select {
    width: 100%;
  }
`;

const emptyFilter = (_: any) => _;
const ALL_FILTER_SELECT_OPTION = 'All';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const LoansPage = () => {
  const { useGetLoans } = useLoans();
  const { isError, isLoading, loans, refetch } = useGetLoans();
  const [statusFilter, setStatusFilter] = useState<string>(ALL_FILTER_SELECT_OPTION);
  const [lenderNameFilter, setLenderNameFilter] = useState<string>(ALL_FILTER_SELECT_OPTION);
  const { selectedCompany } = useSelectedCompany();
  const [showOnlyLoansWithFiles, setShowOnlyLoansWithFiles] = useState(false);

  const filteredLoans = useMemo(() => {
    return loans
      ?.filter(statusFilter !== ALL_FILTER_SELECT_OPTION ? (_) => _.status === statusFilter : emptyFilter)
      .filter(showOnlyLoansWithFiles ? (_) => _.agreementFileNames?.length > 0 : emptyFilter)
      .filter(
        lenderNameFilter !== ALL_FILTER_SELECT_OPTION
          ? (_) => _.lenderId === lenders.find((lender) => lender.name === lenderNameFilter)?.id
          : emptyFilter,
      );
  }, [statusFilter, lenderNameFilter, loans, showOnlyLoansWithFiles]);

  const loanGroups = useMemo(() => _.groupBy(filteredLoans || [], (_) => _.status), [filteredLoans]);

  if (!selectedCompany) return <>First select company in the menu</>;

  return (
    <Container>
      <HeaderContainer>
        <Divider />
        <HeaderSection>
          Filter By:
          <FilterContainer>
            Status:
            <select
              value={statusFilter}
              onChange={(_) => {
                const value = _.target.value;
                setStatusFilter(value as any);
              }}
            >
              {[LoanStatus.processing, LoanStatus.actionRequired, LoanStatus.approved].map((_, index) => (
                <option key={index}>{_}</option>
              ))}
              <option>{ALL_FILTER_SELECT_OPTION}</option>
            </select>
          </FilterContainer>
          <FilterContainer>
            Lender:
            <select
              value={lenderNameFilter}
              onChange={(_) => {
                const value = _.target.value;
                setLenderNameFilter(value as any);
              }}
            >
              {lenders.map((_, index) => (
                <option key={index}>{_.name}</option>
              ))}
              <option>{ALL_FILTER_SELECT_OPTION}</option>
            </select>
          </FilterContainer>
          <FilterContainer>
            Only loans with files:
            <Switch checked={showOnlyLoansWithFiles} onChange={(_) => setShowOnlyLoansWithFiles(_.target.checked)} />
          </FilterContainer>
        </HeaderSection>
        <HeaderSection>
          <Button fullWidth={false} onClick={() => refetch()}>
            refresh loans
          </Button>
          <AddANewLoan />
        </HeaderSection>
      </HeaderContainer>

      {isLoading && <div>loading...</div>}
      {isError && <div>failed to get loans</div>}
      {!isLoading && !isError && !loans?.length && <>No loans were found</>}
      {[LoanStatus.processing, LoanStatus.actionRequired, LoanStatus.approved]
        .filter((_) => loanGroups[_]?.length > 0)
        .map((status, statusIndex) => (
          <LoansByStatusContainer key={statusIndex}>
            <Divider orientation="horizontal" />
            <h1>{status} loans:</h1>
            <LoansContainer>
              {loanGroups[status]?.map((loan, index) => <LoanComponent key={index} loan={loan} />)}
            </LoansContainer>
          </LoansByStatusContainer>
        ))}
    </Container>
  );
};
