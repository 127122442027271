import { useCallback, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTimeRangePicker, TimeRange } from '../common/DateTimeRangePicker';
import { FinalMetricValue } from './FinalMetricValue';
import { BusinessEventsTableV2 } from './BusinessEventsTableV2';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { MetricFragmentSelect } from './MetricFragmentSelector';
import { useGetActionsSum } from '../common/hooks/useGetActionsSum';
import { AccountFragments } from '../../types/AccountFragments';
import { useGetAccountFragments } from '../common/hooks/useGetAccountFragments';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { BusinessEventTypesArray } from './BusinessEventTypesArrayV2';

const Loader = styled(LinearProgress)`
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-row-gap: 1em;
  justify-items: center;
`;

const ONE_HOUR_MS = 1000 * 60 * 60;
export const MetricsExplorer = () => {
  const [isPnl, setIsPnl] = useState(false);
  const [showOriginalCurrency, setShowOriginalCurrency] = useState(false);
  const { selectedCompany } = useSelectedCompany();
  const [selectedAccountRole, setSelectedAccountRole] = useState<string>();
  const [selectedIntegrationAccountName, setSelectedIntegrationAccountName] = useState<string>();
  const [selectedIntegrationType, setSelectedIntegrationType] = useState<string>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const anyFragmentSelected =
    selectedAccountRole || selectedIntegrationAccountName || selectedIntegrationType || selectedCurrency;

  const accountFragments: AccountFragments = useMemo(
    () => ({
      accountRole: selectedAccountRole,
      integrationAccountName: selectedIntegrationAccountName,
      integrationType: selectedIntegrationType,
      currency: selectedCurrency,
    }),
    [selectedAccountRole, selectedIntegrationAccountName, selectedIntegrationType, selectedCurrency, selectedCompany],
  );

  // if accountFragments keys all undefined make the object undefined - so it won't trigger data fetching.
  const accountFragmentsDefined = Object.keys(accountFragments).some(
    (key) => accountFragments[key as keyof AccountFragments],
  )
    ? accountFragments
    : undefined;
  const [timeRange, setTimeRange] = useState<TimeRange>();

  const [selectedEventType, setSelectedEventType] = useState<string | undefined>();
  const { data: currentMetricValue, loading: currentMetricValueIsLoading } = useGetActionsSum(
    selectedCompany?.id,
    accountFragmentsDefined,
    showOriginalCurrency,
    timeRange?.start ?? undefined,
    timeRange?.end ?? undefined,
    selectedEventType,
  );
  const handleBusinessEventTimeClick = useCallback(
    (eventTime: Date) =>
      setTimeRange({
        start: eventTime.getTime() - ONE_HOUR_MS,
        end: eventTime.getTime() + ONE_HOUR_MS,
      }),
    [],
  );
  const handleIsPnlClick = useCallback((_, newValue) => setIsPnl(newValue), [setIsPnl]);
  const handleShowOriginalCurrencyClick = useCallback(
    (_, newValue) => setShowOriginalCurrency(newValue),
    [setShowOriginalCurrency],
  );
  const handleAccountRoleSelected = useCallback(
    (newAccountRole) => {
      setSelectedAccountRole(newAccountRole);
      if (!newAccountRole) {
        setSelectedCurrency(undefined);
        setSelectedIntegrationAccountName(undefined);
        setSelectedIntegrationType(undefined);
        setSelectedEventType(undefined);
        setIsPnl(false);
        setShowOriginalCurrency(false);
      }
    },
    [selectedAccountRole],
  );
  const { data: accountFragmentsList, loading } = useGetAccountFragments(selectedCompany?.id);
  const filteredAccountFragments = accountFragmentsList?.filter((accountFragment) => {
    let isValidSelection = true;
    Object.keys(accountFragmentsDefined || {}).forEach((key) => {
      if (
        accountFragmentsDefined![key as keyof AccountFragments] &&
        accountFragment[key as keyof AccountFragments] !== accountFragmentsDefined?.[key as keyof AccountFragments]
      ) {
        isValidSelection = false;
      }
    });
    return isValidSelection;
  });

  if (!selectedCompany) {
    return (
      <Container>
        <Typography variant="h5">Please select company to start...</Typography>
      </Container>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!accountFragmentsList.length && !loading && selectedCompany) {
    return (
      <Container>
        <Typography variant="h5">No metrics for company</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <MetricFragmentSelect
        companyId={selectedCompany?.id}
        fragmentName="accountRole"
        onSelected={handleAccountRoleSelected}
        accountFragmentsList={filteredAccountFragments}
        isLoading={loading}
      />
      {selectedAccountRole && (
        <>
          <MetricFragmentSelect
            companyId={selectedCompany?.id}
            fragmentName="integrationType"
            onSelected={setSelectedIntegrationType}
            accountFragmentsList={filteredAccountFragments}
            isLoading={loading}
          />
          <MetricFragmentSelect
            companyId={selectedCompany?.id}
            fragmentName="integrationAccountName"
            onSelected={setSelectedIntegrationAccountName}
            accountFragmentsList={filteredAccountFragments}
            isLoading={loading}
          />
          <MetricFragmentSelect
            companyId={selectedCompany?.id}
            fragmentName="currency"
            onSelected={setSelectedCurrency}
            accountFragmentsList={filteredAccountFragments}
            isLoading={loading}
          />
          <FormControlLabel
            control={
              <Checkbox defaultChecked checked={showOriginalCurrency} onChange={handleShowOriginalCurrencyClick} />
            }
            label="Sum By Original Currency?"
          />
          <FormControlLabel
            control={<Checkbox defaultChecked checked={isPnl} onChange={handleIsPnlClick} />}
            label="is PnL account?"
          />
          <DateTimeRangePicker
            disabled={!anyFragmentSelected}
            initialTimeRange={timeRange}
            onRangeSelect={setTimeRange}
          />
          <FinalMetricValue value={currentMetricValue} isLoading={currentMetricValueIsLoading} />
          <BusinessEventTypesArray
            onBusinessEventTypeClick={setSelectedEventType}
            companyId={selectedCompany?.id}
            accountFragments={accountFragmentsDefined}
            startDate={timeRange?.start}
            endDate={timeRange?.end}
          />
          <BusinessEventsTableV2
            onEventTimeClick={handleBusinessEventTimeClick}
            companyId={selectedCompany?.id}
            accountFragments={accountFragmentsDefined}
            eventType={selectedEventType}
            startTime={timeRange?.start}
            endTime={timeRange?.end}
            showAmountsInOriginalCurrency={showOriginalCurrency}
            openingBalance={currentMetricValue}
          />{' '}
        </>
      )}
    </Container>
  );
};
