import { useAuth0, User } from '@auth0/auth0-react';
import { AnyMission, MissionType } from '@finaloop/mission-types';
import { useContext, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { environmentContext } from '../../../environmentContext/environmentContext';
import {
  createBalanceBankStatementMission as createBalanceBankStatementMissionApi,
  deleteMission as deleteMissionApi,
  deleteRecurringCsvMission as deleteRecurringCsvMissionApi,
  editMission as editMissionApi,
  getCompanyMissions,
  getIdentifiedBankConnectionMissionPayload,
  getIdentifiedIntegrationConnectionMissionPayload,
  getRecurringCsvMissions,
  newRecurringCsvMission,
  triggerMission as triggerMissionApi,
} from '../../../services/missionsApi';
import { useSelectedCompany } from './useSelectedCompany';

const getMissionEditor = (user: User | undefined) =>
  user?.email || 'unknown data-explorer user';

const useGetIdentifiedBankConnectionMissionPayload = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const { isLoading, isError, mutateAsync: getPayload, error } = useMutation(
    'get-identified-bank-connection-mission-payload',
    async (options: { transactionId: string }) => {
      const authToken = await getAccessTokenSilently();
      return await getIdentifiedBankConnectionMissionPayload({
        authToken,
        env,
        ...options,
      });
    }
  );

  return { isLoading, isError, getPayload, error };
};

const useGetIdentifiedIntegrationConnectionMissionPayload = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const { isLoading, isError, mutateAsync: getPayload, error } = useMutation(
    'get-identified-integration-connection-mission-payload',
    async (options: { transactionId: string }) => {
      const authToken = await getAccessTokenSilently();
      return await getIdentifiedIntegrationConnectionMissionPayload({
        authToken,
        env,
        ...options,
      });
    }
  );

  return { isLoading, isError, getPayload, error };
};

const useTriggerMission = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const {
    isLoading,
    isError,
    isSuccess,
    mutateAsync: triggerMission,
    error,
  } = useMutation(
    'trigger-mission',
    async (options: { type: MissionType; companyId: string; payload: any }) => {
      const authToken = await getAccessTokenSilently();
      return await triggerMissionApi({
        ...options,
        authToken,
        env,
        createdBy: getMissionEditor(user),
      });
    }
  );

  return { isLoading, isError, triggerMission, isSuccess, error };
};

const now = Date.now();
const GET_COMPANY_MISSIONS_QUERY = 'GET_COMPANY_MISSIONS';
const useCompanyMissions = <T extends AnyMission = any>(options: {
  type?: MissionType;
  companyId?: string;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { type, companyId } = options;

  const { isError, isFetching, refetch, remove, data: missions } = useQuery<
    T[]
  >({
    queryKey: [GET_COMPANY_MISSIONS_QUERY, companyId, type],
    enabled: !!companyId,
    queryFn: async () => {
      if (!companyId) return [];
      const authToken = await getAccessTokenSilently();
      const { missions } = await getCompanyMissions<T>({
        authToken,
        env,
        type,
        companyId,
      });
      return missions.sort(
        (_, __) => (__.createdAtMs || now) - (_.createdAtMs || now)
      );
    },
    refetchOnWindowFocus: false,
    staleTime: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    remove();
    refetch();
  }, [env, companyId]);

  return { missions, isError, isLoading: isFetching };
};

const SCHEDULE_A_NEW_RECURRING_CSV_MISSION =
  'schedule-a-new-recurring-csv-mission';
const useScheduleANewRecurringCsvMission = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { selectedCompany } = useSelectedCompany();

  const queryClient = useQueryClient();

  const { isLoading, isError, isSuccess, mutate: schedule } = useMutation(
    SCHEDULE_A_NEW_RECURRING_CSV_MISSION,
    async (params: {
      bankId: string;
      initialMonthAtMs: number;
      companyId: string;
    }) => {
      if (!selectedCompany)
        throw `can't set new recurring missions, no company was selected`;
      const authToken = await getAccessTokenSilently();
      await newRecurringCsvMission({
        ...params,
        authToken,
        env,
        createdBy: getMissionEditor(user),
      });
    },
    {
      onSuccess: () => {
        queryClient.fetchQuery(GET_COMPANY_MISSIONS_QUERY);
        queryClient.fetchQuery(GET_RECURRING_CSV_MISSIONS);
      },
    }
  );

  return { isError, isLoading, isSuccess, schedule };
};

const GET_RECURRING_CSV_MISSIONS = 'get-recurring-csv-missions';
const useCompanyRecurringCsvMissions = (companyId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const {
    isError,
    isFetching,
    refetch,
    remove,
    data: recurringCsvMissions,
  } = useQuery({
    enabled: !!companyId,
    queryKey: [GET_RECURRING_CSV_MISSIONS, companyId],
    queryFn: async () => {
      if (!companyId) return [];
      const authToken = await getAccessTokenSilently();
      return await getRecurringCsvMissions({
        authToken,
        env,
        companyId,
      });
    },
    refetchOnWindowFocus: false,
    staleTime: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    remove();
    refetch();
  }, [env, companyId]);

  return { recurringCsvMissions, isError, isLoading: isFetching };
};

const useEditMission = (missionId: string) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    mutateAsync: editMission,
    error,
  } = useMutation({
    mutationKey: [`edit-mission-${missionId}`],
    mutationFn: async (options: { mission: AnyMission }) => {
      const authToken = await getAccessTokenSilently();
      return await editMissionApi({
        ...options,
        authToken,
        env,
        updatedBy: getMissionEditor(user),
      });
    },
    onSuccess: async () => {
      await queryClient.resetQueries([GET_COMPANY_MISSIONS_QUERY]);
      await queryClient.fetchQuery([GET_COMPANY_MISSIONS_QUERY]);
    },
  });

  return { isLoading, isError, editMission, isSuccess, error };
};

const useDeleteMission = (missionId: string) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    mutateAsync: deleteMission,
    error,
  } = useMutation(
    `delete-mission-${missionId}`,
    async () => {
      const authToken = await getAccessTokenSilently();
      return await deleteMissionApi({ authToken, env, missionId });
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.fetchQuery(GET_COMPANY_MISSIONS_QUERY);
        }, 3000);
      },
    }
  );

  return { isLoading, isError, deleteMission, isSuccess, error };
};

const useDeleteRecurringCsvMission = (
  companyId: string | undefined,
  bankId: string
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    mutateAsync: deleteRecurringCsvMission,
    error,
  } = useMutation(
    `delete-recurring-csv-mission-${bankId}`,
    async () => {
      if (!companyId) {
        throw new Error('No company selected');
      }
      const authToken = await getAccessTokenSilently();
      return await deleteRecurringCsvMissionApi({
        authToken,
        env,
        bankId,
        companyId,
      });
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.fetchQuery(GET_COMPANY_MISSIONS_QUERY);
        }, 3000);
      },
    }
  );

  return { isLoading, isError, deleteRecurringCsvMission, isSuccess, error };
};

const useCreateBalanceBankStatementMission = (
  companyId: string | null,
  type: string,
  fromDateString: string,
  toDateString: string
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    mutateAsync: createBalanceBankStatementMission,
    error,
  } = useMutation(
    `create-balance-bank-statement-mission`,
    async () => {
      const authToken = await getAccessTokenSilently();

      if (!companyId) {
        throw new Error('No company selected');
      }

      return await createBalanceBankStatementMissionApi({
        authToken,
        env,
        type,
        fromDateString,
        toDateString,
        companyId,
      });
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.fetchQuery(GET_COMPANY_MISSIONS_QUERY);
        }, 3000);
      },
    }
  );

  return {
    isLoading,
    isError,
    createBalanceBankStatementMission,
    isSuccess,
    error,
  };
};

export const useMissionsApiClient = () => ({
  useGetIdentifiedBankConnectionMissionPayload,
  useGetIdentifiedIntegrationConnectionMissionPayload,
  useTriggerMission,
  useEditMission,
  useDeleteMission,
  useCompanyMissions,
  useScheduleANewRecurringCsvMission,
  useCompanyRecurringCsvMissions,
  useDeleteRecurringCsvMission,
  useCreateBalanceBankStatementMission,
});
