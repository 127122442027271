import { Alert } from '@mui/material';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { CompanyQbAccountsMappingView } from './CompanyQbAccountsMappingView';
import { Loader } from './CompanyQbAccountsMappingView/Loader';
import { useBillsClassificationTags } from '../common/hooks/useBillsClassificationTags';
import { useAuth0 } from '@auth0/auth0-react';

export const BillsQbAccountsMapper = () => {
  const { selectedCompany } = useSelectedCompany();
  const { getAccessTokenSilently } = useAuth0();

  const { classificationTags, isLoading: isClassificationTagsListLoading } = useBillsClassificationTags(() =>
    getAccessTokenSilently(),
  );

  if (selectedCompany?.id == null) {
    return <Alert severity="info">You need to select a company first</Alert>;
  }

  if (isClassificationTagsListLoading)
    return (
      <div className="flex flex-col items-center h-screen">
        <div className="py-5">
          <Loader variant="big" />
          Loading classification tags...
        </div>
      </div>
    );

  return (
    <div className="flex flex-col items-center h-screen px-4">
      <CompanyQbAccountsMappingView companyId={selectedCompany?.id} classificationTags={classificationTags!} />
    </div>
  );
};
