interface MultiLineTextInputProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export function MultiLineTextInput(props: MultiLineTextInputProps) {
  const { value, placeholder, onChange } = props;

  return (
    <div className="xl:w-96">
      <textarea
        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-l transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        rows={3}
        placeholder={placeholder}
        onChange={(_) => onChange(_.target.value)}
        value={value}
      ></textarea>
    </div>
  );
}
