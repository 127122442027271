import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BusinessEvent } from '../types';
import { BusinessEventParams } from '../../../types/BusinessEventParams';

const getUrl = (env: string) => {
  const urlSuffix = env === 'prod' ? '' : `-${env}`;
  return `https://api3${urlSuffix}.finaloop.com/fixbooks`;
};

const getClient = (authToken: string, env: string, config?: AxiosRequestConfig) =>
  axios.create({
    baseURL: getUrl(env),
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    ...config,
  });

const axiosRequestWithAxiosErrorCatch = async <T = any>(action: () => Promise<T>): Promise<T> => {
  try {
    return await action();
  } catch (error) {
    const axiosError = error as AxiosError;
    if (!axiosError.isAxiosError) throw error;
    throw axiosError.response?.data;
  }
};

export type GetPayloadParams = {
  env: string;
  authToken: string;
  transactionId: string;
};

export type MissionPayload = { payload: any; companyId: string };

export const getEvents = async (authToken: string, env: string, companyId: string): Promise<BusinessEvent[]> => {
  const { data } = await axiosRequestWithAxiosErrorCatch(() =>
    getClient(authToken, env).get<BusinessEvent[]>(`/events/${companyId}`),
  );
  return data;
};

export const createEvent = async (authToken: string, env: string, event: BusinessEventParams) => {
  const { data } = await axiosRequestWithAxiosErrorCatch(() => getClient(authToken, env).post(`/events`, event));

  return data;
};

export type FixbooksDeleteRequestParams = {
  env: string;
  companyId: string;
  authToken: string;
  eventId: string;
};

export const deleteFixbooksEvent = async ({
  authToken,
  env,
  companyId,
  eventId,
}: FixbooksDeleteRequestParams): Promise<void> => {
  await axiosRequestWithAxiosErrorCatch(() =>
    getClient(authToken, env).delete(`event/${eventId}`, { params: { companyId } }),
  );
};
