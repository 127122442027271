export type MetricsValidationStatusForCompany = {
  integrationAccountId: string;
  companyId: string;
  endTime: string;
  startTime: string;
  createdAt: string;
  currentValues: ValidationResultByTime;
  firstGapValues: ValidationResultByTime | null;
  status: ValidationStatus;
  salesChannelByStatus: Record<SalesChannelStatus, string[]>;
  errMsg?: string;
  version?: string;
};

export type GiftCardValidationStatusForCompany = {
  integrationAccountId: string;
  companyId: string;
  endTime: string;
  startTime: string;
  createdAt: string;
  shopifyBalance: number;
  shopifyExpiredBalance: number;
  finaloopBalance: number;
  gap: number;
  status: ValidationStatus;
  errMsg?: string;
  version?: string;
};
export enum SalesChannelStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum ValidationStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type ValidationResultByTime = {
  startTime: Date;
  endTime: Date;
  gap: number;
  shopifyValues: MetricValues;
  finaloopValues: MetricValues;
};
export type MetricValues = {
  grossSales: number;
  discounts: number;
  shipping: number;
  returns: number;
  taxes: number;
  net: number;
  discountShippingAndReturns: number;
};
