import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useMissionsApiClient } from '../common/hooks/useMissionsApiClient';

const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

export const BalanceBankStatement = () => {
  const [type, setType] = useState('Opening balance');
  const [timeRange, setTimeRange] = useState({
    fromDateString: '2020-12-01',
    toDateString: '2021-01-01',
  });
  const [companyId, setCompanyId] = useState('' as string);

  const isCompanyIdValid = uuidRegex.test(companyId || '');

  const { useCreateBalanceBankStatementMission } = useMissionsApiClient();

  const { isLoading, isError, createBalanceBankStatementMission, isSuccess } = useCreateBalanceBankStatementMission(
    companyId,
    type,
    timeRange.fromDateString,
    timeRange.toDateString,
  );

  return (
    <Table>
      <TableBody>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            <Box style={{ marginBottom: '10px' }}>Create balance bank statement mission</Box>
          </TableCell>
        </TableRow>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            <TextField
              id="companyid"
              error={!isCompanyIdValid}
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              style={{ width: '100%' }}
              label="Company Id"
            />
          </TableCell>
        </TableRow>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            <Select
              id="statementtype"
              onChange={(e) => setType(e.target.value)}
              value={type}
              label={'Statement type'}
              style={{ width: '100%' }}
            >
              <MenuItem value="Opening balance">Opening balance</MenuItem>
              <MenuItem value="Closing balance">Closing balance</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            <Select
              id="timerange"
              style={{ width: '100%' }}
              onChange={(e) => {
                const [fromDateString, toDateString] = (e.target.value as string).split(' - ');
                console.log('romadn', fromDateString, toDateString);
                setTimeRange({ fromDateString, toDateString });
              }}
              value={`${timeRange.fromDateString} - ${timeRange.toDateString}`}
              label="Time range"
            >
              <MenuItem value="2019-12-01 - 2020-01-01">2019-12-01 - 2020-01-01</MenuItem>
              <MenuItem value="2020-12-01 - 2021-01-01">2020-12-01 - 2021-01-01</MenuItem>
              <MenuItem value="2021-12-01 - 2022-01-01">2021-12-01 - 2022-01-01</MenuItem>
              <MenuItem value="2022-12-01 - 2023-01-01">2022-12-01 - 2023-01-01</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            <Button
              id="submitbutton"
              disabled={isLoading}
              onClick={() => isCompanyIdValid && createBalanceBankStatementMission()}
              style={{ width: '100%' }}
            >
              Create statement
            </Button>
          </TableCell>
        </TableRow>
        <TableRow style={{ border: 'none' }}>
          <TableCell style={{ border: 'none' }}>
            {isSuccess &&
              `Created bank statement mission for ${type} from ${timeRange.fromDateString} to ${timeRange.toDateString}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
