import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { keyBy } from 'lodash';
import { useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import {
  ClearingAccountValidationStatusForCompany,
  ValidationStatus,
} from '../../types/ClearingAccountValidationStatusForCompany';
import { useFetchClearingAccountValidationStatus } from '../common/hooks/useFetchClearingAccountStatus';
import { useListCompaniesName } from '../common/hooks/useListCompaniesName';
import { Company } from '../../services/FinaloopGql';

interface ReportsTableProps {
  reports: ClearingAccountValidationStatusForCompany[];
  companies: Required<Pick<Company, 'id' | 'name'>>[];
}

interface ReportTableRowProps {
  report: ClearingAccountValidationStatusForCompany;
  companyName: string;
  rowIndex: number;
}

const ReportsTableRow = ({ report, companyName, rowIndex }: ReportTableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{rowIndex}</TableCell>
        <TableCell component="th" scope="row">
          {companyName}
        </TableCell>
        <TableCell>{report.companyId}</TableCell>
        <TableCell>{report.integrationAccountId}</TableCell>
        <TableCell>{report.createdAt}</TableCell>
        <TableCell>{report?.validationErrors?.length || 0}</TableCell>
        <TableCell>
          {report.status === ValidationStatus.SUCCESS ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : (
            <div>
              <ErrorOutlineOutlinedIcon color="error" />
              <span>{report.errMsg}</span>
            </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <ReactJson collapsed={1} src={report.validationErrors} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ReportsTable = ({ reports, companies }: ReportsTableProps) => {
  const [sorter, setSorter] = useState(() => (a: any, b: any) => a.companyName.localeCompare(b.companyName));
  const [sortDirection, setSortDirection] = useState(true);

  const companyById = useMemo(() => {
    if (!companies) {
      return {};
    }
    return keyBy(companies, 'id');
  }, [companies]);

  const getStringSorterFunc = (extractProperty: (a: any) => string) => {
    return (a: any, b: any) => (extractProperty(a) || '').localeCompare(extractProperty(b) || '');
  };

  const sortedReports = useMemo(() => {
    if (!reports) {
      return [];
    }
    const extendedReports = reports.map((report) => ({ ...report, companyName: companyById[report.companyId]?.name }));
    const sortReportsInit = extendedReports.sort(sorter);
    return sortDirection ? sortReportsInit : sortReportsInit.reverse();
  }, [reports, sorter, sortDirection, companyById]);

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>#</TableCell>
            <TableCell
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDirection(!sortDirection);
                setSorter(() => getStringSorterFunc((a: any) => a.companyName));
              }}
            >
              Company Name
            </TableCell>
            <TableCell
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDirection(!sortDirection);
                setSorter(() => getStringSorterFunc((a: any) => a.companyName));
              }}
            >
              Company Id
            </TableCell>

            <TableCell
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDirection(!sortDirection);
                setSorter(() => getStringSorterFunc((a: any) => a.integrationAccountId));
              }}
            >
              Shop Name
            </TableCell>
            <TableCell
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDirection(!sortDirection);
                setSorter(() => getStringSorterFunc((a: any) => a.createdAt));
              }}
            >
              Created At
            </TableCell>
            <TableCell># of validation errors!</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedReports.map((report, index) => {
            const company = companyById[report.companyId];
            if (!company) return null;

            return (
              <ReportsTableRow
                rowIndex={index}
                key={`${report.companyId}-${report.integrationAccountId}`}
                report={report}
                companyName={company.name}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ClearingAccountValidation = () => {
  const { integrationType } = useParams();
  const listCompanies = useListCompaniesName();
  const clearingAccountValidationStatus = useFetchClearingAccountValidationStatus(integrationType);
  if (listCompanies.isLoading || clearingAccountValidationStatus.loading) {
    return <>Loading...</>;
  }

  return <ReportsTable reports={clearingAccountValidationStatus.data} companies={listCompanies.data || []} />;
};
