import { createTheme } from '@mui/material';

export const APP_THEME = createTheme({
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: { main: '#0288d1' },
    secondary: { main: '#414141' },
    error: { main: '#f44336' },
  },
  components: {
    MuiTextField: {
      defaultProps: { variant: 'filled' },
    },
    MuiSelect: {
      defaultProps: { variant: 'filled' },
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
    },
  },
});
