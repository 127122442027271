import { HTMLAttributes, ReactNode, useCallback } from 'react';
import { Selector } from '../../../../common/Selector';
import { ClassificationTag } from '../../../../../services/classificationTagsApiClient';

export interface EditableDropdownCellProps {
  value?: string;
  row: { index: number };
  column: { id: string };
  updateData: any;
  data: ClassificationTag[];
  loading?: boolean;
  onOptionSelected?: (option: ClassificationTag) => void;
  onRenderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: ClassificationTag,
    state: any,
  ) => ReactNode | undefined;
  InputIcon?: () => JSX.Element;
}

export function EditableDropdownCell(props: EditableDropdownCellProps) {
  const {
    row: { index },
    column: { id },
    updateData,
    value,
    data,
    loading,
    onRenderOption,
    onOptionSelected,
    InputIcon,
  } = props;

  const onChange = useCallback(
    (value: ClassificationTag) => {
      if (!data.some((itemValue) => itemValue?.id === value.id)) return;

      updateData(index, id, value);
      onOptionSelected?.(value);
    },
    [index, id, updateData, onOptionSelected, data],
  );

  const initialValue = data.find((item) => item?.id === value)?.name;

  return (
    <Selector
      disabled={false}
      initialValue={initialValue}
      resetInput=""
      data={data}
      title="Choose classification tag"
      onSelect={onChange}
      onRenderOption={onRenderOption}
      labelExtractor={(e) => e.name}
      loading={loading ?? false}
      InputIcon={InputIcon}
    />
  );
}
