import { format } from 'date-fns';
import moment, { Moment } from 'moment';

export const timeInMsToFriendlyTime = (ms?: number) =>
  !ms ? '' : moment.unix(ms / 1000).format('DD/MM/yyyy');
const timeInMsToUtcTimeInMs = (timeInMs: number): number =>
  new Date(new Date(timeInMs).toISOString().replace(/Z/g, '')).getTime();

export const timeInMsToMonthName = (timeInMs: number) =>
  format(timeInMsToUtcTimeInMs(timeInMs), 'MMMM yyyy');

export const dateToNextMonthFirstDay = (date: Moment): Moment =>
  date
    ?.clone()
    .add('month', 1)
    .startOf('month');
