import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import { environmentContext } from '../../../environmentContext/environmentContext';
import { Company } from '../../../types/Company';

export const useUpdateCompanyMutation = (companyId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const services = useContext(environmentContext);

  return useMutation((updatedCompanyFields: Partial<Company>) =>
    services.CompanyApiClient.updateCompany(companyId, updatedCompanyFields, getAccessTokenSilently),
  );
};
