import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { loansApi } from '../services/loans-api';
import { GetLoansResponse, LoanResponse, LoanToUpsert } from '../types';

const GET_LOANS_QUERY_KEY = 'get-loans';
const useGetLoans = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id;

  const { isFetching, isLoading, isError, data, refetch, remove } = useQuery<LoanResponse[]>({
    queryKey: [GET_LOANS_QUERY_KEY, companyId],
    enabled: !!companyId,
    queryFn: async () => {
      const authToken = await getAccessTokenSilently();
      if (!companyId) return [];
      const response = await loansApi.get({ authToken, env, companyId });
      return response.loans;
    },
    select: (loans) => {
      if (!loans) return [];
      return _.sortBy(loans, (_) => _.commencementDateMs);
    },
    retry: 2,
  });

  return { isLoading: isFetching || isLoading, isError, loans: data, refetch };
};

const useUpsertLoan = (loanId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);
  const queryClient = useQueryClient();

  const {
    mutateAsync,
    isError: hadErrorSavingLoan,
    isSuccess: savedSuccessfully,
    isLoading: isSaving,
    reset,
  } = useMutation(
    loanId,
    async (loan: LoanToUpsert) => {
      const authToken = await getAccessTokenSilently();
      await loansApi.upsert({ loan, authToken, env });
    },
    {
      onSuccess: () => {
        queryClient.resetQueries({ queryKey: GET_LOANS_QUERY_KEY });
        queryClient.refetchQueries({ queryKey: GET_LOANS_QUERY_KEY });
      },
    },
  );

  return { upsertLoan: mutateAsync, hadErrorSavingLoan, savedSuccessfully, isSaving, reset };
};

const useDeleteLoan = (loanId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const {
    mutateAsync,
    isError: hasErrorDeleting,
    isSuccess: deletedSuccessfully,
    isLoading: isDeleting,
  } = useMutation(
    loanId,
    async (loanId: string) => {
      const authToken = await getAccessTokenSilently();
      await loansApi.delete({ authToken, env, loanId });
    },
    {
      onSuccess: () => {
        queryClient.fetchQuery({ queryKey: GET_LOANS_QUERY_KEY });
      },
    },
  );

  return { deleteLoan: mutateAsync, hasErrorDeleting, deletedSuccessfully, isDeleting };
};

export const useLoans = () => ({ useGetLoans, useUpsertLoan, useDeleteLoan });
