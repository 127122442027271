import { useMemo } from 'react';
import { Company } from '../../../services/FinaloopGql';
import { useListCompanies2 } from './useListCompanies';
import { useQueryState } from './useQueryState';

const SELECTED_COMPANY_SEARCH_PARAM = 'companyId';

export const useSelectedCompany = () => {
  const { data } = useListCompanies2();
  const [selectedCompanyId, setSelectedCompanyId] = useQueryState(
    SELECTED_COMPANY_SEARCH_PARAM
  );

  const selectedCompany = useMemo(() => {
    return data?.find((_) => _.id === selectedCompanyId);
  }, [data, selectedCompanyId]);

  return {
    selectedCompany,
    setSelectedCompany: (company: Company | undefined) => {
      if (!company) {
        setSelectedCompanyId(undefined);
        return;
      }

      setSelectedCompanyId(company.id);
    },
  };
};
