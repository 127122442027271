import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { CompanyBillsBalanceView } from './CompanyBillsBalanceView';

export const CompanyBillsBalance = () => {
  const { selectedCompany } = useSelectedCompany();
  const [date, setDate] = useState<number | null>(Date.now());

  return (
    <div className="flex flex-col items-center h-screen px-4">
      <DatePicker
        inputFormat="yyyy-MM-DD"
        views={['year', 'month', 'day']}
        label="Year-Month-Day"
        onChange={setDate}
        value={date}
        renderInput={(params: any) => (
          <TextField sx={{ mt: '1rem' }} {...params} helperText={null} />
        )}
      />
      {selectedCompany?.id && date !== null && (
        <CompanyBillsBalanceView companyId={selectedCompany?.id} date={date} />
      )}
    </div>
  );
};
