import { FixbooksEventRequest } from '@finaloop/fixbooks-types';
import { BusinessEventParams } from '../../types/BusinessEventParams';

export const createBusinessEventModel = (
  companyId: string,
  eventType: string,
  eventId: string,
  integrationAccountId: string,
  rawEvent: FixbooksEventRequest,
  eventTimeMS: number,
): BusinessEventParams => {
  return {
    subjectId: eventId,
    companyId,
    eventId,
    eventType,
    integrationAccountId,
    integrationType: 'FINALOOP_INTERNAL',
    eventTimeMS,
    rawEvent,
  };
};
