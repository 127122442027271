import axios from 'axios';

type TokenFetcher = () => Promise<string>;
let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export const getOnboardingStateForCompany = async (
  companyId: string,
  fetchToken: TokenFetcher,
): Promise<any | undefined> => {
  const authToken = await fetchToken();
  if (!url.length) {
    return;
  }
  const result = await axios.get<any>(`${url}/companyOnboardingState/${companyId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return result.data;
};

export const setOnboardingStateForCompany = async (
  companyId: string,
  data: any,
  fetchToken: TokenFetcher,
): Promise<void> => {
  const authToken = await fetchToken();
  if (!url.length) {
    return;
  }

  await axios.put<any>(`${url}/companyOnboardingState/${companyId}`, data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authToken}` },
  });
};
