import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useContext } from 'react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { createClient, QueryRequest, MutationRequest, Mutation } from '../../../__generated_amazon_products_gql__';
import * as reactQuery from 'react-query';
import { FieldsSelection } from '@genql/runtime';
import _ from 'lodash';

function getUrl(env: string) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:4000/graphql';
  }

  const urlSuffix = env === 'prod' ? '' : `-${env}`;
  return `https://api3${urlSuffix}.finaloop.com/inventory/amazonProductsValidation`;
}

export function createWorksheetsGqlClient(env: string) {
  const url = getUrl(env);
  console.log('createWorksheetsGqlClient', env, url);
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: url,
  });
}

export function gqlClient(env: string, token: string) {
  const url = getUrl(env);
  return createClient({ url, headers: { Authorization: token } });
}

export function useQuery<R extends QueryRequest>(query: R, token: string) {
  const { currentEnv } = useContext(environmentContext);
  const client = gqlClient(currentEnv, token);
  return reactQuery.useQuery(['products', query], () => client.query(query));
}

export function useMutation<R extends MutationRequest>(
  query: R,
  token: string,
  onSuccess?: (newData: FieldsSelection<Mutation, R>) => any,
) {
  const { currentEnv } = useContext(environmentContext);
  const client = gqlClient(currentEnv, token);
  return reactQuery.useMutation(['products', query], () => client.mutation(query), { onSuccess });
}
