import { Bill } from '../../types/Bill';
import { BillEntityType } from '../../types/BillEntityType';
import { BillPayment } from '../../types/BillPayment';
import { BillToPaymentRelation } from '../../types/BillToPaymentRelation';
import { fromCents, toCents } from '../common/money';
import { BillBalanceDue } from './types';

type BillGroup = { bill: Bill; billPaymentsReleations: BillToPaymentRelation[] };
type BillIdToGroup = Record<string, BillGroup>;

export const getBillsBalanceForDate = (
  data: (Bill | BillPayment | BillToPaymentRelation)[] | undefined,
  dueDate: number,
): BillBalanceDue[] => {
  if (!data) return [];

  const qbBillsWithItsPayments = data
    .filter(
      (item): item is Bill | BillToPaymentRelation =>
        item.source === 'QUICKBOOKS' && item.entity !== BillEntityType.BILL_PAYMENT,
    )
    .reduce(groupBillAndPayments, {} as BillIdToGroup);

  const billsWithBalanceDue = Object.values(qbBillsWithItsPayments)
    .filter(({ bill }) => bill?.createdDateMs <= dueDate)
    .map(toBillBalanceDate(dueDate));

  return billsWithBalanceDue.filter(({ balanceForDate }) => balanceForDate > 0);
};

const groupBillAndPayments = (billGroups: BillIdToGroup, item: Bill | BillToPaymentRelation) => {
  const billGroup = billGroups[item.billId] || { billPaymentsReleations: [] };
  if (item.entity === BillEntityType.BILL) {
    billGroup.bill = item as Bill;
  } else if (item.entity === BillEntityType.BILL_TO_PAYMENT_RELATION) {
    billGroup.billPaymentsReleations.push(item as BillToPaymentRelation);
  } else {
    return billGroups;
  }
  return { ...billGroups, [item.billId]: billGroup };
};

const toBillBalanceDate = (dueDate: number) => (item: BillGroup) => {
  const { bill, billPaymentsReleations } = item;
  const { amount, billId, externalSourceId } = bill;

  const sumPaymentsToDate = fromCents(
    billPaymentsReleations
      .filter((relation) => relation.paymentDateMs < dueDate)
      .reduce((acc, payment) => acc + toCents(payment.amount), 0),
  );

  const balanceForDate = amount - sumPaymentsToDate;

  return { billId, externalSourceId, balanceForDate, amount };
};
