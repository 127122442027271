import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type { MatchingReportForAccount } from '../../types/MatchingReportForAccount';
import { useFetchMatchingReports } from '../common/hooks/useFetchingMatchingReports';
import { formatMoney } from '../../common/utils/format-money';
import { formatPercentage } from '../../common/utils/format-percentage';
import { useListCompaniesName } from '../common/hooks/useListCompaniesName';
import { Company } from '../../services/FinaloopGql';

interface ReportsTableProps {
  reports: MatchingReportForAccount[];
  companies: Required<Pick<Company, 'id' | 'name'>>[];
}

interface ReportTableRowProps {
  report: MatchingReportForAccount;
  companyName: string;
}

const ReportsTableRow = (props: ReportTableRowProps) => {
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {props.companyName}
        </TableCell>
        <TableCell>{props.report.integrationAccountName}</TableCell>
        <TotalsCells {...props} />
        <MatchedPercentagesCell {...props} />
        <UnmatchedBreakdownCell {...props} />
      </TableRow>
    </>
  );
};

const TotalsCells = (props: ReportTableRowProps) => {
  const { totals } = props.report;

  return (
    <>
      {(['all', 'matched', 'unmatched'] as const).map((matchStatus) => {
        const totalsAtMatchStatus = totals[matchStatus];

        return (
          <TableCell key={matchStatus}>
            {(['all', 'payment', 'refund'] as const).map((paymentType) => {
              const totalsAtPaymentType = totalsAtMatchStatus[paymentType];

              return (
                <div key={['totals', matchStatus, paymentType].join('_')}>
                  <span style={{ textDecoration: 'underline' }}>
                    {{ all: 'ALL', payment: 'A/R', refund: 'R/P' }[paymentType]}
                  </span>{' '}
                  {totalsAtPaymentType.count}, {formatMoney(totalsAtPaymentType.amountUSD)}
                </div>
              );
            })}
          </TableCell>
        );
      })}
    </>
  );
};

const MatchedPercentagesCell = (props: ReportTableRowProps) => {
  const { all, matched } = props.report.totals;

  return (
    <TableCell>
      {(['all', 'payment', 'refund'] as const).map((paymentType) => {
        return (
          <div key={paymentType}>
            {formatPercentage(matched[paymentType].count / (all[paymentType].count || 1))} of{' '}
            {paymentType === 'all' ? 'all' : `${paymentType}s`}
          </div>
        );
      })}
    </TableCell>
  );
};

const UnmatchedBreakdownCell = (props: ReportTableRowProps) => {
  const { unmatched } = props.report.totals;

  return (
    <TableCell>
      {(['payment', 'refund'] as const).flatMap((paymentType) => {
        const { breakdown } = unmatched[paymentType];

        if (!breakdown.length) {
          return null;
        }

        return [
          <div
            key={['breakdown', paymentType].join('_')}
            style={{ paddingBottom: '2.5px', paddingTop: '2.5px', textDecoration: 'underline' }}
          >
            {paymentType === 'payment' ? 'A/R' : 'R/P'}
          </div>,
          ...breakdown
            .slice()
            .sort((a, b) => b.count - a.count || b.amountUSD - a.amountUSD)
            .map((item, index) => (
              <div key={['breakdown', paymentType, index].join('_')}>
                {[item.gateway || 'N/A', item.salesChannel || 'N/A', item.count, formatMoney(item.amountUSD)].join(
                  ' - ',
                )}
              </div>
            )),
        ];
      })}
    </TableCell>
  );
};

const ReportsTable = ({ reports, companies }: ReportsTableProps) => {
  const companyDictionary = useMemo(() => keyBy(companies, 'id'), [companies]);

  return (
    <Table stickyHeader sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow
          sx={{
            '& th': {
              borderBottom: '1px solid black',
              fontSize: '1.1rem',
              color: 'rgba(96, 96, 96)',
            },
          }}
        >
          <TableCell>Company</TableCell>
          <TableCell>Shop Name</TableCell>
          <TableCell>
            Total
            <br /> Txns
          </TableCell>
          <TableCell>
            Matched
            <br /> Txns
          </TableCell>
          <TableCell>
            Unmatched
            <br /> Txns
          </TableCell>
          <TableCell>
            % of Matched
            <br /> Txns
          </TableCell>
          <TableCell>
            Unmatched <br />
            Txn Breakdown
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reports.map((report) => {
          const { companyId } = report;

          const company = companyDictionary[companyId];

          if (!company) {
            return null;
          }

          return (
            <ReportsTableRow
              key={[companyId, report.integrationAccountName].join('_')}
              companyName={company.name}
              report={report}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export const MatchingValidation = () => {
  const { integrationType } = useParams();
  const listCompanies = useListCompaniesName();
  const { data: reportData, loading: isLoadingReportData } = useFetchMatchingReports(integrationType);

  if (listCompanies.isLoading || isLoadingReportData) {
    return <>Loading...</>;
  }

  return <ReportsTable reports={reportData} companies={listCompanies.data || []} />;
};
