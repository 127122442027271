import axios, { AxiosRequestConfig } from 'axios';

const getUrl = (env: string) => {
  const urlSuffix = env === 'prod' ? '' : `-${env}`;
  return `https://api3${urlSuffix}.finaloop.com`;
};

const getClient = ({
  env,
  authToken,
  config,
  loanId,
}: {
  authToken: string;
  env: string;
  config?: AxiosRequestConfig;
  loanId: string;
}) =>
  axios.create({
    baseURL: `${getUrl(env)}/loan/${loanId}/agreements`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    ...config,
  });

type SignedUrlResponse = { signedUrl: string };

export type UploadFileOptions = { fileName: string; fileType: string };
export type DownloadFileOptions = { fileName: string };

type DownloadAgreementParams = { loanId: string; fileName: string; authToken: string; env: string };
const downloadAgreement = async (params: DownloadAgreementParams): Promise<void> => {
  try {
    const { data } = await getClient(params).post<SignedUrlResponse>('', { fileName: params.fileName });
    const response = await axios.get(data.signedUrl, {
      transformRequest: (data, headers) => {
        delete (headers?.common as any)['Authorization'];
        return data;
      },
      responseType: 'blob',
      validateStatus: (_) => _ === 200,
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', params.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    console.debug('downloaded file', params.fileName);
  } catch (err: any) {
    const error = new Error('failed to download loan agreement');
    error.stack = err;
    console.error(error);
    throw error;
  }
};

export const loanAgreementsApi = { downloadAgreement };
