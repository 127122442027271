import { useQuery, gqlClient } from './hooks';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer, Table, TableRow, TableCell, Box, TableHead, TableBody, Button } from '@mui/material';
import { Fragment, useContext } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { isEqual, isEmpty } from 'lodash';
import {
  ValidationIntegrationType,
  ValidationMismatchData,
  Validation,
  everything,
} from '../../../__generated_products_gql__';
import ReactJson from 'react-json-view';
import * as reactQuery from 'react-query';
import { environmentContext } from '../../../environmentContext/environmentContext';

const Status = ({ ok }: { ok: boolean }) => {
  return ok ? (
    <CheckIcon style={{ color: 'green' }}></CheckIcon>
  ) : (
    <PriorityHighIcon style={{ color: 'red' }}></PriorityHighIcon>
  );
};

const RerunValidation = ({
  userToken,
  companyId,
  integrationAccountId,
  setValidationData,
}: {
  userToken: string;
  companyId: string;
  integrationAccountId: string;
  integrationType: ValidationIntegrationType;
  setValidationData: (data: any) => any;
}) => {
  const { currentEnv } = useContext(environmentContext);

  const client = gqlClient(currentEnv, userToken);
  const { isLoading, mutate } = reactQuery.useMutation(
    [
      'rerunValidation',
      {
        companyId,
        integrationAccountId,
      },
    ],
    () => {
      return client.mutation({
        rerunValidation: [
          {
            args: {
              companyId,
              integrationAccountId,
            },
          },
          {
            ...everything,
            company: everything,
          },
        ],
      });
    },
    {
      onSuccess: (newData) => setValidationData(newData.rerunValidation),
    },
  );

  return (
    <Button disabled={isLoading} onClick={() => mutate()}>
      Rerun
    </Button>
  );
};

const ProductsValidationsList = ({
  userToken,
  validations: validationsData,
}: {
  userToken: string;
  validations: Validation[];
}) => {
  const [validations, setValidations] = useState(validationsData);

  const [showDetailsIdx, setShowDetailsIdx] = useState(null as number | null);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Integration Type</TableCell>
            <TableCell>Integration Account Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validations.map((v, idx) => {
            const rowSelector = {
              companyId: v.company.companyId,
              integrationAccountId: v.integrationAccountId,
              integrationType: v.integrationType,
            };
            const isSelected = showDetailsIdx === idx;
            return (
              <Fragment>
                <TableRow>
                  <TableCell>{`${v.company.companyName} / ${v.company.companyId}`}</TableCell>
                  <TableCell>{v.integrationType}</TableCell>
                  <TableCell>{v.integrationAccountId}</TableCell>
                  <TableCell>{v.date}</TableCell>
                  <TableCell>{v.status}</TableCell>
                  <TableCell>
                    <Status ok={v.status === 'Pass'} />
                  </TableCell>
                  <TableCell>
                    <RerunValidation
                      userToken={userToken}
                      {...rowSelector}
                      setValidationData={(data) => {
                        const temp = [...validations];
                        temp.splice(idx, 1, data);
                        setValidations(temp);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {showDetailsIdx === idx ? (
                      <ValidationDetails
                        userToken={userToken}
                        companyId={v.company.companyId}
                        integrationAccountId={v.integrationAccountId}
                      />
                    ) : (
                      <Button onClick={() => setShowDetailsIdx(idx)}>Details</Button>
                    )}
                  </TableCell>
                </TableRow>
                {isSelected ? (
                  <TableRow>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                ) : null}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ValidationDetails = ({
  userToken,
  companyId,
  integrationAccountId,
}: {
  userToken: string;
  companyId: string;
  integrationAccountId: string;
}) => {
  const validationsData = useQuery(
    {
      getValidationDetails: [
        {
          args: {
            companyId: companyId,
            integrationAccountId: integrationAccountId,
          },
        },
        {
          mismatchData: {
            orderId: true,
            isOk: true,
            finaloopData: true,
            metricsData: true,
          },
        },
      ],
    },
    userToken,
  );

  const data = validationsData.data?.getValidationDetails;
  return <ReactJson collapsed={1} src={{ data: data?.mismatchData }} />;
};

const ProductsValidationWrapper = ({ userToken }: { userToken: string }) => {
  const validationsData = useQuery(
    {
      getAllValidations: {
        ...everything,
        company: everything,
      },
    },
    userToken,
  );

  if (validationsData.isLoading) {
    return <Box>Loading product validation data</Box>;
  }

  const validations = validationsData.data?.getAllValidations;
  if (!validations) {
    return <Box>Empty validations, something is wrong</Box>;
  }

  return <ProductsValidationsList validations={validations} userToken={userToken} />;
};

export const ProductsValidations = () => {
  const [userToken, setUserToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    getAccessTokenSilently().then((token) => setUserToken(token));
  });

  if (!userToken) {
    return <Box>Authorizing</Box>;
  }

  return <ProductsValidationWrapper userToken={userToken} />;
};
