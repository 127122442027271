import axios, { AxiosRequestConfig } from 'axios';
import { GetLoansResponse, LoanToUpsert, UpsertLoanResponse } from '../types';

const getUrl = (env: string) => {
  const urlSuffix = env === 'prod' ? '' : `-${env}`;
  return `https://api3${urlSuffix}.finaloop.com`;
};

type BaseParams = { env: string; authToken: string };
const getClient = ({ env, authToken, config }: BaseParams & { config?: AxiosRequestConfig }) =>
  axios.create({
    baseURL: getUrl(env),
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    ...config,
  });

const upsert = async (params: BaseParams & { loan: LoanToUpsert }): Promise<void> => {
  await getClient(params).post<UpsertLoanResponse>('/admin/loan', { loan: params.loan });
};

const deleteLoan = async (params: BaseParams & { loanId: string }): Promise<void> => {
  await getClient(params).delete(`/loan/${params.loanId}`);
};

const get = async (params: BaseParams & { companyId: string }): Promise<GetLoansResponse> => {
  const companyId = params.companyId;
  const { data } = await getClient(params).get<GetLoansResponse>(`/loans`, { params: { companyId } });
  return data;
};

export const loansApi = { get, upsert, delete: deleteLoan };
