import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { CurrentRecurringCsvMissions } from './components/CurrentRecurringCsvMissions';
import { ExistingCsvMissions } from './components/ExistingCsvMissions';
import { NewCSVMission } from './components/new-csv-mission';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const CSVMissions = () => {
  const { selectedCompany } = useSelectedCompany();
  if (!selectedCompany) return <>First select company in the menu</>;

  return (
    <Container>
      <NewCSVMission />
      <Divider />
      <CurrentRecurringCsvMissions />
      <Divider />
      <ExistingCsvMissions />
    </Container>
  );
};
