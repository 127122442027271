import axios from 'axios';
import { gqlClient, ClassificationTag as ClassificationTagGql } from './FinaloopGql';

let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export type ClassificationTag = ClassificationTagGql;
interface ClassificationTagClassification {
  in?: string;
  out?: string;
}

export const getBillsClassificationTags = async (
  getToken: () => Promise<string>,
): Promise<{ tags: ClassificationTag[] }> => {
  const authToken = await getToken();
  if (!url.length) {
    throw new Error('Url is not set');
  }

  if (!authToken) {
    return { tags: [] };
  }

  const { classificationTags } = await gqlClient.query({
    classificationTags: [
      {
        input: { forBillsOnly: true },
      },
      {
        id: true,
        name: true,
        keywords: true,
        moneyDirection: true,
        isRelevantToBills: true,
        displayNameForReports: true,
        isRelevantToApplyAlways: true,
        isHidden: true,
        isApplicableForRefund: true,
        __typename: true,
        classification: {
          __typename: true,
          in: true,
          out: true,
        },
      },
    ],
  });

  console.log('classificationTags', classificationTags);

  return { tags: classificationTags };
};
