import styled from '@emotion/styled';
import { ContentCopy, Delete } from '@mui/icons-material';
import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { useAccountingTypeFinder } from '../../hooks/useAccountingTypeFinder';
import { useFixbooksApi } from '../../hooks/useFixbooksApi';
import { AccountingType, BusinessEvent, FixbooksAction } from '../../types';
import { accountingTypeToColor, accountingTypeToText, timeInMsToFriendlyTime } from '../../utils';
import { ClassificationTag } from '../../../../services/FinaloopGql';

export type BusinessEventItemProps = {
  event: BusinessEvent;
  classificationTag?: ClassificationTag;
};

const Flex = styled.div`
  display: flex;
  gap: 5px;
`;

const HorizontalFlex = styled(Flex)`
  flex-direction: row;
`;

const VerticalFlex = styled(Flex)`
  flex-direction: column;
`;

const Container = styled(HorizontalFlex)`
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 10px;
`;

const ActionsContainer = styled(VerticalFlex)`
  width: 100%;
  align-items: flex-start;
`;

const ButtonsWrapper = styled(VerticalFlex)`
  margin: 0 10px;
  align-items: center;
  justify-content: center;
`;

const BaseTextWrapper = styled.div`
  width: 200px;
  flex-shrink: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DateAndTypeTextWrapper = styled(BaseTextWrapper)`
  width: 120px;
  font-size: 16px;
  color: black;
`;

const RoleTextWrapper = styled(BaseTextWrapper)``;

const ContextTextWrapper = styled(BaseTextWrapper)``;

const SignTextWrapper = styled(BaseTextWrapper)`
  width: 100px;
`;

const DescriptionTextWrapper = styled(BaseTextWrapper)`
  width: 400px;
`;

const MemoTextWrapper = styled(BaseTextWrapper)`
  width: 100%;
`;

const Titles = styled(HorizontalFlex)`
  font-size: 13px;
  color: gray;
  align-items: center;
`;

export const BusinessEventItem = (props: BusinessEventItemProps) => {
  const {
    classificationTag,
    event: {
      rawEvent: { memo, context, merchant, eventTimeMs, cashActions, accrualActions },
      isSynced,
    },
  } = props;
  const { useDeleteFixbooksEvent } = useFixbooksApi();
  const { deleteFixbooksEvent, isDeletingFixbooksEvent, hasErrorDeletingFixbooksEvent } = useDeleteFixbooksEvent();
  const { getAccountingType } = useAccountingTypeFinder();

  const onCopyClicked = useCallback(async () => {
    console.log('copying', props.event);
    await navigator.clipboard.writeText(JSON.stringify(props.event));
  }, [props.event]);

  const onDeleteClicked = useCallback(async () => {
    if (window.confirm('are you sure?')) {
      console.log('deleting', props.event.businessEventId);
      await deleteFixbooksEvent({ eventId: props.event.businessEventId });
    }
  }, [deleteFixbooksEvent, props.event]);

  const accountingType: AccountingType = getAccountingType(props.event);

  const isShowingMessagesSections = hasErrorDeletingFixbooksEvent;

  return (
    <AccountingTypeWrapper type={accountingType} dateInMs={eventTimeMs}>
      {context && (
        <Box sx={{ ml: 1, mt: 1 }}>
          <Typography fontSize={12} display="inline-block" fontWeight="bold" mr={1}>
            Context:
          </Typography>
          <Chip label={context} color="primary" variant="outlined" size="small" />
        </Box>
      )}
      {merchant && (
        <Box sx={{ ml: 1, mt: 1 }}>
          <Typography fontSize={12} display="inline-block" fontWeight="bold" mr={1}>
            Merchant:
          </Typography>
          <Chip label={`${merchant.name} (${merchant.__typename})`} color="secondary" variant="outlined" size="small" />
        </Box>
      )}
      {classificationTag?.id && (
        <Box sx={{ ml: 1, mt: 1 }}>
          <Typography fontSize={12} display="inline-block" fontWeight="bold" mr={1}>
            Classification tag:
          </Typography>
          <Chip label={classificationTag.name} color="secondary" variant="outlined" size="small" />
        </Box>
      )}
      <Container>
        <ActionsContainer>
          {accountingType === 'Different' && (
            <>
              <AccountingTypeWrapper type={'Cash'}>
                <ActionsTable actions={cashActions} />
              </AccountingTypeWrapper>
              <AccountingTypeWrapper type={'Accrual'}>
                <ActionsTable actions={accrualActions} />
              </AccountingTypeWrapper>
            </>
          )}
          {accountingType === 'Both' && <ActionsTable actions={accrualActions} />}
          {accountingType === 'Cash' && <ActionsTable actions={cashActions} />}
          {accountingType === 'Accrual' && <ActionsTable actions={accrualActions} />}
          {memo && (
            <>
              <VerticalFlex>
                <Titles>Memo</Titles>
                <ActionWrapper>
                  <MemoTextWrapper>{memo}</MemoTextWrapper>
                </ActionWrapper>
              </VerticalFlex>
            </>
          )}
        </ActionsContainer>
        <ButtonsWrapper>
          <Tooltip title="Copy" arrow>
            <IconButton onClick={onCopyClicked}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
          {isSynced &&
            (isDeletingFixbooksEvent ? (
              <Tooltip title="Deleting" arrow>
                <CircularProgress size={20} />
              </Tooltip>
            ) : (
              <Tooltip title="Delete" arrow>
                <IconButton disabled={isDeletingFixbooksEvent} onClick={onDeleteClicked}>
                  <Delete />
                </IconButton>
              </Tooltip>
            ))}
          {!isSynced && (
            <Tooltip
              title="The event is not yet synced, hence can't be deleted. Please try again in a few seconds"
              arrow
            >
              <div>Not yet synced</div>
            </Tooltip>
          )}
        </ButtonsWrapper>
      </Container>
      {isShowingMessagesSections && (
        <Box padding={2} color={'red'}>
          {hasErrorDeletingFixbooksEvent && <Box>Failed to delete</Box>}
        </Box>
      )}
    </AccountingTypeWrapper>
  );
};

const ActionTableWrapper = styled(VerticalFlex)`
  width: 100%;
`;

const ActionsTable = (props: { actions: FixbooksAction[]; classificationTag?: ClassificationTag }) => {
  return !props.actions?.length ? (
    <>no actions</>
  ) : (
    <VerticalFlex>
      <Titles>
        <RoleTextWrapper>Role</RoleTextWrapper>
        <ContextTextWrapper>Old Context</ContextTextWrapper>
        <SignTextWrapper>Debit</SignTextWrapper>
        <SignTextWrapper>Credit</SignTextWrapper>
        <DescriptionTextWrapper>Description</DescriptionTextWrapper>
      </Titles>
      <HorizontalFlex>
        <ActionTableWrapper>
          {props.actions.map(({ accountRole, accountContext, sign, amount, description }, index) => (
            <ActionWrapper key={index}>
              <Tooltip title={accountRole || ''} arrow>
                <RoleTextWrapper>{accountRole}</RoleTextWrapper>
              </Tooltip>
              <Tooltip title={accountContext || ''} arrow>
                <ContextTextWrapper>{accountContext}</ContextTextWrapper>
              </Tooltip>
              <SignTextWrapper>{sign === 'debit' ? amount : 0}</SignTextWrapper>
              <SignTextWrapper>{sign === 'credit' ? amount : 0}</SignTextWrapper>
              <Tooltip title={description || ''} arrow>
                <DescriptionTextWrapper>{description}</DescriptionTextWrapper>
              </Tooltip>
            </ActionWrapper>
          ))}
        </ActionTableWrapper>
      </HorizontalFlex>
    </VerticalFlex>
  );
};

const ActionWrapper = styled(HorizontalFlex)`
  width: 100%;
  background-color: #f8f8f8;
`;

const AccountingTypeContainer = styled(VerticalFlex)<{ type: AccountingType }>`
  border-color: ${props => accountingTypeToColor[props.type]};
  border-width: 2px;
  border-style: solid;
  border-radius: 0 10px 10px 10px;
`;

const AccountingTypeText = styled(HorizontalFlex)<{ type: AccountingType }>`
  background-color: ${props => accountingTypeToColor[props.type]};
  width: auto;
  justify-self: flex-start;
  align-self: flex-start;
  text-align: center;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  margin-bottom: -5px;
`;

const AccountingTypeWrapper = (props: { type: AccountingType; dateInMs?: number; children?: ReactNode }) => {
  const { dateInMs, type, children } = props;
  return (
    <VerticalFlex>
      <AccountingTypeText type={type}>
        {dateInMs && <label>{timeInMsToFriendlyTime(dateInMs)}</label>}
        <label>{accountingTypeToText[type]}</label>
      </AccountingTypeText>
      <AccountingTypeContainer type={type}>{children}</AccountingTypeContainer>
    </VerticalFlex>
  );
};
