module.exports = {
  scalars: [0, 1, 3, 4, 6, 7, 13, 19, 20, 31, 32, 33],
  types: {
    Date: {},
    JSON: {},
    UserProductData: {
      unitCost: [3],
      lineOfBusiness: [4],
      __typename: [4],
    },
    Float: {},
    String: {},
    Product: {
      id: [4],
      sku: [4],
      companyId: [4],
      integrationAccountId: [4],
      price: [3],
      displayName: [4],
      inventoryQuantity: [6],
      imageUrl: [4],
      isDeleted: [7],
      unitCost: [3],
      userData: [2],
      active: [7],
      on_ShopifyProduct: [8],
      __typename: [4],
    },
    Int: {},
    Boolean: {},
    ShopifyProduct: {
      id: [4],
      sku: [4],
      companyId: [4],
      integrationAccountId: [4],
      price: [3],
      displayName: [4],
      inventoryQuantity: [6],
      imageUrl: [4],
      isDeleted: [7],
      unitCost: [3],
      userData: [2],
      variantId: [4],
      productId: [4],
      title: [4],
      active: [7],
      __typename: [4],
    },
    GetProductsArgs: {
      fetchDeleted: [7],
      __typename: [4],
    },
    AddUserProductAppliedCostInput: {
      productId: [4],
      appliedCost: [3],
      asOfDate: [0],
      __typename: [4],
    },
    GetSoldProductsInPeriodArgs: {
      startDate: [0],
      endDate: [0],
      fetchZeroPriced: [7],
      productIds: [4],
      __typename: [4],
    },
    SoldProductInPeriod: {
      product: [5],
      itemsSold: [6],
      integrationType: [13],
      on_FinaloopSoldProductInPeriod: [14],
      on_UserAdjustedSoldProductInPeriod: [15],
      __typename: [4],
    },
    IntegrationType: {},
    FinaloopSoldProductInPeriod: {
      product: [5],
      itemsSold: [6],
      integrationType: [13],
      integrationAccountName: [4],
      __typename: [4],
    },
    UserAdjustedSoldProductInPeriod: {
      product: [5],
      itemsSold: [6],
      integrationType: [13],
      __typename: [4],
    },
    GetProductSalesTimelineArgs: {
      id: [4],
      startDate: [0],
      endDate: [0],
      __typename: [4],
    },
    ProductSalesTimelineItem: {
      date: [0],
      itemsSold: [6],
      itemsSoldToDate: [6],
      __typename: [4],
    },
    ProductSalesTimeline: {
      timeline: [17],
      totalSold: [6],
      totalRefunded: [6],
      __typename: [4],
    },
    ValidationStatus: {},
    ValidationIntegrationType: {},
    ValidationMismatchMetrics: {
      finaloopEvents: [1],
      integrationMetrics: [1],
      __typename: [4],
    },
    ValidationMismatchData: {
      orderId: [4],
      isOk: [7],
      metricsData: [1],
      finaloopData: [1],
      __typename: [4],
    },
    ValidationDetails: {
      mismatchData: [22],
      __typename: [4],
    },
    Company: {
      companyId: [4],
      companyName: [4],
      __typename: [4],
    },
    Validation: {
      company: [24],
      integrationAccountId: [4],
      integrationType: [20],
      status: [19],
      date: [0],
      __typename: [4],
    },
    GetValidationDetailsArgs: {
      companyId: [4],
      integrationAccountId: [4],
      __typename: [4],
    },
    ComputeValidationMismatchDetailsArgs: {
      companyId: [4],
      integrationAccountId: [4],
      integrationType: [20],
      timeRangeStart: [0],
      timeRangeEnd: [0],
      __typename: [4],
    },
    RerunValidationInput: {
      companyId: [4],
      integrationAccountId: [4],
      __typename: [4],
    },
    Query: {
      getAllValidations: [25],
      getValidationDetails: [
        23,
        {
          args: [26, 'GetValidationDetailsArgs!'],
        },
      ],
      __typename: [4],
    },
    Mutation: {
      rerunValidation: [
        25,
        {
          args: [28, 'RerunValidationInput!'],
        },
      ],
      __typename: [4],
    },
    UserToken: {},
    RequestedCompanyIdScalar: {},
    JSONObject: {},
  },
};
