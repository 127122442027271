import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { loanAgreementsApi } from '../services/loan-agreements-api';
import { LoanResponse } from '../types';

const AgreementsContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;

const AgreementLink = styled.li`
  text-decoration: underline;
  cursor: pointer;
  color: blue;

  &:hover {
    background-color: lightgray;
  }
`;

export const LoanAgreements = ({ loan }: { loan: LoanResponse }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentEnv: env } = useContext(environmentContext);

  const onAgreementDownloadClicked = async (fileName: string) => {
    console.log('downloading', fileName);
    const authToken = await getAccessTokenSilently();
    loanAgreementsApi.downloadAgreement({ loanId: loan.id, fileName, authToken, env });
  };

  return (
    <AgreementsContainer>
      {loan.agreementFileNames.map((agreement, index) => (
        <AgreementLink key={index} onClick={() => onAgreementDownloadClicked(agreement)}>
          {agreement}
        </AgreementLink>
      ))}
    </AgreementsContainer>
  );
};
