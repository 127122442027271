import { useContext, useEffect } from 'react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { FetchHookState } from './types/FetchHookState';
// import { Company } from '../../../types/Company';
import { useQuery } from 'react-query';
import { useAuthToken } from '../../../AuthContext';
import { Company, everything, gqlClient } from '../../../services/FinaloopGql';

export type UseListCompaniesState = FetchHookState<Company[]>;

export const useListCompanies = () => {
  const { CompanyApiClient, currentEnv } = useContext(environmentContext);

  const { isError, isFetching, data, refetch } = useQuery(
    'get-companies',
    async () => {
      return await CompanyApiClient.listCompanies();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch, currentEnv]);

  return { data, isError, isLoading: isFetching };
};

export const useListCompanies2 = () => {
  const { authToken } = useAuthToken();

  const { isError, isFetching, data } = useQuery({
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: Number.POSITIVE_INFINITY,
    queryKey: 'get-companies-2',
    enabled: !!authToken,
    queryFn: async () => {
      const { listCompanies } = await gqlClient.query({
        listCompanies: {
          id: 1,
          name: 1,
          userName: 1,
          isManufacturer: 1,
          booksStartTime: 1,
          phoneNumber: 1,
          address: everything,
          generalDetails: 1,
          accountingMethod: 1,
          customerSelectedAccountingMethod: 1,
          configuration: everything,
          isTestCompany: 1,
          isPaying: 1,
          lockStatus: everything,
        },
      });

      return (listCompanies as any) as Company[];
    },
  });

  return { data, isError, isLoading: isFetching };
};
