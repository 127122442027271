import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import 'moment-timezone';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import './App.css';
import { setAuthTokenFetcher } from './environmentContext/auth';

const AuthTokenContext = createContext<{ authToken: string }>({} as any);
export const useAuthToken = () => useContext(AuthTokenContext);

export const AuthListener = (props: PropsWithChildren<{}>) => {
  const { getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>();

  const refreshAuthToken = async () => {
    const authToken = await getAccessTokenSilently();
    setAuthToken(authToken);
  };

  useEffect(() => {
    refreshAuthToken();
    setAuthTokenFetcher(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (!authToken) return <CircularProgress />;

  return (
    <AuthTokenContext.Provider value={{ authToken }}>
      {props.children}
    </AuthTokenContext.Provider>
  );
};
