import styled from '@emotion/styled';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import copyToClipboard from 'copy-to-clipboard';
import { useCallback, useMemo } from 'react';
import { Company } from '../../services/FinaloopGql';
import { Selector } from './Selector';
import { useListCompanies2 } from './hooks/useListCompanies';
import { useQueryState } from './hooks/useQueryState';
import { useSelectedCompany } from './hooks/useSelectedCompany';

const CompanySelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const getCompanyDetailsToCopy = (company: Company | undefined): string => {
  if (!company) return 'Company not selected';
  const { name: companyName, id } = company;
  return JSON.stringify({ companyName, id });
};

export const CompanySelector = () => {
  const [isShowingTestCompanies, setIsShowingTestCompanies] = useQueryState<
    boolean
  >('showTestCompanies', false);

  const { selectedCompany, setSelectedCompany } = useSelectedCompany();
  const { data: companiesList, isLoading } = useListCompanies2();

  const onFilterClicked = useCallback(
    (isShowingTestCompanies: boolean) => {
      setIsShowingTestCompanies(isShowingTestCompanies);
    },
    [setIsShowingTestCompanies]
  );

  const onCompanySelect = (company: Company) => {
    setSelectedCompany(company);
  };

  const companyFromList = useMemo(
    () => companiesList?.find((company) => company.id === selectedCompany?.id),
    [companiesList, selectedCompany]
  );
  const filteredCompanies = useMemo(
    () =>
      (isShowingTestCompanies
        ? companiesList
        : companiesList?.filter((company) => !company.isTestCompany)
      )?.filter(
        (company) =>
          !['archive', 'archived'].includes(company.email?.toLowerCase?.())
      ),
    [isShowingTestCompanies, companiesList]
  );

  const onCopyCompanyDetailsClicked = useCallback(() => {
    copyToClipboard(getCompanyDetailsToCopy(selectedCompany));
  }, [selectedCompany]);

  const onCopyCompanyIdClicked = useCallback(() => {
    copyToClipboard(selectedCompany?.id || '');
  }, [selectedCompany]);

  if (!companiesList) return null;

  return (
    <CompanySelectorWrapper>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isShowingTestCompanies}
              onChange={(_) => onFilterClicked(_.target.checked)}
            />
          }
          label='show test companies'
        />
      </FormGroup>
      <Selector
        filterOptions={(options, inputValue) => {
          if (!inputValue) return options;
          const textToSearch = inputValue.toLowerCase();
          return options.filter((_: Company) =>
            [_.id, _.name, _.email, _.userName]
              .filter(Boolean)
              .map((field) => field!.toLowerCase())
              .some((field) => field.includes(textToSearch))
          );
        }}
        isOptionEqualToValue={(_, value) => _.name === value.name}
        initialValue={companyFromList?.name}
        disabled={false}
        data={filteredCompanies || []}
        title={'Company'}
        onSelect={onCompanySelect}
        labelExtractor={(e) => e.name ?? ''}
        loading={isLoading}
      />
      {!!selectedCompany && (
        <>
          <Button variant='text' onClick={onCopyCompanyIdClicked}>
            Copy company id
          </Button>
          <Button variant='text' onClick={onCopyCompanyDetailsClicked}>
            Copy company details
          </Button>
        </>
      )}
    </CompanySelectorWrapper>
  );
};
