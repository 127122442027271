import { safeNumber } from '../../../common/money';
import { FixbooksAction } from '../../types';

export const salesBasedAccountRoles = new Set([
  'Inventory - sales-based',
  'Cost of goods sold - sales-based',
  'Inventory in-process - sales-based',
  'Inventory in-transit - sales-based',
]);

export const purchaseBasedAccountRoles = new Set([
  'Cost of goods sold - purchase-based',
  'Packaging supplies - purchase-based',
  'Shipping & freight-in - purchase-based',
  'Inventory - purchase-based',
  'Inventory in-process - purchase-based',
  'Inventory in-transit - purchase-based',
]);

export const inventoryAccountRoles = new Set([...salesBasedAccountRoles, ...purchaseBasedAccountRoles]);

export interface StandardActionsValidation {
  hasInventoryAccountRoles: false;
  isValid: boolean;
  totalDebits: number;
  totalCredits: number;
}

export interface InventoryActionsValidation {
  hasInventoryAccountRoles: true;
  isValid: boolean;
  salesBasedTotalDebits: number;
  salesBasedTotalCredits: number;
  purchaseBasedTotalDebits: number;
  purchaseBasedTotalCredits: number;
}

export type AccountActionsValidation = (StandardActionsValidation | InventoryActionsValidation) & {
  renderSummaryRow: () => JSX.Element;
};

export const useAccountActionsValidation = (actions: FixbooksAction[]): AccountActionsValidation => {
  const accountRoles = actions.map(({ accountRole }) => accountRole);

  if (accountRoles.every((accountRole) => !inventoryAccountRoles.has(accountRole))) {
    const totalDebits = sumSign(actions, 'debit');
    const totalCredits = sumSign(actions, 'credit');

    const result = {
      hasInventoryAccountRoles: false,
      isValid: totalCredits === totalDebits,
      totalDebits,
      totalCredits,
    };

    return { ...result, hasInventoryAccountRoles: false, renderSummaryRow: createRenderSummaryRow(result as any) };
  }

  const { salesBased, purchaseBased } = partitionByInventoryManagementMethod(actions);

  const salesBasedTotalDebits = sumSign(salesBased, 'debit');
  const salesBasedTotalCredits = sumSign(salesBased, 'credit');
  const purchaseBasedTotalDebits = sumSign(purchaseBased, 'debit');
  const purchaseBasedTotalCredits = sumSign(purchaseBased, 'credit');

  const result = {
    hasInventoryAccountRoles: true,
    isValid: salesBasedTotalCredits === salesBasedTotalDebits && purchaseBasedTotalCredits === purchaseBasedTotalDebits,
    salesBasedTotalDebits,
    salesBasedTotalCredits,
    purchaseBasedTotalDebits,
    purchaseBasedTotalCredits,
  };

  return { ...result, hasInventoryAccountRoles: true, renderSummaryRow: createRenderSummaryRow(result as any) };
};

const sumSign = (data: FixbooksAction[], sign: 'credit' | 'debit'): number =>
  data
    .filter((_) => _.sign === sign)
    .map((d) => d.amount)
    .reduce((totalAmount, amount) => totalAmount.plus(amount || 0), safeNumber(0))
    .toNumber();

const partitionByInventoryManagementMethod = (actions: FixbooksAction[]) => {
  return {
    salesBased: partitionForManagementMethod(actions, salesBasedAccountRoles),
    purchaseBased: partitionForManagementMethod(actions, purchaseBasedAccountRoles),
  };
};

const partitionForManagementMethod = (actions: FixbooksAction[], applicableAccountRoles: Set<string>) => {
  const results = actions.filter(({ accountRole }) => {
    if (!inventoryAccountRoles.has(accountRole)) return true;
    return applicableAccountRoles.has(accountRole);
  });
  return results;
};

const createRenderSummaryRow = (result: InventoryActionsValidation | StandardActionsValidation) => {
  return () =>
    hasInventoryAccountRoles(result) ? renderInventorySummaryRow(result) : renderStandardSummaryRow(result);
};

const hasInventoryAccountRoles = (result: any): result is InventoryActionsValidation => result.hasInventoryAccountRoles;

const renderInventorySummaryRow = ({
  isValid,
  purchaseBasedTotalDebits,
  purchaseBasedTotalCredits,
  salesBasedTotalCredits,
  salesBasedTotalDebits,
}: InventoryActionsValidation) => {
  return (
    <SummaryRowContainer isValid={isValid}>
      <div className="space-y-2">
        <div>
          <div className="font-bold">Sales Based:</div>
          <div className="flex space-x-2">
            <span>Total Debits: {salesBasedTotalDebits}</span>
            <span>Total Credits: {salesBasedTotalCredits}</span>
          </div>
        </div>

        <div>
          <div className="font-bold">Purchase Based:</div>
          <div className="flex space-x-2">
            <span>Total Debits: {purchaseBasedTotalDebits}</span>
            <span>Total Credits: {purchaseBasedTotalCredits}</span>
          </div>
        </div>
      </div>
    </SummaryRowContainer>
  );
};

const renderStandardSummaryRow = ({ isValid, totalDebits, totalCredits }: StandardActionsValidation) => {
  return (
    <SummaryRowContainer isValid={isValid}>
      <div className="flex space-x-2">
        <span>Total Debits: {totalDebits}</span>
        <span>Total Credits: {totalCredits}</span>
      </div>
    </SummaryRowContainer>
  );
};

const SummaryRowContainer = ({ isValid, children }: { isValid: boolean; children: JSX.Element }) => {
  const sharedClasses = 'px-3 py-1 rounded-lg';
  return <div className={isValid ? `${sharedClasses} bg-green-200` : `${sharedClasses} bg-red-200`}>{children}</div>;
};
