import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FetchBusinessEventsParams } from '../../../services/businessEventApiClient';
import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';

export type UseFetchBusinessEventsForMetricState = FetchHookState<any | undefined>;

const defaultState: UseFetchBusinessEventsForMetricState = {
  data: undefined,
  loading: true,
};

export const useFetchBusinessEventsForMetric = ({
  companyId,
  accountFragments,
  ...optionalParams
}: Partial<FetchBusinessEventsParams>): UseFetchBusinessEventsForMetricState => {
  const { getAccessTokenSilently } = useAuth0();
  const [fetchHookState, setFetchHookState] = useState<UseFetchBusinessEventsForMetricState>(defaultState);
  const services = useContext(environmentContext);
  useEffect(() => {
    if (!companyId || !accountFragments) {
      setFetchHookState({ ...defaultState, loading: false });
      return;
    }
    setFetchHookState(defaultState);
    getAccessTokenSilently()
      .then((authToken) =>
        services.BusinessEventsApiClient.fetchBusinessEvents({
          authToken,
          companyId,
          accountFragments,
          ...optionalParams,
        }),
      )
      .then((data) => {
        setFetchHookState({
          data,
          loading: false,
        });
      })
      .catch((error) => {
        setFetchHookState({
          data: undefined,
          error,
          loading: false,
        });
      });
    // eslint-disable-next-line
  }, [services, companyId, accountFragments, ...Object.values(optionalParams)]);
  return fetchHookState;
};
