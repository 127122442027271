import { useCallback, useEffect, useState, Fragment, HTMLAttributes, ReactNode } from 'react';
import { uniqBy } from 'lodash';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

interface SelectorProps<T> {
  filterOptions?: (options: T[], input: string) => T[];
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  data: T[];
  labelExtractor?: (element: T) => string;
  onSelect: (selectedElement: T) => void;
  onRenderOption?: (props: HTMLAttributes<HTMLLIElement>, option: T, state: any) => ReactNode | undefined;
  title?: string;
  disabled?: boolean;
  initialValue?: string;
  resetInput?: string;
  loading?: boolean;
  InputIcon?: () => JSX.Element;
  value?: T;
}

const identity = (a: any) => a;

export function Selector<T>({
  filterOptions,
  title,
  data,
  labelExtractor,
  onSelect,
  disabled,
  initialValue,
  resetInput,
  loading,
  onRenderOption,
  InputIcon,
  isOptionEqualToValue,
  value: selectedValue,
}: SelectorProps<T>) {
  const safeLabelExtractor = useCallback(
    (element: T) => (labelExtractor ? labelExtractor(element) : identity(element)),
    [labelExtractor],
  );
  const [value, setValue] = useState<string>(selectedValue ? safeLabelExtractor(selectedValue) : '');

  const onChange = useCallback(
    (_, newValue) => {
      onSelect(newValue ?? '');
      const valueToSet = safeLabelExtractor(newValue ?? '');
      setValue(valueToSet || '');
    },
    [onSelect, safeLabelExtractor],
  );

  const onInputChange = useCallback(
    (event, newInput, reason) => {
      if (reason === 'input') {
        return setValue(newInput);
      }
      if (reason === 'clear') {
        return setValue('');
      }
      if (reason === 'reset' && !initialValue) {
        return setValue(resetInput ?? newInput);
      }
    },
    [initialValue, resetInput],
  );

  useEffect(() => {
    if (initialValue !== null && initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const uniqueData = uniqBy(data, safeLabelExtractor);

  return (
    <Autocomplete
      inputValue={value}
      disabled={disabled}
      filterOptions={!filterOptions ? undefined : (options, state) => filterOptions(options, state.inputValue)}
      disablePortal
      value={selectedValue}
      options={uniqueData}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) => safeLabelExtractor(option as T)}
      sx={{ width: '100%', maxWidth: '360px', minWidth: '240px' }}
      onChange={onChange}
      onInputChange={onInputChange}
      renderOption={onRenderOption}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          multiline
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress className="self-baseline" color="inherit" size={20} /> : null}
                {InputIcon ? <InputIcon /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
