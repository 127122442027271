import styled from '@emotion/styled';
import { Box, Button, Modal, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Selector } from '../../common/Selector';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { useLoans } from '../hooks/useLoans';
import { lenders, unlistedLender } from '../services/lenders-provider';
import { LoanLender, LoanStatus } from '../types';

const TupleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  label:first-of-type {
    color: gray;
    text-decoration: underline;
  }
`;

const Tuple = ({ label, value }: { label: string; value?: any }) => (
  <TupleContainer>
    <label>{label}:</label>
    {value}
  </TupleContainer>
);

export const AddANewLoan = () => {
  const [isAddLoanPopupOpen, setIsAddLoanPopupOpen] = useState(false);
  const [selectedLender, setSelectedLender] = useState<
    LoanLender | undefined
  >();
  const [selectedDate, setSelectedDate] = useState<Moment | null>();
  const [selectedAmount, setSelectedAmount] = useState<number | undefined>();
  const [otherLenderName, setOtherLenderName] = useState<string | undefined>();
  const { selectedCompany } = useSelectedCompany();

  const { useUpsertLoan } = useLoans();
  const {
    isSaving,
    reset,
    hadErrorSavingLoan,
    savedSuccessfully,
    upsertLoan,
  } = useUpsertLoan('new-loan');

  const onModalClose = () => {
    setIsAddLoanPopupOpen(false);
    setSelectedLender(undefined);
    setSelectedDate(undefined);
    setSelectedAmount(undefined);
    setOtherLenderName(undefined);
    reset();
  };

  const isOtherLenderSelected = selectedLender?.id === unlistedLender.id;

  const onSaveClick = async () => {
    await upsertLoan({
      companyId: selectedCompany?.id,
      lenderId: selectedLender?.id,
      status: LoanStatus.actionRequired,
      amount: selectedAmount,
      commencementDateMs: selectedDate?.valueOf(),
      unlistedLenderName: isOtherLenderSelected ? otherLenderName : undefined,
    });
  };

  useEffect(() => {
    setOtherLenderName(undefined);
  }, [selectedLender]);

  return (
    <>
      <Button fullWidth={false} onClick={() => setIsAddLoanPopupOpen(true)}>
        add a new loan
      </Button>
      <Modal open={isAddLoanPopupOpen} onClose={onModalClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '400px',
            backgroundColor: 'white',
            padding: '10px',
            position: 'absolute',
            top: '30%',
            left: '30%',
          }}>
          {!selectedCompany && (
            <div>First select a company in the main screen</div>
          )}
          <label>all fields are optional</label>
          <Tuple label='Company' value={selectedCompany?.name} />
          <Tuple
            label='Lender'
            value={
              <Selector
                data={lenders}
                onSelect={setSelectedLender}
                labelExtractor={(_) => _.name}
              />
            }
          />
          <Tuple
            label='Commencement date'
            value={
              <DatePicker
                inputFormat='yyyy-MM-DD'
                views={['year', 'month', 'day']}
                label='Year-Month-Day'
                onChange={setSelectedDate}
                renderInput={(params: any) => (
                  <TextField {...params} helperText={null} />
                )}
                value={selectedDate}
              />
            }
          />
          <Tuple
            label='Amount'
            value={
              <TextField
                onChange={(_) => {
                  const value = _.target.value;
                  if (!value) setSelectedAmount(undefined);
                  else setSelectedAmount(Number(_.target.value));
                }}
              />
            }
          />

          {isOtherLenderSelected && (
            <Tuple
              label='Other lender name'
              value={
                <TextField
                  onChange={(_) => setOtherLenderName(_.target.value)}
                />
              }
            />
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}>
            {!savedSuccessfully && (
              <Button
                disabled={!selectedCompany || isSaving}
                onClick={onSaveClick}>
                Save
              </Button>
            )}
            <Button variant='outlined' onClick={onModalClose}>
              {savedSuccessfully ? 'Close' : 'Cancel'}
            </Button>
            {isSaving && <label>Saving...</label>}
            {hadErrorSavingLoan && (
              <label>There was an error saving the loan</label>
            )}
            {savedSuccessfully && <label>Loan saved successfully</label>}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
