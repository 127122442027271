import { useCallback, useEffect, useState } from 'react';
import { useGetCompanyQbAccounts } from '../../common/hooks/useGetCompanyQbAccounts';
import { CompanyQuickbooksAccountState } from '../types';
import { ClassificationTag } from '../../../services/classificationTagsApiClient';

export const useQuickbooksAccountsData = (
  companyId: string,
  classificationTags: ClassificationTag[],
): [
  CompanyQuickbooksAccountState[],
  (rowIndex: number, columnId: number, value: ClassificationTag) => void,
  boolean,
] => {
  const { data: companyQbAccountsResponse, isLoading: isLoadingAccounts } = useGetCompanyQbAccounts(companyId);

  const [companyQbAccountsState, setCompanyQbAccountsState] = useState<CompanyQuickbooksAccountState[]>([]);
  useEffect(() => {
    setCompanyQbAccountsState(
      (companyQbAccountsResponse || []).map((item) => ({
        ...item,
        originalFinaloopClassificationTagId: item.finaloopClassificationTagId,
      })),
    );
  }, [companyQbAccountsResponse, isLoadingAccounts]);

  const updateQbAccountsData = useCallback(
    (rowIndex: number, columnId: number, value: ClassificationTag) => {
      setCompanyQbAccountsState((quickbooksAccounts) => {
        const quickbooksAccountsCopy = [...quickbooksAccounts];
        const updatedItem = { ...quickbooksAccountsCopy[rowIndex], [columnId]: value.id };
        quickbooksAccountsCopy[rowIndex] = updatedItem;

        return quickbooksAccountsCopy;
      });
    },
    [classificationTags],
  );

  return [companyQbAccountsState, updateQbAccountsData, isLoadingAccounts];
};
