import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { gqlClient } from "../../services/FinaloopGql";
import { Bank } from "./types";

export const useCompanyBanks = () => {
  const { selectedCompany } = useSelectedCompany();

  const {
    isError,
    isFetching,
    refetch,
    data: companyBanks,
    remove,
  } = useQuery({
    queryKey: ['GET_COMPANY_BANKS', selectedCompany, selectedCompany?.id],
    queryFn: async () => {
      if (!selectedCompany) return [];
      console.log('selectedCompany',selectedCompany);
      return await getBankAccounts(selectedCompany.id);
    },

    refetchOnWindowFocus: false,
    staleTime: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    remove();
    refetch();
  }, [refetch, selectedCompany]);

  return { companyBanks, isError, isLoading: isFetching };
};

export const getBankAccounts = async (companyId: string) => {
  const { bankAccounts } = await gqlClient.query({
    bankAccounts: [{ input: {
        companyId,
        statuses: ['ACTIVE'],
      }},{
      companyId: true,
        id: true,
        source: true,
        integrationAccountId: true,
        institutionName: true,
        name: true,
        fullName: true,
        displayName: true,
        type: true,
        subtype: true,
        mask: true,
        status: true,
        duplicateOfBankAccountId: true,
        isHistoricalUpdateCompleted: true,
        currency: true,
        currentBalance: true,
      }]});

  return bankAccounts.map((account) => ({
    id: account.id,
    type: account.type,
    displayName: account.displayName,
    fullName: account.fullName,
    name: account.name,
    mask: account.mask,
    institutionName: account.institutionName,
    currentBalance: account.currentBalance,
  })) as Bank[];
};
