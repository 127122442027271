import { type SyntheticEvent, useCallback } from 'react';
import { createFilterOptions, type FilterOptionsState } from '@mui/material';

import type { MerchantSelectorProps } from './types';
import { Merchant } from '../../../services/FinaloopGql';

type UseHandleSelectionChangeArgs = Pick<MerchantSelectorProps, 'companyId' | 'canAdd' | 'onChange'> & {
  setIsAdding: (a: boolean) => void;
};

export function useHandleSelectionChange(args: UseHandleSelectionChangeArgs) {
  const { canAdd, onChange } = args;

  return useCallback(
    async (event: SyntheticEvent<Element, Event>, value: unknown) => {
      if (value == null) {
        onChange?.(undefined);
        return;
      }

      if (typeof value === 'string') {
        throw new Error('Cannot handle string values');
        // const vendor = await addNewLocalVendor(value, args);
        //
        // return onChange?.(vendorToMerchant(vendor));
      }

      const { inputValue } = value as { inputValue: string };

      if (!inputValue) {
        onChange?.(value as any);
        return;
      }

      if (!canAdd) return;

      throw new Error('Creation of local vendors is not implemented.');
      // const vendor = await addNewLocalVendor(inputValue, args);
      //
      // return onChange?.(vendorToMerchant(vendor));
    },
    [args, canAdd, onChange],
  );
}

const addNewLocalVendor = async (name: string, { companyId, setIsAdding }: UseHandleSelectionChangeArgs) => {
  throw new Error('Not implemented');
  // if (companyId == null) throw new Error('Cannot add a local vendor without a company id!');
  //
  // setIsAdding(true);
  //
  // const createdCompanyVendor = await CompanyVendorsService.getInstance().createCompanyVendor(
  //   { name: name.trim(), type: VendorType.LOCAL, logoUrl: null },
  //   companyId,
  // );
  //
  // console.log('createdCompanyVendor', createdCompanyVendor);
  //
  // setIsAdding(false);
  //
  // return createdCompanyVendor;
};

// const vendorToMerchant = (vendor: Vendor): Merchant => ({
//   id: vendor.id,
//   name: vendor.name,
//   type: 'VENDOR',
//   logo: vendor.logoUrl || undefined,
//   merchantData: vendor,
//   __typename: 'Merchant',
// });

const filterOptions = createFilterOptions({ limit: 100 });

export function useFilter(canAdd?: boolean | null) {
  return useCallback(
    (options: unknown[], state: FilterOptionsState<unknown>) => {
      const filtered = filterOptions(options, state);

      const { inputValue } = state;

      if (!inputValue) {
        return filtered;
      }

      const isExisting = options.some((option) => inputValue === (option as any).name);

      if (!isExisting && canAdd) {
        filtered.push({ label: `Add "${inputValue}"`, inputValue });
      }

      return filtered;
    },
    [canAdd],
  );
}

export function useGetOptionLabel() {
  return useCallback((option: unknown) => {
    if (typeof option === 'string') return option;

    if (isInputOption(option)) {
      return option.inputValue;
    }

    const { name } = option as Merchant;

    return name;
  }, []);
}

const isInputOption = (option: unknown): option is { inputValue: string; label: string } =>
  typeof option === 'object' && option != null && 'inputValue' in option;
