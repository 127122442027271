import { SaveButton } from './SaveButton';
import AccountsTable from './AccountsTable/AccountsTable';
import { Toast } from '../../common/Toast';
import { Loader } from './Loader';
import { useAccountsTableColumns } from './AccountsTable/useAccountsTableColumns';
import { useQuickbooksAccountsData } from './useQuickbooksAccountsData';
import { useUpdateCompanyQbAccountsMutation } from '../../common/hooks/useUpdateCompanyQbAccountsMutation';
import { QueryStatus } from 'react-query';
import { ClassificationTag } from '../../../services/classificationTagsApiClient';

interface Props {
  companyId: string;
  classificationTags: ClassificationTag[];
  onDispatchData?: (data: any) => Promise<any> | undefined;
}

export function CompanyQbAccountsMappingView({ companyId, classificationTags }: Props) {
  const columns = useAccountsTableColumns(classificationTags);
  const [companyQbAccounts, updateCompanyQbAccountsData, isLoadingCompanyQbAccounts] = useQuickbooksAccountsData(
    companyId,
    classificationTags,
  );

  const changedAccounts = companyQbAccounts
    .filter((account) => account.finaloopClassificationTagId !== account.originalFinaloopClassificationTagId)
    .map(({ originalFinaloopClassificationTagId, ...account }) => account);

  const updateCompanyQbAccountsMutation = useUpdateCompanyQbAccountsMutation(companyId);
  const isSaveButtonDisabled = changedAccounts.length === 0;

  if (isLoadingCompanyQbAccounts || !companyQbAccounts?.length)
    return (
      <div className="py-5 d-flex justify-center">
        <Loader variant="big" />
        Loading Quickbooks account list...
      </div>
    );

  return (
    <div className="mt-8">
      <AccountsTable columns={columns} data={companyQbAccounts} updateData={updateCompanyQbAccountsData} />
      <div className="flex flex-col mt-5 items-center">
        <SaveButton
          onSaveData={() => updateCompanyQbAccountsMutation.mutate(changedAccounts)}
          isSaving={updateCompanyQbAccountsMutation.isLoading}
          isDisabled={isSaveButtonDisabled}
        />
        <Toast
          type={updateCompanyQbAccountsMutation.isError ? 'error' : 'success'}
          message={getToastMessage(updateCompanyQbAccountsMutation.status)}
        />
      </div>
    </div>
  );
}

const getToastMessage = (status: QueryStatus) => {
  if (status === 'idle') return '';
  if (status === 'error') return 'Failed updating accounts';
  if (status === 'success') return 'Accounts updated successfully!';
};
