import { useContext } from 'react';
import { useMutation } from 'react-query';

import { environmentContext } from '../../../environmentContext/environmentContext';

interface CompanyIntegrationInfo {
  companyId: string;
  integrationAccountId: string;
}

export const useTriggerCompanyValidationMutation = () => {
  const services = useContext(environmentContext);

  return useMutation((updatedCompanyFields: CompanyIntegrationInfo) =>
    services.ValidationApiClient.triggerCompanyValidation(
      updatedCompanyFields.companyId,
      updatedCompanyFields.integrationAccountId,
    ),
  );
};
