import { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { Button, Divider, Drawer, List, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSearchParams } from 'react-router-dom';
import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  Healing as HealingIcon,
  ManageAccounts as ManageAccountsIcon,
  MoneyOutlined as LoansIcon,
} from '@mui/icons-material';

import { environmentContext } from '../../../environmentContext/environmentContext';
import { ListItemLink } from './ListItemLink';
import { TreeItem, TreeView } from '@mui/lab';
import styled from '@emotion/styled';
import { CompanySelector } from '../../common/CompanySelector';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export const SideBar = () => {
  const { setEnv, currentEnv } = useContext(environmentContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [envNameTextFieldValue, setEnvNameTextFieldValue] = useState<string>(
    (searchParams.has('env') && searchParams.get('env')) || (process.env.REACT_APP_INITIAL_ENV ?? 'dev'),
  );

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded(oldExpanded => (oldExpanded.length === 0 ? ['1', '2', '3', '4', '5', '6', '7'] : []));
  };

  if (searchParams.has('env') && currentEnv !== searchParams.get('env')) {
    setEnv(envNameTextFieldValue);
  }

  const handleEnvButtonClick = useCallback(() => {
    envNameTextFieldValue &&
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        env: envNameTextFieldValue,
      });
    setEnv(envNameTextFieldValue);
  }, [setSearchParams, searchParams, setEnv, envNameTextFieldValue]);

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          position: 'relative',
          paddingRight: '1em',
          width: '260px',
        },
        height: '100%',
        overflowY: 'auto',
      }}
      variant="persistent"
      open
    >
      <CompanySelector />
      <Divider sx={{ margin: '10px 0' }} orientation={'horizontal'} />
      <TextField
        label="Environment name"
        defaultValue={envNameTextFieldValue}
        sx={{ mb: '0.15em' }}
        onChange={e => {
          setEnvNameTextFieldValue(e.target.value! ?? '');
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleEnvButtonClick();
          }
        }}
      />
      <Button sx={{ mb: '1em', fontWeight: '600' }} variant="contained" onClick={handleEnvButtonClick}>
        Set Environment
      </Button>

      <List>
        <Button onClick={handleExpandClick} sx={{ width: '100%' }} variant="outlined">
          {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>

        <ListItemLink icon={<AssessmentOutlinedIcon />} to="/metrics" primary="Metrics" />
        <ListItemLink icon={<AdminPanelSettingsIcon />} to="/users-admin" primary="Users Administration" />
        <ListItemLink icon={<HealingIcon />} to="/fixbooks" primary="Fixbooks" />
        <ListItemLink icon={<ManageAccountsIcon />} to="/company-configuration" primary="Company Configuration" />
        <ListItemLink icon={<LoansIcon />} to="/loans" primary="Loans" />

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          <StyledTreeItem nodeId="1" label="Validations">
            <ListItemLink to="/validation/afterpay" primary="Afterpay Validation" />
            <ListItemLink to="/validation/amazon" primary="Amazon Validation" />
            <ListItemLink to="/validation/stripe" primary="Stripe Validation" />
            <ListItemLink to="/validation/etsy" primary="Etsy Validation" />
            <ListItemLink to="/validation/paypal" primary="Paypal Validation" />
            <ListItemLink to="/validation/plaid" primary="Plaid Validation" />
            <ListItemLink to="/validation/gusto" primary="Gusto Validation" />
            <ListItemLink to="/validation/shop_pay" primary="ShopPay Validation" />
            <ListItemLink to="/validation/walmart" primary="Walmart Validation" />
            <ListItemLink to="/validation/ebay" primary="Ebay Validation" />
            <ListItemLink to="/metrics-validation/shopify" primary="Shopify Metrics Validation" />
            <ListItemLink to="/gift-cards-validation/shopify" primary="Shopify Gift Card Validation" />
            <ListItemLink to="/clearing-account-validation/shopify" primary="Shopify Clearing Account Validation" />
            <ListItemLink to="/products-validation" primary="Shopify Products Validation" />
            <ListItemLink to="/amazon-products-validation" primary="Amazon Products Validation" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="3" label="Bills">
            <ListItemLink to="/bills-qb-accounts-mapper" primary="Bills QB accounts mapper" />

            <ListItemLink to="/bills-balance-due" primary="Bills balance due" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="5" label="Onboarding">
            <ListItemLink to="/onboarding-state" primary="Onboarding State" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="6" label="Missions">
            <ListItemLink to="/missions/bank-connection/new" primary="Connect Identified Bank" />
            <ListItemLink to="/missions/integration-connection/new" primary="Connect Identified Integration" />
            <ListItemLink to="/missions/csv" primary="Transactions CSV Upload" />
            <ListItemLink to="/missions/pdf" primary="Bulk Bank Statements Upload" />
            <ListItemLink to="/missions/by-company" primary="By Company" />
            <ListItemLink to="/missions/balance-bank-statement" primary="Balance Bank Statement" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="7" label="Duty">
            <ListItemLink to="/sqs-queue-metrics" primary="SQS Queue Metrics" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="8" label="Shopify">
            <ListItemLink to="/matching-validation/shopify" primary="Matching" />
          </StyledTreeItem>
        </TreeView>
      </List>
    </Drawer>
  );
};

const StyledTreeItem = styled(TreeItem)``;
