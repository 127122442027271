import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import {
  AnyMission,
  BankStatementsPdfUploadPayload,
  DisconnectedBankConnectionMissionPayload,
  IdentifiedBankConnectionPayload,
  IdentifiedIntegrationConnectionMissionPayload,
  MissionResolution,
  MissionStatus,
  MissionType,
  TopupOrPayoutToMissingBankConnectionPayload,
  TransactionsCSVUploadPayload,
} from '@finaloop/mission-types';
import { Button, Divider } from '@mui/material';
import _ from 'lodash';
import { PropsWithChildren } from 'react';
import { useMissionsApiClient } from '../../common/hooks/useMissionsApiClient';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { timeInMsToFriendlyTime } from '../shared';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const MissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
`;

const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 10px;

  h2 {
    font-size: 15px;
    color: gray;
    display: flex;
  }
`;

const MissionByStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
`;

const MissionStatusHeader = styled.h2`
  position: sticky;
  top: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 9999;
`;

const TupleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Tuple = (props: PropsWithChildren<{ label: string }>) => (
  <TupleContainer>
    <label>{props.label}:</label>
    {props.children}
  </TupleContainer>
);

const now = Date.now();
export const CompanyMissions = () => {
  const { selectedCompany } = useSelectedCompany();
  const { useCompanyMissions } = useMissionsApiClient();
  const { isError, isLoading, missions } = useCompanyMissions<AnyMission>({
    companyId: selectedCompany?.id,
  });

  const sortedMissions = (missions || []).sort(
    (a, b) => (b.createdAtMs || now) - (a.createdAtMs || now)
  );
  const groupedByStatus = _.groupBy(sortedMissions, (_) => _.status);
  const renderingOrder: MissionStatus[] = [
    MissionStatus.Open,
    MissionStatus.Dismissed,
    MissionStatus.ClosedByFinaloopTeam,
    MissionStatus.Closed,
  ];

  if (!selectedCompany) return <label>First select company</label>;
  if (isError) return <label>Something went wrong</label>;
  if (isLoading) return <label>Loading...</label>;

  return (
    <Container>
      <MissionsContainer>
        {missions?.length === 0 && <label>No missions</label>}
        {renderingOrder
          .filter((status) => !!groupedByStatus[status]?.length)
          .map((status, index) => (
            <MissionByStatusContainer key={index}>
              <MissionStatusHeader>{status} missions</MissionStatusHeader>
              {groupedByStatus[status].map((_, index) => (
                <Mission mission={_} key={index} />
              ))}
              <Divider />
            </MissionByStatusContainer>
          ))}
      </MissionsContainer>
    </Container>
  );
};

const ExtraDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 20px;
  margin: 10px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Mission = ({ mission }: { mission: AnyMission }) => {
  const { user } = useAuth0();
  const { useDeleteMission, useEditMission } = useMissionsApiClient();
  const {
    deleteMission,
    isError: hasErrorDeleting,
    isLoading: isDeleting,
    isSuccess: deleteSucceeded,
  } = useDeleteMission(mission.id);
  const {
    editMission,
    isError: hasErrorEditMission,
    isLoading: isEditingMission,
  } = useEditMission(mission.id);

  const onDeleteClicked = async () => {
    if (
      !window.confirm(
        'Are you sure you want to delete this mission? This action cannot be undone.'
      )
    )
      return;
    await deleteMission();
  };

  const onEditCommentClicked = async () => {
    const newComment = window.prompt(
      'Enter comment: (once confirmed - the comment will be saved)',
      mission.comment?.text
    );

    if (newComment === null) return;

    const comment = !!newComment ? newComment : undefined;
    const now = new Date();
    await editMission({
      mission: {
        ...mission,
        comment: !comment
          ? null
          : {
              createdAtIso: now.toISOString(),
              createdBy: user?.email || 'backoffice',
              createdAtMs: now.getTime(),
              text: comment,
            },
      },
    });
  };

  const isLoading = isDeleting || isEditingMission;
  const hasError = hasErrorDeleting || hasErrorEditMission;

  return (
    <MissionContainer>
      <Tuple label='id'>{mission.id}</Tuple>
      <Tuple label='type'>{mission.type}</Tuple>
      <Tuple label='status'>{mission.status}</Tuple>
      {mission.createdAtMs && (
        <Tuple label='created at'>
          {timeInMsToFriendlyTime(mission.createdAtMs)}
        </Tuple>
      )}
      {mission.updatedAtMs && (
        <Tuple label='updated at'>
          {timeInMsToFriendlyTime(mission.updatedAtMs)}
        </Tuple>
      )}
      {mission.createdBy && (
        <Tuple label='created by'>{mission.createdBy}</Tuple>
      )}
      {mission.updatedBy && (
        <Tuple label='updated by'>{mission.updatedBy}</Tuple>
      )}
      <ExtraDataWrapper>
        {mission.payload && (
          <Payload payload={mission.payload} missionType={mission.type} />
        )}
        {mission.resolution && <Resolution resolution={mission.resolution} />}
      </ExtraDataWrapper>
      {mission.comment && (
        <ExtraDataWrapper>
          <Tuple label='comment'>
            from {mission.comment.createdAtIso}, by {mission.comment.createdBy}
          </Tuple>
          <Tuple label='text'>{mission.comment.text}</Tuple>
        </ExtraDataWrapper>
      )}
      <ButtonsWrapper>
        <Button
          onClick={onDeleteClicked}
          disabled={isLoading}
          sx={{ width: '100px' }}>
          Delete
        </Button>
        <Button
          color='secondary'
          onClick={onEditCommentClicked}
          disabled={isLoading}
          sx={{ width: '200px' }}>
          {mission.comment ? 'Update comment' : 'Add comment'}
        </Button>
      </ButtonsWrapper>
      {isLoading && <label>updating ...</label>}
      {deleteSucceeded && <label>The mission was deleted successfully</label>}
      {hasError && <label>Something went wrong</label>}
    </MissionContainer>
  );
};

const Payload = (props: { payload: unknown; missionType: MissionType }) => {
  switch (props.missionType) {
    case MissionType.DisconnectedBankConnection: {
      const payload = props.payload as DisconnectedBankConnectionMissionPayload;

      return (
        <>
          <label>institution: {payload.institution.name}</label>
          <label>integration account id: {payload.integrationAccountId}</label>
          <label>item id: {payload.integrationAccountId}</label>
        </>
      );
    }
    case MissionType.IdentifiedBankConnection: {
      const payload = props.payload as IdentifiedBankConnectionPayload;
      return (
        <>
          <label>
            direction: {payload.identifiedAccount.transactionDirection}
          </label>
          <label>
            source account: {payload.sourceAccount.account.mask}{' '}
            {payload.sourceAccount.account.name}
          </label>
          <label>{`transaction account: ${payload.transaction.amount.value} ${payload.transaction.transactionDetails} ${payload.transaction.bankDetails}`}</label>
        </>
      );
    }
    case MissionType.IdentifiedIntegrationConnection: {
      const payload = props.payload as IdentifiedIntegrationConnectionMissionPayload;
      return (
        <>
          <label>event: {payload.identifiedEvent}</label>
          <label>integration type: {payload.integrationType}</label>
          <label>{`source account ${payload.sourceAccount.mask} ${payload.sourceAccount.institution.name} ${payload.sourceAccount.name}`}</label>
        </>
      );
    }
    case MissionType.TopupOrPayoutToMissingBankConnection: {
      const payload = props.payload as TopupOrPayoutToMissingBankConnectionPayload;
      return (
        <>
          <label>
            transaction: {payload.transaction.amount.value}{' '}
            {timeInMsToFriendlyTime(payload.transaction.dateInMs)}
          </label>
          <label>
            identified event: {payload.identificationSource.eventType}
          </label>
          <label>
            integration type: {payload.identificationSource.integrationType}
          </label>
        </>
      );
    }
    case MissionType.TransactionsCSVUpload: {
      const payload = props.payload as TransactionsCSVUploadPayload;
      return (
        <>
          <label>{`from: ${timeInMsToFriendlyTime(
            payload.fromTimeInMs
          )}`}</label>
          <label>{`to: ${timeInMsToFriendlyTime(payload.toTimeInMs)}`}</label>
          <label>{`bank id: ${payload.bankId}`}</label>
          <label>
            account: {payload.account.mask} {payload.account.type}{' '}
            {payload.account.name}
          </label>
          <label>institution: {payload.institution.name}</label>
        </>
      );
    }
    case MissionType.BankStatementsPdfUpload: {
      const payload = props.payload as BankStatementsPdfUploadPayload;
      return (
        <>
          <label>{`from: ${timeInMsToFriendlyTime(
            payload.fromTimeInMs
          )}`}</label>
          <label>{`to: ${timeInMsToFriendlyTime(payload.toTimeInMs)}`}</label>
          <label>{`bank id: ${payload.bankId}`}</label>
          <label>
            account: {payload.account.mask} {payload.account.type}{' '}
            {payload.account.name}
          </label>
          <label>institution: {payload.account.institutionName}</label>
        </>
      );
    }
    default:
      return <>payload: {JSON.stringify(props.payload)}</>;
  }
};

const Resolution = (props: { resolution: MissionResolution }) => {
  const { resolvedAtMs, resolvedBy, ...rest } = props.resolution;
  return (
    <>
      <label>resolved at: {timeInMsToFriendlyTime(resolvedAtMs)}</label>
      {props.resolution.resolvedBy && <label>resolved by: {resolvedBy}</label>}
      {rest && <label>resolution: {JSON.stringify(rest)}</label>}
    </>
  );
};
