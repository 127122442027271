module.exports = {
  scalars: [0, 1, 3, 5],
  types: {
    Date: {},
    JSON: {},
    Company: {
      companyId: [3],
      companyName: [3],
      __typename: [3],
    },
    String: {},
    AmazonProductsValidation: {
      company: [2],
      integrationAccountId: [3],
      date: [0],
      validationDetails: [1],
      isOk: [5],
      areProductsSynced: [5],
      isAmazonEnabled: [5],
      __typename: [3],
    },
    Boolean: {},
    GetAmazonProductsValidationArgs: {
      companyId: [3],
      integrationAccountId: [3],
      __typename: [3],
    },
    Query: {
      getAmazonProductsValidations: [
        4,
        {
          args: [6],
        },
      ],
      __typename: [3],
    },
    RunValidationArgs: {
      companyId: [3],
      integrationAccountId: [3],
      __typename: [3],
    },
    Mutation: {
      runValidation: [
        4,
        {
          args: [8, 'RunValidationArgs!'],
        },
      ],
      __typename: [3],
    },
  },
};
