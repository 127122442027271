import { Button } from '@mui/material';
import { useEffect, useState, type ReactElement } from 'react';
import { Company, gqlClient } from '../../services/FinaloopGql';
import { useFetchCompany } from '../common/hooks/useFetchCompany';
import { useGetCompanyById } from '../common/hooks/useGetCompanyById';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { useUpdateCompanyMutation } from '../common/hooks/useUpdateCompanyMutation';
import { CompanyReadonlyInformation } from './CompanyReadonlyInformation';

export const CompanyConfiguration = (): ReactElement => {
  const { selectedCompany } = useSelectedCompany();
  const selectedCompanyInformation = (useFetchCompany(selectedCompany?.id).data as Company) || {};
  const updateCompanyMutation = useUpdateCompanyMutation(selectedCompany?.id!);
  const [isSaving, setIsSaving] = useState(false);
  const [updatedInformation, setUpdatedInformation] = useState<Partial<Company> | null>({
    accountingMethod: selectedCompanyInformation.accountingMethod,
    configuration: {
      __typename:'CompanyConfiguration',
      isPnlReportReady: selectedCompanyInformation?.configuration?.isPnlReportReady ?? false,
      isBalanceSheetReportFinal: selectedCompanyInformation?.configuration?.isBalanceSheetReportFinal ?? false,
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    const { configuration, accountingMethod } = updatedInformation || {};
    await updateCompanyMutation.mutate(
      {
        configuration,
        ...(accountingMethod ? { accountingMethod } : {}),
      } || {},
    );
    setIsSaving(false);
  };

  useEffect(() => {
    if (Object.keys(selectedCompanyInformation).length === 0) return;

    setUpdatedInformation(selectedCompanyInformation);
  }, [selectedCompanyInformation]);


  if (!selectedCompany) {
    return <div>Please select a company</div>;
  }

  return (
    <div>
      <div className="flex flex-col mx-auto w-full items-center">
        <div className="mt-6">
          <h2 className="text-xl font-bold">Company information:</h2>
          <div className="flex flex-col gap-1">
            <CompanyReadonlyInformation company={selectedCompanyInformation} />
            <DataRestrictionConfiguration {...selectedCompany} />
          </div>
        </div>
      </div>
    </div>
  );
};

const DataRestrictionConfiguration  = ({id: companyId}: Company) => {
  const { data: company, isLoading, isError } = useGetCompanyById(companyId);
  const [dataRestrictionTime, setDataRestrictionTime] = useState<string | null>(company?.dataRestrictionTime);

  useEffect(() => {
    setDataRestrictionTime(company?.dataRestrictionTime || null);
  }, [company]);

  if (isError) {
    return <div>Error</div>;
  }

  if (isLoading ) {
    return <div>Loading...</div>;
  }

  return <div>
    <strong>Data Restriction:</strong> {dataRestrictionTime ? <span>
      <span>{dataRestrictionTime}</span>
      <span style={{marginLeft: 10}}><Button onClick={() => {
        setDataRestrictionTime(null);
        gqlClient.mutation({
        removeDataRestriction: [{
          args: {
            companyId: company!.id,
          },
        }, {
          id: true
        }],
      })
      }}>Delete</Button></span>
    </span> : 'No restriction'}
  </div>;
}