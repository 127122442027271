import { useContext, useState } from 'react';
import { Typography, AppBar, useTheme, Toolbar } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { Company } from '../../../types/Company';
import styled from '@emotion/styled';

const HeaderText = styled.div`
  display: flex;
  flex: 1;
`;

export const AppHeader = () => {
  const theme = useTheme();
  const { currentEnv } = useContext(environmentContext);

  return (
    <AppBar
      sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}
      position="relative"
    >
      <Toolbar disableGutters>
        <HeaderText>
          <AssessmentOutlinedIcon sx={{ ml: '1em', mr: '0.25em' }} />

          <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '0.5em' }}>
            Data Explorer
          </Typography>

          <Typography variant="h6" fontWeight="medium" sx={{ textTransform: 'uppercase' }}>
            (Environment: <b>{currentEnv}</b>)
          </Typography>
        </HeaderText>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
