import { useMemo } from 'react';
import { EditableDropdownCellProps, EditableDropdownCell } from './Cells/EditableDropdownCell';
import { ClassificationTag } from '../../../../services/classificationTagsApiClient';

export const useAccountsTableColumns = (classificationTags: ClassificationTag[]) =>
  useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Account subtype',
        accessor: 'subType',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Classification tag',
        accessor: 'finaloopClassificationTagId',
        Cell: (props: EditableDropdownCellProps) =>
          EditableDropdownCell({
            ...props,
            data: classificationTags,
          }),
      },
      {
        Header: 'Last modified by',
        accessor: 'roleMappingLastModifiedBy',
      },
    ],

    [classificationTags],
  );
