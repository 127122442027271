import axios from 'axios';
import { CompanyQuickbooksAccount } from '../types/CompanyQuickbooksAccount';

let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export const getAccounts = async (companyId: string): Promise<CompanyQuickbooksAccount[]> => {
  if (!url.length) {
    throw new Error('Url is not set');
  }
  const result = await axios.get<CompanyQuickbooksAccount[]>(
    `${url}/bills-qb-accounts?companyId=${companyId}&showConnectedToBillsOnly=true`,
  );

  return result.data;
};

export const updateAccounts = async (
  companyId: string,
  accounts: CompanyQuickbooksAccount[],
  getToken: () => Promise<string>,
): Promise<void | undefined> => {
  const token = await getToken();

  if (!url.length) {
    return;
  }
  await axios.patch(
    `${url}/bills-qb-accounts?companyId=${companyId}`,
    { accounts },
    {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    },
  );
};
