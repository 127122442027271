import { BusinessEventsDispatcherView } from './components/BusinessEventsDispatcherView';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';
import { BusinessEventList } from './components/EventsHistory';
import styled from '@emotion/styled';
import { CircularProgress, Divider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useBillsClassificationTags } from '../common/hooks/useBillsClassificationTags';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1150px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const Fixbooks = () => {
  const { selectedCompany } = useSelectedCompany();
  const { getAccessTokenSilently } = useAuth0();
  const { classificationTags, isLoading } = useBillsClassificationTags(getAccessTokenSilently);

  if (!selectedCompany) return <>First select company in the menu</>;

  return (
    <Container>
      <h1>New:</h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <BusinessEventsDispatcherView
          companyId={selectedCompany?.id}
          isDatePickerVisible
          classificationTags={classificationTags || []}
        />
      )}
      <Divider orientation="horizontal" />
      <BusinessEventList classificationTags={classificationTags || []} />
    </Container>
  );
};
