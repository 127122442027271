
var AcceptedInviteCompanyInfo_possibleTypes = ['AcceptedInviteCompanyInfo']
export var isAcceptedInviteCompanyInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAcceptedInviteCompanyInfo"')
  return AcceptedInviteCompanyInfo_possibleTypes.includes(obj.__typename)
}



var AcceptInvitationResponse_possibleTypes = ['AcceptInvitationResponse']
export var isAcceptInvitationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAcceptInvitationResponse"')
  return AcceptInvitationResponse_possibleTypes.includes(obj.__typename)
}



var AccountingInfo_possibleTypes = ['AccountingInfo']
export var isAccountingInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAccountingInfo"')
  return AccountingInfo_possibleTypes.includes(obj.__typename)
}



var AccountValidation_possibleTypes = ['AccountValidation']
export var isAccountValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAccountValidation"')
  return AccountValidation_possibleTypes.includes(obj.__typename)
}



var Accrual_possibleTypes = ['Accrual']
export var isAccrual = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAccrual"')
  return Accrual_possibleTypes.includes(obj.__typename)
}



var AccrualData_possibleTypes = ['AccrualData']
export var isAccrualData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAccrualData"')
  return AccrualData_possibleTypes.includes(obj.__typename)
}



var AccrualsSpreadResponse_possibleTypes = ['AccrualsSpreadResponse']
export var isAccrualsSpreadResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAccrualsSpreadResponse"')
  return AccrualsSpreadResponse_possibleTypes.includes(obj.__typename)
}



var AcknowledgeLoansChanges_possibleTypes = ['AcknowledgeLoansChanges']
export var isAcknowledgeLoansChanges = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAcknowledgeLoansChanges"')
  return AcknowledgeLoansChanges_possibleTypes.includes(obj.__typename)
}



var AckPhysicalCountResult_possibleTypes = ['BooleanResult','PhysicalCountError']
export var isAckPhysicalCountResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAckPhysicalCountResult"')
  return AckPhysicalCountResult_possibleTypes.includes(obj.__typename)
}



var ActionChange_possibleTypes = ['ActionChange']
export var isActionChange = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isActionChange"')
  return ActionChange_possibleTypes.includes(obj.__typename)
}



var AddDepreciationBonus_possibleTypes = ['AddDepreciationBonus']
export var isAddDepreciationBonus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAddDepreciationBonus"')
  return AddDepreciationBonus_possibleTypes.includes(obj.__typename)
}



var Address_possibleTypes = ['Address']
export var isAddress = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAddress"')
  return Address_possibleTypes.includes(obj.__typename)
}



var AddRoleToAffiliatedEntityResult_possibleTypes = ['AddRoleToAffiliatedEntityResult']
export var isAddRoleToAffiliatedEntityResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAddRoleToAffiliatedEntityResult"')
  return AddRoleToAffiliatedEntityResult_possibleTypes.includes(obj.__typename)
}



var AdjustmentRestockingAction_possibleTypes = ['AdjustmentRestockingAction']
export var isAdjustmentRestockingAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAdjustmentRestockingAction"')
  return AdjustmentRestockingAction_possibleTypes.includes(obj.__typename)
}



var AdjustmentsValidation_possibleTypes = ['AdjustmentsValidation']
export var isAdjustmentsValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAdjustmentsValidation"')
  return AdjustmentsValidation_possibleTypes.includes(obj.__typename)
}



var AffectedTransactionsIds_possibleTypes = ['AffectedTransactionsIds']
export var isAffectedTransactionsIds = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAffectedTransactionsIds"')
  return AffectedTransactionsIds_possibleTypes.includes(obj.__typename)
}



var AffiliatedEntity_possibleTypes = ['AffiliatedEntity']
export var isAffiliatedEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAffiliatedEntity"')
  return AffiliatedEntity_possibleTypes.includes(obj.__typename)
}



var AlterBankTransactionsLifecycleStateResult_possibleTypes = ['AlterBankTransactionsLifecycleStateResult']
export var isAlterBankTransactionsLifecycleStateResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAlterBankTransactionsLifecycleStateResult"')
  return AlterBankTransactionsLifecycleStateResult_possibleTypes.includes(obj.__typename)
}



var AlterBankTransactionsLifecycleStateUnprocessedResult_possibleTypes = ['AlterBankTransactionsLifecycleStateUnprocessedResult']
export var isAlterBankTransactionsLifecycleStateUnprocessedResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAlterBankTransactionsLifecycleStateUnprocessedResult"')
  return AlterBankTransactionsLifecycleStateUnprocessedResult_possibleTypes.includes(obj.__typename)
}



var AmazonAvailableMarketplace_possibleTypes = ['AmazonAvailableMarketplace']
export var isAmazonAvailableMarketplace = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonAvailableMarketplace"')
  return AmazonAvailableMarketplace_possibleTypes.includes(obj.__typename)
}



var AmazonAvailableValidationDataResponse_possibleTypes = ['AmazonAvailableValidationDataResponse']
export var isAmazonAvailableValidationDataResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonAvailableValidationDataResponse"')
  return AmazonAvailableValidationDataResponse_possibleTypes.includes(obj.__typename)
}



var AmazonIntegrationAccountIdAvailableData_possibleTypes = ['AmazonIntegrationAccountIdAvailableData']
export var isAmazonIntegrationAccountIdAvailableData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonIntegrationAccountIdAvailableData"')
  return AmazonIntegrationAccountIdAvailableData_possibleTypes.includes(obj.__typename)
}



var AmazonOrdersDiffResponse_possibleTypes = ['AmazonOrdersDiffResponse']
export var isAmazonOrdersDiffResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonOrdersDiffResponse"')
  return AmazonOrdersDiffResponse_possibleTypes.includes(obj.__typename)
}



var AmazonProduct_possibleTypes = ['AmazonProduct']
export var isAmazonProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonProduct"')
  return AmazonProduct_possibleTypes.includes(obj.__typename)
}



var AmazonProductsReconciliationResponse_possibleTypes = ['AmazonProductsReconciliationResponse']
export var isAmazonProductsReconciliationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonProductsReconciliationResponse"')
  return AmazonProductsReconciliationResponse_possibleTypes.includes(obj.__typename)
}



var AmazonProductsValidationReadyResponse_possibleTypes = ['AmazonProductsValidationReadyResponse']
export var isAmazonProductsValidationReadyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonProductsValidationReadyResponse"')
  return AmazonProductsValidationReadyResponse_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationEntityData_possibleTypes = ['AmazonReconciliationEntityData']
export var isAmazonReconciliationEntityData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationEntityData"')
  return AmazonReconciliationEntityData_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationEntityEntityValidationStatus_possibleTypes = ['AmazonReconciliationEntityEntityValidationStatus']
export var isAmazonReconciliationEntityEntityValidationStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationEntityEntityValidationStatus"')
  return AmazonReconciliationEntityEntityValidationStatus_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationProductData_possibleTypes = ['AmazonReconciliationProductData']
export var isAmazonReconciliationProductData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationProductData"')
  return AmazonReconciliationProductData_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationProductValidationStatus_possibleTypes = ['AmazonReconciliationProductValidationStatus']
export var isAmazonReconciliationProductValidationStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationProductValidationStatus"')
  return AmazonReconciliationProductValidationStatus_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationRestockedReportData_possibleTypes = ['AmazonReconciliationRestockedReportData']
export var isAmazonReconciliationRestockedReportData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationRestockedReportData"')
  return AmazonReconciliationRestockedReportData_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationSalesOrderIds_possibleTypes = ['AmazonReconciliationSalesOrderIds']
export var isAmazonReconciliationSalesOrderIds = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationSalesOrderIds"')
  return AmazonReconciliationSalesOrderIds_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationSalesReportData_possibleTypes = ['AmazonReconciliationSalesReportData']
export var isAmazonReconciliationSalesReportData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationSalesReportData"')
  return AmazonReconciliationSalesReportData_possibleTypes.includes(obj.__typename)
}



var AmazonReconciliationSummary_possibleTypes = ['AmazonReconciliationSummary']
export var isAmazonReconciliationSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonReconciliationSummary"')
  return AmazonReconciliationSummary_possibleTypes.includes(obj.__typename)
}



var AmazonSkuReconciliationData_possibleTypes = ['AmazonSkuReconciliationData']
export var isAmazonSkuReconciliationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmazonSkuReconciliationData"')
  return AmazonSkuReconciliationData_possibleTypes.includes(obj.__typename)
}



var Amounts_possibleTypes = ['Amounts']
export var isAmounts = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAmounts"')
  return Amounts_possibleTypes.includes(obj.__typename)
}



var ArtificialTransactionsByMonth_possibleTypes = ['ArtificialTransactionsByMonth']
export var isArtificialTransactionsByMonth = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isArtificialTransactionsByMonth"')
  return ArtificialTransactionsByMonth_possibleTypes.includes(obj.__typename)
}



var ArtificialTransactionsByMonthResponse_possibleTypes = ['ArtificialTransactionsByMonthResponse']
export var isArtificialTransactionsByMonthResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isArtificialTransactionsByMonthResponse"')
  return ArtificialTransactionsByMonthResponse_possibleTypes.includes(obj.__typename)
}



var AskTheUserNotificationServiceResult_possibleTypes = ['AskTheUserNotificationServiceResult']
export var isAskTheUserNotificationServiceResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAskTheUserNotificationServiceResult"')
  return AskTheUserNotificationServiceResult_possibleTypes.includes(obj.__typename)
}



var AskTheUserResult_possibleTypes = ['AskTheUserResult']
export var isAskTheUserResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAskTheUserResult"')
  return AskTheUserResult_possibleTypes.includes(obj.__typename)
}



var AssembledByMap_possibleTypes = ['AssembledByMap']
export var isAssembledByMap = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAssembledByMap"')
  return AssembledByMap_possibleTypes.includes(obj.__typename)
}



var AssignCompanyToUserResponse_possibleTypes = ['AssignCompanyToUserResponse']
export var isAssignCompanyToUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAssignCompanyToUserResponse"')
  return AssignCompanyToUserResponse_possibleTypes.includes(obj.__typename)
}



var AttachmentTargetURL_possibleTypes = ['AttachmentTargetURL']
export var isAttachmentTargetURL = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAttachmentTargetURL"')
  return AttachmentTargetURL_possibleTypes.includes(obj.__typename)
}



var AttachmentTargetURLS_possibleTypes = ['AttachmentTargetURLS']
export var isAttachmentTargetURLS = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAttachmentTargetURLS"')
  return AttachmentTargetURLS_possibleTypes.includes(obj.__typename)
}



var Auth0UserLookupResponse_possibleTypes = ['Auth0UserLookupResponse']
export var isAuth0UserLookupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAuth0UserLookupResponse"')
  return Auth0UserLookupResponse_possibleTypes.includes(obj.__typename)
}



var AwaitedExportResponse_possibleTypes = ['AwaitedExportResponse']
export var isAwaitedExportResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isAwaitedExportResponse"')
  return AwaitedExportResponse_possibleTypes.includes(obj.__typename)
}



var BalanceAdjustment_possibleTypes = ['BalanceAdjustment']
export var isBalanceAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBalanceAdjustment"')
  return BalanceAdjustment_possibleTypes.includes(obj.__typename)
}



var BalanceLocation_possibleTypes = ['BalanceLocation']
export var isBalanceLocation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBalanceLocation"')
  return BalanceLocation_possibleTypes.includes(obj.__typename)
}



var BankAccount_possibleTypes = ['BankAccount']
export var isBankAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccount"')
  return BankAccount_possibleTypes.includes(obj.__typename)
}



var BankAccountLock_possibleTypes = ['BankAccountLock']
export var isBankAccountLock = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountLock"')
  return BankAccountLock_possibleTypes.includes(obj.__typename)
}



var BankAccountLockStatus_possibleTypes = ['BankAccountLockStatus']
export var isBankAccountLockStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountLockStatus"')
  return BankAccountLockStatus_possibleTypes.includes(obj.__typename)
}



var BankAccountReconciliation_possibleTypes = ['BankAccountReconciliation']
export var isBankAccountReconciliation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountReconciliation"')
  return BankAccountReconciliation_possibleTypes.includes(obj.__typename)
}



var BankAccountStatementRequest_possibleTypes = ['BankAccountStatementRequest']
export var isBankAccountStatementRequest = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountStatementRequest"')
  return BankAccountStatementRequest_possibleTypes.includes(obj.__typename)
}



var BankAccountStopImportNewOwner_possibleTypes = ['BankAccountStopImportNewOwner']
export var isBankAccountStopImportNewOwner = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountStopImportNewOwner"')
  return BankAccountStopImportNewOwner_possibleTypes.includes(obj.__typename)
}



var BankAccountTransactionsImportStatus_possibleTypes = ['BankAccountTransactionsImportStatus']
export var isBankAccountTransactionsImportStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankAccountTransactionsImportStatus"')
  return BankAccountTransactionsImportStatus_possibleTypes.includes(obj.__typename)
}



var BankConnection_possibleTypes = ['BankConnection']
export var isBankConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankConnection"')
  return BankConnection_possibleTypes.includes(obj.__typename)
}



var BankConnectionAccount_possibleTypes = ['BankConnectionAccount']
export var isBankConnectionAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankConnectionAccount"')
  return BankConnectionAccount_possibleTypes.includes(obj.__typename)
}



var BankConnectionAccountLock_possibleTypes = ['BankConnectionAccountLock']
export var isBankConnectionAccountLock = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankConnectionAccountLock"')
  return BankConnectionAccountLock_possibleTypes.includes(obj.__typename)
}



var BankConnectionAccountTransactionsImportStatus_possibleTypes = ['BankConnectionAccountTransactionsImportStatus']
export var isBankConnectionAccountTransactionsImportStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankConnectionAccountTransactionsImportStatus"')
  return BankConnectionAccountTransactionsImportStatus_possibleTypes.includes(obj.__typename)
}



var BankConnectionSyncStatus_possibleTypes = ['BankConnectionSyncStatus']
export var isBankConnectionSyncStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankConnectionSyncStatus"')
  return BankConnectionSyncStatus_possibleTypes.includes(obj.__typename)
}



var BankDetails_possibleTypes = ['BankDetails']
export var isBankDetails = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankDetails"')
  return BankDetails_possibleTypes.includes(obj.__typename)
}



var BankReconciliationRequest_possibleTypes = ['BankReconciliationRequest']
export var isBankReconciliationRequest = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankReconciliationRequest"')
  return BankReconciliationRequest_possibleTypes.includes(obj.__typename)
}



var BankStatementAccountHolder_possibleTypes = ['BankStatementAccountHolder']
export var isBankStatementAccountHolder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementAccountHolder"')
  return BankStatementAccountHolder_possibleTypes.includes(obj.__typename)
}



var BankStatementBankAccount_possibleTypes = ['BankStatementBankAccount']
export var isBankStatementBankAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementBankAccount"')
  return BankStatementBankAccount_possibleTypes.includes(obj.__typename)
}



var BankStatementCustomerData_possibleTypes = ['BankStatementCustomerData']
export var isBankStatementCustomerData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementCustomerData"')
  return BankStatementCustomerData_possibleTypes.includes(obj.__typename)
}



var BankStatementFinaloopData_possibleTypes = ['BankStatementFinaloopData']
export var isBankStatementFinaloopData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementFinaloopData"')
  return BankStatementFinaloopData_possibleTypes.includes(obj.__typename)
}



var BankStatementParsedTransaction_possibleTypes = ['BankStatementParsedTransaction']
export var isBankStatementParsedTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementParsedTransaction"')
  return BankStatementParsedTransaction_possibleTypes.includes(obj.__typename)
}



var BankStatementParsingRequest_possibleTypes = ['BankStatementParsingRequest']
export var isBankStatementParsingRequest = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementParsingRequest"')
  return BankStatementParsingRequest_possibleTypes.includes(obj.__typename)
}



var BankStatementParsingResult_possibleTypes = ['BankStatementParsingResult']
export var isBankStatementParsingResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementParsingResult"')
  return BankStatementParsingResult_possibleTypes.includes(obj.__typename)
}



var BankStatementRequestResolutionResponse_possibleTypes = ['BankStatementRequestResolutionResponse']
export var isBankStatementRequestResolutionResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementRequestResolutionResponse"')
  return BankStatementRequestResolutionResponse_possibleTypes.includes(obj.__typename)
}



var BankStatementValidationResults_possibleTypes = ['BankStatementValidationResults']
export var isBankStatementValidationResults = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankStatementValidationResults"')
  return BankStatementValidationResults_possibleTypes.includes(obj.__typename)
}



var BankTransaction_possibleTypes = ['BankTransaction']
export var isBankTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransaction"')
  return BankTransaction_possibleTypes.includes(obj.__typename)
}



var BankTransactionAttachment_possibleTypes = ['BankTransactionAttachment']
export var isBankTransactionAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionAttachment"')
  return BankTransactionAttachment_possibleTypes.includes(obj.__typename)
}



var BankTransactionBusinessEvent_possibleTypes = ['BankTransactionBusinessEvent']
export var isBankTransactionBusinessEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionBusinessEvent"')
  return BankTransactionBusinessEvent_possibleTypes.includes(obj.__typename)
}



var BankTransactionBusinessEventClassification_possibleTypes = ['BankTransactionBusinessEventClassification']
export var isBankTransactionBusinessEventClassification = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionBusinessEventClassification"')
  return BankTransactionBusinessEventClassification_possibleTypes.includes(obj.__typename)
}



var BankTransactionByMerchant_possibleTypes = ['BankTransactionByMerchant']
export var isBankTransactionByMerchant = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionByMerchant"')
  return BankTransactionByMerchant_possibleTypes.includes(obj.__typename)
}



var BankTransactionByMerchantResult_possibleTypes = ['BankTransactionByMerchantResult']
export var isBankTransactionByMerchantResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionByMerchantResult"')
  return BankTransactionByMerchantResult_possibleTypes.includes(obj.__typename)
}



var BankTransactionCategorizationResult_possibleTypes = ['BankTransactionCategorizationResult']
export var isBankTransactionCategorizationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionCategorizationResult"')
  return BankTransactionCategorizationResult_possibleTypes.includes(obj.__typename)
}



var BankTransactionMemo_possibleTypes = ['BankTransactionMemo']
export var isBankTransactionMemo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionMemo"')
  return BankTransactionMemo_possibleTypes.includes(obj.__typename)
}



var BankTransactionPairingResult_possibleTypes = ['BankTransactionPairingResult']
export var isBankTransactionPairingResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionPairingResult"')
  return BankTransactionPairingResult_possibleTypes.includes(obj.__typename)
}



var BankTransactionResponse_possibleTypes = ['BankTransactionResponse']
export var isBankTransactionResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionResponse"')
  return BankTransactionResponse_possibleTypes.includes(obj.__typename)
}



var BankTransactionsResponse_possibleTypes = ['BankTransactionsResponse']
export var isBankTransactionsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionsResponse"')
  return BankTransactionsResponse_possibleTypes.includes(obj.__typename)
}



var BankTransactionsSummary_possibleTypes = ['BankTransactionsSummary']
export var isBankTransactionsSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionsSummary"')
  return BankTransactionsSummary_possibleTypes.includes(obj.__typename)
}



var BankTransactionsUpload_possibleTypes = ['BankTransactionsUpload']
export var isBankTransactionsUpload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionsUpload"')
  return BankTransactionsUpload_possibleTypes.includes(obj.__typename)
}



var BankTransactionsUploadFileLocation_possibleTypes = ['BankTransactionsUploadFileLocation']
export var isBankTransactionsUploadFileLocation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionsUploadFileLocation"')
  return BankTransactionsUploadFileLocation_possibleTypes.includes(obj.__typename)
}



var BankTransactionsUploadProcessingLog_possibleTypes = ['BankTransactionsUploadProcessingLog']
export var isBankTransactionsUploadProcessingLog = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTransactionsUploadProcessingLog"')
  return BankTransactionsUploadProcessingLog_possibleTypes.includes(obj.__typename)
}



var BankTxSaleTransaction_possibleTypes = ['BankTxSaleTransaction']
export var isBankTxSaleTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBankTxSaleTransaction"')
  return BankTxSaleTransaction_possibleTypes.includes(obj.__typename)
}



var BaseAffiliatedEntity_possibleTypes = ['BaseAffiliatedEntity']
export var isBaseAffiliatedEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBaseAffiliatedEntity"')
  return BaseAffiliatedEntity_possibleTypes.includes(obj.__typename)
}



var BigValidationAccountRoleAmount_possibleTypes = ['BigValidationAccountRoleAmount']
export var isBigValidationAccountRoleAmount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationAccountRoleAmount"')
  return BigValidationAccountRoleAmount_possibleTypes.includes(obj.__typename)
}



var BigValidationData_possibleTypes = ['BigValidationData']
export var isBigValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationData"')
  return BigValidationData_possibleTypes.includes(obj.__typename)
}



var BigValidationInventoryBalanceData_possibleTypes = ['BigValidationInventoryBalanceData']
export var isBigValidationInventoryBalanceData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationInventoryBalanceData"')
  return BigValidationInventoryBalanceData_possibleTypes.includes(obj.__typename)
}



var BigValidationPeriodData_possibleTypes = ['BigValidationPeriodData']
export var isBigValidationPeriodData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationPeriodData"')
  return BigValidationPeriodData_possibleTypes.includes(obj.__typename)
}



var BigValidationResponse_possibleTypes = ['BigValidationResponse']
export var isBigValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationResponse"')
  return BigValidationResponse_possibleTypes.includes(obj.__typename)
}



var BigValidationStatus_possibleTypes = ['BigValidationStatus']
export var isBigValidationStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBigValidationStatus"')
  return BigValidationStatus_possibleTypes.includes(obj.__typename)
}



var Bill_possibleTypes = ['Bill']
export var isBill = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBill"')
  return Bill_possibleTypes.includes(obj.__typename)
}



var BillAdjustment_possibleTypes = ['BillAdjustment']
export var isBillAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillAdjustment"')
  return BillAdjustment_possibleTypes.includes(obj.__typename)
}



var BillCredit_possibleTypes = ['BillCredit']
export var isBillCredit = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillCredit"')
  return BillCredit_possibleTypes.includes(obj.__typename)
}



var Billing_possibleTypes = ['Billing']
export var isBilling = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBilling"')
  return Billing_possibleTypes.includes(obj.__typename)
}



var BillingPayment_possibleTypes = ['RecurringPayment','OneTimePayment']
export var isBillingPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillingPayment"')
  return BillingPayment_possibleTypes.includes(obj.__typename)
}



var BillPayment_possibleTypes = ['BillPayment']
export var isBillPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillPayment"')
  return BillPayment_possibleTypes.includes(obj.__typename)
}



var BillRefund_possibleTypes = ['BillRefund']
export var isBillRefund = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillRefund"')
  return BillRefund_possibleTypes.includes(obj.__typename)
}



var BillsDueDateSummary_possibleTypes = ['BillsDueDateSummary']
export var isBillsDueDateSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBillsDueDateSummary"')
  return BillsDueDateSummary_possibleTypes.includes(obj.__typename)
}



var BooleanResult_possibleTypes = ['BooleanResult']
export var isBooleanResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBooleanResult"')
  return BooleanResult_possibleTypes.includes(obj.__typename)
}



var BusinessEventActionsConfiguration_possibleTypes = ['BusinessEventActionsConfiguration']
export var isBusinessEventActionsConfiguration = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBusinessEventActionsConfiguration"')
  return BusinessEventActionsConfiguration_possibleTypes.includes(obj.__typename)
}



var BusinessEventDefinition_possibleTypes = ['BusinessEventDefinition']
export var isBusinessEventDefinition = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBusinessEventDefinition"')
  return BusinessEventDefinition_possibleTypes.includes(obj.__typename)
}



var BusinessEventsAccrualsConfig_possibleTypes = ['BusinessEventsAccrualsConfig']
export var isBusinessEventsAccrualsConfig = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isBusinessEventsAccrualsConfig"')
  return BusinessEventsAccrualsConfig_possibleTypes.includes(obj.__typename)
}



var CalendlyEventResponse_possibleTypes = ['CalendlyEventResponse']
export var isCalendlyEventResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCalendlyEventResponse"')
  return CalendlyEventResponse_possibleTypes.includes(obj.__typename)
}



var CanceledAccrualData_possibleTypes = ['CanceledAccrualData']
export var isCanceledAccrualData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCanceledAccrualData"')
  return CanceledAccrualData_possibleTypes.includes(obj.__typename)
}



var Category_possibleTypes = ['Category']
export var isCategory = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCategory"')
  return Category_possibleTypes.includes(obj.__typename)
}



var CategoryBreakdown_possibleTypes = ['CategoryBreakdown']
export var isCategoryBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCategoryBreakdown"')
  return CategoryBreakdown_possibleTypes.includes(obj.__typename)
}



var ChartDataResponse_possibleTypes = ['ChartDataResponse']
export var isChartDataResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isChartDataResponse"')
  return ChartDataResponse_possibleTypes.includes(obj.__typename)
}



var ClassificationMetadata_possibleTypes = ['ClassificationMetadata']
export var isClassificationMetadata = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isClassificationMetadata"')
  return ClassificationMetadata_possibleTypes.includes(obj.__typename)
}



var ClassificationResult_possibleTypes = ['ClassificationResult']
export var isClassificationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isClassificationResult"')
  return ClassificationResult_possibleTypes.includes(obj.__typename)
}



var ClassificationTag_possibleTypes = ['ClassificationTag']
export var isClassificationTag = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isClassificationTag"')
  return ClassificationTag_possibleTypes.includes(obj.__typename)
}



var ClassificationTagClassification_possibleTypes = ['ClassificationTagClassification']
export var isClassificationTagClassification = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isClassificationTagClassification"')
  return ClassificationTagClassification_possibleTypes.includes(obj.__typename)
}



var ClosingBalance_possibleTypes = ['ClosingBalance']
export var isClosingBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isClosingBalance"')
  return ClosingBalance_possibleTypes.includes(obj.__typename)
}



var COGSBreakdownItem_possibleTypes = ['COGSBreakdownItem']
export var isCOGSBreakdownItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCOGSBreakdownItem"')
  return COGSBreakdownItem_possibleTypes.includes(obj.__typename)
}



var COGSEvent_possibleTypes = ['COGSEvent']
export var isCOGSEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCOGSEvent"')
  return COGSEvent_possibleTypes.includes(obj.__typename)
}



var COGSEventPeriod_possibleTypes = ['COGSEventPeriod']
export var isCOGSEventPeriod = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCOGSEventPeriod"')
  return COGSEventPeriod_possibleTypes.includes(obj.__typename)
}



var COGSSummaryV2_possibleTypes = ['COGSSummaryV2']
export var isCOGSSummaryV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCOGSSummaryV2"')
  return COGSSummaryV2_possibleTypes.includes(obj.__typename)
}



var CommonAction_possibleTypes = ['CommonAction']
export var isCommonAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCommonAction"')
  return CommonAction_possibleTypes.includes(obj.__typename)
}



var Company_possibleTypes = ['Company']
export var isCompany = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompany"')
  return Company_possibleTypes.includes(obj.__typename)
}



var CompanyAccountValidation_possibleTypes = ['CompanyAccountValidation']
export var isCompanyAccountValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyAccountValidation"')
  return CompanyAccountValidation_possibleTypes.includes(obj.__typename)
}



var CompanyAddress_possibleTypes = ['CompanyAddress']
export var isCompanyAddress = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyAddress"')
  return CompanyAddress_possibleTypes.includes(obj.__typename)
}



var CompanyAffiliate_possibleTypes = ['CompanyAffiliate']
export var isCompanyAffiliate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyAffiliate"')
  return CompanyAffiliate_possibleTypes.includes(obj.__typename)
}



var CompanyAffiliateTransaction_possibleTypes = ['CompanyAffiliateTransaction']
export var isCompanyAffiliateTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyAffiliateTransaction"')
  return CompanyAffiliateTransaction_possibleTypes.includes(obj.__typename)
}



var CompanyArCashDiffsActionsResponse_possibleTypes = ['CompanyArCashDiffsActionsResponse']
export var isCompanyArCashDiffsActionsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyArCashDiffsActionsResponse"')
  return CompanyArCashDiffsActionsResponse_possibleTypes.includes(obj.__typename)
}



var CompanyArCashDiffsFixbooksResponse_possibleTypes = ['CompanyArCashDiffsFixbooksResponse']
export var isCompanyArCashDiffsFixbooksResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyArCashDiffsFixbooksResponse"')
  return CompanyArCashDiffsFixbooksResponse_possibleTypes.includes(obj.__typename)
}



var CompanyArCashDiffsOrdersResponse_possibleTypes = ['CompanyArCashDiffsOrdersResponse']
export var isCompanyArCashDiffsOrdersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyArCashDiffsOrdersResponse"')
  return CompanyArCashDiffsOrdersResponse_possibleTypes.includes(obj.__typename)
}



var CompanyArCashDiffsResponse_possibleTypes = ['CompanyArCashDiffsResponse']
export var isCompanyArCashDiffsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyArCashDiffsResponse"')
  return CompanyArCashDiffsResponse_possibleTypes.includes(obj.__typename)
}



var CompanyArCashTotalsResponse_possibleTypes = ['CompanyArCashTotalsResponse']
export var isCompanyArCashTotalsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyArCashTotalsResponse"')
  return CompanyArCashTotalsResponse_possibleTypes.includes(obj.__typename)
}



var CompanyBilling_possibleTypes = ['CompanyBilling']
export var isCompanyBilling = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyBilling"')
  return CompanyBilling_possibleTypes.includes(obj.__typename)
}



var CompanyConfiguration_possibleTypes = ['CompanyConfiguration']
export var isCompanyConfiguration = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyConfiguration"')
  return CompanyConfiguration_possibleTypes.includes(obj.__typename)
}



var CompanyEventsLockHistory_possibleTypes = ['CompanyEventsLockHistory']
export var isCompanyEventsLockHistory = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyEventsLockHistory"')
  return CompanyEventsLockHistory_possibleTypes.includes(obj.__typename)
}



var CompanyFiscalYearStart_possibleTypes = ['CompanyFiscalYearStart']
export var isCompanyFiscalYearStart = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyFiscalYearStart"')
  return CompanyFiscalYearStart_possibleTypes.includes(obj.__typename)
}



var CompanyHealthStatus_possibleTypes = ['CompanyHealthStatus']
export var isCompanyHealthStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyHealthStatus"')
  return CompanyHealthStatus_possibleTypes.includes(obj.__typename)
}



var CompanyIdAndStripeCustomerId_possibleTypes = ['CompanyIdAndStripeCustomerId']
export var isCompanyIdAndStripeCustomerId = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyIdAndStripeCustomerId"')
  return CompanyIdAndStripeCustomerId_possibleTypes.includes(obj.__typename)
}



var CompanyIntegration_possibleTypes = ['CompanyIntegration']
export var isCompanyIntegration = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyIntegration"')
  return CompanyIntegration_possibleTypes.includes(obj.__typename)
}



var CompanyLoan_possibleTypes = ['CompanyLoan']
export var isCompanyLoan = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLoan"')
  return CompanyLoan_possibleTypes.includes(obj.__typename)
}



var CompanyLoanResponse_possibleTypes = ['CompanyLoanResponse']
export var isCompanyLoanResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLoanResponse"')
  return CompanyLoanResponse_possibleTypes.includes(obj.__typename)
}



var CompanyLoansResponse_possibleTypes = ['CompanyLoansResponse']
export var isCompanyLoansResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLoansResponse"')
  return CompanyLoansResponse_possibleTypes.includes(obj.__typename)
}



var CompanyLockConditions_possibleTypes = ['CompanyLockConditions']
export var isCompanyLockConditions = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLockConditions"')
  return CompanyLockConditions_possibleTypes.includes(obj.__typename)
}



var CompanyLockHistory_possibleTypes = ['CompanyLockHistory']
export var isCompanyLockHistory = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLockHistory"')
  return CompanyLockHistory_possibleTypes.includes(obj.__typename)
}



var CompanyLockStatus_possibleTypes = ['CompanyLockStatus']
export var isCompanyLockStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyLockStatus"')
  return CompanyLockStatus_possibleTypes.includes(obj.__typename)
}



var CompanyUser_possibleTypes = ['CompanyUser']
export var isCompanyUser = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyUser"')
  return CompanyUser_possibleTypes.includes(obj.__typename)
}



var CompanyUsersResponse_possibleTypes = ['CompanyUsersResponse']
export var isCompanyUsersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyUsersResponse"')
  return CompanyUsersResponse_possibleTypes.includes(obj.__typename)
}



var CompanyValidation_possibleTypes = ['CompanyValidation']
export var isCompanyValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyValidation"')
  return CompanyValidation_possibleTypes.includes(obj.__typename)
}



var CompanyValidationDomain_possibleTypes = ['CompanyValidationDomain']
export var isCompanyValidationDomain = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyValidationDomain"')
  return CompanyValidationDomain_possibleTypes.includes(obj.__typename)
}



var CompanyWorksheet_possibleTypes = ['CompanyWorksheet']
export var isCompanyWorksheet = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyWorksheet"')
  return CompanyWorksheet_possibleTypes.includes(obj.__typename)
}



var CompanyWorksheetRow_possibleTypes = ['CompanyWorksheetRow']
export var isCompanyWorksheetRow = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyWorksheetRow"')
  return CompanyWorksheetRow_possibleTypes.includes(obj.__typename)
}



var CompanyWorksheetRowLockableEntity_possibleTypes = ['BankAccount']
export var isCompanyWorksheetRowLockableEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCompanyWorksheetRowLockableEntity"')
  return CompanyWorksheetRowLockableEntity_possibleTypes.includes(obj.__typename)
}



var Contractor_possibleTypes = ['Contractor']
export var isContractor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContractor"')
  return Contractor_possibleTypes.includes(obj.__typename)
}



var ContractorPayment_possibleTypes = ['ContractorPayment']
export var isContractorPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContractorPayment"')
  return ContractorPayment_possibleTypes.includes(obj.__typename)
}



var ContractorPaymentIdsByCompanyItem_possibleTypes = ['ContractorPaymentIdsByCompanyItem']
export var isContractorPaymentIdsByCompanyItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContractorPaymentIdsByCompanyItem"')
  return ContractorPaymentIdsByCompanyItem_possibleTypes.includes(obj.__typename)
}



var ContractorPaymentIdsByCompanyResponse_possibleTypes = ['ContractorPaymentIdsByCompanyResponse']
export var isContractorPaymentIdsByCompanyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContractorPaymentIdsByCompanyResponse"')
  return ContractorPaymentIdsByCompanyResponse_possibleTypes.includes(obj.__typename)
}



var ContractorPaymentsInternalResponse_possibleTypes = ['ContractorPaymentsInternalResponse']
export var isContractorPaymentsInternalResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isContractorPaymentsInternalResponse"')
  return ContractorPaymentsInternalResponse_possibleTypes.includes(obj.__typename)
}



var CreateCheckoutSessionResponse_possibleTypes = ['CreateCheckoutSessionResponse']
export var isCreateCheckoutSessionResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateCheckoutSessionResponse"')
  return CreateCheckoutSessionResponse_possibleTypes.includes(obj.__typename)
}



var CreateCustomerCategorizationRuleResult_possibleTypes = ['CreateCustomerCategorizationRuleResult']
export var isCreateCustomerCategorizationRuleResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateCustomerCategorizationRuleResult"')
  return CreateCustomerCategorizationRuleResult_possibleTypes.includes(obj.__typename)
}



var CreateGlobalRuleResult_possibleTypes = ['CreateGlobalRuleResult']
export var isCreateGlobalRuleResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateGlobalRuleResult"')
  return CreateGlobalRuleResult_possibleTypes.includes(obj.__typename)
}



var CreateInventoryVendorValidationResponse_possibleTypes = ['CreateInventoryVendorValidationResponse']
export var isCreateInventoryVendorValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateInventoryVendorValidationResponse"')
  return CreateInventoryVendorValidationResponse_possibleTypes.includes(obj.__typename)
}



var CreateOrUpdateAccrualResult_possibleTypes = ['CreateOrUpdateAccrualResult']
export var isCreateOrUpdateAccrualResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateOrUpdateAccrualResult"')
  return CreateOrUpdateAccrualResult_possibleTypes.includes(obj.__typename)
}



var CreatePhysicalCountAttachmentResult_possibleTypes = ['PhysicalCountAttachment','PhysicalCountError']
export var isCreatePhysicalCountAttachmentResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreatePhysicalCountAttachmentResult"')
  return CreatePhysicalCountAttachmentResult_possibleTypes.includes(obj.__typename)
}



var CreateProductManuallyError_possibleTypes = ['CreateProductManuallyError']
export var isCreateProductManuallyError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateProductManuallyError"')
  return CreateProductManuallyError_possibleTypes.includes(obj.__typename)
}



var CreateProductManuallyResponse_possibleTypes = ['CreateProductManuallyError','CreateProductManuallySuccess']
export var isCreateProductManuallyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateProductManuallyResponse"')
  return CreateProductManuallyResponse_possibleTypes.includes(obj.__typename)
}



var CreateProductManuallySuccess_possibleTypes = ['CreateProductManuallySuccess']
export var isCreateProductManuallySuccess = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateProductManuallySuccess"')
  return CreateProductManuallySuccess_possibleTypes.includes(obj.__typename)
}



var CreateTeamCategorizationRuleResult_possibleTypes = ['CreateTeamCategorizationRuleResult']
export var isCreateTeamCategorizationRuleResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateTeamCategorizationRuleResult"')
  return CreateTeamCategorizationRuleResult_possibleTypes.includes(obj.__typename)
}



var CreateUserResponse_possibleTypes = ['CreateUserResponse']
export var isCreateUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCreateUserResponse"')
  return CreateUserResponse_possibleTypes.includes(obj.__typename)
}



var CSVUpload_possibleTypes = ['CSVUpload']
export var isCSVUpload = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCSVUpload"')
  return CSVUpload_possibleTypes.includes(obj.__typename)
}



var CurrentlyRunningInventoryDataSyncResponse_possibleTypes = ['CurrentlyRunningInventoryDataSyncResponse']
export var isCurrentlyRunningInventoryDataSyncResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCurrentlyRunningInventoryDataSyncResponse"')
  return CurrentlyRunningInventoryDataSyncResponse_possibleTypes.includes(obj.__typename)
}



var Customer_possibleTypes = ['Customer']
export var isCustomer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomer"')
  return Customer_possibleTypes.includes(obj.__typename)
}



var CustomerAddress_possibleTypes = ['CustomerAddress']
export var isCustomerAddress = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomerAddress"')
  return CustomerAddress_possibleTypes.includes(obj.__typename)
}



var CustomerNotExistError_possibleTypes = ['CustomerNotExistError']
export var isCustomerNotExistError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomerNotExistError"')
  return CustomerNotExistError_possibleTypes.includes(obj.__typename)
}



var CustomerV2_possibleTypes = ['CustomerV2']
export var isCustomerV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomerV2"')
  return CustomerV2_possibleTypes.includes(obj.__typename)
}



var CustomSpread_possibleTypes = ['CustomSpread']
export var isCustomSpread = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isCustomSpread"')
  return CustomSpread_possibleTypes.includes(obj.__typename)
}



var DailyAggregatedOwnersDraw_possibleTypes = ['DailyAggregatedOwnersDraw']
export var isDailyAggregatedOwnersDraw = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyAggregatedOwnersDraw"')
  return DailyAggregatedOwnersDraw_possibleTypes.includes(obj.__typename)
}



var DailyAggregatedPaymentCheck_possibleTypes = ['DailyAggregatedPaymentCheck']
export var isDailyAggregatedPaymentCheck = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyAggregatedPaymentCheck"')
  return DailyAggregatedPaymentCheck_possibleTypes.includes(obj.__typename)
}



var DailyAggregatedPayrollData_possibleTypes = ['DailyAggregatedPayrollData']
export var isDailyAggregatedPayrollData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyAggregatedPayrollData"')
  return DailyAggregatedPayrollData_possibleTypes.includes(obj.__typename)
}



var DailyAggregatedReimbursement_possibleTypes = ['DailyAggregatedReimbursement']
export var isDailyAggregatedReimbursement = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyAggregatedReimbursement"')
  return DailyAggregatedReimbursement_possibleTypes.includes(obj.__typename)
}



var DailyPaymentsByContractor_possibleTypes = ['DailyPaymentsByContractor']
export var isDailyPaymentsByContractor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyPaymentsByContractor"')
  return DailyPaymentsByContractor_possibleTypes.includes(obj.__typename)
}



var DailyPaymentsByEmployee_possibleTypes = ['DailyPaymentsByEmployee']
export var isDailyPaymentsByEmployee = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDailyPaymentsByEmployee"')
  return DailyPaymentsByEmployee_possibleTypes.includes(obj.__typename)
}



var DataSerie_possibleTypes = ['DataSerie']
export var isDataSerie = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDataSerie"')
  return DataSerie_possibleTypes.includes(obj.__typename)
}



var DateRange_possibleTypes = ['DateRange']
export var isDateRange = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDateRange"')
  return DateRange_possibleTypes.includes(obj.__typename)
}



var DeadInventoryCogs_possibleTypes = ['DeadInventoryCogs']
export var isDeadInventoryCogs = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeadInventoryCogs"')
  return DeadInventoryCogs_possibleTypes.includes(obj.__typename)
}



var DeadInventoryMonthlyBreakdown_possibleTypes = ['DeadInventoryMonthlyBreakdown']
export var isDeadInventoryMonthlyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeadInventoryMonthlyBreakdown"')
  return DeadInventoryMonthlyBreakdown_possibleTypes.includes(obj.__typename)
}



var DeleteInvoicesResponse_possibleTypes = ['DeleteInvoicesResponse']
export var isDeleteInvoicesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteInvoicesResponse"')
  return DeleteInvoicesResponse_possibleTypes.includes(obj.__typename)
}



var DeleteLoansEventsEffectConfigurationResult_possibleTypes = ['DeleteLoansEventsEffectConfigurationResult']
export var isDeleteLoansEventsEffectConfigurationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteLoansEventsEffectConfigurationResult"')
  return DeleteLoansEventsEffectConfigurationResult_possibleTypes.includes(obj.__typename)
}



var DeleteLoansResults_possibleTypes = ['DeleteLoansResults']
export var isDeleteLoansResults = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteLoansResults"')
  return DeleteLoansResults_possibleTypes.includes(obj.__typename)
}



var DeleteOrderGroupResponse_possibleTypes = ['DeleteOrderGroupResponse']
export var isDeleteOrderGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteOrderGroupResponse"')
  return DeleteOrderGroupResponse_possibleTypes.includes(obj.__typename)
}



var DeleteOrderGroupUponTransactionChangeResponse_possibleTypes = ['DeleteOrderGroupUponTransactionChangeResponse']
export var isDeleteOrderGroupUponTransactionChangeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeleteOrderGroupUponTransactionChangeResponse"')
  return DeleteOrderGroupUponTransactionChangeResponse_possibleTypes.includes(obj.__typename)
}



var DeletePaymentGroupResponse_possibleTypes = ['DeletePaymentGroupResponse']
export var isDeletePaymentGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeletePaymentGroupResponse"')
  return DeletePaymentGroupResponse_possibleTypes.includes(obj.__typename)
}



var DeletePaymentGroupUponTransactionChangeResponse_possibleTypes = ['DeletePaymentGroupUponTransactionChangeResponse']
export var isDeletePaymentGroupUponTransactionChangeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeletePaymentGroupUponTransactionChangeResponse"')
  return DeletePaymentGroupUponTransactionChangeResponse_possibleTypes.includes(obj.__typename)
}



var DeletePhysicalCountAttachmentResult_possibleTypes = ['PhysicalCountAttachment','PhysicalCountError']
export var isDeletePhysicalCountAttachmentResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeletePhysicalCountAttachmentResult"')
  return DeletePhysicalCountAttachmentResult_possibleTypes.includes(obj.__typename)
}



var DeletePhysicalCountResult_possibleTypes = ['PhysicalCount','PhysicalCountError']
export var isDeletePhysicalCountResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDeletePhysicalCountResult"')
  return DeletePhysicalCountResult_possibleTypes.includes(obj.__typename)
}



var Department_possibleTypes = ['Department']
export var isDepartment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDepartment"')
  return Department_possibleTypes.includes(obj.__typename)
}



var DirectLineItem_possibleTypes = ['DirectLineItem']
export var isDirectLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDirectLineItem"')
  return DirectLineItem_possibleTypes.includes(obj.__typename)
}



var Discount_possibleTypes = ['Discount']
export var isDiscount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDiscount"')
  return Discount_possibleTypes.includes(obj.__typename)
}



var DonationCogs_possibleTypes = ['DonationCogs']
export var isDonationCogs = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDonationCogs"')
  return DonationCogs_possibleTypes.includes(obj.__typename)
}



var DonationMonthlyBreakdown_possibleTypes = ['DonationMonthlyBreakdown']
export var isDonationMonthlyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDonationMonthlyBreakdown"')
  return DonationMonthlyBreakdown_possibleTypes.includes(obj.__typename)
}



var DownloadAttachmentResult_possibleTypes = ['DownloadAttachmentResult']
export var isDownloadAttachmentResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDownloadAttachmentResult"')
  return DownloadAttachmentResult_possibleTypes.includes(obj.__typename)
}



var DownloadExplainFifoResult_possibleTypes = ['DownloadExplainFifoResult']
export var isDownloadExplainFifoResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDownloadExplainFifoResult"')
  return DownloadExplainFifoResult_possibleTypes.includes(obj.__typename)
}



var DueDateSummary_possibleTypes = ['DueDateSummary']
export var isDueDateSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isDueDateSummary"')
  return DueDateSummary_possibleTypes.includes(obj.__typename)
}



var EarlyRepaymentDiscount_possibleTypes = ['EarlyRepaymentDiscount']
export var isEarlyRepaymentDiscount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEarlyRepaymentDiscount"')
  return EarlyRepaymentDiscount_possibleTypes.includes(obj.__typename)
}



var Employee_possibleTypes = ['Employee']
export var isEmployee = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEmployee"')
  return Employee_possibleTypes.includes(obj.__typename)
}



var Error_possibleTypes = ['CustomerNotExistError','VendorNotExistError']
export var isError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isError"')
  return Error_possibleTypes.includes(obj.__typename)
}



var EventsLock_possibleTypes = ['EventsLock']
export var isEventsLock = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isEventsLock"')
  return EventsLock_possibleTypes.includes(obj.__typename)
}



var ExcludedSalesChannelItemReconciliation_possibleTypes = ['ExcludedSalesChannelItemReconciliation']
export var isExcludedSalesChannelItemReconciliation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExcludedSalesChannelItemReconciliation"')
  return ExcludedSalesChannelItemReconciliation_possibleTypes.includes(obj.__typename)
}



var ExplainFifoResult_possibleTypes = ['ExplainFifoResult']
export var isExplainFifoResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExplainFifoResult"')
  return ExplainFifoResult_possibleTypes.includes(obj.__typename)
}



var ExportCOGSBreakdownResponse_possibleTypes = ['ExportCOGSBreakdownResponse']
export var isExportCOGSBreakdownResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportCOGSBreakdownResponse"')
  return ExportCOGSBreakdownResponse_possibleTypes.includes(obj.__typename)
}



var ExportCOGSOverviewResponse_possibleTypes = ['ExportCOGSOverviewResponse']
export var isExportCOGSOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportCOGSOverviewResponse"')
  return ExportCOGSOverviewResponse_possibleTypes.includes(obj.__typename)
}



var ExportInvoicesResponse_possibleTypes = ['ExportInvoicesResponse']
export var isExportInvoicesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportInvoicesResponse"')
  return ExportInvoicesResponse_possibleTypes.includes(obj.__typename)
}



var ExportLoansResult_possibleTypes = ['ExportLoansResult']
export var isExportLoansResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportLoansResult"')
  return ExportLoansResult_possibleTypes.includes(obj.__typename)
}



var ExportProductsResponse_possibleTypes = ['ExportProductsResponse']
export var isExportProductsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportProductsResponse"')
  return ExportProductsResponse_possibleTypes.includes(obj.__typename)
}



var ExportResponse_possibleTypes = ['ExportResponse']
export var isExportResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isExportResponse"')
  return ExportResponse_possibleTypes.includes(obj.__typename)
}



var FifoCogsBusinessEventsMetrics_possibleTypes = ['FifoCogsBusinessEventsMetrics']
export var isFifoCogsBusinessEventsMetrics = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoCogsBusinessEventsMetrics"')
  return FifoCogsBusinessEventsMetrics_possibleTypes.includes(obj.__typename)
}



var FifoCogsBusinessEventsSingleMetric_possibleTypes = ['FifoCogsBusinessEventsSingleMetric']
export var isFifoCogsBusinessEventsSingleMetric = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoCogsBusinessEventsSingleMetric"')
  return FifoCogsBusinessEventsSingleMetric_possibleTypes.includes(obj.__typename)
}



var FifoValidationCogsSummary_possibleTypes = ['FifoValidationCogsSummary']
export var isFifoValidationCogsSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationCogsSummary"')
  return FifoValidationCogsSummary_possibleTypes.includes(obj.__typename)
}



var FifoValidationProductData_possibleTypes = ['FifoValidationProductData']
export var isFifoValidationProductData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationProductData"')
  return FifoValidationProductData_possibleTypes.includes(obj.__typename)
}



var FifoValidationProductTotals_possibleTypes = ['FifoValidationProductTotals']
export var isFifoValidationProductTotals = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationProductTotals"')
  return FifoValidationProductTotals_possibleTypes.includes(obj.__typename)
}



var FifoValidationProductValidationData_possibleTypes = ['FifoValidationProductValidationData']
export var isFifoValidationProductValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationProductValidationData"')
  return FifoValidationProductValidationData_possibleTypes.includes(obj.__typename)
}



var FifoValidationResponse_possibleTypes = ['FifoValidationResponse']
export var isFifoValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationResponse"')
  return FifoValidationResponse_possibleTypes.includes(obj.__typename)
}



var FifoValidationsListResponse_possibleTypes = ['FifoValidationsListResponse']
export var isFifoValidationsListResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationsListResponse"')
  return FifoValidationsListResponse_possibleTypes.includes(obj.__typename)
}



var FifoValidationSummary_possibleTypes = ['FifoValidationSummary']
export var isFifoValidationSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationSummary"')
  return FifoValidationSummary_possibleTypes.includes(obj.__typename)
}



var FifoValidationTotalsBalance_possibleTypes = ['FifoValidationTotalsBalance']
export var isFifoValidationTotalsBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFifoValidationTotalsBalance"')
  return FifoValidationTotalsBalance_possibleTypes.includes(obj.__typename)
}



var FileLocation_possibleTypes = ['FileLocation']
export var isFileLocation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFileLocation"')
  return FileLocation_possibleTypes.includes(obj.__typename)
}



var FileUploadUrlResult_possibleTypes = ['FileUploadUrlResult']
export var isFileUploadUrlResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFileUploadUrlResult"')
  return FileUploadUrlResult_possibleTypes.includes(obj.__typename)
}



var FinalizeAskTheUserFlowResult_possibleTypes = ['FinalizeAskTheUserFlowResult']
export var isFinalizeAskTheUserFlowResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFinalizeAskTheUserFlowResult"')
  return FinalizeAskTheUserFlowResult_possibleTypes.includes(obj.__typename)
}



var FinaloopProduct_possibleTypes = ['FinaloopProduct']
export var isFinaloopProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFinaloopProduct"')
  return FinaloopProduct_possibleTypes.includes(obj.__typename)
}



var FixedAssetsData_possibleTypes = ['FixedAssetsData']
export var isFixedAssetsData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFixedAssetsData"')
  return FixedAssetsData_possibleTypes.includes(obj.__typename)
}



var FulfillmentAction_possibleTypes = ['FulfillmentAction']
export var isFulfillmentAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFulfillmentAction"')
  return FulfillmentAction_possibleTypes.includes(obj.__typename)
}



var FulfillmentCost_possibleTypes = ['FulfillmentCost']
export var isFulfillmentCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFulfillmentCost"')
  return FulfillmentCost_possibleTypes.includes(obj.__typename)
}



var FulfillmentMethod_possibleTypes = ['FulfillmentMethod']
export var isFulfillmentMethod = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFulfillmentMethod"')
  return FulfillmentMethod_possibleTypes.includes(obj.__typename)
}



var FulfillmentSetting_possibleTypes = ['FulfillmentSetting']
export var isFulfillmentSetting = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFulfillmentSetting"')
  return FulfillmentSetting_possibleTypes.includes(obj.__typename)
}



var FulfillmentsValidation_possibleTypes = ['FulfillmentsValidation']
export var isFulfillmentsValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isFulfillmentsValidation"')
  return FulfillmentsValidation_possibleTypes.includes(obj.__typename)
}



var GenerateNoUnitsValidationError_possibleTypes = ['GenerateNoUnitsValidationError']
export var isGenerateNoUnitsValidationError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGenerateNoUnitsValidationError"')
  return GenerateNoUnitsValidationError_possibleTypes.includes(obj.__typename)
}



var GenerateNoUnitsValidationResponse_possibleTypes = ['GenerateNoUnitsValidationResponse']
export var isGenerateNoUnitsValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGenerateNoUnitsValidationResponse"')
  return GenerateNoUnitsValidationResponse_possibleTypes.includes(obj.__typename)
}



var GenerateNoUnitsValidationResult_possibleTypes = ['GenerateNoUnitsValidationResult']
export var isGenerateNoUnitsValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGenerateNoUnitsValidationResult"')
  return GenerateNoUnitsValidationResult_possibleTypes.includes(obj.__typename)
}



var GeneratePdfError_possibleTypes = ['GeneratePdfError']
export var isGeneratePdfError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGeneratePdfError"')
  return GeneratePdfError_possibleTypes.includes(obj.__typename)
}



var GeneratePdfResponse_possibleTypes = ['GeneratePdfResponse']
export var isGeneratePdfResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGeneratePdfResponse"')
  return GeneratePdfResponse_possibleTypes.includes(obj.__typename)
}



var GeneratePdfResponseV2_possibleTypes = ['GeneratePdfResponse','GeneratePdfError']
export var isGeneratePdfResponseV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGeneratePdfResponseV2"')
  return GeneratePdfResponseV2_possibleTypes.includes(obj.__typename)
}



var GetBillsResponse_possibleTypes = ['GetBillsResponse']
export var isGetBillsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetBillsResponse"')
  return GetBillsResponse_possibleTypes.includes(obj.__typename)
}



var GetCOGSBreakdownResponse_possibleTypes = ['GetCOGSBreakdownResponse']
export var isGetCOGSBreakdownResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetCOGSBreakdownResponse"')
  return GetCOGSBreakdownResponse_possibleTypes.includes(obj.__typename)
}



var GetCOGSOverviewResponse_possibleTypes = ['GetCOGSOverviewResponse']
export var isGetCOGSOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetCOGSOverviewResponse"')
  return GetCOGSOverviewResponse_possibleTypes.includes(obj.__typename)
}



var GetCustomerResponse_possibleTypes = ['Customer','CustomerNotExistError']
export var isGetCustomerResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetCustomerResponse"')
  return GetCustomerResponse_possibleTypes.includes(obj.__typename)
}



var GetFromEmailAddressError_possibleTypes = ['GetFromEmailAddressError']
export var isGetFromEmailAddressError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetFromEmailAddressError"')
  return GetFromEmailAddressError_possibleTypes.includes(obj.__typename)
}



var GetFromEmailAddressResponse_possibleTypes = ['GetFromEmailAddressResponse']
export var isGetFromEmailAddressResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetFromEmailAddressResponse"')
  return GetFromEmailAddressResponse_possibleTypes.includes(obj.__typename)
}



var GetFromEmailAddressResult_possibleTypes = ['GetFromEmailAddressResponse','GetFromEmailAddressError']
export var isGetFromEmailAddressResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetFromEmailAddressResult"')
  return GetFromEmailAddressResult_possibleTypes.includes(obj.__typename)
}



var GetFulfillmentSettingsResponse_possibleTypes = ['GetFulfillmentSettingsResponse']
export var isGetFulfillmentSettingsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetFulfillmentSettingsResponse"')
  return GetFulfillmentSettingsResponse_possibleTypes.includes(obj.__typename)
}



var GetInventoryAccountBalanceResponse_possibleTypes = ['GetInventoryAccountBalanceResponse']
export var isGetInventoryAccountBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetInventoryAccountBalanceResponse"')
  return GetInventoryAccountBalanceResponse_possibleTypes.includes(obj.__typename)
}



var GetInvoicesAffectingPaymentGroupsResponse_possibleTypes = ['GetInvoicesAffectingPaymentGroupsResponse']
export var isGetInvoicesAffectingPaymentGroupsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetInvoicesAffectingPaymentGroupsResponse"')
  return GetInvoicesAffectingPaymentGroupsResponse_possibleTypes.includes(obj.__typename)
}



var GetInvoicesResult_possibleTypes = ['GetInvoicesResult']
export var isGetInvoicesResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetInvoicesResult"')
  return GetInvoicesResult_possibleTypes.includes(obj.__typename)
}



var GetLatestValidationResult_possibleTypes = ['GetLatestValidationResult']
export var isGetLatestValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetLatestValidationResult"')
  return GetLatestValidationResult_possibleTypes.includes(obj.__typename)
}



var GetLoanEventsLengthResult_possibleTypes = ['GetLoanEventsLengthResult']
export var isGetLoanEventsLengthResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetLoanEventsLengthResult"')
  return GetLoanEventsLengthResult_possibleTypes.includes(obj.__typename)
}



var GetLoanEventsResult_possibleTypes = ['GetLoanEventsResult']
export var isGetLoanEventsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetLoanEventsResult"')
  return GetLoanEventsResult_possibleTypes.includes(obj.__typename)
}



var GetLoanRelationshipsResult_possibleTypes = ['GetLoanRelationshipsResult']
export var isGetLoanRelationshipsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetLoanRelationshipsResult"')
  return GetLoanRelationshipsResult_possibleTypes.includes(obj.__typename)
}



var GetLoansOutstandingAmountOnDateResult_possibleTypes = ['GetLoansOutstandingAmountOnDateResult']
export var isGetLoansOutstandingAmountOnDateResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetLoansOutstandingAmountOnDateResult"')
  return GetLoansOutstandingAmountOnDateResult_possibleTypes.includes(obj.__typename)
}



var GetMatchingEffectRuleResult_possibleTypes = ['GetMatchingEffectRuleResult']
export var isGetMatchingEffectRuleResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetMatchingEffectRuleResult"')
  return GetMatchingEffectRuleResult_possibleTypes.includes(obj.__typename)
}



var GetOffersResponse_possibleTypes = ['GetOffersResponse']
export var isGetOffersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetOffersResponse"')
  return GetOffersResponse_possibleTypes.includes(obj.__typename)
}



var GetOrderFilteringBoundsResult_possibleTypes = ['GetOrderFilteringBoundsResult']
export var isGetOrderFilteringBoundsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetOrderFilteringBoundsResult"')
  return GetOrderFilteringBoundsResult_possibleTypes.includes(obj.__typename)
}



var GetOrdersResultV2_possibleTypes = ['GetOrdersResultV2']
export var isGetOrdersResultV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetOrdersResultV2"')
  return GetOrdersResultV2_possibleTypes.includes(obj.__typename)
}



var GetReadyValidationResponse_possibleTypes = ['GetReadyValidationResponse']
export var isGetReadyValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetReadyValidationResponse"')
  return GetReadyValidationResponse_possibleTypes.includes(obj.__typename)
}



var GetSalesResult_possibleTypes = ['GetSalesResult']
export var isGetSalesResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetSalesResult"')
  return GetSalesResult_possibleTypes.includes(obj.__typename)
}



var GetVendorResponse_possibleTypes = ['Vendor','VendorNotExistError']
export var isGetVendorResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGetVendorResponse"')
  return GetVendorResponse_possibleTypes.includes(obj.__typename)
}



var GiveawayCogs_possibleTypes = ['GiveawayCogs']
export var isGiveawayCogs = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGiveawayCogs"')
  return GiveawayCogs_possibleTypes.includes(obj.__typename)
}



var GiveawayMonthlyBreakdown_possibleTypes = ['GiveawayMonthlyBreakdown']
export var isGiveawayMonthlyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGiveawayMonthlyBreakdown"')
  return GiveawayMonthlyBreakdown_possibleTypes.includes(obj.__typename)
}



var GlobalVendorMappingRule_possibleTypes = ['GlobalVendorMappingRule']
export var isGlobalVendorMappingRule = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGlobalVendorMappingRule"')
  return GlobalVendorMappingRule_possibleTypes.includes(obj.__typename)
}



var GoogleConnectLinkResponse_possibleTypes = ['GoogleConnectLinkResponse']
export var isGoogleConnectLinkResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGoogleConnectLinkResponse"')
  return GoogleConnectLinkResponse_possibleTypes.includes(obj.__typename)
}



var GoogleConnectStatusResponse_possibleTypes = ['GoogleConnectStatusResponse']
export var isGoogleConnectStatusResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGoogleConnectStatusResponse"')
  return GoogleConnectStatusResponse_possibleTypes.includes(obj.__typename)
}



var GoogleTokenCodeResponse_possibleTypes = ['GoogleTokenCodeResponse']
export var isGoogleTokenCodeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGoogleTokenCodeResponse"')
  return GoogleTokenCodeResponse_possibleTypes.includes(obj.__typename)
}



var GTMSource_possibleTypes = ['GTMSource']
export var isGTMSource = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGTMSource"')
  return GTMSource_possibleTypes.includes(obj.__typename)
}



var GTMSourceUtms_possibleTypes = ['GTMSourceUtms']
export var isGTMSourceUtms = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGTMSourceUtms"')
  return GTMSourceUtms_possibleTypes.includes(obj.__typename)
}



var GTMUtms_possibleTypes = ['GTMUtms']
export var isGTMUtms = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isGTMUtms"')
  return GTMUtms_possibleTypes.includes(obj.__typename)
}



var HandleUserLoginResponse_possibleTypes = ['HandleUserLoginResponse']
export var isHandleUserLoginResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHandleUserLoginResponse"')
  return HandleUserLoginResponse_possibleTypes.includes(obj.__typename)
}



var Holder_possibleTypes = ['Holder']
export var isHolder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHolder"')
  return Holder_possibleTypes.includes(obj.__typename)
}



var HolderCapitalInvestment_possibleTypes = ['HolderCapitalInvestment']
export var isHolderCapitalInvestment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHolderCapitalInvestment"')
  return HolderCapitalInvestment_possibleTypes.includes(obj.__typename)
}



var HolderShare_possibleTypes = ['HolderShare']
export var isHolderShare = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHolderShare"')
  return HolderShare_possibleTypes.includes(obj.__typename)
}



var HolderTransaction_possibleTypes = ['HolderTransaction']
export var isHolderTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isHolderTransaction"')
  return HolderTransaction_possibleTypes.includes(obj.__typename)
}



var ImageUploadUrlResult_possibleTypes = ['ImageUploadUrlResult']
export var isImageUploadUrlResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isImageUploadUrlResult"')
  return ImageUploadUrlResult_possibleTypes.includes(obj.__typename)
}



var ImpersonateResponse_possibleTypes = ['ImpersonateResponse']
export var isImpersonateResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isImpersonateResponse"')
  return ImpersonateResponse_possibleTypes.includes(obj.__typename)
}



var IncludedFinaloopSalesChannelItemReconciliation_possibleTypes = ['IncludedFinaloopSalesChannelItemReconciliation']
export var isIncludedFinaloopSalesChannelItemReconciliation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIncludedFinaloopSalesChannelItemReconciliation"')
  return IncludedFinaloopSalesChannelItemReconciliation_possibleTypes.includes(obj.__typename)
}



var IncludedShopifySalesChannelItemReconciliation_possibleTypes = ['IncludedShopifySalesChannelItemReconciliation']
export var isIncludedShopifySalesChannelItemReconciliation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIncludedShopifySalesChannelItemReconciliation"')
  return IncludedShopifySalesChannelItemReconciliation_possibleTypes.includes(obj.__typename)
}



var IncomingBatchAnalytics_possibleTypes = ['IncomingBatchAnalytics']
export var isIncomingBatchAnalytics = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIncomingBatchAnalytics"')
  return IncomingBatchAnalytics_possibleTypes.includes(obj.__typename)
}



var IncomingBatchOrigin_possibleTypes = ['Shipment','WarehouseOpeningBalance','RecipeWithBreakdown','ManualAssemblyWithBreakdown','WarehouseTransferWithBreakdown']
export var isIncomingBatchOrigin = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIncomingBatchOrigin"')
  return IncomingBatchOrigin_possibleTypes.includes(obj.__typename)
}



var IncomingInventoryAnalytics_possibleTypes = ['IncomingInventoryAnalytics']
export var isIncomingInventoryAnalytics = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIncomingInventoryAnalytics"')
  return IncomingInventoryAnalytics_possibleTypes.includes(obj.__typename)
}



var IndirectCostAllocation_possibleTypes = ['IndirectCostAllocation']
export var isIndirectCostAllocation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIndirectCostAllocation"')
  return IndirectCostAllocation_possibleTypes.includes(obj.__typename)
}



var IndirectLineItem_possibleTypes = ['IndirectLineItem']
export var isIndirectLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIndirectLineItem"')
  return IndirectLineItem_possibleTypes.includes(obj.__typename)
}



var Institution_possibleTypes = ['Institution']
export var isInstitution = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInstitution"')
  return Institution_possibleTypes.includes(obj.__typename)
}



var InstitutionMapping_possibleTypes = ['InstitutionMapping']
export var isInstitutionMapping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInstitutionMapping"')
  return InstitutionMapping_possibleTypes.includes(obj.__typename)
}



var InstitutionMappingResponse_possibleTypes = ['InstitutionMappingResponse']
export var isInstitutionMappingResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInstitutionMappingResponse"')
  return InstitutionMappingResponse_possibleTypes.includes(obj.__typename)
}



var InstitutionsResponse_possibleTypes = ['InstitutionsResponse']
export var isInstitutionsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInstitutionsResponse"')
  return InstitutionsResponse_possibleTypes.includes(obj.__typename)
}



var InstitutionVendorID_possibleTypes = ['InstitutionVendorID']
export var isInstitutionVendorID = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInstitutionVendorID"')
  return InstitutionVendorID_possibleTypes.includes(obj.__typename)
}



var IntegrationConnectionAccount_possibleTypes = ['IntegrationConnectionAccount']
export var isIntegrationConnectionAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationConnectionAccount"')
  return IntegrationConnectionAccount_possibleTypes.includes(obj.__typename)
}



var IntegrationFulfillmentSetting_possibleTypes = ['IntegrationFulfillmentSetting']
export var isIntegrationFulfillmentSetting = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationFulfillmentSetting"')
  return IntegrationFulfillmentSetting_possibleTypes.includes(obj.__typename)
}



var IntegrationInfo_possibleTypes = ['IntegrationInfo']
export var isIntegrationInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationInfo"')
  return IntegrationInfo_possibleTypes.includes(obj.__typename)
}



var IntegrationSaleTransaction_possibleTypes = ['IntegrationSaleTransaction']
export var isIntegrationSaleTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationSaleTransaction"')
  return IntegrationSaleTransaction_possibleTypes.includes(obj.__typename)
}



var IntegrationTransfer_possibleTypes = ['Payout','Topup']
export var isIntegrationTransfer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransfer"')
  return IntegrationTransfer_possibleTypes.includes(obj.__typename)
}



var IntegrationTransferClassification_possibleTypes = ['IntegrationTransferClassification']
export var isIntegrationTransferClassification = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransferClassification"')
  return IntegrationTransferClassification_possibleTypes.includes(obj.__typename)
}



var IntegrationTransferGroup_possibleTypes = ['IntegrationTransferGroup']
export var isIntegrationTransferGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransferGroup"')
  return IntegrationTransferGroup_possibleTypes.includes(obj.__typename)
}



var IntegrationTransferPairedEntity_possibleTypes = ['IntegrationTransferPairedEntity']
export var isIntegrationTransferPairedEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransferPairedEntity"')
  return IntegrationTransferPairedEntity_possibleTypes.includes(obj.__typename)
}



var IntegrationTransferParty_possibleTypes = ['UnknownIntegrationTransferBank','UnknownIntegrationTransferAccount','Vendor','BankAccount']
export var isIntegrationTransferParty = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransferParty"')
  return IntegrationTransferParty_possibleTypes.includes(obj.__typename)
}



var IntegrationTransferRule_possibleTypes = ['IntegrationTransferRule']
export var isIntegrationTransferRule = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIntegrationTransferRule"')
  return IntegrationTransferRule_possibleTypes.includes(obj.__typename)
}



var InventoryBatch_possibleTypes = ['InventoryBatch']
export var isInventoryBatch = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryBatch"')
  return InventoryBatch_possibleTypes.includes(obj.__typename)
}



var InventoryInitStatus_possibleTypes = ['InventoryInitStatus']
export var isInventoryInitStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryInitStatus"')
  return InventoryInitStatus_possibleTypes.includes(obj.__typename)
}



var InventoryPurchase_possibleTypes = ['InventoryPurchase']
export var isInventoryPurchase = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchase"')
  return InventoryPurchase_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseAttachment_possibleTypes = ['InventoryPurchaseAttachment']
export var isInventoryPurchaseAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseAttachment"')
  return InventoryPurchaseAttachment_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseCategory_possibleTypes = ['InventoryPurchaseCategory']
export var isInventoryPurchaseCategory = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseCategory"')
  return InventoryPurchaseCategory_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseCategorySummary_possibleTypes = ['InventoryPurchaseCategorySummary']
export var isInventoryPurchaseCategorySummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseCategorySummary"')
  return InventoryPurchaseCategorySummary_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseItem_possibleTypes = ['InventoryPurchaseItem']
export var isInventoryPurchaseItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseItem"')
  return InventoryPurchaseItem_possibleTypes.includes(obj.__typename)
}



var InventoryPurchasesExportResponse_possibleTypes = ['InventoryPurchasesExportResponse']
export var isInventoryPurchasesExportResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchasesExportResponse"')
  return InventoryPurchasesExportResponse_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseSourceEntity_possibleTypes = ['BankTransaction','Bill']
export var isInventoryPurchaseSourceEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseSourceEntity"')
  return InventoryPurchaseSourceEntity_possibleTypes.includes(obj.__typename)
}



var InventoryPurchasesResponse_possibleTypes = ['InventoryPurchasesResponse']
export var isInventoryPurchasesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchasesResponse"')
  return InventoryPurchasesResponse_possibleTypes.includes(obj.__typename)
}



var InventoryPurchasesSummaryResponse_possibleTypes = ['InventoryPurchasesSummaryResponse']
export var isInventoryPurchasesSummaryResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchasesSummaryResponse"')
  return InventoryPurchasesSummaryResponse_possibleTypes.includes(obj.__typename)
}



var InventoryPurchasesValidationResult_possibleTypes = ['InventoryPurchasesValidationResult']
export var isInventoryPurchasesValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchasesValidationResult"')
  return InventoryPurchasesValidationResult_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseUpdateOutOfDateError_possibleTypes = ['InventoryPurchaseUpdateOutOfDateError']
export var isInventoryPurchaseUpdateOutOfDateError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseUpdateOutOfDateError"')
  return InventoryPurchaseUpdateOutOfDateError_possibleTypes.includes(obj.__typename)
}



var InventoryPurchaseValidationResultResponse_possibleTypes = ['InventoryPurchaseValidationResultResponse']
export var isInventoryPurchaseValidationResultResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryPurchaseValidationResultResponse"')
  return InventoryPurchaseValidationResultResponse_possibleTypes.includes(obj.__typename)
}



var InventorySettings_possibleTypes = ['InventorySettings']
export var isInventorySettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventorySettings"')
  return InventorySettings_possibleTypes.includes(obj.__typename)
}



var InventoryVendor_possibleTypes = ['InventoryVendor']
export var isInventoryVendor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendor"')
  return InventoryVendor_possibleTypes.includes(obj.__typename)
}



var InventoryVendorAdjustment_possibleTypes = ['InventoryVendorAdjustment']
export var isInventoryVendorAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorAdjustment"')
  return InventoryVendorAdjustment_possibleTypes.includes(obj.__typename)
}



var InventoryVendorBillItem_possibleTypes = ['InventoryVendorBillItem']
export var isInventoryVendorBillItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorBillItem"')
  return InventoryVendorBillItem_possibleTypes.includes(obj.__typename)
}



var InventoryVendorBillItemSource_possibleTypes = ['InventoryPurchase','InventoryVendorPayment','InventoryVendorCredit','InventoryVendorAdjustment']
export var isInventoryVendorBillItemSource = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorBillItemSource"')
  return InventoryVendorBillItemSource_possibleTypes.includes(obj.__typename)
}



var InventoryVendorBillsOverviewResponse_possibleTypes = ['InventoryVendorBillsOverviewResponse']
export var isInventoryVendorBillsOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorBillsOverviewResponse"')
  return InventoryVendorBillsOverviewResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorBillsResponse_possibleTypes = ['InventoryVendorBillsResponse']
export var isInventoryVendorBillsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorBillsResponse"')
  return InventoryVendorBillsResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorCredit_possibleTypes = ['InventoryVendorCredit']
export var isInventoryVendorCredit = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorCredit"')
  return InventoryVendorCredit_possibleTypes.includes(obj.__typename)
}



var InventoryVendorFilterValuesResponse_possibleTypes = ['InventoryVendorFilterValuesResponse']
export var isInventoryVendorFilterValuesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorFilterValuesResponse"')
  return InventoryVendorFilterValuesResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorOverviewItem_possibleTypes = ['InventoryVendorOverviewItem']
export var isInventoryVendorOverviewItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorOverviewItem"')
  return InventoryVendorOverviewItem_possibleTypes.includes(obj.__typename)
}



var InventoryVendorParamsResponse_possibleTypes = ['InventoryVendorParamsResponse']
export var isInventoryVendorParamsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorParamsResponse"')
  return InventoryVendorParamsResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorPayment_possibleTypes = ['InventoryVendorPayment']
export var isInventoryVendorPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorPayment"')
  return InventoryVendorPayment_possibleTypes.includes(obj.__typename)
}



var InventoryVendorRollingBalanceItem_possibleTypes = ['InventoryVendorRollingBalanceItem']
export var isInventoryVendorRollingBalanceItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorRollingBalanceItem"')
  return InventoryVendorRollingBalanceItem_possibleTypes.includes(obj.__typename)
}



var InventoryVendorRollingBalanceItemSource_possibleTypes = ['PurchaseOrder','InventoryPurchase','WarehouseTransfer']
export var isInventoryVendorRollingBalanceItemSource = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorRollingBalanceItemSource"')
  return InventoryVendorRollingBalanceItemSource_possibleTypes.includes(obj.__typename)
}



var InventoryVendorRollingBalanceOverviewResponse_possibleTypes = ['InventoryVendorRollingBalanceOverviewResponse']
export var isInventoryVendorRollingBalanceOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorRollingBalanceOverviewResponse"')
  return InventoryVendorRollingBalanceOverviewResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorRollingBalanceResponse_possibleTypes = ['InventoryVendorRollingBalanceResponse']
export var isInventoryVendorRollingBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorRollingBalanceResponse"')
  return InventoryVendorRollingBalanceResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorsOverviewResponse_possibleTypes = ['InventoryVendorsOverviewResponse']
export var isInventoryVendorsOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorsOverviewResponse"')
  return InventoryVendorsOverviewResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorsResponse_possibleTypes = ['InventoryVendorsResponse']
export var isInventoryVendorsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorsResponse"')
  return InventoryVendorsResponse_possibleTypes.includes(obj.__typename)
}



var InventoryVendorValidationResult_possibleTypes = ['InventoryVendorValidationResult']
export var isInventoryVendorValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorValidationResult"')
  return InventoryVendorValidationResult_possibleTypes.includes(obj.__typename)
}



var InventoryVendorValidationResultItem_possibleTypes = ['InventoryVendorValidationResultItem']
export var isInventoryVendorValidationResultItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorValidationResultItem"')
  return InventoryVendorValidationResultItem_possibleTypes.includes(obj.__typename)
}



var InventoryVendorValidationTotalsResult_possibleTypes = ['InventoryVendorValidationTotalsResult']
export var isInventoryVendorValidationTotalsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInventoryVendorValidationTotalsResult"')
  return InventoryVendorValidationTotalsResult_possibleTypes.includes(obj.__typename)
}



var InviteUserResponse_possibleTypes = ['InviteUserResponse']
export var isInviteUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInviteUserResponse"')
  return InviteUserResponse_possibleTypes.includes(obj.__typename)
}



var Invoice_possibleTypes = ['Invoice']
export var isInvoice = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoice"')
  return Invoice_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroup_possibleTypes = ['InvoicePaymentGroup']
export var isInvoicePaymentGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroup"')
  return InvoicePaymentGroup_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroupByIdResponse_possibleTypes = ['InvoicePaymentGroupByIdResponse']
export var isInvoicePaymentGroupByIdResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroupByIdResponse"')
  return InvoicePaymentGroupByIdResponse_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroupsByIdsResponse_possibleTypes = ['InvoicePaymentGroupsByIdsResponse']
export var isInvoicePaymentGroupsByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroupsByIdsResponse"')
  return InvoicePaymentGroupsByIdsResponse_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroupsResponse_possibleTypes = ['InvoicePaymentGroupsResponse']
export var isInvoicePaymentGroupsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroupsResponse"')
  return InvoicePaymentGroupsResponse_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroupsUnclearedBalanceResponse_possibleTypes = ['InvoicePaymentGroupsUnclearedBalanceResponse']
export var isInvoicePaymentGroupsUnclearedBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroupsUnclearedBalanceResponse"')
  return InvoicePaymentGroupsUnclearedBalanceResponse_possibleTypes.includes(obj.__typename)
}



var InvoicePaymentGroupUnclearedBalance_possibleTypes = ['InvoicePaymentGroupUnclearedBalance']
export var isInvoicePaymentGroupUnclearedBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePaymentGroupUnclearedBalance"')
  return InvoicePaymentGroupUnclearedBalance_possibleTypes.includes(obj.__typename)
}



var InvoicePdf_possibleTypes = ['InvoicePdf']
export var isInvoicePdf = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicePdf"')
  return InvoicePdf_possibleTypes.includes(obj.__typename)
}



var InvoicesAggregations_possibleTypes = ['InvoicesAggregations']
export var isInvoicesAggregations = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicesAggregations"')
  return InvoicesAggregations_possibleTypes.includes(obj.__typename)
}



var InvoicesBalance_possibleTypes = ['InvoicesBalance']
export var isInvoicesBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoicesBalance"')
  return InvoicesBalance_possibleTypes.includes(obj.__typename)
}



var InvoiceSettings_possibleTypes = ['InvoiceSettings']
export var isInvoiceSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoiceSettings"')
  return InvoiceSettings_possibleTypes.includes(obj.__typename)
}



var InvoiceToPaymentGroups_possibleTypes = ['InvoiceToPaymentGroups']
export var isInvoiceToPaymentGroups = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isInvoiceToPaymentGroups"')
  return InvoiceToPaymentGroups_possibleTypes.includes(obj.__typename)
}



var IPaymentGroup_possibleTypes = ['InvoicePaymentGroup','OrderPaymentGroup']
export var isIPaymentGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIPaymentGroup"')
  return IPaymentGroup_possibleTypes.includes(obj.__typename)
}



var IsGlobalRuleExistResult_possibleTypes = ['IsGlobalRuleExistResult']
export var isIsGlobalRuleExistResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIsGlobalRuleExistResult"')
  return IsGlobalRuleExistResult_possibleTypes.includes(obj.__typename)
}



var IsInvitationCodeForRegisteredUserResponse_possibleTypes = ['IsInvitationCodeForRegisteredUserResponse']
export var isIsInvitationCodeForRegisteredUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isIsInvitationCodeForRegisteredUserResponse"')
  return IsInvitationCodeForRegisteredUserResponse_possibleTypes.includes(obj.__typename)
}



var ItemReconciliationFinaloopSalesChannel_possibleTypes = ['ItemReconciliationFinaloopSalesChannel']
export var isItemReconciliationFinaloopSalesChannel = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isItemReconciliationFinaloopSalesChannel"')
  return ItemReconciliationFinaloopSalesChannel_possibleTypes.includes(obj.__typename)
}



var JournalEntryActions_possibleTypes = ['JournalEntryActions']
export var isJournalEntryActions = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isJournalEntryActions"')
  return JournalEntryActions_possibleTypes.includes(obj.__typename)
}



var LastAccountingReportSyncResponse_possibleTypes = ['LastAccountingReportSyncResponse']
export var isLastAccountingReportSyncResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLastAccountingReportSyncResponse"')
  return LastAccountingReportSyncResponse_possibleTypes.includes(obj.__typename)
}



var LinkedBankTransaction_possibleTypes = ['LinkedBankTransaction']
export var isLinkedBankTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkedBankTransaction"')
  return LinkedBankTransaction_possibleTypes.includes(obj.__typename)
}



var LinkedOrder_possibleTypes = ['LinkedOrder']
export var isLinkedOrder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkedOrder"')
  return LinkedOrder_possibleTypes.includes(obj.__typename)
}



var LinkInvoicePaymentGroupResponse_possibleTypes = ['LinkInvoicePaymentGroupResponse']
export var isLinkInvoicePaymentGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkInvoicePaymentGroupResponse"')
  return LinkInvoicePaymentGroupResponse_possibleTypes.includes(obj.__typename)
}



var LinkOrderGroupResponse_possibleTypes = ['LinkOrderGroupResponse']
export var isLinkOrderGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkOrderGroupResponse"')
  return LinkOrderGroupResponse_possibleTypes.includes(obj.__typename)
}



var LinkOrderPaymentGroupResponse_possibleTypes = ['LinkOrderPaymentGroupResponse']
export var isLinkOrderPaymentGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLinkOrderPaymentGroupResponse"')
  return LinkOrderPaymentGroupResponse_possibleTypes.includes(obj.__typename)
}



var ListPhysicalCountResult_possibleTypes = ['PhysicalCountList','PhysicalCountError']
export var isListPhysicalCountResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isListPhysicalCountResult"')
  return ListPhysicalCountResult_possibleTypes.includes(obj.__typename)
}



var Loan_possibleTypes = ['Loan']
export var isLoan = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoan"')
  return Loan_possibleTypes.includes(obj.__typename)
}



var LoanAccountingEvent_possibleTypes = ['LoanAccountingEvent']
export var isLoanAccountingEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanAccountingEvent"')
  return LoanAccountingEvent_possibleTypes.includes(obj.__typename)
}



var LoanAffiliatedTransactionEvent_possibleTypes = ['LoanAffiliatedTransactionEvent']
export var isLoanAffiliatedTransactionEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanAffiliatedTransactionEvent"')
  return LoanAffiliatedTransactionEvent_possibleTypes.includes(obj.__typename)
}



var LoanAlreadyExistsResult_possibleTypes = ['LoanAlreadyExistsResult']
export var isLoanAlreadyExistsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanAlreadyExistsResult"')
  return LoanAlreadyExistsResult_possibleTypes.includes(obj.__typename)
}



var LoanAttachment_possibleTypes = ['LoanAttachment']
export var isLoanAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanAttachment"')
  return LoanAttachment_possibleTypes.includes(obj.__typename)
}



var LoanBillEvent_possibleTypes = ['LoanBillEvent']
export var isLoanBillEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanBillEvent"')
  return LoanBillEvent_possibleTypes.includes(obj.__typename)
}



var LoanBillPaymentEvent_possibleTypes = ['LoanBillPaymentEvent']
export var isLoanBillPaymentEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanBillPaymentEvent"')
  return LoanBillPaymentEvent_possibleTypes.includes(obj.__typename)
}



var LoanBusinessEvent_possibleTypes = ['LoanBusinessEvent']
export var isLoanBusinessEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanBusinessEvent"')
  return LoanBusinessEvent_possibleTypes.includes(obj.__typename)
}



var LoanBusinessEventData_possibleTypes = ['LoanBusinessEventData']
export var isLoanBusinessEventData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanBusinessEventData"')
  return LoanBusinessEventData_possibleTypes.includes(obj.__typename)
}



var LoanComment_possibleTypes = ['LoanComment']
export var isLoanComment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanComment"')
  return LoanComment_possibleTypes.includes(obj.__typename)
}



var LoanCreationFailureResult_possibleTypes = ['LoanCreationFailureResult']
export var isLoanCreationFailureResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanCreationFailureResult"')
  return LoanCreationFailureResult_possibleTypes.includes(obj.__typename)
}



var LoanCreationResult_possibleTypes = ['LoanUpdatedResult','LoanCreationFailureResult','LoanAlreadyExistsResult','LoanTransactionProcessedOnlineResult']
export var isLoanCreationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanCreationResult"')
  return LoanCreationResult_possibleTypes.includes(obj.__typename)
}



var LoanEvent_possibleTypes = ['LoanManualEvent','BankTransaction','LoanBillEvent','LoanBusinessEvent','LoanBillPaymentEvent','Payout','Topup','LoanAffiliatedTransactionEvent']
export var isLoanEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanEvent"')
  return LoanEvent_possibleTypes.includes(obj.__typename)
}



var LoanEventDefinition_possibleTypes = ['LoanEventDefinition']
export var isLoanEventDefinition = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanEventDefinition"')
  return LoanEventDefinition_possibleTypes.includes(obj.__typename)
}



var LoanManualEvent_possibleTypes = ['LoanManualEvent']
export var isLoanManualEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanManualEvent"')
  return LoanManualEvent_possibleTypes.includes(obj.__typename)
}



var LoanManualEventSpreadData_possibleTypes = ['LoanManualEventSpreadData']
export var isLoanManualEventSpreadData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanManualEventSpreadData"')
  return LoanManualEventSpreadData_possibleTypes.includes(obj.__typename)
}



var LoanOutstandingAmountOnDate_possibleTypes = ['LoanOutstandingAmountOnDate']
export var isLoanOutstandingAmountOnDate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanOutstandingAmountOnDate"')
  return LoanOutstandingAmountOnDate_possibleTypes.includes(obj.__typename)
}



var LoanRelationship_possibleTypes = ['LoanRelationship']
export var isLoanRelationship = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanRelationship"')
  return LoanRelationship_possibleTypes.includes(obj.__typename)
}



var LoansByIdsResponse_possibleTypes = ['LoansByIdsResponse']
export var isLoansByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoansByIdsResponse"')
  return LoansByIdsResponse_possibleTypes.includes(obj.__typename)
}



var LoansEventsEffectConfiguration_possibleTypes = ['LoansEventsEffectConfiguration']
export var isLoansEventsEffectConfiguration = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoansEventsEffectConfiguration"')
  return LoansEventsEffectConfiguration_possibleTypes.includes(obj.__typename)
}



var LoansResponse_possibleTypes = ['LoansResponse']
export var isLoansResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoansResponse"')
  return LoansResponse_possibleTypes.includes(obj.__typename)
}



var LoanTransactionProcessedOnlineResult_possibleTypes = ['LoanTransactionProcessedOnlineResult']
export var isLoanTransactionProcessedOnlineResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanTransactionProcessedOnlineResult"')
  return LoanTransactionProcessedOnlineResult_possibleTypes.includes(obj.__typename)
}



var LoanUpdatedResult_possibleTypes = ['LoanUpdatedResult']
export var isLoanUpdatedResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLoanUpdatedResult"')
  return LoanUpdatedResult_possibleTypes.includes(obj.__typename)
}



var LockedStatus_possibleTypes = ['LockedStatus']
export var isLockedStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isLockedStatus"')
  return LockedStatus_possibleTypes.includes(obj.__typename)
}



var MachineClassificationJob_possibleTypes = ['MachineClassificationJob']
export var isMachineClassificationJob = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMachineClassificationJob"')
  return MachineClassificationJob_possibleTypes.includes(obj.__typename)
}



var MachineValidationData_possibleTypes = ['MachineValidationData']
export var isMachineValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMachineValidationData"')
  return MachineValidationData_possibleTypes.includes(obj.__typename)
}



var ManualAssembly_possibleTypes = ['ManualAssembly']
export var isManualAssembly = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualAssembly"')
  return ManualAssembly_possibleTypes.includes(obj.__typename)
}



var ManualAssemblyAction_possibleTypes = ['ManualAssemblyAction']
export var isManualAssemblyAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualAssemblyAction"')
  return ManualAssemblyAction_possibleTypes.includes(obj.__typename)
}



var ManualAssemblyBreakdown_possibleTypes = ['ManualAssemblyBreakdown']
export var isManualAssemblyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualAssemblyBreakdown"')
  return ManualAssemblyBreakdown_possibleTypes.includes(obj.__typename)
}



var ManualAssemblyDuplicateError_possibleTypes = ['ManualAssemblyDuplicateError']
export var isManualAssemblyDuplicateError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualAssemblyDuplicateError"')
  return ManualAssemblyDuplicateError_possibleTypes.includes(obj.__typename)
}



var ManualAssemblyWithBreakdown_possibleTypes = ['ManualAssemblyWithBreakdown']
export var isManualAssemblyWithBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualAssemblyWithBreakdown"')
  return ManualAssemblyWithBreakdown_possibleTypes.includes(obj.__typename)
}



var ManualCompanyLoanCreationResult_possibleTypes = ['ManualCompanyLoanCreationResult']
export var isManualCompanyLoanCreationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualCompanyLoanCreationResult"')
  return ManualCompanyLoanCreationResult_possibleTypes.includes(obj.__typename)
}



var ManualLoanCreationResult_possibleTypes = ['ManualLoanCreationResult']
export var isManualLoanCreationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualLoanCreationResult"')
  return ManualLoanCreationResult_possibleTypes.includes(obj.__typename)
}



var ManualTransactionsAttachToLoanResult_possibleTypes = ['ManualTransactionsAttachToLoanResult']
export var isManualTransactionsAttachToLoanResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isManualTransactionsAttachToLoanResult"')
  return ManualTransactionsAttachToLoanResult_possibleTypes.includes(obj.__typename)
}



var MarkOrdersAsOvercollectedResponse_possibleTypes = ['MarkOrdersAsOvercollectedResponse']
export var isMarkOrdersAsOvercollectedResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMarkOrdersAsOvercollectedResponse"')
  return MarkOrdersAsOvercollectedResponse_possibleTypes.includes(obj.__typename)
}



var MarkOrdersAsUncollectibleResponse_possibleTypes = ['MarkOrdersAsUncollectibleResponse']
export var isMarkOrdersAsUncollectibleResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMarkOrdersAsUncollectibleResponse"')
  return MarkOrdersAsUncollectibleResponse_possibleTypes.includes(obj.__typename)
}



var Merchant_possibleTypes = ['Vendor','Customer','CompanyAffiliate','Institution']
export var isMerchant = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMerchant"')
  return Merchant_possibleTypes.includes(obj.__typename)
}



var MerchantsBankTransactionStats_possibleTypes = ['MerchantsBankTransactionStats']
export var isMerchantsBankTransactionStats = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMerchantsBankTransactionStats"')
  return MerchantsBankTransactionStats_possibleTypes.includes(obj.__typename)
}



var MeResponse_possibleTypes = ['MeResponse']
export var isMeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMeResponse"')
  return MeResponse_possibleTypes.includes(obj.__typename)
}



var MergedProduct_possibleTypes = ['MergedProduct']
export var isMergedProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergedProduct"')
  return MergedProduct_possibleTypes.includes(obj.__typename)
}



var MergeProductsV2Response_possibleTypes = ['MergeProductsV2Result','MergeProductsValidationError']
export var isMergeProductsV2Response = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergeProductsV2Response"')
  return MergeProductsV2Response_possibleTypes.includes(obj.__typename)
}



var MergeProductsV2Result_possibleTypes = ['MergeProductsV2Result']
export var isMergeProductsV2Result = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergeProductsV2Result"')
  return MergeProductsV2Result_possibleTypes.includes(obj.__typename)
}



var MergeProductsValidationError_possibleTypes = ['MergeProductsValidationError']
export var isMergeProductsValidationError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMergeProductsValidationError"')
  return MergeProductsValidationError_possibleTypes.includes(obj.__typename)
}



var Movement_possibleTypes = ['Movement']
export var isMovement = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMovement"')
  return Movement_possibleTypes.includes(obj.__typename)
}



var MovementIndirectCost_possibleTypes = ['MovementIndirectCost']
export var isMovementIndirectCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMovementIndirectCost"')
  return MovementIndirectCost_possibleTypes.includes(obj.__typename)
}



var Mutation_possibleTypes = ['Mutation']
export var isMutation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isMutation"')
  return Mutation_possibleTypes.includes(obj.__typename)
}



var NewLender_possibleTypes = ['NewLender']
export var isNewLender = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNewLender"')
  return NewLender_possibleTypes.includes(obj.__typename)
}



var NonMatchedSku_possibleTypes = ['NonMatchedSku']
export var isNonMatchedSku = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNonMatchedSku"')
  return NonMatchedSku_possibleTypes.includes(obj.__typename)
}



var NonMatchingProductsResponse_possibleTypes = ['NonMatchingProductsResponse']
export var isNonMatchingProductsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNonMatchingProductsResponse"')
  return NonMatchingProductsResponse_possibleTypes.includes(obj.__typename)
}



var NonMatchingUnpaidBill_possibleTypes = ['NonMatchingUnpaidBill']
export var isNonMatchingUnpaidBill = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNonMatchingUnpaidBill"')
  return NonMatchingUnpaidBill_possibleTypes.includes(obj.__typename)
}



var NotFoundProductSkus_possibleTypes = ['NotFoundProductSkus']
export var isNotFoundProductSkus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNotFoundProductSkus"')
  return NotFoundProductSkus_possibleTypes.includes(obj.__typename)
}



var NoUnitsDailyProductValidationResult_possibleTypes = ['NoUnitsDailyProductValidationResult']
export var isNoUnitsDailyProductValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsDailyProductValidationResult"')
  return NoUnitsDailyProductValidationResult_possibleTypes.includes(obj.__typename)
}



var NoUnitsIntegrationValidation_possibleTypes = ['NoUnitsIntegrationValidation']
export var isNoUnitsIntegrationValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsIntegrationValidation"')
  return NoUnitsIntegrationValidation_possibleTypes.includes(obj.__typename)
}



var NoUnitsOverviewValidationResult_possibleTypes = ['NoUnitsOverviewValidationResult']
export var isNoUnitsOverviewValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsOverviewValidationResult"')
  return NoUnitsOverviewValidationResult_possibleTypes.includes(obj.__typename)
}



var NoUnitsValidationError_possibleTypes = ['NoUnitsValidationError']
export var isNoUnitsValidationError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsValidationError"')
  return NoUnitsValidationError_possibleTypes.includes(obj.__typename)
}



var NoUnitsValidationListItem_possibleTypes = ['NoUnitsValidationListItem']
export var isNoUnitsValidationListItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsValidationListItem"')
  return NoUnitsValidationListItem_possibleTypes.includes(obj.__typename)
}



var NoUnitsValidationResponse_possibleTypes = ['NoUnitsValidationResult','NoUnitsValidationError']
export var isNoUnitsValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsValidationResponse"')
  return NoUnitsValidationResponse_possibleTypes.includes(obj.__typename)
}



var NoUnitsValidationResult_possibleTypes = ['NoUnitsValidationResult']
export var isNoUnitsValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isNoUnitsValidationResult"')
  return NoUnitsValidationResult_possibleTypes.includes(obj.__typename)
}



var Offer_possibleTypes = ['Offer']
export var isOffer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOffer"')
  return Offer_possibleTypes.includes(obj.__typename)
}



var OnBoardingState_possibleTypes = ['OnBoardingState']
export var isOnBoardingState = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOnBoardingState"')
  return OnBoardingState_possibleTypes.includes(obj.__typename)
}



var OnboardingStatus_possibleTypes = ['OnboardingStatus']
export var isOnboardingStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOnboardingStatus"')
  return OnboardingStatus_possibleTypes.includes(obj.__typename)
}



var OnboardingStatuses_possibleTypes = ['OnboardingStatuses']
export var isOnboardingStatuses = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOnboardingStatuses"')
  return OnboardingStatuses_possibleTypes.includes(obj.__typename)
}



var OnDemandAssemblyAction_possibleTypes = ['OnDemandAssemblyAction']
export var isOnDemandAssemblyAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOnDemandAssemblyAction"')
  return OnDemandAssemblyAction_possibleTypes.includes(obj.__typename)
}



var OnDemandAssemblyBreakdown_possibleTypes = ['OnDemandAssemblyBreakdown']
export var isOnDemandAssemblyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOnDemandAssemblyBreakdown"')
  return OnDemandAssemblyBreakdown_possibleTypes.includes(obj.__typename)
}



var OneTimePayment_possibleTypes = ['OneTimePayment']
export var isOneTimePayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOneTimePayment"')
  return OneTimePayment_possibleTypes.includes(obj.__typename)
}



var OpeningBalanceAction_possibleTypes = ['OpeningBalanceAction']
export var isOpeningBalanceAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOpeningBalanceAction"')
  return OpeningBalanceAction_possibleTypes.includes(obj.__typename)
}



var OrderAccountingReconciliationAccount_possibleTypes = ['OrderAccountingReconciliationAccount']
export var isOrderAccountingReconciliationAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAccountingReconciliationAccount"')
  return OrderAccountingReconciliationAccount_possibleTypes.includes(obj.__typename)
}



var OrderAccountingReconciliationAmount_possibleTypes = ['OrderAccountingReconciliationAmount']
export var isOrderAccountingReconciliationAmount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAccountingReconciliationAmount"')
  return OrderAccountingReconciliationAmount_possibleTypes.includes(obj.__typename)
}



var OrderAccountingReconciliationRow_possibleTypes = ['OrderAccountingReconciliationRow']
export var isOrderAccountingReconciliationRow = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAccountingReconciliationRow"')
  return OrderAccountingReconciliationRow_possibleTypes.includes(obj.__typename)
}



var OrderAccountingReconciliationsResponse_possibleTypes = ['OrderAccountingReconciliationsResponse']
export var isOrderAccountingReconciliationsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAccountingReconciliationsResponse"')
  return OrderAccountingReconciliationsResponse_possibleTypes.includes(obj.__typename)
}



var OrderAdjustment_possibleTypes = ['OrderAdjustment']
export var isOrderAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAdjustment"')
  return OrderAdjustment_possibleTypes.includes(obj.__typename)
}



var OrderAggregationEntry_possibleTypes = ['OrderAggregationEntry']
export var isOrderAggregationEntry = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderAggregationEntry"')
  return OrderAggregationEntry_possibleTypes.includes(obj.__typename)
}



var OrderEntity_possibleTypes = ['OrderEntity']
export var isOrderEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderEntity"')
  return OrderEntity_possibleTypes.includes(obj.__typename)
}



var OrderFulfillment_possibleTypes = ['OrderFulfillment']
export var isOrderFulfillment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderFulfillment"')
  return OrderFulfillment_possibleTypes.includes(obj.__typename)
}



var OrderFulfillmentLineItem_possibleTypes = ['OrderFulfillmentLineItem']
export var isOrderFulfillmentLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderFulfillmentLineItem"')
  return OrderFulfillmentLineItem_possibleTypes.includes(obj.__typename)
}



var OrderGroup_possibleTypes = ['OrderGroup']
export var isOrderGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroup"')
  return OrderGroup_possibleTypes.includes(obj.__typename)
}



var OrderGroupByIdResponse_possibleTypes = ['OrderGroupByIdResponse']
export var isOrderGroupByIdResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupByIdResponse"')
  return OrderGroupByIdResponse_possibleTypes.includes(obj.__typename)
}



var OrderGroupExpense_possibleTypes = ['OrderGroupExpense']
export var isOrderGroupExpense = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupExpense"')
  return OrderGroupExpense_possibleTypes.includes(obj.__typename)
}



var OrderGroupLink_possibleTypes = ['OrderGroupLink']
export var isOrderGroupLink = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupLink"')
  return OrderGroupLink_possibleTypes.includes(obj.__typename)
}



var OrderGroupsByIdsResponse_possibleTypes = ['OrderGroupsByIdsResponse']
export var isOrderGroupsByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupsByIdsResponse"')
  return OrderGroupsByIdsResponse_possibleTypes.includes(obj.__typename)
}



var OrderGroupsResponse_possibleTypes = ['OrderGroupsResponse']
export var isOrderGroupsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupsResponse"')
  return OrderGroupsResponse_possibleTypes.includes(obj.__typename)
}



var OrderGroupsTotalUnclearedBalanceResponse_possibleTypes = ['OrderGroupsTotalUnclearedBalanceResponse']
export var isOrderGroupsTotalUnclearedBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupsTotalUnclearedBalanceResponse"')
  return OrderGroupsTotalUnclearedBalanceResponse_possibleTypes.includes(obj.__typename)
}



var OrderGroupsUnclearedBalanceResponse_possibleTypes = ['OrderGroupsUnclearedBalanceResponse']
export var isOrderGroupsUnclearedBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupsUnclearedBalanceResponse"')
  return OrderGroupsUnclearedBalanceResponse_possibleTypes.includes(obj.__typename)
}



var OrderGroupUnclearedBalance_possibleTypes = ['OrderGroupUnclearedBalance']
export var isOrderGroupUnclearedBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderGroupUnclearedBalance"')
  return OrderGroupUnclearedBalance_possibleTypes.includes(obj.__typename)
}



var OrderItemsFulfillmentV2_possibleTypes = ['OrderItemsFulfillmentV2']
export var isOrderItemsFulfillmentV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderItemsFulfillmentV2"')
  return OrderItemsFulfillmentV2_possibleTypes.includes(obj.__typename)
}



var OrderItemStatusWithOverride_possibleTypes = ['OrderItemStatusWithOverride']
export var isOrderItemStatusWithOverride = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderItemStatusWithOverride"')
  return OrderItemStatusWithOverride_possibleTypes.includes(obj.__typename)
}



var OrderItemV2_possibleTypes = ['OrderItemV2']
export var isOrderItemV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderItemV2"')
  return OrderItemV2_possibleTypes.includes(obj.__typename)
}



var OrderOtherAccountingReconciliationRow_possibleTypes = ['OrderOtherAccountingReconciliationRow']
export var isOrderOtherAccountingReconciliationRow = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderOtherAccountingReconciliationRow"')
  return OrderOtherAccountingReconciliationRow_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroup_possibleTypes = ['OrderPaymentGroup']
export var isOrderPaymentGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroup"')
  return OrderPaymentGroup_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroupByIdResponse_possibleTypes = ['OrderPaymentGroupByIdResponse']
export var isOrderPaymentGroupByIdResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroupByIdResponse"')
  return OrderPaymentGroupByIdResponse_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroupsByIdsResponse_possibleTypes = ['OrderPaymentGroupsByIdsResponse']
export var isOrderPaymentGroupsByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroupsByIdsResponse"')
  return OrderPaymentGroupsByIdsResponse_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroupsResponse_possibleTypes = ['OrderPaymentGroupsResponse']
export var isOrderPaymentGroupsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroupsResponse"')
  return OrderPaymentGroupsResponse_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroupsUnclearedBalanceResponse_possibleTypes = ['OrderPaymentGroupsUnclearedBalanceResponse']
export var isOrderPaymentGroupsUnclearedBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroupsUnclearedBalanceResponse"')
  return OrderPaymentGroupsUnclearedBalanceResponse_possibleTypes.includes(obj.__typename)
}



var OrderPaymentGroupUnclearedBalance_possibleTypes = ['OrderPaymentGroupUnclearedBalance']
export var isOrderPaymentGroupUnclearedBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderPaymentGroupUnclearedBalance"')
  return OrderPaymentGroupUnclearedBalance_possibleTypes.includes(obj.__typename)
}



var OrderReconciliationRow_possibleTypes = ['OrderReconciliationRow']
export var isOrderReconciliationRow = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderReconciliationRow"')
  return OrderReconciliationRow_possibleTypes.includes(obj.__typename)
}



var OrderReconciliationsResponse_possibleTypes = ['OrderReconciliationsResponse']
export var isOrderReconciliationsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderReconciliationsResponse"')
  return OrderReconciliationsResponse_possibleTypes.includes(obj.__typename)
}



var OrderRestock_possibleTypes = ['OrderRestock']
export var isOrderRestock = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderRestock"')
  return OrderRestock_possibleTypes.includes(obj.__typename)
}



var OrderRestockLineItem_possibleTypes = ['OrderRestockLineItem']
export var isOrderRestockLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderRestockLineItem"')
  return OrderRestockLineItem_possibleTypes.includes(obj.__typename)
}



var OrdersByIdsResponse_possibleTypes = ['OrdersByIdsResponse']
export var isOrdersByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrdersByIdsResponse"')
  return OrdersByIdsResponse_possibleTypes.includes(obj.__typename)
}



var OrderSettings_possibleTypes = ['OrderSettings']
export var isOrderSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderSettings"')
  return OrderSettings_possibleTypes.includes(obj.__typename)
}



var OrderSettingsResponse_possibleTypes = ['OrderSettingsResponse']
export var isOrderSettingsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderSettingsResponse"')
  return OrderSettingsResponse_possibleTypes.includes(obj.__typename)
}



var OrdersSettings_possibleTypes = ['OrdersSettings']
export var isOrdersSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrdersSettings"')
  return OrdersSettings_possibleTypes.includes(obj.__typename)
}



var OrderStatusWithOverride_possibleTypes = ['OrderStatusWithOverride']
export var isOrderStatusWithOverride = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderStatusWithOverride"')
  return OrderStatusWithOverride_possibleTypes.includes(obj.__typename)
}



var OrderTag_possibleTypes = ['OrderTag']
export var isOrderTag = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderTag"')
  return OrderTag_possibleTypes.includes(obj.__typename)
}



var OrderTotalSummary_possibleTypes = ['OrderTotalSummary']
export var isOrderTotalSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderTotalSummary"')
  return OrderTotalSummary_possibleTypes.includes(obj.__typename)
}



var OrderTransactionMatchV2_possibleTypes = ['OrderTransactionMatchV2']
export var isOrderTransactionMatchV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderTransactionMatchV2"')
  return OrderTransactionMatchV2_possibleTypes.includes(obj.__typename)
}



var OrderTransactionV2_possibleTypes = ['OrderTransactionV2']
export var isOrderTransactionV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderTransactionV2"')
  return OrderTransactionV2_possibleTypes.includes(obj.__typename)
}



var OrderV2_possibleTypes = ['OrderV2']
export var isOrderV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderV2"')
  return OrderV2_possibleTypes.includes(obj.__typename)
}



var OrderV2NotFound_possibleTypes = ['OrderV2NotFound']
export var isOrderV2NotFound = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderV2NotFound"')
  return OrderV2NotFound_possibleTypes.includes(obj.__typename)
}



var OrderV2Result_possibleTypes = ['OrderV2','OrderV2NotFound']
export var isOrderV2Result = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOrderV2Result"')
  return OrderV2Result_possibleTypes.includes(obj.__typename)
}



var OverviewCycle_possibleTypes = ['OverviewCycle']
export var isOverviewCycle = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isOverviewCycle"')
  return OverviewCycle_possibleTypes.includes(obj.__typename)
}



var PairedEntity_possibleTypes = ['Bill','Payout','Topup','BankTransaction','CompanyAffiliate']
export var isPairedEntity = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPairedEntity"')
  return PairedEntity_possibleTypes.includes(obj.__typename)
}



var Payment_possibleTypes = ['Payment']
export var isPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayment"')
  return Payment_possibleTypes.includes(obj.__typename)
}



var PaymentCheck_possibleTypes = ['PaymentCheck']
export var isPaymentCheck = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentCheck"')
  return PaymentCheck_possibleTypes.includes(obj.__typename)
}



var PaymentDetails_possibleTypes = ['PaymentDetails']
export var isPaymentDetails = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentDetails"')
  return PaymentDetails_possibleTypes.includes(obj.__typename)
}



var PaymentGroup_possibleTypes = ['PaymentGroup']
export var isPaymentGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroup"')
  return PaymentGroup_possibleTypes.includes(obj.__typename)
}



var PaymentGroupExpense_possibleTypes = ['PaymentGroupExpense']
export var isPaymentGroupExpense = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupExpense"')
  return PaymentGroupExpense_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLink_possibleTypes = ['PaymentGroupLink']
export var isPaymentGroupLink = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLink"')
  return PaymentGroupLink_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLinkedBankTransaction_possibleTypes = ['PaymentGroupLinkedBankTransaction']
export var isPaymentGroupLinkedBankTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLinkedBankTransaction"')
  return PaymentGroupLinkedBankTransaction_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLinkedInvoice_possibleTypes = ['PaymentGroupLinkedInvoice']
export var isPaymentGroupLinkedInvoice = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLinkedInvoice"')
  return PaymentGroupLinkedInvoice_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLinkedOrder_possibleTypes = ['PaymentGroupLinkedOrder']
export var isPaymentGroupLinkedOrder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLinkedOrder"')
  return PaymentGroupLinkedOrder_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLinksForInvoiceResponse_possibleTypes = ['PaymentGroupLinksForInvoiceResponse']
export var isPaymentGroupLinksForInvoiceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLinksForInvoiceResponse"')
  return PaymentGroupLinksForInvoiceResponse_possibleTypes.includes(obj.__typename)
}



var PaymentGroupLinksResponse_possibleTypes = ['PaymentGroupLinksResponse']
export var isPaymentGroupLinksResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupLinksResponse"')
  return PaymentGroupLinksResponse_possibleTypes.includes(obj.__typename)
}



var PaymentGroupsByIdsResponse_possibleTypes = ['PaymentGroupsByIdsResponse']
export var isPaymentGroupsByIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupsByIdsResponse"')
  return PaymentGroupsByIdsResponse_possibleTypes.includes(obj.__typename)
}



var PaymentGroupsTotalUnclearedBalanceResponse_possibleTypes = ['PaymentGroupsTotalUnclearedBalanceResponse']
export var isPaymentGroupsTotalUnclearedBalanceResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupsTotalUnclearedBalanceResponse"')
  return PaymentGroupsTotalUnclearedBalanceResponse_possibleTypes.includes(obj.__typename)
}



var PaymentGroupTransaction_possibleTypes = ['PaymentGroupTransaction']
export var isPaymentGroupTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentGroupTransaction"')
  return PaymentGroupTransaction_possibleTypes.includes(obj.__typename)
}



var PaymentMethod_possibleTypes = ['PaymentMethod']
export var isPaymentMethod = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentMethod"')
  return PaymentMethod_possibleTypes.includes(obj.__typename)
}



var PaymentProcessorByCompanyAndVendorResponse_possibleTypes = ['PaymentProcessorByCompanyAndVendorResponse']
export var isPaymentProcessorByCompanyAndVendorResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPaymentProcessorByCompanyAndVendorResponse"')
  return PaymentProcessorByCompanyAndVendorResponse_possibleTypes.includes(obj.__typename)
}



var Payout_possibleTypes = ['Payout']
export var isPayout = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayout"')
  return Payout_possibleTypes.includes(obj.__typename)
}



var Payroll_possibleTypes = ['Payroll']
export var isPayroll = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayroll"')
  return Payroll_possibleTypes.includes(obj.__typename)
}



var PayrollCategoryMapping_possibleTypes = ['PayrollDepartmentCategoryMapping','PayrollEmployeeCategoryMapping','PayrollContractorCategoryMapping']
export var isPayrollCategoryMapping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollCategoryMapping"')
  return PayrollCategoryMapping_possibleTypes.includes(obj.__typename)
}



var PayrollConnection_possibleTypes = ['PayrollConnection']
export var isPayrollConnection = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollConnection"')
  return PayrollConnection_possibleTypes.includes(obj.__typename)
}



var PayrollConnectionImportStatus_possibleTypes = ['PayrollConnectionImportStatus']
export var isPayrollConnectionImportStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollConnectionImportStatus"')
  return PayrollConnectionImportStatus_possibleTypes.includes(obj.__typename)
}



var PayrollConnectionImportStatusData_possibleTypes = ['PayrollConnectionImportStatusData']
export var isPayrollConnectionImportStatusData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollConnectionImportStatusData"')
  return PayrollConnectionImportStatusData_possibleTypes.includes(obj.__typename)
}



var PayrollContractorCategoryMapping_possibleTypes = ['PayrollContractorCategoryMapping']
export var isPayrollContractorCategoryMapping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollContractorCategoryMapping"')
  return PayrollContractorCategoryMapping_possibleTypes.includes(obj.__typename)
}



var PayrollDailyAggregate_possibleTypes = ['PayrollDailyAggregate']
export var isPayrollDailyAggregate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollDailyAggregate"')
  return PayrollDailyAggregate_possibleTypes.includes(obj.__typename)
}



var PayrollDepartmentCategoryMapping_possibleTypes = ['PayrollDepartmentCategoryMapping']
export var isPayrollDepartmentCategoryMapping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollDepartmentCategoryMapping"')
  return PayrollDepartmentCategoryMapping_possibleTypes.includes(obj.__typename)
}



var PayrollEmployeeCategoryMapping_possibleTypes = ['PayrollEmployeeCategoryMapping']
export var isPayrollEmployeeCategoryMapping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollEmployeeCategoryMapping"')
  return PayrollEmployeeCategoryMapping_possibleTypes.includes(obj.__typename)
}



var PayrollIdsByCompanyItem_possibleTypes = ['PayrollIdsByCompanyItem']
export var isPayrollIdsByCompanyItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollIdsByCompanyItem"')
  return PayrollIdsByCompanyItem_possibleTypes.includes(obj.__typename)
}



var PayrollIdsByCompanyResponse_possibleTypes = ['PayrollIdsByCompanyResponse']
export var isPayrollIdsByCompanyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollIdsByCompanyResponse"')
  return PayrollIdsByCompanyResponse_possibleTypes.includes(obj.__typename)
}



var PayrollSettings_possibleTypes = ['PayrollSettings']
export var isPayrollSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPayrollSettings"')
  return PayrollSettings_possibleTypes.includes(obj.__typename)
}



var PendingSalesDataAdjustments_possibleTypes = ['PendingSalesDataAdjustments']
export var isPendingSalesDataAdjustments = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingSalesDataAdjustments"')
  return PendingSalesDataAdjustments_possibleTypes.includes(obj.__typename)
}



var PendingSalesDataAdjustmentsItem_possibleTypes = ['PendingSalesDataAdjustmentsItem']
export var isPendingSalesDataAdjustmentsItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingSalesDataAdjustmentsItem"')
  return PendingSalesDataAdjustmentsItem_possibleTypes.includes(obj.__typename)
}



var PendingSalesDataAdjustmentsOverviewResponse_possibleTypes = ['PendingSalesDataAdjustmentsOverviewResponse']
export var isPendingSalesDataAdjustmentsOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingSalesDataAdjustmentsOverviewResponse"')
  return PendingSalesDataAdjustmentsOverviewResponse_possibleTypes.includes(obj.__typename)
}



var PendingSalesDataAdjustmentsResponse_possibleTypes = ['PendingSalesDataAdjustmentsResponse']
export var isPendingSalesDataAdjustmentsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingSalesDataAdjustmentsResponse"')
  return PendingSalesDataAdjustmentsResponse_possibleTypes.includes(obj.__typename)
}



var PendingWarehouseOpeningBalance_possibleTypes = ['PendingWarehouseOpeningBalance']
export var isPendingWarehouseOpeningBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingWarehouseOpeningBalance"')
  return PendingWarehouseOpeningBalance_possibleTypes.includes(obj.__typename)
}



var PendingWarehouseOpeningBalanceAttachment_possibleTypes = ['PendingWarehouseOpeningBalanceAttachment']
export var isPendingWarehouseOpeningBalanceAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingWarehouseOpeningBalanceAttachment"')
  return PendingWarehouseOpeningBalanceAttachment_possibleTypes.includes(obj.__typename)
}



var PendingWarehouseOpeningBalancesAttachmentSummary_possibleTypes = ['PendingWarehouseOpeningBalancesAttachmentSummary']
export var isPendingWarehouseOpeningBalancesAttachmentSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingWarehouseOpeningBalancesAttachmentSummary"')
  return PendingWarehouseOpeningBalancesAttachmentSummary_possibleTypes.includes(obj.__typename)
}



var PendingWarehouseOpeningBalancesResponse_possibleTypes = ['PendingWarehouseOpeningBalancesResponse']
export var isPendingWarehouseOpeningBalancesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingWarehouseOpeningBalancesResponse"')
  return PendingWarehouseOpeningBalancesResponse_possibleTypes.includes(obj.__typename)
}



var PendingWarehouseOpeningBalancesWithCsvResponse_possibleTypes = ['PendingWarehouseOpeningBalancesWithCsvResponse']
export var isPendingWarehouseOpeningBalancesWithCsvResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPendingWarehouseOpeningBalancesWithCsvResponse"')
  return PendingWarehouseOpeningBalancesWithCsvResponse_possibleTypes.includes(obj.__typename)
}



var PeriodicPartnerReport_possibleTypes = ['PeriodicPartnerReport']
export var isPeriodicPartnerReport = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPeriodicPartnerReport"')
  return PeriodicPartnerReport_possibleTypes.includes(obj.__typename)
}



var Permission_possibleTypes = ['Permission']
export var isPermission = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPermission"')
  return Permission_possibleTypes.includes(obj.__typename)
}



var PhysicalCount_possibleTypes = ['PhysicalCount']
export var isPhysicalCount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPhysicalCount"')
  return PhysicalCount_possibleTypes.includes(obj.__typename)
}



var PhysicalCountAttachment_possibleTypes = ['PhysicalCountAttachment']
export var isPhysicalCountAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPhysicalCountAttachment"')
  return PhysicalCountAttachment_possibleTypes.includes(obj.__typename)
}



var PhysicalCountError_possibleTypes = ['PhysicalCountError']
export var isPhysicalCountError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPhysicalCountError"')
  return PhysicalCountError_possibleTypes.includes(obj.__typename)
}



var PhysicalCountList_possibleTypes = ['PhysicalCountList']
export var isPhysicalCountList = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPhysicalCountList"')
  return PhysicalCountList_possibleTypes.includes(obj.__typename)
}



var PresignedUrl_possibleTypes = ['PresignedUrl']
export var isPresignedUrl = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPresignedUrl"')
  return PresignedUrl_possibleTypes.includes(obj.__typename)
}



var ProcessBillContentResult_possibleTypes = ['ProcessBillContentResult']
export var isProcessBillContentResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProcessBillContentResult"')
  return ProcessBillContentResult_possibleTypes.includes(obj.__typename)
}



var Product_possibleTypes = ['AmazonProduct','MergedProduct','ShopifyProduct','TikTokProduct','UserManualProduct','WalmartProduct']
export var isProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProduct"')
  return Product_possibleTypes.includes(obj.__typename)
}



var ProductAnalyticsOverview_possibleTypes = ['ProductAnalyticsOverview']
export var isProductAnalyticsOverview = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductAnalyticsOverview"')
  return ProductAnalyticsOverview_possibleTypes.includes(obj.__typename)
}



var ProductAppliedCost_possibleTypes = ['ProductAppliedCost']
export var isProductAppliedCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductAppliedCost"')
  return ProductAppliedCost_possibleTypes.includes(obj.__typename)
}



var ProductAppliedCostWithDepletedCount_possibleTypes = ['ProductAppliedCostWithDepletedCount']
export var isProductAppliedCostWithDepletedCount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductAppliedCostWithDepletedCount"')
  return ProductAppliedCostWithDepletedCount_possibleTypes.includes(obj.__typename)
}



var ProductAssembliesBreakdownResult_possibleTypes = ['ProductAssembliesBreakdownResult']
export var isProductAssembliesBreakdownResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductAssembliesBreakdownResult"')
  return ProductAssembliesBreakdownResult_possibleTypes.includes(obj.__typename)
}



var ProductDeadInventoryAdjustment_possibleTypes = ['ProductDeadInventoryAdjustment']
export var isProductDeadInventoryAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductDeadInventoryAdjustment"')
  return ProductDeadInventoryAdjustment_possibleTypes.includes(obj.__typename)
}



var ProductDeadInventoryAdjustments_possibleTypes = ['ProductDeadInventoryAdjustments']
export var isProductDeadInventoryAdjustments = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductDeadInventoryAdjustments"')
  return ProductDeadInventoryAdjustments_possibleTypes.includes(obj.__typename)
}



var ProductDonationAdjustment_possibleTypes = ['ProductDonationAdjustment']
export var isProductDonationAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductDonationAdjustment"')
  return ProductDonationAdjustment_possibleTypes.includes(obj.__typename)
}



var ProductDonationAdjustments_possibleTypes = ['ProductDonationAdjustments']
export var isProductDonationAdjustments = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductDonationAdjustments"')
  return ProductDonationAdjustments_possibleTypes.includes(obj.__typename)
}



var ProductGiveawayAdjustment_possibleTypes = ['ProductGiveawayAdjustment']
export var isProductGiveawayAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductGiveawayAdjustment"')
  return ProductGiveawayAdjustment_possibleTypes.includes(obj.__typename)
}



var ProductGiveawayAdjustments_possibleTypes = ['ProductGiveawayAdjustments']
export var isProductGiveawayAdjustments = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductGiveawayAdjustments"')
  return ProductGiveawayAdjustments_possibleTypes.includes(obj.__typename)
}



var ProductPurchases_possibleTypes = ['ProductPurchases']
export var isProductPurchases = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductPurchases"')
  return ProductPurchases_possibleTypes.includes(obj.__typename)
}



var Products2Result_possibleTypes = ['Products2Result']
export var isProducts2Result = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProducts2Result"')
  return Products2Result_possibleTypes.includes(obj.__typename)
}



var ProductSalesDataAdjustment_possibleTypes = ['ProductSalesDataAdjustment']
export var isProductSalesDataAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductSalesDataAdjustment"')
  return ProductSalesDataAdjustment_possibleTypes.includes(obj.__typename)
}



var ProductSalesDataAdjustments_possibleTypes = ['ProductSalesDataAdjustments']
export var isProductSalesDataAdjustments = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductSalesDataAdjustments"')
  return ProductSalesDataAdjustments_possibleTypes.includes(obj.__typename)
}



var ProductsOverviewResponse_possibleTypes = ['ProductsOverviewResponse']
export var isProductsOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductsOverviewResponse"')
  return ProductsOverviewResponse_possibleTypes.includes(obj.__typename)
}



var ProductsResult_possibleTypes = ['ProductsResult']
export var isProductsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductsResult"')
  return ProductsResult_possibleTypes.includes(obj.__typename)
}



var ProductsValidationData_possibleTypes = ['ProductsValidationData']
export var isProductsValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductsValidationData"')
  return ProductsValidationData_possibleTypes.includes(obj.__typename)
}



var ProductWarehousesAnalyticsOverview_possibleTypes = ['ProductWarehousesAnalyticsOverview']
export var isProductWarehousesAnalyticsOverview = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isProductWarehousesAnalyticsOverview"')
  return ProductWarehousesAnalyticsOverview_possibleTypes.includes(obj.__typename)
}



var PurchasedOffer_possibleTypes = ['PurchasedOffer']
export var isPurchasedOffer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchasedOffer"')
  return PurchasedOffer_possibleTypes.includes(obj.__typename)
}



var PurchaseOrder_possibleTypes = ['PurchaseOrder']
export var isPurchaseOrder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchaseOrder"')
  return PurchaseOrder_possibleTypes.includes(obj.__typename)
}



var PurchaseOrderAttachment_possibleTypes = ['PurchaseOrderAttachment']
export var isPurchaseOrderAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchaseOrderAttachment"')
  return PurchaseOrderAttachment_possibleTypes.includes(obj.__typename)
}



var PurchaseOrderLandedCostBreakdown_possibleTypes = ['PurchaseOrderLandedCostBreakdown']
export var isPurchaseOrderLandedCostBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchaseOrderLandedCostBreakdown"')
  return PurchaseOrderLandedCostBreakdown_possibleTypes.includes(obj.__typename)
}



var PurchaseOrderOverview_possibleTypes = ['PurchaseOrderOverview']
export var isPurchaseOrderOverview = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchaseOrderOverview"')
  return PurchaseOrderOverview_possibleTypes.includes(obj.__typename)
}



var PurchaseOrderOverviewV2_possibleTypes = ['PurchaseOrderOverviewV2']
export var isPurchaseOrderOverviewV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isPurchaseOrderOverviewV2"')
  return PurchaseOrderOverviewV2_possibleTypes.includes(obj.__typename)
}



var QuantityAndCost_possibleTypes = ['QuantityAndCost']
export var isQuantityAndCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuantityAndCost"')
  return QuantityAndCost_possibleTypes.includes(obj.__typename)
}



var Query_possibleTypes = ['Query']
export var isQuery = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isQuery"')
  return Query_possibleTypes.includes(obj.__typename)
}



var RangedManagementMethod_possibleTypes = ['RangedManagementMethod']
export var isRangedManagementMethod = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRangedManagementMethod"')
  return RangedManagementMethod_possibleTypes.includes(obj.__typename)
}



var ReadyValidation_possibleTypes = ['ReadyValidation']
export var isReadyValidation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReadyValidation"')
  return ReadyValidation_possibleTypes.includes(obj.__typename)
}



var RealtimeCogsStatus_possibleTypes = ['RealtimeCogsStatus']
export var isRealtimeCogsStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRealtimeCogsStatus"')
  return RealtimeCogsStatus_possibleTypes.includes(obj.__typename)
}



var Recipe_possibleTypes = ['Recipe']
export var isRecipe = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipe"')
  return Recipe_possibleTypes.includes(obj.__typename)
}



var RecipeBreakdown_possibleTypes = ['RecipeBreakdown']
export var isRecipeBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeBreakdown"')
  return RecipeBreakdown_possibleTypes.includes(obj.__typename)
}



var RecipeCirclesFoundError_possibleTypes = ['RecipeCirclesFoundError']
export var isRecipeCirclesFoundError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeCirclesFoundError"')
  return RecipeCirclesFoundError_possibleTypes.includes(obj.__typename)
}



var RecipeCirclesFoundErrorData_possibleTypes = ['RecipeCirclesFoundErrorData']
export var isRecipeCirclesFoundErrorData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeCirclesFoundErrorData"')
  return RecipeCirclesFoundErrorData_possibleTypes.includes(obj.__typename)
}



var RecipeComponent_possibleTypes = ['RecipeComponent']
export var isRecipeComponent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeComponent"')
  return RecipeComponent_possibleTypes.includes(obj.__typename)
}



var RecipeOverlappingError_possibleTypes = ['RecipeOverlappingError']
export var isRecipeOverlappingError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeOverlappingError"')
  return RecipeOverlappingError_possibleTypes.includes(obj.__typename)
}



var RecipeWithBreakdown_possibleTypes = ['RecipeWithBreakdown']
export var isRecipeWithBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecipeWithBreakdown"')
  return RecipeWithBreakdown_possibleTypes.includes(obj.__typename)
}



var ReconciliationRequestStatementFile_possibleTypes = ['ReconciliationRequestStatementFile']
export var isReconciliationRequestStatementFile = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReconciliationRequestStatementFile"')
  return ReconciliationRequestStatementFile_possibleTypes.includes(obj.__typename)
}



var ReconciliationSummary_possibleTypes = ['ReconciliationSummary']
export var isReconciliationSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReconciliationSummary"')
  return ReconciliationSummary_possibleTypes.includes(obj.__typename)
}



var RecurrenceSettings_possibleTypes = ['RecurrenceSettings']
export var isRecurrenceSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecurrenceSettings"')
  return RecurrenceSettings_possibleTypes.includes(obj.__typename)
}



var RecurringPayment_possibleTypes = ['RecurringPayment']
export var isRecurringPayment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRecurringPayment"')
  return RecurringPayment_possibleTypes.includes(obj.__typename)
}



var referUserResponse_possibleTypes = ['referUserResponse']
export var isreferUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isreferUserResponse"')
  return referUserResponse_possibleTypes.includes(obj.__typename)
}



var Refund_possibleTypes = ['Refund']
export var isRefund = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRefund"')
  return Refund_possibleTypes.includes(obj.__typename)
}



var Reimbursement_possibleTypes = ['Reimbursement']
export var isReimbursement = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReimbursement"')
  return Reimbursement_possibleTypes.includes(obj.__typename)
}



var RelatedParty_possibleTypes = ['RelatedParty']
export var isRelatedParty = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRelatedParty"')
  return RelatedParty_possibleTypes.includes(obj.__typename)
}



var RemoveUserResponse_possibleTypes = ['RemoveUserResponse']
export var isRemoveUserResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRemoveUserResponse"')
  return RemoveUserResponse_possibleTypes.includes(obj.__typename)
}



var Report_possibleTypes = ['Report']
export var isReport = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReport"')
  return Report_possibleTypes.includes(obj.__typename)
}



var ReportsPartner_possibleTypes = ['ReportsPartner']
export var isReportsPartner = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isReportsPartner"')
  return ReportsPartner_possibleTypes.includes(obj.__typename)
}



var RestockingAction_possibleTypes = ['RestockingAction']
export var isRestockingAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isRestockingAction"')
  return RestockingAction_possibleTypes.includes(obj.__typename)
}



var Sale_possibleTypes = ['Sale']
export var isSale = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSale"')
  return Sale_possibleTypes.includes(obj.__typename)
}



var SaleAdjustment_possibleTypes = ['SaleAdjustment']
export var isSaleAdjustment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleAdjustment"')
  return SaleAdjustment_possibleTypes.includes(obj.__typename)
}



var SaleLineItem_possibleTypes = ['SaleLineItem']
export var isSaleLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleLineItem"')
  return SaleLineItem_possibleTypes.includes(obj.__typename)
}



var SaleLineItemTaxInfo_possibleTypes = ['SaleLineItemTaxInfo']
export var isSaleLineItemTaxInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleLineItemTaxInfo"')
  return SaleLineItemTaxInfo_possibleTypes.includes(obj.__typename)
}



var SaleOrder_possibleTypes = ['SaleOrder']
export var isSaleOrder = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleOrder"')
  return SaleOrder_possibleTypes.includes(obj.__typename)
}



var SalesChannel_possibleTypes = ['SalesChannel']
export var isSalesChannel = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannel"')
  return SalesChannel_possibleTypes.includes(obj.__typename)
}



var SalesChannelDailySalesData_possibleTypes = ['SalesChannelDailySalesData']
export var isSalesChannelDailySalesData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelDailySalesData"')
  return SalesChannelDailySalesData_possibleTypes.includes(obj.__typename)
}



var SalesChannelInfo_possibleTypes = ['SalesChannelInfo']
export var isSalesChannelInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelInfo"')
  return SalesChannelInfo_possibleTypes.includes(obj.__typename)
}



var SalesChannelMonthlyBreakdown_possibleTypes = ['SalesChannelMonthlyBreakdown']
export var isSalesChannelMonthlyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelMonthlyBreakdown"')
  return SalesChannelMonthlyBreakdown_possibleTypes.includes(obj.__typename)
}



var SalesChannelPeriod_possibleTypes = ['SalesChannelPeriod']
export var isSalesChannelPeriod = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelPeriod"')
  return SalesChannelPeriod_possibleTypes.includes(obj.__typename)
}



var SalesChannelSalesCogs_possibleTypes = ['SalesChannelSalesCogs']
export var isSalesChannelSalesCogs = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelSalesCogs"')
  return SalesChannelSalesCogs_possibleTypes.includes(obj.__typename)
}



var SalesChannelSalesData_possibleTypes = ['SalesChannelSalesData']
export var isSalesChannelSalesData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelSalesData"')
  return SalesChannelSalesData_possibleTypes.includes(obj.__typename)
}



var SalesChannelsByCompanyAndIntegrationResponse_possibleTypes = ['SalesChannelsByCompanyAndIntegrationResponse']
export var isSalesChannelsByCompanyAndIntegrationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelsByCompanyAndIntegrationResponse"')
  return SalesChannelsByCompanyAndIntegrationResponse_possibleTypes.includes(obj.__typename)
}



var SalesChannelSkuSalesSummary_possibleTypes = ['SalesChannelSkuSalesSummary']
export var isSalesChannelSkuSalesSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelSkuSalesSummary"')
  return SalesChannelSkuSalesSummary_possibleTypes.includes(obj.__typename)
}



var SalesChannelSkuSummary_possibleTypes = ['SalesChannelSkuSummary']
export var isSalesChannelSkuSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelSkuSummary"')
  return SalesChannelSkuSummary_possibleTypes.includes(obj.__typename)
}



var SalesChannelTemplate_possibleTypes = ['SalesChannelTemplate']
export var isSalesChannelTemplate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesChannelTemplate"')
  return SalesChannelTemplate_possibleTypes.includes(obj.__typename)
}



var SalesData_possibleTypes = ['SalesData']
export var isSalesData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSalesData"')
  return SalesData_possibleTypes.includes(obj.__typename)
}



var SaleTotals_possibleTypes = ['SaleTotals']
export var isSaleTotals = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleTotals"')
  return SaleTotals_possibleTypes.includes(obj.__typename)
}



var SaleTransaction_possibleTypes = ['BankTxSaleTransaction','IntegrationSaleTransaction','PaymentGroupTransaction']
export var isSaleTransaction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSaleTransaction"')
  return SaleTransaction_possibleTypes.includes(obj.__typename)
}



var ScheduleAccountingReportExportResponse_possibleTypes = ['ScheduleAccountingReportExportResponse']
export var isScheduleAccountingReportExportResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScheduleAccountingReportExportResponse"')
  return ScheduleAccountingReportExportResponse_possibleTypes.includes(obj.__typename)
}



var ScheduleAtuIntegrationTransfersResultProcessingResult_possibleTypes = ['ScheduleAtuIntegrationTransfersResultProcessingResult']
export var isScheduleAtuIntegrationTransfersResultProcessingResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScheduleAtuIntegrationTransfersResultProcessingResult"')
  return ScheduleAtuIntegrationTransfersResultProcessingResult_possibleTypes.includes(obj.__typename)
}



var ScheduleAtuTransactionsResultProcessingResult_possibleTypes = ['ScheduleAtuTransactionsResultProcessingResult']
export var isScheduleAtuTransactionsResultProcessingResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isScheduleAtuTransactionsResultProcessingResult"')
  return ScheduleAtuTransactionsResultProcessingResult_possibleTypes.includes(obj.__typename)
}



var SearchVendorsTransactionsPriorityResult_possibleTypes = ['SearchVendorsTransactionsPriorityResult']
export var isSearchVendorsTransactionsPriorityResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSearchVendorsTransactionsPriorityResult"')
  return SearchVendorsTransactionsPriorityResult_possibleTypes.includes(obj.__typename)
}



var Section179Deduction_possibleTypes = ['Section179Deduction']
export var isSection179Deduction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSection179Deduction"')
  return Section179Deduction_possibleTypes.includes(obj.__typename)
}



var SellingFee_possibleTypes = ['SellingFee']
export var isSellingFee = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSellingFee"')
  return SellingFee_possibleTypes.includes(obj.__typename)
}



var SendPoEmailError_possibleTypes = ['SendPoEmailError']
export var isSendPoEmailError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendPoEmailError"')
  return SendPoEmailError_possibleTypes.includes(obj.__typename)
}



var SendPoEmailResponse_possibleTypes = ['SendPoEmailResponse']
export var isSendPoEmailResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendPoEmailResponse"')
  return SendPoEmailResponse_possibleTypes.includes(obj.__typename)
}



var SendPoEmailResult_possibleTypes = ['SendPoEmailResponse','SendPoEmailError']
export var isSendPoEmailResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSendPoEmailResult"')
  return SendPoEmailResult_possibleTypes.includes(obj.__typename)
}



var SetFulfillmentSettingsResponse_possibleTypes = ['SetFulfillmentSettingsResponse']
export var isSetFulfillmentSettingsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSetFulfillmentSettingsResponse"')
  return SetFulfillmentSettingsResponse_possibleTypes.includes(obj.__typename)
}



var SetProductAdjustmentsResponse_possibleTypes = ['SetProductAdjustmentsResponse']
export var isSetProductAdjustmentsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSetProductAdjustmentsResponse"')
  return SetProductAdjustmentsResponse_possibleTypes.includes(obj.__typename)
}



var SetUserNameResponse_possibleTypes = ['SetUserNameResponse']
export var isSetUserNameResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSetUserNameResponse"')
  return SetUserNameResponse_possibleTypes.includes(obj.__typename)
}



var Shipment_possibleTypes = ['Shipment']
export var isShipment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShipment"')
  return Shipment_possibleTypes.includes(obj.__typename)
}



var ShipmentAction_possibleTypes = ['ShipmentAction']
export var isShipmentAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShipmentAction"')
  return ShipmentAction_possibleTypes.includes(obj.__typename)
}



var ShipmentArrival_possibleTypes = ['ShipmentArrival']
export var isShipmentArrival = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShipmentArrival"')
  return ShipmentArrival_possibleTypes.includes(obj.__typename)
}



var ShipmentAttachment_possibleTypes = ['ShipmentAttachment']
export var isShipmentAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShipmentAttachment"')
  return ShipmentAttachment_possibleTypes.includes(obj.__typename)
}



var ShipmentContents_possibleTypes = ['ShipmentContents']
export var isShipmentContents = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShipmentContents"')
  return ShipmentContents_possibleTypes.includes(obj.__typename)
}



var ShippingCost_possibleTypes = ['ShippingCost']
export var isShippingCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShippingCost"')
  return ShippingCost_possibleTypes.includes(obj.__typename)
}



var ShippingLineItem_possibleTypes = ['ShippingLineItem']
export var isShippingLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShippingLineItem"')
  return ShippingLineItem_possibleTypes.includes(obj.__typename)
}



var ShopifyCsvBalance_possibleTypes = ['ShopifyCsvBalance']
export var isShopifyCsvBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyCsvBalance"')
  return ShopifyCsvBalance_possibleTypes.includes(obj.__typename)
}



var ShopifyItemsReconciliationOrderData_possibleTypes = ['ShopifyItemsReconciliationOrderData']
export var isShopifyItemsReconciliationOrderData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyItemsReconciliationOrderData"')
  return ShopifyItemsReconciliationOrderData_possibleTypes.includes(obj.__typename)
}



var ShopifyItemsReconciliationOrdersDiffResult_possibleTypes = ['ShopifyItemsReconciliationOrdersDiffResult']
export var isShopifyItemsReconciliationOrdersDiffResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyItemsReconciliationOrdersDiffResult"')
  return ShopifyItemsReconciliationOrdersDiffResult_possibleTypes.includes(obj.__typename)
}



var ShopifyItemsReconciliationOrdersDiffResultData_possibleTypes = ['ShopifyItemsReconciliationOrdersDiffResultData']
export var isShopifyItemsReconciliationOrdersDiffResultData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyItemsReconciliationOrdersDiffResultData"')
  return ShopifyItemsReconciliationOrdersDiffResultData_possibleTypes.includes(obj.__typename)
}



var ShopifyItemsReconciliationOrdersResponse_possibleTypes = ['ShopifyItemsReconciliationOrdersResponse']
export var isShopifyItemsReconciliationOrdersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyItemsReconciliationOrdersResponse"')
  return ShopifyItemsReconciliationOrdersResponse_possibleTypes.includes(obj.__typename)
}



var ShopifyOrderSourceData_possibleTypes = ['ShopifyOrderSourceData']
export var isShopifyOrderSourceData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyOrderSourceData"')
  return ShopifyOrderSourceData_possibleTypes.includes(obj.__typename)
}



var ShopifyOrderValidationData_possibleTypes = ['ShopifyOrderValidationData']
export var isShopifyOrderValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyOrderValidationData"')
  return ShopifyOrderValidationData_possibleTypes.includes(obj.__typename)
}



var ShopifyOrderValidationRefund_possibleTypes = ['ShopifyOrderValidationRefund']
export var isShopifyOrderValidationRefund = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyOrderValidationRefund"')
  return ShopifyOrderValidationRefund_possibleTypes.includes(obj.__typename)
}



var ShopifyOrderValidationSale_possibleTypes = ['ShopifyOrderValidationSale']
export var isShopifyOrderValidationSale = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyOrderValidationSale"')
  return ShopifyOrderValidationSale_possibleTypes.includes(obj.__typename)
}



var ShopifyProduct_possibleTypes = ['ShopifyProduct']
export var isShopifyProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyProduct"')
  return ShopifyProduct_possibleTypes.includes(obj.__typename)
}



var ShopifyProductsValidationData_possibleTypes = ['ShopifyProductsValidationData']
export var isShopifyProductsValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyProductsValidationData"')
  return ShopifyProductsValidationData_possibleTypes.includes(obj.__typename)
}



var ShopifyProductsValidationResponse_possibleTypes = ['ShopifyProductsValidationResponse']
export var isShopifyProductsValidationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyProductsValidationResponse"')
  return ShopifyProductsValidationResponse_possibleTypes.includes(obj.__typename)
}



var ShopifyReconciliationProductsStatus_possibleTypes = ['ShopifyReconciliationProductsStatus']
export var isShopifyReconciliationProductsStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyReconciliationProductsStatus"')
  return ShopifyReconciliationProductsStatus_possibleTypes.includes(obj.__typename)
}



var ShopifyReportDateLimitsResponse_possibleTypes = ['ShopifyReportDateLimitsResponse']
export var isShopifyReportDateLimitsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyReportDateLimitsResponse"')
  return ShopifyReportDateLimitsResponse_possibleTypes.includes(obj.__typename)
}



var ShopifySkuReconciliationData_possibleTypes = ['ShopifySkuReconciliationData']
export var isShopifySkuReconciliationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifySkuReconciliationData"')
  return ShopifySkuReconciliationData_possibleTypes.includes(obj.__typename)
}



var ShopifySkuReconciliationProductItemSoldData_possibleTypes = ['ShopifySkuReconciliationProductItemSoldData']
export var isShopifySkuReconciliationProductItemSoldData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifySkuReconciliationProductItemSoldData"')
  return ShopifySkuReconciliationProductItemSoldData_possibleTypes.includes(obj.__typename)
}



var ShopifyTimezoneBalance_possibleTypes = ['ShopifyTimezoneBalance']
export var isShopifyTimezoneBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isShopifyTimezoneBalance"')
  return ShopifyTimezoneBalance_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanFailure_possibleTypes = ['SimulatedLoanFailure']
export var isSimulatedLoanFailure = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanFailure"')
  return SimulatedLoanFailure_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanIrrelevantEvent_possibleTypes = ['SimulatedLoanIrrelevantEvent']
export var isSimulatedLoanIrrelevantEvent = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanIrrelevantEvent"')
  return SimulatedLoanIrrelevantEvent_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanSummary_possibleTypes = ['SimulatedLoanIrrelevantEvent','SimulatedLoanUpdate','SimulatedLoanFailure']
export var isSimulatedLoanSummary = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanSummary"')
  return SimulatedLoanSummary_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanUpdate_possibleTypes = ['SimulatedLoanUpdate']
export var isSimulatedLoanUpdate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanUpdate"')
  return SimulatedLoanUpdate_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanUpdateCreate_possibleTypes = ['SimulatedLoanUpdateCreate']
export var isSimulatedLoanUpdateCreate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanUpdateCreate"')
  return SimulatedLoanUpdateCreate_possibleTypes.includes(obj.__typename)
}



var SimulatedLoanUpdateDelete_possibleTypes = ['SimulatedLoanUpdateDelete']
export var isSimulatedLoanUpdateDelete = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSimulatedLoanUpdateDelete"')
  return SimulatedLoanUpdateDelete_possibleTypes.includes(obj.__typename)
}



var SkuValidationData_possibleTypes = ['SkuValidationData']
export var isSkuValidationData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSkuValidationData"')
  return SkuValidationData_possibleTypes.includes(obj.__typename)
}



var SkuValidations_possibleTypes = ['SkuValidations']
export var isSkuValidations = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSkuValidations"')
  return SkuValidations_possibleTypes.includes(obj.__typename)
}



var SpecificDate_possibleTypes = ['SpecificDate']
export var isSpecificDate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSpecificDate"')
  return SpecificDate_possibleTypes.includes(obj.__typename)
}



var SpreadOverTime_possibleTypes = ['SpreadOverTime']
export var isSpreadOverTime = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSpreadOverTime"')
  return SpreadOverTime_possibleTypes.includes(obj.__typename)
}



var Store_possibleTypes = ['Store']
export var isStore = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStore"')
  return Store_possibleTypes.includes(obj.__typename)
}



var StorePaymentV2_possibleTypes = ['StorePaymentV2']
export var isStorePaymentV2 = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStorePaymentV2"')
  return StorePaymentV2_possibleTypes.includes(obj.__typename)
}



var StripeCustomerIdsResponse_possibleTypes = ['StripeCustomerIdsResponse']
export var isStripeCustomerIdsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isStripeCustomerIdsResponse"')
  return StripeCustomerIdsResponse_possibleTypes.includes(obj.__typename)
}



var SuccessResponse_possibleTypes = ['SuccessResponse']
export var isSuccessResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSuccessResponse"')
  return SuccessResponse_possibleTypes.includes(obj.__typename)
}



var SuccessResult_possibleTypes = ['SuccessResult']
export var isSuccessResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSuccessResult"')
  return SuccessResult_possibleTypes.includes(obj.__typename)
}



var SuggestBankTransactionClassificationResult_possibleTypes = ['SuggestBankTransactionClassificationResult']
export var isSuggestBankTransactionClassificationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSuggestBankTransactionClassificationResult"')
  return SuggestBankTransactionClassificationResult_possibleTypes.includes(obj.__typename)
}



var SuspectedBankAccountDuplication_possibleTypes = ['SuspectedBankAccountDuplication']
export var isSuspectedBankAccountDuplication = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSuspectedBankAccountDuplication"')
  return SuspectedBankAccountDuplication_possibleTypes.includes(obj.__typename)
}



var SwitchCompanyResponse_possibleTypes = ['SwitchCompanyResponse']
export var isSwitchCompanyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isSwitchCompanyResponse"')
  return SwitchCompanyResponse_possibleTypes.includes(obj.__typename)
}



var Tax_possibleTypes = ['Tax']
export var isTax = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTax"')
  return Tax_possibleTypes.includes(obj.__typename)
}



var Tax1099Vendor_possibleTypes = ['Tax1099Vendor']
export var isTax1099Vendor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTax1099Vendor"')
  return Tax1099Vendor_possibleTypes.includes(obj.__typename)
}



var Tax1099VendorGroup_possibleTypes = ['Tax1099VendorGroup']
export var isTax1099VendorGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTax1099VendorGroup"')
  return Tax1099VendorGroup_possibleTypes.includes(obj.__typename)
}



var Tax1099VendorGroupFile_possibleTypes = ['Tax1099VendorGroupFile']
export var isTax1099VendorGroupFile = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTax1099VendorGroupFile"')
  return Tax1099VendorGroupFile_possibleTypes.includes(obj.__typename)
}



var Tax1099VendorGroupsByType_possibleTypes = ['Tax1099VendorGroupsByType']
export var isTax1099VendorGroupsByType = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTax1099VendorGroupsByType"')
  return Tax1099VendorGroupsByType_possibleTypes.includes(obj.__typename)
}



var TaxInfo_possibleTypes = ['TaxInfo']
export var isTaxInfo = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTaxInfo"')
  return TaxInfo_possibleTypes.includes(obj.__typename)
}



var TikTokProduct_possibleTypes = ['TikTokProduct']
export var isTikTokProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTikTokProduct"')
  return TikTokProduct_possibleTypes.includes(obj.__typename)
}



var TimeRange_possibleTypes = ['TimeRange']
export var isTimeRange = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTimeRange"')
  return TimeRange_possibleTypes.includes(obj.__typename)
}



var Topup_possibleTypes = ['Topup']
export var isTopup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTopup"')
  return Topup_possibleTypes.includes(obj.__typename)
}



var TransactionDescription_possibleTypes = ['TransactionDescription']
export var isTransactionDescription = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionDescription"')
  return TransactionDescription_possibleTypes.includes(obj.__typename)
}



var TransactionsImportStatus_possibleTypes = ['TransactionsImportStatus']
export var isTransactionsImportStatus = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isTransactionsImportStatus"')
  return TransactionsImportStatus_possibleTypes.includes(obj.__typename)
}



var UnconnectedAccount_possibleTypes = ['UnconnectedAccount']
export var isUnconnectedAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnconnectedAccount"')
  return UnconnectedAccount_possibleTypes.includes(obj.__typename)
}



var UnidentifiedBankTransactionParty_possibleTypes = ['BankAccount','UnknownTransactionBank','UnknownTransactionAccount','UnconnectedAccount','Vendor','TransactionDescription']
export var isUnidentifiedBankTransactionParty = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnidentifiedBankTransactionParty"')
  return UnidentifiedBankTransactionParty_possibleTypes.includes(obj.__typename)
}



var UnidentifiedBankTransactionsGroup_possibleTypes = ['UnidentifiedBankTransactionsGroup']
export var isUnidentifiedBankTransactionsGroup = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnidentifiedBankTransactionsGroup"')
  return UnidentifiedBankTransactionsGroup_possibleTypes.includes(obj.__typename)
}



var UnknownIntegrationTransferAccount_possibleTypes = ['UnknownIntegrationTransferAccount']
export var isUnknownIntegrationTransferAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnknownIntegrationTransferAccount"')
  return UnknownIntegrationTransferAccount_possibleTypes.includes(obj.__typename)
}



var UnknownIntegrationTransferBank_possibleTypes = ['UnknownIntegrationTransferBank']
export var isUnknownIntegrationTransferBank = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnknownIntegrationTransferBank"')
  return UnknownIntegrationTransferBank_possibleTypes.includes(obj.__typename)
}



var UnknownTransactionAccount_possibleTypes = ['UnknownTransactionAccount']
export var isUnknownTransactionAccount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnknownTransactionAccount"')
  return UnknownTransactionAccount_possibleTypes.includes(obj.__typename)
}



var UnknownTransactionBank_possibleTypes = ['UnknownTransactionBank']
export var isUnknownTransactionBank = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnknownTransactionBank"')
  return UnknownTransactionBank_possibleTypes.includes(obj.__typename)
}



var UnMarkOrdersAsOvercollectedResponse_possibleTypes = ['UnMarkOrdersAsOvercollectedResponse']
export var isUnMarkOrdersAsOvercollectedResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnMarkOrdersAsOvercollectedResponse"')
  return UnMarkOrdersAsOvercollectedResponse_possibleTypes.includes(obj.__typename)
}



var UnMarkOrdersAsUncollectibleResponse_possibleTypes = ['UnMarkOrdersAsUncollectibleResponse']
export var isUnMarkOrdersAsUncollectibleResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnMarkOrdersAsUncollectibleResponse"')
  return UnMarkOrdersAsUncollectibleResponse_possibleTypes.includes(obj.__typename)
}



var UnpaidAmountsViaProvider_possibleTypes = ['UnpaidAmountsViaProvider']
export var isUnpaidAmountsViaProvider = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnpaidAmountsViaProvider"')
  return UnpaidAmountsViaProvider_possibleTypes.includes(obj.__typename)
}



var UnvoidOrdersResponse_possibleTypes = ['UnvoidOrdersResponse']
export var isUnvoidOrdersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUnvoidOrdersResponse"')
  return UnvoidOrdersResponse_possibleTypes.includes(obj.__typename)
}



var UpdatedLoans_possibleTypes = ['UpdatedLoans']
export var isUpdatedLoans = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdatedLoans"')
  return UpdatedLoans_possibleTypes.includes(obj.__typename)
}



var UpdateInventoryPurchaseResponse_possibleTypes = ['InventoryPurchase','InventoryPurchaseUpdateOutOfDateError']
export var isUpdateInventoryPurchaseResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateInventoryPurchaseResponse"')
  return UpdateInventoryPurchaseResponse_possibleTypes.includes(obj.__typename)
}



var UpdateInvoicePaymentGroupResponse_possibleTypes = ['UpdateInvoicePaymentGroupResponse']
export var isUpdateInvoicePaymentGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateInvoicePaymentGroupResponse"')
  return UpdateInvoicePaymentGroupResponse_possibleTypes.includes(obj.__typename)
}



var UpdateManualAssemblyResult_possibleTypes = ['ManualAssembly','RecipeCirclesFoundError']
export var isUpdateManualAssemblyResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateManualAssemblyResult"')
  return UpdateManualAssemblyResult_possibleTypes.includes(obj.__typename)
}



var UpdateManualLoanEventResult_possibleTypes = ['UpdateManualLoanEventResult']
export var isUpdateManualLoanEventResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateManualLoanEventResult"')
  return UpdateManualLoanEventResult_possibleTypes.includes(obj.__typename)
}



var UpdateManualLoanEventsResult_possibleTypes = ['UpdateManualLoanEventsResult']
export var isUpdateManualLoanEventsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateManualLoanEventsResult"')
  return UpdateManualLoanEventsResult_possibleTypes.includes(obj.__typename)
}



var UpdateOrderGroupResponse_possibleTypes = ['UpdateOrderGroupResponse']
export var isUpdateOrderGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateOrderGroupResponse"')
  return UpdateOrderGroupResponse_possibleTypes.includes(obj.__typename)
}



var UpdateOrderPaymentGroupResponse_possibleTypes = ['UpdateOrderPaymentGroupResponse']
export var isUpdateOrderPaymentGroupResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateOrderPaymentGroupResponse"')
  return UpdateOrderPaymentGroupResponse_possibleTypes.includes(obj.__typename)
}



var UpdateRecipeResult_possibleTypes = ['Recipe','RecipeCirclesFoundError','RecipeOverlappingError']
export var isUpdateRecipeResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateRecipeResult"')
  return UpdateRecipeResult_possibleTypes.includes(obj.__typename)
}



var UpdateUserDetailsResponse_possibleTypes = ['UpdateUserDetailsResponse']
export var isUpdateUserDetailsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateUserDetailsResponse"')
  return UpdateUserDetailsResponse_possibleTypes.includes(obj.__typename)
}



var UpdateUserRolesForCompanyResponse_possibleTypes = ['UpdateUserRolesForCompanyResponse']
export var isUpdateUserRolesForCompanyResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpdateUserRolesForCompanyResponse"')
  return UpdateUserRolesForCompanyResponse_possibleTypes.includes(obj.__typename)
}



var UploadBankStatementResponse_possibleTypes = ['UploadBankStatementResponse']
export var isUploadBankStatementResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUploadBankStatementResponse"')
  return UploadBankStatementResponse_possibleTypes.includes(obj.__typename)
}



var UpsertPhysicalCountResult_possibleTypes = ['PhysicalCount','PhysicalCountError']
export var isUpsertPhysicalCountResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUpsertPhysicalCountResult"')
  return UpsertPhysicalCountResult_possibleTypes.includes(obj.__typename)
}



var User_possibleTypes = ['User']
export var isUser = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUser"')
  return User_possibleTypes.includes(obj.__typename)
}



var UserByEmailResponse_possibleTypes = ['UserByEmailResponse']
export var isUserByEmailResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserByEmailResponse"')
  return UserByEmailResponse_possibleTypes.includes(obj.__typename)
}



var UserCompanies_possibleTypes = ['UserCompanies']
export var isUserCompanies = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserCompanies"')
  return UserCompanies_possibleTypes.includes(obj.__typename)
}



var UserFollowUp_possibleTypes = ['UserFollowUp']
export var isUserFollowUp = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserFollowUp"')
  return UserFollowUp_possibleTypes.includes(obj.__typename)
}



var UserInvitation_possibleTypes = ['UserInvitation']
export var isUserInvitation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserInvitation"')
  return UserInvitation_possibleTypes.includes(obj.__typename)
}



var UserInvitationResponse_possibleTypes = ['UserInvitationResponse']
export var isUserInvitationResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserInvitationResponse"')
  return UserInvitationResponse_possibleTypes.includes(obj.__typename)
}



var UserInvitationsByEmailResponse_possibleTypes = ['UserInvitationsByEmailResponse']
export var isUserInvitationsByEmailResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserInvitationsByEmailResponse"')
  return UserInvitationsByEmailResponse_possibleTypes.includes(obj.__typename)
}



var UserInvitationWithCompany_possibleTypes = ['UserInvitationWithCompany']
export var isUserInvitationWithCompany = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserInvitationWithCompany"')
  return UserInvitationWithCompany_possibleTypes.includes(obj.__typename)
}



var UserManualProduct_possibleTypes = ['UserManualProduct']
export var isUserManualProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserManualProduct"')
  return UserManualProduct_possibleTypes.includes(obj.__typename)
}



var UserNameFromReferralCodeResponse_possibleTypes = ['UserNameFromReferralCodeResponse']
export var isUserNameFromReferralCodeResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserNameFromReferralCodeResponse"')
  return UserNameFromReferralCodeResponse_possibleTypes.includes(obj.__typename)
}



var UserReferralLinkResponse_possibleTypes = ['UserReferralLinkResponse']
export var isUserReferralLinkResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserReferralLinkResponse"')
  return UserReferralLinkResponse_possibleTypes.includes(obj.__typename)
}



var UserRole_possibleTypes = ['UserRole']
export var isUserRole = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserRole"')
  return UserRole_possibleTypes.includes(obj.__typename)
}



var UserRolesResponse_possibleTypes = ['UserRolesResponse']
export var isUserRolesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUserRolesResponse"')
  return UserRolesResponse_possibleTypes.includes(obj.__typename)
}



var UsersByEmailsResponse_possibleTypes = ['UsersByEmailsResponse']
export var isUsersByEmailsResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isUsersByEmailsResponse"')
  return UsersByEmailsResponse_possibleTypes.includes(obj.__typename)
}



var ValidationCycle_possibleTypes = ['ValidationCycle']
export var isValidationCycle = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationCycle"')
  return ValidationCycle_possibleTypes.includes(obj.__typename)
}



var ValidationResult_possibleTypes = ['ValidationResult']
export var isValidationResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationResult"')
  return ValidationResult_possibleTypes.includes(obj.__typename)
}



var ValidationResultSet_possibleTypes = ['ValidationResultSet']
export var isValidationResultSet = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationResultSet"')
  return ValidationResultSet_possibleTypes.includes(obj.__typename)
}



var ValidationTemplate_possibleTypes = ['ValidationTemplate']
export var isValidationTemplate = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isValidationTemplate"')
  return ValidationTemplate_possibleTypes.includes(obj.__typename)
}



var Vendor_possibleTypes = ['Vendor']
export var isVendor = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVendor"')
  return Vendor_possibleTypes.includes(obj.__typename)
}



var VendorByPaymentGatewayResponse_possibleTypes = ['VendorByPaymentGatewayResponse']
export var isVendorByPaymentGatewayResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVendorByPaymentGatewayResponse"')
  return VendorByPaymentGatewayResponse_possibleTypes.includes(obj.__typename)
}



var VendorNotExistError_possibleTypes = ['VendorNotExistError']
export var isVendorNotExistError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVendorNotExistError"')
  return VendorNotExistError_possibleTypes.includes(obj.__typename)
}



var VendorsTransactionsCount_possibleTypes = ['VendorsTransactionsCount']
export var isVendorsTransactionsCount = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVendorsTransactionsCount"')
  return VendorsTransactionsCount_possibleTypes.includes(obj.__typename)
}



var VoidOrderSettings_possibleTypes = ['VoidOrderSettings']
export var isVoidOrderSettings = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVoidOrderSettings"')
  return VoidOrderSettings_possibleTypes.includes(obj.__typename)
}



var VoidOrdersResponse_possibleTypes = ['VoidOrdersResponse']
export var isVoidOrdersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isVoidOrdersResponse"')
  return VoidOrdersResponse_possibleTypes.includes(obj.__typename)
}



var WalmartProduct_possibleTypes = ['WalmartProduct']
export var isWalmartProduct = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWalmartProduct"')
  return WalmartProduct_possibleTypes.includes(obj.__typename)
}



var Warehouse_possibleTypes = ['Warehouse']
export var isWarehouse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouse"')
  return Warehouse_possibleTypes.includes(obj.__typename)
}



var WarehouseAction_possibleTypes = ['AdjustmentRestockingAction','CommonAction','FulfillmentAction','ManualAssemblyAction','OnDemandAssemblyAction','OpeningBalanceAction','RestockingAction','ShipmentAction','WarehouseTransferArrivalAction','WarehouseTransferShippingAction']
export var isWarehouseAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseAction"')
  return WarehouseAction_possibleTypes.includes(obj.__typename)
}



var WarehouseActionSalesChannel_possibleTypes = ['WarehouseActionSalesChannel']
export var isWarehouseActionSalesChannel = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseActionSalesChannel"')
  return WarehouseActionSalesChannel_possibleTypes.includes(obj.__typename)
}



var WarehouseCreateError_possibleTypes = ['WarehouseCreateError']
export var isWarehouseCreateError = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseCreateError"')
  return WarehouseCreateError_possibleTypes.includes(obj.__typename)
}



var WarehouseCreateResult_possibleTypes = ['Warehouse','WarehouseCreateError']
export var isWarehouseCreateResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseCreateResult"')
  return WarehouseCreateResult_possibleTypes.includes(obj.__typename)
}



var WarehouseIncomingInventoryBreakdownResult_possibleTypes = ['WarehouseIncomingInventoryBreakdownResult']
export var isWarehouseIncomingInventoryBreakdownResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseIncomingInventoryBreakdownResult"')
  return WarehouseIncomingInventoryBreakdownResult_possibleTypes.includes(obj.__typename)
}



var WarehouseIncomingInventoryOverviewResult_possibleTypes = ['WarehouseIncomingInventoryOverviewResult']
export var isWarehouseIncomingInventoryOverviewResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseIncomingInventoryOverviewResult"')
  return WarehouseIncomingInventoryOverviewResult_possibleTypes.includes(obj.__typename)
}



var WarehouseMovements_possibleTypes = ['WarehouseMovements']
export var isWarehouseMovements = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseMovements"')
  return WarehouseMovements_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalance_possibleTypes = ['WarehouseOpeningBalance']
export var isWarehouseOpeningBalance = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalance"')
  return WarehouseOpeningBalance_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalanceErrorItem_possibleTypes = ['WarehouseOpeningBalanceErrorItem']
export var isWarehouseOpeningBalanceErrorItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalanceErrorItem"')
  return WarehouseOpeningBalanceErrorItem_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalanceItem_possibleTypes = ['WarehouseOpeningBalanceItem']
export var isWarehouseOpeningBalanceItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalanceItem"')
  return WarehouseOpeningBalanceItem_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalanceOverviewResponse_possibleTypes = ['WarehouseOpeningBalanceOverviewResponse']
export var isWarehouseOpeningBalanceOverviewResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalanceOverviewResponse"')
  return WarehouseOpeningBalanceOverviewResponse_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalancesCsvTemplateResponse_possibleTypes = ['WarehouseOpeningBalancesCsvTemplateResponse']
export var isWarehouseOpeningBalancesCsvTemplateResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalancesCsvTemplateResponse"')
  return WarehouseOpeningBalancesCsvTemplateResponse_possibleTypes.includes(obj.__typename)
}



var WarehouseOpeningBalancesResponse_possibleTypes = ['WarehouseOpeningBalancesResponse']
export var isWarehouseOpeningBalancesResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseOpeningBalancesResponse"')
  return WarehouseOpeningBalancesResponse_possibleTypes.includes(obj.__typename)
}



var WarehouseResult_possibleTypes = ['WarehouseResult']
export var isWarehouseResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseResult"')
  return WarehouseResult_possibleTypes.includes(obj.__typename)
}



var WarehouseSalesData_possibleTypes = ['WarehouseSalesData']
export var isWarehouseSalesData = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseSalesData"')
  return WarehouseSalesData_possibleTypes.includes(obj.__typename)
}



var WarehousesDefaults_possibleTypes = ['WarehousesDefaults']
export var isWarehousesDefaults = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehousesDefaults"')
  return WarehousesDefaults_possibleTypes.includes(obj.__typename)
}



var WarehousesDefaultsResult_possibleTypes = ['WarehousesDefaultsResult']
export var isWarehousesDefaultsResult = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehousesDefaultsResult"')
  return WarehousesDefaultsResult_possibleTypes.includes(obj.__typename)
}



var WarehouseTransfer_possibleTypes = ['WarehouseTransfer']
export var isWarehouseTransfer = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransfer"')
  return WarehouseTransfer_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferArrival_possibleTypes = ['WarehouseTransferArrival']
export var isWarehouseTransferArrival = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferArrival"')
  return WarehouseTransferArrival_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferArrivalAction_possibleTypes = ['WarehouseTransferArrivalAction']
export var isWarehouseTransferArrivalAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferArrivalAction"')
  return WarehouseTransferArrivalAction_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferAttachment_possibleTypes = ['WarehouseTransferAttachment']
export var isWarehouseTransferAttachment = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferAttachment"')
  return WarehouseTransferAttachment_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferBatchHistoryItem_possibleTypes = ['WarehouseTransferBatchHistoryItem']
export var isWarehouseTransferBatchHistoryItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferBatchHistoryItem"')
  return WarehouseTransferBatchHistoryItem_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferBreakdownBatch_possibleTypes = ['WarehouseTransferBreakdownBatch']
export var isWarehouseTransferBreakdownBatch = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferBreakdownBatch"')
  return WarehouseTransferBreakdownBatch_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferBreakdownItem_possibleTypes = ['WarehouseTransferBreakdownItem']
export var isWarehouseTransferBreakdownItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferBreakdownItem"')
  return WarehouseTransferBreakdownItem_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferCostAllocation_possibleTypes = ['WarehouseTransferCostAllocation']
export var isWarehouseTransferCostAllocation = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferCostAllocation"')
  return WarehouseTransferCostAllocation_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferCostLineItem_possibleTypes = ['WarehouseTransferCostLineItem']
export var isWarehouseTransferCostLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferCostLineItem"')
  return WarehouseTransferCostLineItem_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferLineItem_possibleTypes = ['WarehouseTransferLineItem']
export var isWarehouseTransferLineItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferLineItem"')
  return WarehouseTransferLineItem_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferLineItemFifoCost_possibleTypes = ['WarehouseTransferLineItemFifoCost']
export var isWarehouseTransferLineItemFifoCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferLineItemFifoCost"')
  return WarehouseTransferLineItemFifoCost_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferLineItemFifoIndirectCost_possibleTypes = ['WarehouseTransferLineItemFifoIndirectCost']
export var isWarehouseTransferLineItemFifoIndirectCost = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferLineItemFifoIndirectCost"')
  return WarehouseTransferLineItemFifoIndirectCost_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferOverviewItem_possibleTypes = ['WarehouseTransferOverviewItem']
export var isWarehouseTransferOverviewItem = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferOverviewItem"')
  return WarehouseTransferOverviewItem_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferShipping_possibleTypes = ['WarehouseTransferShipping']
export var isWarehouseTransferShipping = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferShipping"')
  return WarehouseTransferShipping_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferShippingAction_possibleTypes = ['WarehouseTransferShippingAction']
export var isWarehouseTransferShippingAction = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferShippingAction"')
  return WarehouseTransferShippingAction_possibleTypes.includes(obj.__typename)
}



var WarehouseTransfersOverview_possibleTypes = ['WarehouseTransfersOverview']
export var isWarehouseTransfersOverview = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransfersOverview"')
  return WarehouseTransfersOverview_possibleTypes.includes(obj.__typename)
}



var WarehouseTransfersResponse_possibleTypes = ['WarehouseTransfersResponse']
export var isWarehouseTransfersResponse = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransfersResponse"')
  return WarehouseTransfersResponse_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferTransit_possibleTypes = ['WarehouseTransferTransit']
export var isWarehouseTransferTransit = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferTransit"')
  return WarehouseTransferTransit_possibleTypes.includes(obj.__typename)
}



var WarehouseTransferWithBreakdown_possibleTypes = ['WarehouseTransferWithBreakdown']
export var isWarehouseTransferWithBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWarehouseTransferWithBreakdown"')
  return WarehouseTransferWithBreakdown_possibleTypes.includes(obj.__typename)
}



var WasteCogs_possibleTypes = ['WasteCogs']
export var isWasteCogs = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWasteCogs"')
  return WasteCogs_possibleTypes.includes(obj.__typename)
}



var WasteMonthlyBreakdown_possibleTypes = ['WasteMonthlyBreakdown']
export var isWasteMonthlyBreakdown = function(obj) {
  if (!obj || !obj.__typename) throw new Error('__typename is missing in "isWasteMonthlyBreakdown"')
  return WasteMonthlyBreakdown_possibleTypes.includes(obj.__typename)
}
