import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import ReactJson from 'react-json-view';
import { BusinessEventDataV2 } from '../../types/BusinessEvent';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface BusinessEventDataRowProps {
  companyId: string;
  event: BusinessEventDataV2;
  onEventTimeClick: (eventTime: Date) => void;
  showAmountsInOriginalCurrency: boolean;
}

const BusinessEventDataRow = ({
  event,
  onEventTimeClick,
  showAmountsInOriginalCurrency,
  companyId,
}: BusinessEventDataRowProps) => {
  const [isOpen, setIsOpen] = useState(false);
  // @ts-ignore
  const eventTimeString = useMemo(() => new Date(event.eventTime).toUTCString(), [event]);
  // @ts-ignore
  const eventTimeDate = useMemo(() => new Date(event.eventTime), [event]);

  const amountKey = showAmountsInOriginalCurrency ? 'amountInOriginalCurrency' : 'amount';

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Button variant="text" onClick={() => onEventTimeClick(eventTimeDate)}>
            {eventTimeString}
          </Button>
        </TableCell>
        <TableCell>{event.source}</TableCell>
        <TableCell>{event.type}</TableCell>
        <TableCell>{event.integrationAccountId}</TableCell>
        <TableCell>{event.action[amountKey]}</TableCell>
        <TableCell>{event.currentBalance}</TableCell>
        <TableCell align="right">
          <IconButton size="small">
            <Link to={`/business-event/${event.businessEventId}?companyId=${companyId}`}>
              <SearchIcon />
            </Link>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <ReactJson collapsed={1} src={event.rawEvent} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

interface BusinessEventsTableViewPropsV2 {
  businessEvents: BusinessEventDataV2[];
  onEventTimeClick: (eventTime: Date) => void;
  onEventTimeTitleClick: () => void;
  companyId: string;
  showAmountsInOriginalCurrency: boolean;
}

export const BusinessEventsTableView = ({
  businessEvents,
  onEventTimeClick,
  onEventTimeTitleClick,
  showAmountsInOriginalCurrency,
  companyId,
}: BusinessEventsTableViewPropsV2) => {
  return (
    <TableContainer component={Paper}>
      <CSVLink data={csvData(businessEvents)} filename={'business-events.csv'}>
        <IconButton aria-label="download as csv" color="primary" component="span">
          <FileDownloadIcon />
        </IconButton>
      </CSVLink>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ cursor: 'pointer' }} onClick={onEventTimeTitleClick}>
              Event Time
            </TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Integration Account Id</TableCell>
            <TableCell>{`Amount${!showAmountsInOriginalCurrency ? ' (USD)' : ''}`}</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {businessEvents.map((event) => (
            <BusinessEventDataRow
              key={event.businessEventId}
              event={event}
              onEventTimeClick={onEventTimeClick}
              showAmountsInOriginalCurrency={showAmountsInOriginalCurrency}
              companyId={companyId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const csvData = (businessEvents: BusinessEventDataV2[]) => {
  return businessEvents.map((be) => {
    return {
      'Event Time': be.eventTime,
      Source: be.source,
      Type: be.type,
      'Integration Account Id': be.integrationAccountId,
      Amount: be.action.amount,
      Balance: be.currentBalance,
    };
  });
};
