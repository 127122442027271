import clsx from 'clsx';
import { Loader } from './Loader';

interface ActionButtonsProps {
  onSaveData: () => void;
  isDisabled?: boolean;
  isSaving?: boolean;
}

export function SaveButton(props: ActionButtonsProps) {
  const { onSaveData, isSaving, isDisabled } = props;

  return (
    <div className="text-white font-bold">
      <button
        className={clsx('flex items-center bg-blue-500 hover:bg-blue-700 py-2 px-2 mr-2 rounded-lg', {
          'disabled:bg-blue-400': isDisabled,
        })}
        disabled={isDisabled || isSaving}
        onClick={onSaveData}
      >
        {isSaving && <Loader />}
        {isSaving ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
}
