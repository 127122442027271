import axios from 'axios';
import { BalanceStatusForCompany } from '../types/BalanceStatusForCompany';
import { ClearingAccountValidationStatusForCompany } from '../types/ClearingAccountValidationStatusForCompany';
import {
  GiftCardValidationStatusForCompany,
  MetricsValidationStatusForCompany,
} from '../types/MetricsValidationStatusForCompany';
import { ValidationResult, ValidationResultSet, gqlClient } from './FinaloopGql';

let url = '';
export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchBalanceValidationStatusV2 = async (
  integrationType: string,
  authToken: string,
): Promise<BalanceStatusForCompany[]> => {
  if (!url.length) {
    return [];
  }

  const { validations } = await gqlClient.query({
    validations: [
      {
        args: {
          integrationType: integrationType.toUpperCase(),
          resultsLimit: 10,
          payingCompaniesOnly: true,
        },
      },
      {
        companyId: true,
        accountingMethod: true,
        integrationType: true,
        integrationAccountId: true,
        integrationAccountName: true,
        resultSet: {
          createdAt: true,
          sampleDate: true,
          sampleValue: true,
          currentValue: true,
        },
        accountRoles: true,
        currency: true,
      },
    ],
  });
  return toBalanceStatusForCompanyArray(validations);
};

export const fetchBalanceValidationStatus = async (
  integrationType: string,
  token: string,
): Promise<BalanceStatusForCompany[]> => {
  if (!url.length) {
    return [];
  }

  const result = await axios.get<BalanceStatusForCompany[]>(
    `${url}/balance-validate/companies-status/${integrationType.toUpperCase()}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return result.data;
};

export const fetchMetricsValidationStatus = async (
  integrationType: string,
): Promise<MetricsValidationStatusForCompany[]> => {
  if (!url.length) {
    return [];
  }

  const result = await axios.get<MetricsValidationStatusForCompany[]>(
    `${url}/metrics-validate/companies-status/${integrationType.toUpperCase()}`,
  );

  return result.data;
};

export const fetchGiftCardValidationStatus = async (
  integrationType: string,
): Promise<GiftCardValidationStatusForCompany[]> => {
  if (!url.length) {
    return [];
  }

  const result = await axios.get<GiftCardValidationStatusForCompany[]>(
    `${url}/gift-card-validate/companies-status/${integrationType.toUpperCase()}`,
  );
  return result.data;
};

export const fetchClearingAccountValidationStatus = async (
  integrationType: string,
): Promise<ClearingAccountValidationStatusForCompany[]> => {
  if (!url.length) {
    return [];
  }

  const result = await axios.get<ClearingAccountValidationStatusForCompany[]>(
    `${url}/clearing-account-validate/companies-status/${integrationType.toUpperCase()}`,
  );
  return result.data;
};

export const triggerCompanyValidation = async (companyId: string, integrationAccountId: string) => {
  if (!url.length) {
    return [];
  }
  console.info('triggerCompanyValidation', companyId, integrationAccountId);
  const validationType = 'metrics';
  return await axios.post(`${url}/trigger-validation`, {
    validationType: validationType,
    companyId,
    integrationAccountId,
  });
};

export const toBalanceStatusForCompanyArray = (validations: any): BalanceStatusForCompany[] => {
  return validations.map((validation: ValidationResult) => ({
    companyId: validation.companyId,
    integrationAccountId: validation.integrationAccountId!,
    isSync: validation.resultSet[0].currentValue === validation.resultSet[0].sampleValue,
    currency: validation.currency,
    integrationAccountName: validation.integrationAccountName,
    lastSuccessfulSyncTimeMs: getLastSuccessfulSyncTimeMs(validation.resultSet),
    lastRunMs: new Date(validation.resultSet[0].createdAt).getTime(),
    lastSyncBalance: validation.resultSet[0].currentValue,
    accountRoles: validation.accountRoles,
    outOfSyncRows: validation.resultSet
      .filter(result => result.currentValue !== result.sampleValue)
      .map(result => ({
        companyId: validation.companyId,
        integrationAccountId: validation.integrationAccountId!,
        ...result,
        balanceVsFinaloopAmount: result.sampleValue - result.currentValue,
      })),
  }));
};

const getLastSuccessfulSyncTimeMs = (resultSet: ValidationResultSet[]) => {
  const lastSuccessfulSync = resultSet.find(result => result.currentValue === result.sampleValue);
  return lastSuccessfulSync?.createdAt ? new Date(lastSuccessfulSync.createdAt).getTime() : undefined;
};
