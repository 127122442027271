import { Button } from '@mui/material';
import clsx from 'clsx';
import { useCallback } from 'react';
import { BusinessEvent } from '../../types';
import { Loader } from './Loader';

interface ActionButtonsProps {
  onPaste: (event: BusinessEvent) => void;
  onSaveClicked: (shouldClearForm: boolean) => void;
  isLoading?: boolean;
  canSave: boolean;
}

export function ActionButtons(props: ActionButtonsProps) {
  const { onPaste, onSaveClicked, isLoading, canSave } = props;

  const onPasteClicked = useCallback(() => {
    navigator.clipboard.readText().then((text) => {
      try {
        const parsedEvent = JSON.parse(text);
        onPaste(parsedEvent);
      } catch {}
    });
  }, [onPaste]);

  return (
    <div className="flex gap-2">
      <SaveButton onClick={() => onSaveClicked(true)} isLoading={isLoading} text={'Save'} canSave={canSave} />
      <SaveButton onClick={() => onSaveClicked(false)} isLoading={isLoading} text={'Save and Copy'} canSave={canSave} />
      <Button onClick={onPasteClicked}>Paste</Button>
    </div>
  );
}

const SaveButton = (props: {
  onClick: VoidFunction;
  isLoading: boolean | undefined;
  text: string;
  canSave: boolean;
}) => (
  <Button
    className={clsx('flex items-center bg-blue-500 hover:bg-blue-700 py-2 px-2 mr-2 rounded-lg', {
      'disabled:bg-blue-400': props.isLoading,
    })}
    disabled={!props.canSave || props.isLoading}
    onClick={() => props.onClick()}
  >
    {props.isLoading && <Loader />}
    {props.isLoading ? 'Saving...' : props.text}
  </Button>
);
