import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { AccountFragments } from '../../../types/AccountFragments';

export type UseGetAccountFragmentsState = FetchHookState<AccountFragments[]>;

const defaultState: UseGetAccountFragmentsState = {
  data: [],
  loading: true,
};

export const useGetAccountFragments = (companyId?: string): UseGetAccountFragmentsState => {
  const [fetchHookState, setFetchHookState] = useState<UseGetAccountFragmentsState>(defaultState);
  const { getAccessTokenSilently } = useAuth0();
  const services = useContext(environmentContext);

  useEffect(() => {
    if (!companyId) {
      setFetchHookState({ ...defaultState, loading: false });
      return;
    }

    setFetchHookState(defaultState);

    getAccessTokenSilently()
      .then((token) => services.MetricsApiClient.getAccountFragments(token, companyId))
      .then((data) => setFetchHookState({ data, loading: false }))
      .catch((error) => setFetchHookState({ data: [], error, loading: false }));
  }, [services, companyId]);

  return fetchHookState;
};
