import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { SQSQueueMetrics } from '../../../types/SQSQueueMetrics';

export type UseFetchSQSQueueMetricsState = FetchHookState<SQSQueueMetrics[]>;

const defaultState: UseFetchSQSQueueMetricsState = {
  data: [],
  loading: true,
  refetch: () => {},
};
export const useFetchSQSQueueMetrics = () => {
  const [fetchHookState, setFetchHookState] = useState<UseFetchSQSQueueMetricsState>(defaultState);
  const [refetchToken, setRefetchToken] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const services = useContext(environmentContext);
  const refetch = () => {
    setRefetchToken((prev) => prev + 1);
  };

  useEffect(() => {
    setFetchHookState(defaultState);

    getAccessTokenSilently().then((token) =>
      services.DutyApiClient.fetchSQSQueueMetrics()
        .then((data) => {
          setFetchHookState({
            data,
            loading: false,
            refetch,
          });
        })
        .catch((error) => {
          setFetchHookState({
            data: [],
            error,
            loading: false,
            refetch,
          });
        }),
    );
  }, [services, refetchToken]);
  return fetchHookState;
};
