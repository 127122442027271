import clsx from 'clsx';
import { useEffect, useState } from 'react';

interface ToastProps {
  message?: string | null;
  type: 'success' | 'error';
}

const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
    <path
      fill-rule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
      clip-rule="evenodd"
    />
  </svg>
);

const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 64 64">
    <path fill="currentColor" d="M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64S0,49.7,0,32S14.3,0,32,0z" />
    <polygon fill="#FFFFFF" points="26,45.9 15.6,35.4 18.4,32.6 26,40.1 45.6,19.6 48.4,22.4" />
  </svg>
);

export function Toast(props: ToastProps) {
  const { message, type } = props;
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(!!message);

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [message]);

  const className = clsx('flex items-center justify-between py-4', {
    invisible: !isVisible,
    'text-red-600': type === 'error',
    'text-green-600': type === 'success',
  });

  return (
    <div className={className}>
      <div className="flex items-center">
        {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
        <p className="ml-3 text-sm font-bold">{message}</p>
      </div>
    </div>
  );
}
