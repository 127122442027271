import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container, fontSize } from '@mui/system';
import { capitalize, keyBy, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { Company } from '../../services/FinaloopGql';
import { BalanceStatusForCompany } from '../../types/BalanceStatusForCompany';
import { useFetchBalanceStatuses } from '../common/hooks/useFetchBalanceStatuses';
import { useListCompaniesName } from '../common/hooks/useListCompaniesName';

interface StatusesTableProps {
  statuses: BalanceStatusForCompany[];
  companies: Record<string, Required<Pick<Company, 'id' | 'name'>>>;
}

interface StatusesTableRowProps {
  status: BalanceStatusForCompany;
  companyName: string;
  index: number;
}

const StatusesTableRow = ({ status, companyName, index }: StatusesTableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const lastSuccessfulSyncTimeCell = useMemo(() => {
    if (!status.lastSuccessfulSyncTimeMs) {
      return <TableCell sx={{ backgroundColor: '#d32f2f' }}>{'N/A'}</TableCell>;
    }
    return <TableCell>{new Date(status.lastSuccessfulSyncTimeMs!).toUTCString()}</TableCell>;
  }, [status]);

  const lastRunTimeCell = useMemo(() => {
    if (!status.lastRunMs) {
      return <TableCell sx={{ backgroundColor: '#d32f2f' }}>{'N/A'}</TableCell>;
    }
    return <TableCell>{new Date(status.lastRunMs!).toUTCString()}</TableCell>;
  }, [status]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{index}</TableCell>
        <TableCell>
          <IconButton
            disabled={!status.outOfSyncRows || !!status.errMsg}
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" width={190}>
          <div>
            <span>{companyName}</span>
            {status.errMsg != null && (
              <>
                <br />
                <span style={{ fontSize: 10, color: 'red' }}>{status.errMsg}</span>
              </>
            )}
          </div>
        </TableCell>
        <TableCell>{status.integrationAccountId}</TableCell>
        <TableCell>{status.currency}</TableCell>
        <TableCell>{status.integrationAccountName}</TableCell>
        <TableCell>{status.accountRoles?.join(', ') || ''}</TableCell>
        {lastSuccessfulSyncTimeCell}
        {lastRunTimeCell}
        <TableCell>
          {status.isSync ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : (
            <ErrorOutlineOutlinedIcon color="error" />
          )}
        </TableCell>
        <TableCell>
          {status.outOfSyncRows?.length ? status.outOfSyncRows[0].balanceVsFinaloopAmount.toFixed(2) : ''}
        </TableCell>
        <TableCell>
          {status.reportsVsFinaloopDiff === 0 ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : !status.reportsVsFinaloopDiff ? (
            <HelpOutlineIcon color="warning" />
          ) : (
            <ErrorOutlineOutlinedIcon color="error" />
          )}
        </TableCell>
        <TableCell>{status.reportsVsFinaloopDiff}</TableCell>
        <TableCell>{status.balanceStartOfYearDiff ?? ''}</TableCell>
        <TableCell>
          {status.balanceStartOfYearDiff === 0 ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : !status.balanceStartOfYearDiff ? (
            <HelpOutlineIcon color="warning" />
          ) : (
            <ErrorOutlineOutlinedIcon color="error" />
          )}
        </TableCell>
        <TableCell>{status.balanceStartOfYearCheckTimeISO || ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <ReactJson collapsed={1} src={status.outOfSyncRows!} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const filterStatuses = (statuses: BalanceStatusForCompany[]) => statuses.filter(status => status.lastRunMs);

const StatusesTable = ({ statuses, companies }: StatusesTableProps) => {
  const { integrationType } = useParams();

  const defaultFilterAlwaysFailing = integrationType?.toLocaleLowerCase() !== 'ebay';
  const [relevantStatuses, setRelevantStatuses] = useState<BalanceStatusForCompany[]>(
    defaultFilterAlwaysFailing ? statuses : filterStatuses(statuses),
  );

  const toggleFailingCompaniesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRelevantStatuses(event.target.checked ? statuses : filterStatuses(statuses));
  };

  return (
    <Container maxWidth="xl">
      <FormGroup sx={{ marginBottom: 5 }}>
        <FormLabel style={{ fontSize: 20, fontWeight: 700 }}>
          {capitalize(integrationType)} validation results:
        </FormLabel>
        <FormControlLabel
          control={<Checkbox defaultChecked={defaultFilterAlwaysFailing} onChange={toggleFailingCompaniesFilter} />}
          label="Show companies without successful runs at all"
        />
      </FormGroup>
      <TableContainer component={Paper} sx={{ overflowX: 'unset' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 999,
              background: 'white',
              th: {
                lineHeight: 'unset',
              },
            }}
          >
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell />
              <TableCell>Company</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Integration Account Name</TableCell>
              <TableCell>Account Roles</TableCell>
              <TableCell>Last Valid Time</TableCell>
              <TableCell>Last Run</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Difference</TableCell>
              <TableCell>Reports status</TableCell>
              <TableCell>Reports diff</TableCell>
              <TableCell>End of year diff</TableCell>
              <TableCell>End of year status</TableCell>
              <TableCell>end of year check time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {relevantStatuses
              .map(status => ({
                ...status,
                company: companies[status.companyId],
              }))
              .filter(status => status.company != null)
              .map((status, index) => {
                const company = companies[status.companyId];
                if (!company) return null;

                return (
                  <StatusesTableRow
                    key={`${status.companyId}-${status.integrationAccountId}-${status.currency}`}
                    status={status}
                    companyName={company.name}
                    index={index + 1}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export const Validation = () => {
  const { integrationType } = useParams();
  const listCompanies = useListCompaniesName();
  const statuses = useFetchBalanceStatuses(integrationType);

  const isReady = !listCompanies.isLoading && !statuses.loading;
  let companyIdToCompany: Record<string, { id: string; name: string }> = {};
  if (isReady) {
    companyIdToCompany = keyBy(listCompanies.data || [], 'id');
  }

  const statusRows = useMemo(() => {
    if (!statuses.data) {
      return [];
    }
    const failed = sortBy(
      statuses.data.filter(s => !s.isSync),
      status => Math.abs(status.outOfSyncRows?.[0]?.balanceVsFinaloopAmount || 0),
    ).reverse();
    const succeeded = sortBy(
      statuses.data.filter(s => s.isSync),
      status => companyIdToCompany[status.companyId]?.name,
    );
    return [...failed, ...succeeded];
  }, [statuses.data]);

  return isReady ? <StatusesTable statuses={statusRows} companies={companyIdToCompany} /> : <>Loading...</>;
};
