import { useTable, TableOptions, Cell, Column } from 'react-table';
import { CompanyQuickbooksAccountState } from '../../types';
import { ClassificationTag } from '../../../../services/classificationTagsApiClient';

interface Props {
  columns: Column[];
  data: CompanyQuickbooksAccountState[];
  updateData: (rowIndex: number, columnId: number, value: ClassificationTag) => void;
}
export function AccountsTable(props: Props) {
  const { columns, data, updateData } = props;

  const extendedTableOptions: TableOptions<{}> & {
    updateData: (rowIndex: number, columnId: number, value: ClassificationTag) => void;
  } = {
    columns,
    data,
    updateData,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(extendedTableOptions);

  return (
    <div className="py-5 flex flex-col">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className="shadow overflow-auto border border-gray-300 sm:rounded-lg"
            style={{ height: '70vh', width: '75vw' }}
          >
            <table {...getTableProps()} className="divide-y divide-gray-200">
              <thead className="bg-gray-50 text-white w-full">
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="w-full mb-4">
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4"
                        style={{
                          position: 'sticky',
                          top: 0,
                          zIndex: 1,
                          boxShadow: 'inset 0 -1px 0 rgb(229, 231, 235)',
                        }}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200  items-center justify-between overflow-y-scroll w-full"
                style={{ height: '65vh' }}
                {...getTableBodyProps()}
              >
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="w-full mb-4">
                      {row.cells.map((cell: Cell, index: number) => {
                        return (
                          <td className="px-4 py-4 whitespace-nowrap  w-1/4" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountsTable;
