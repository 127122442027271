import { useContext, useEffect, useState } from 'react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { Company } from '../../../types/Company';

export type UseFetchCompanyState = FetchHookState<Company | undefined>;

const defaultState: UseFetchCompanyState = {
  data: undefined,
  loading: true,
};

export const useFetchCompany = (companyId?: string): UseFetchCompanyState => {
  const [fetchHookState, setFetchHookState] = useState<UseFetchCompanyState>(defaultState);
  const services = useContext(environmentContext);
  useEffect(() => {
    if (!companyId) {
      setFetchHookState({ ...defaultState, loading: false });
      return;
    }
    setFetchHookState(defaultState);
    services.CompanyApiClient.fetchCompany(companyId)
      .then((data) => {
        setFetchHookState({
          data,
          loading: false,
        });
      })
      .catch((error) => {
        setFetchHookState({
          data: undefined,
          error,
          loading: false,
        });
      });
  }, [services, companyId]);
  return fetchHookState;
};
