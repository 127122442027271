import RefreshIcon from '@mui/icons-material/Refresh';
import { FormControl, LinearProgress, MenuItem, Select, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container } from '@mui/system';
import { useFetchSQSQueueMetrics } from '../common/hooks/useFetchSQSQueueMetrics';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export const SQSQueueMetrics = () => {
  const queueMetrics = useFetchSQSQueueMetrics();
  const { loading: loadingMetrics, refetch: refetchMetrics } = queueMetrics;

  const dlqMetrics = queueMetrics.data.filter((queueMetric) => queueMetric.queueName.includes('DLQ'));
  const nonDLQMetrics = queueMetrics.data.filter((queueMetric) => !queueMetric.queueName.includes('DLQ'));

  const dlqRows = dlqMetrics
    .filter((metric) => metric.approximateNumberOfMessages > 0)
    .sort(
      (qm1, qm2) =>
        qm2.approximateNumberOfMessages - qm1.approximateNumberOfMessages || qm1.queueName.localeCompare(qm2.queueName),
    );

  const nonDLQRows = nonDLQMetrics
    .filter((metric) => metric.approximateNumberOfMessages > 0)
    .sort(
      (qm1, qm2) =>
        qm2.approximateNumberOfMessages - qm1.approximateNumberOfMessages || qm1.queueName.localeCompare(qm2.queueName),
    );

  const queueMetricTypes = ['DLQ', 'Non-DLQ'];
  const [shownQueueMetricType, setShownQueueMetricType] = useState('DLQ');
  const queueDataToDisplay = shownQueueMetricType === 'DLQ' ? dlqRows : nonDLQRows;

  return (
    <Container maxWidth="xl">
      <TableContainer component={Paper}>
        <Typography variant="h5" align="center" fontWeight="bold">
          Non-empty queues
        </Typography>

        <div className="flex flex-row align-center justify-between mx-3">
          <FormControl sx={{ width: '30rem', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ mr: 1 }}>Queue type:</Typography>

            <Select
              value={shownQueueMetricType}
              variant="filled"
              disabled={loadingMetrics}
              onChange={(e) => setShownQueueMetricType(e.target.value as string)}
            >
              {queueMetricTypes.map((queueMetricType) => (
                <MenuItem key={queueMetricType} value={queueMetricType}>
                  {queueMetricType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LoadingButton
            variant="outlined"
            loading={loadingMetrics}
            loadingIndicator="Fetching..."
            startIcon={<RefreshIcon />}
            onClick={() => refetchMetrics?.()}
          >
            Refresh
          </LoadingButton>
        </div>

        <Table width="100%">
          <TableHead>
            <TableRow>
              <TableCell>Queue name</TableCell>
              <TableCell>Number of visible messages</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingMetrics && (
              <TableRow>
                <TableCell colSpan={2} sx={{ position: 'relative' }}>
                  <LinearProgress sx={{ width: '100%', position: 'absolute', top: 0, left: 0 }} />
                </TableCell>
              </TableRow>
            )}

            {queueDataToDisplay.map((row) => (
              <TableRow>
                <TableCell>{row.queueName}</TableCell>
                <TableCell>{row.approximateNumberOfMessages}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
