import { useCallback, useEffect } from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';
import { useCompanyOnboardingStateClient } from '../common/hooks/useCompanyOnboardingStateClient';
import styled from '@emotion/styled';
import { useSelectedCompany } from '../common/hooks/useSelectedCompany';

const Container = styled.div`
  display: grid;
  grid-row-gap: 1em;
  justify-items: center;
`;

export const CompanyOnboardingStateEditor = () => {
  const { selectedCompany } = useSelectedCompany();
  const { fetchOnboardingStateForCompany, data, updateOnboardingStateForCompany } = useCompanyOnboardingStateClient(
    selectedCompany?.id,
  );
  useEffect(() => {
    if (!selectedCompany?.id) {
      return;
    }

    fetchOnboardingStateForCompany().then(() => console.log('selectedCompanys', selectedCompany));
  }, [fetchOnboardingStateForCompany, selectedCompany]);
  const onEdit = useCallback(
    ({ updated_src }: InteractionProps) => {
      updateOnboardingStateForCompany(updated_src);
    },
    [updateOnboardingStateForCompany, selectedCompany],
  );
  return (
    <Container>
      <ReactJson collapsed src={data} onEdit={onEdit} />
    </Container>
  );
};
