import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { AccountFragments } from '../../../types/AccountFragments';

export type UseGetActionsSumState = FetchHookState<number>;

const defaultState: UseGetActionsSumState = {
  data: 0,
  loading: true,
};

export const useGetActionsSum = (
  companyId?: string,
  accountFragments?: AccountFragments,
  useOriginalCurrency: boolean = false,
  startDate?: number,
  endDate?: number,
  eventType?: string,
): UseGetActionsSumState => {
  const [fetchHookState, setFetchHookState] = useState<UseGetActionsSumState>(defaultState);
  const { getAccessTokenSilently } = useAuth0();
  const services = useContext(environmentContext);

  useEffect(() => {
    if (!companyId || !accountFragments) {
      setFetchHookState({ ...defaultState, loading: false });
      return;
    }

    setFetchHookState(defaultState);

    getAccessTokenSilently()
      .then((token) =>
        services.MetricsApiClient.getActionsSum(
          token,
          companyId,
          accountFragments,
          useOriginalCurrency,
          startDate,
          endDate,
          eventType,
        ),
      )
      .then((data) => setFetchHookState({ data, loading: false }))
      .catch((error) => setFetchHookState({ data: 0, error, loading: false }));
  }, [
    getAccessTokenSilently,
    services,
    companyId,
    accountFragments,
    useOriginalCurrency,
    startDate,
    endDate,
    eventType,
  ]);

  return fetchHookState;
};
