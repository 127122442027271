import { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { environmentContext } from '../../environmentContext/environmentContext';
import { AccountFragments } from '../../types/AccountFragments';

const ListItem = styled('li')`
  margin: 4px;
`;

interface BusinessEventTypesArrayProps {
  onBusinessEventTypeClick: (eventType: string) => void;
  companyId?: string;
  accountFragments?: AccountFragments;
  startDate?: number | null;
  endDate?: number | null;
}

export const BusinessEventTypesArray = ({
  companyId,
  accountFragments,
  onBusinessEventTypeClick,
  startDate,
  endDate,
}: BusinessEventTypesArrayProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [selectedEventType, setSelectedEventType] = useState<string | undefined>();
  const services = useContext(environmentContext);
  const handleChipClick = useCallback(
    (eventType: string) => {
      onBusinessEventTypeClick(eventType);
      setSelectedEventType(eventType);
    },
    [onBusinessEventTypeClick],
  );

  useEffect(() => {
    if (!companyId || !accountFragments) return;
    getAccessTokenSilently().then((token) => {
      services.MetricsApiClient.fetchAffectingBusinessEventTypesV2(
        token,
        companyId,
        accountFragments,
        startDate ?? undefined,
        endDate ?? undefined,
      ).then((types) => setEventTypes(types));
    });
  }, [companyId, accountFragments, services, startDate, endDate, getAccessTokenSilently]);

  if (!companyId || !accountFragments) return null;

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        maxWidth: '30em',
      }}
      component="ul"
    >
      <Typography variant="body1" gutterBottom sx={{ width: '100%' }} align="center">
        Affecting Business Event Types
      </Typography>
      {eventTypes.map((eventType) => {
        const backgroundColor = selectedEventType === eventType ? 'green' : '#0288d1';
        return (
          <ListItem key={eventType}>
            <Tooltip title={`Fetch events of type ${eventType}`} arrow>
              <Chip
                sx={{ backgroundColor, color: 'white' }}
                label={eventType}
                onClick={() => handleChipClick(eventType)}
              />
            </Tooltip>
          </ListItem>
        );
      })}
    </Paper>
  );
};
