export enum ValidationStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type ClearingAccountValidationStatusForCompany = {
  companyId: string;
  integrationAccountId: string;
  createdAt: string;
  numOfOrdersWithoutShopifyData: number;
  ordersWithoutShopifyData: string[];
  numberOfValidationErrors: number;
  validationErrors: any[];
  status: ValidationStatus;
  errMsg?: string;
};
