import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { setupAuthentication } from '../services/apiClientUtils';
import * as businessEventsApiClient from '../services/businessEventApiClient';
import * as classificationTagsApiClient from '../services/classificationTagsApiClient';
import * as companyApiClient from '../services/companyApi';
import * as metricsApiClient from '../services/metricsApiClient';
import * as validationApiClient from '../services/validationApi';
import * as matchingApiClient from '../services/matchingApi';
import * as questionnaireResultsApiClient from '../services/questionnaireResultsApi';
import * as companyQbAccountsApiClient from '../services/companyQbAccountsApiClient';
import * as billsApiClient from '../services/billsApiClient';
import * as companyOnboardingStateClient from '../services/companyOnboardingStateClient';
import * as dutyApiClient from '../services/dutyApiClient';
import { getEnv } from '../common/utils/env-provider';
import { setAuthTokenFetcher } from './auth';

interface EnvironmentContext {
  BusinessEventsApiClient: typeof businessEventsApiClient;
  ClassificationTagsApiClient: typeof classificationTagsApiClient;
  CompanyApiClient: typeof companyApiClient;
  MetricsApiClient: typeof metricsApiClient;
  ValidationApiClient: typeof validationApiClient;
  MatchingApiClient: typeof matchingApiClient;
  QuestionnaireResultsApiClient: typeof questionnaireResultsApiClient;
  CompanyQbAccountsApiClient: typeof companyQbAccountsApiClient;
  BillsApiClient: typeof billsApiClient;
  CompanyOnboardingStateClient: typeof companyOnboardingStateClient;
  DutyApiClient: typeof dutyApiClient;
  currentEnv: string;
  setEnv: (envName: string) => void;
}

const setUrls = (envName: string) => {
  businessEventsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  classificationTagsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  companyApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  metricsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  validationApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  matchingApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  questionnaireResultsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  companyQbAccountsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  billsApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  companyOnboardingStateClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
  dutyApiClient.setUrl(`https://api3${envName === 'prod' ? '' : `-${envName}`}.finaloop.com`);
};

const defaultValue: EnvironmentContext = {
  BusinessEventsApiClient: businessEventsApiClient,
  ClassificationTagsApiClient: classificationTagsApiClient,
  CompanyApiClient: companyApiClient,
  MetricsApiClient: metricsApiClient,
  ValidationApiClient: validationApiClient,
  MatchingApiClient: matchingApiClient,
  QuestionnaireResultsApiClient: questionnaireResultsApiClient,
  CompanyQbAccountsApiClient: companyQbAccountsApiClient,
  BillsApiClient: billsApiClient,
  CompanyOnboardingStateClient: companyOnboardingStateClient,
  DutyApiClient: dutyApiClient,
  currentEnv: getEnv(),
  setEnv: (envName: string) => {},
};

export const environmentContext = createContext<EnvironmentContext>(defaultValue);

export const EnvironmentProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentContext, setCurrentContext] = useState(defaultValue);
  useEffect(() => {
    const handler = async () => {
      const token = await getAccessTokenSilently();
      setupAuthentication(token);
      setAuthTokenFetcher(getAccessTokenSilently);
    };
    handler();
  });
  const setEnv = useCallback((envName: string) => {
    setCurrentContext({ ...defaultValue, currentEnv: envName });
  }, []);
  useEffect(() => {
    setUrls(currentContext.currentEnv);
  }, [currentContext.currentEnv]);
  const value = useMemo(() => ({ ...currentContext, setEnv }), [currentContext, setEnv]);
  return <environmentContext.Provider value={value}>{children}</environmentContext.Provider>;
};
