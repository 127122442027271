import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';

export const useQueryState = <T>(
  queryParamName: string,
  defaultValue?: T,
): [value: T | undefined, setValue: (value: T | undefined) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<T | undefined>(defaultValue);

  const setQueryValue = useCallback(
    (value: T | undefined) => {
      if (value !== undefined) searchParams.set(queryParamName, JSON.stringify(value));
      else searchParams.delete(queryParamName);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams, queryParamName],
  );

  useEffect(() => {
    const queryParamValue = searchParams.get(queryParamName);
    if (!queryParamValue) {
      setQueryValue(defaultValue);
      setValue(defaultValue);
      return;
    }

    try {
      const parsedValue = JSON.parse(queryParamValue);
      if (isEqual(parsedValue, value)) return;
      setValue(parsedValue);
    } catch {
      return;
    }
  }, [searchParams, value, setQueryValue, queryParamName, defaultValue]);

  return [value, setQueryValue];
};
