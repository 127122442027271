export default {
    "scalars": [
        1,
        5,
        6,
        7,
        8,
        10,
        12,
        18,
        25,
        27,
        38,
        40,
        45,
        77,
        78,
        81,
        84,
        98,
        100,
        101,
        110,
        113,
        116,
        118,
        119,
        120,
        121,
        123,
        125,
        129,
        132,
        136,
        143,
        144,
        145,
        151,
        152,
        155,
        156,
        157,
        158,
        161,
        162,
        163,
        165,
        166,
        175,
        177,
        194,
        195,
        196,
        209,
        216,
        231,
        258,
        261,
        262,
        264,
        276,
        290,
        297,
        305,
        306,
        312,
        313,
        315,
        316,
        330,
        377,
        385,
        386,
        398,
        444,
        452,
        460,
        462,
        465,
        467,
        485,
        487,
        502,
        503,
        505,
        511,
        518,
        521,
        545,
        624,
        625,
        631,
        639,
        657,
        658,
        699,
        703,
        716,
        722,
        723,
        730,
        739,
        747,
        753,
        754,
        764,
        766,
        770,
        772,
        776,
        777,
        779,
        781,
        782,
        786,
        791,
        801,
        807,
        811,
        834,
        842,
        843,
        848,
        863,
        866,
        868,
        875,
        878,
        879,
        882,
        883,
        884,
        885,
        886,
        887,
        888,
        889,
        893,
        895,
        896,
        898,
        899,
        902,
        905,
        907,
        908,
        912,
        913,
        914,
        915,
        916,
        922,
        924,
        928,
        944,
        956,
        965,
        967,
        968,
        971,
        973,
        990,
        991,
        992,
        996,
        1006,
        1008,
        1010,
        1012,
        1013,
        1016,
        1017,
        1023,
        1031,
        1032,
        1041,
        1043,
        1052,
        1054,
        1065,
        1071,
        1079,
        1080,
        1085,
        1087,
        1093,
        1109,
        1110,
        1115,
        1118,
        1122,
        1126,
        1129,
        1139,
        1144,
        1147,
        1173,
        1177,
        1189,
        1193,
        1206,
        1207,
        1220,
        1250,
        1251,
        1253,
        1254,
        1256,
        1263,
        1283,
        1284,
        1287,
        1302,
        1320,
        1333,
        1344,
        1369,
        1373,
        1385,
        1386,
        1394,
        1401,
        1402,
        1417,
        1426,
        1431,
        1442,
        1443,
        1445,
        1454,
        1455,
        1466,
        1577,
        1582,
        1593,
        1595,
        1596,
        1605,
        1608,
        1617,
        1622,
        1629,
        1630,
        1631,
        1647,
        1658,
        1659,
        1661
    ],
    "types": {
        "AcceptedInviteCompanyInfo": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "String": {},
        "AcceptInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AcceptInvitationResponse": {
            "userInvitation": [
                1561
            ],
            "__typename": [
                1
            ]
        },
        "AccountingInfo": {
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Boolean": {},
        "AccountingMethod": {},
        "AccountState": {},
        "AccountStatus": {},
        "AccountValidation": {
            "openingBalanceValue": [
                10
            ],
            "lastValidAt": [
                398
            ],
            "accountState": [
                7
            ],
            "potentialOpeningValue": [
                10
            ],
            "status": [
                8
            ],
            "madePrivateAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "Float": {},
        "Accrual": {
            "id": [
                12
            ],
            "accrualsData": [
                15
            ],
            "bankTransactionId": [
                12
            ],
            "companyId": [
                12
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "archivedAt": [
                398
            ],
            "createdBy": [
                27
            ],
            "createdById": [
                1
            ],
            "latestActorType": [
                27
            ],
            "latestActorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ID": {},
        "AccrualCategorizationInput": {
            "bankTransactionId": [
                12
            ],
            "merchant": [
                950
            ],
            "classifications": [
                14
            ],
            "__typename": [
                1
            ]
        },
        "AccrualClassificationInput": {
            "amount": [
                10
            ],
            "classificationTagId": [
                12
            ],
            "classificationText": [
                12
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "askTheUserResult": [
                843
            ],
            "extraData": [
                843
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AccrualData": {
            "classificationTagId": [
                12
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1394
            ],
            "residualAmount": [
                10
            ],
            "specificDate": [
                1387
            ],
            "spreadOverTime": [
                1392
            ],
            "customSpread": [
                388
            ],
            "defaultFixedAssetsData": [
                512
            ],
            "customFixedAssetsData": [
                512
            ],
            "canceledAt": [
                398
            ],
            "canceledBy": [
                1
            ],
            "canceledAccrualsData": [
                237
            ],
            "__typename": [
                1
            ]
        },
        "AccrualDataInput": {
            "classificationTagId": [
                1
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1394
            ],
            "specificDate": [
                1388
            ],
            "spreadOverTime": [
                1393
            ],
            "customSpread": [
                389
            ],
            "defaultFixedAssetsData": [
                513
            ],
            "customFixedAssetsData": [
                513
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsSpreadResponse": {
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "classificationTag": [
                251
            ],
            "bankTransaction": [
                167
            ],
            "businessEventType": [
                1
            ],
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "accrualId": [
                1
            ],
            "bankTransactionId": [
                12
            ],
            "isRemainingExpenses": [
                5
            ],
            "isResidualAmount": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsStatus": {},
        "AcknowledgeLoansChanges": {
            "loans": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "AcknowledgeLoansChangesInput": {
            "loans": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AckPhysicalCountInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "diff": [
                10
            ],
            "closingBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AckPhysicalCountResult": {
            "on_BooleanResult": [
                221
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "ActionChange": {
            "accountRole": [
                1
            ],
            "moneyDirection": [
                25
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeInput": {
            "accountRole": [
                1
            ],
            "moneyDirection": [
                25
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeMoneyDirection": {},
        "ActorContextInput": {
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                398
            ],
            "metadata": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "ActorType": {},
        "AddBankTransactionAttachmentInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "attachmentLink": [
                1
            ],
            "attachmentName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonus": {
            "date": [
                398
            ],
            "percent": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonusInput": {
            "date": [
                1
            ],
            "percent": [
                10
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AddPriorYearAdjustmentInput": {
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "Address": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddressArgs": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityInput": {
            "companyId": [
                12
            ],
            "sourceEntityOneOf": [
                44
            ],
            "role": [
                276
            ],
            "metadata": [
                35
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityMetadataInput": {
            "creatorEmail": [
                1
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityResult": {
            "createdEntityId": [
                12
            ],
            "affiliatedEntity": [
                42
            ],
            "__typename": [
                1
            ]
        },
        "AdjustmentRestockingAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1603
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Int": {},
        "AdjustmentsValidation": {
            "adjustments": [
                1208
            ],
            "cogs": [
                1208
            ],
            "source": [
                1208
            ],
            "diff": [
                1208
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AdjustmentType": {},
        "AffectedTransactionsIds": {
            "transactionsIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntity": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "institutions": [
                718
            ],
            "name": [
                1
            ],
            "companyAffiliates": [
                271
            ],
            "holders": [
                692
            ],
            "relatedParties": [
                1241
            ],
            "lenders": [
                975
            ],
            "employees": [
                464
            ],
            "contractors": [
                322
            ],
            "vendors": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntityByIdInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AffiliateSourceInput": {
            "affiliatedEntityId": [
                12
            ],
            "companyAffiliateId": [
                12
            ],
            "vendorId": [
                12
            ],
            "institutionId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AllowClassificationForVersion": {},
        "AlterBankTransactionsLifecycleStateInput": {
            "companyId": [
                12
            ],
            "bankTransactionIds": [
                12
            ],
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateResult": {
            "processedIds": [
                12
            ],
            "unprocessed": [
                48
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateUnprocessedResult": {
            "id": [
                12
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableMarketplace": {
            "displayName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataResponse": {
            "integrationAccountIdsData": [
                52
            ],
            "__typename": [
                1
            ]
        },
        "AmazonIntegrationAccountIdAvailableData": {
            "availableMarketplaces": [
                49
            ],
            "shipmentsReportEndTime": [
                398
            ],
            "returnsReportEndTime": [
                398
            ],
            "integrationAccountId": [
                1
            ],
            "impactOnBooksSince": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "AmazonOrdersDiffResponse": {
            "data": [
                842
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsReconciliationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsValidationReadyResponse": {
            "checkStartTime": [
                398
            ],
            "checkEndTime": [
                398
            ],
            "salesReports": [
                1247
            ],
            "restocksReports": [
                1247
            ],
            "data": [
                65
            ],
            "summary": [
                64
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityData": {
            "restocked": [
                38
            ],
            "mfn": [
                38
            ],
            "afn": [
                38
            ],
            "totalSold": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityEntityValidationStatus": {
            "restockedDiff": [
                38
            ],
            "afnDiff": [
                38
            ],
            "mfnDiff": [
                38
            ],
            "mcfDiff": [
                38
            ],
            "reportDiff": [
                38
            ],
            "totalDiff": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductData": {
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "unitsGiveaway": [
                38
            ],
            "ambiguousSkuQuantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductValidationStatus": {
            "soldDiff": [
                38
            ],
            "restockedDiff": [
                38
            ],
            "totalDiff": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationRestockedReportData": {
            "mcf": [
                38
            ],
            "afn": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesOrderIds": {
            "recentInvoices": [
                1
            ],
            "oldInvoices": [
                1
            ],
            "notInTimeRange": [
                1
            ],
            "mfn": [
                1
            ],
            "afn": [
                1
            ],
            "all": [
                1
            ],
            "restocked": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesReportData": {
            "recentInvoices": [
                38
            ],
            "oldInvoices": [
                38
            ],
            "notInTimeRange": [
                38
            ],
            "mcf": [
                38
            ],
            "afn": [
                38
            ],
            "total": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSummary": {
            "entityData": [
                57
            ],
            "entityValidationStatus": [
                58
            ],
            "salesReportData": [
                63
            ],
            "restocksReportData": [
                61
            ],
            "productData": [
                59
            ],
            "productValidationStatus": [
                60
            ],
            "__typename": [
                1
            ]
        },
        "AmazonSkuReconciliationData": {
            "comments": [
                1
            ],
            "sku": [
                1
            ],
            "orderIds": [
                62
            ],
            "summary": [
                64
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationAdjustSkuQuantitiesInput": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "sku": [
                1
            ],
            "reportQuantity": [
                38
            ],
            "finaloopQuantity": [
                38
            ],
            "orderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationInput": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                12
            ],
            "marketplaceId": [
                1
            ],
            "ignoreCached": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AmountRange": {
            "from": [
                10
            ],
            "to": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AmountRangeInput": {
            "from": [
                10
            ],
            "to": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "Amounts": {
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "AnalyticsOverviewInput": {
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "AppendProcessingLogToBankTransactionsUploadInput": {
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "processingLog": [
                193
            ],
            "status": [
                196
            ],
            "__typename": [
                1
            ]
        },
        "AppliedCostItem": {
            "finaloopProductId": [
                1
            ],
            "cost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ApplyPendingWarehouseOpeningBalanceInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonth": {
            "type": [
                77
            ],
            "integrationType": [
                1
            ],
            "count": [
                38
            ],
            "sum": [
                10
            ],
            "month": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonthResponse": {
            "payments": [
                75
            ],
            "refunds": [
                75
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsType": {},
        "AskTheUserFlowType": {},
        "AskTheUserNotificationInput": {
            "companyId": [
                12
            ],
            "notificationType": [
                81
            ],
            "notificationData": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationServiceResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationType": {},
        "AskTheUserResult": {
            "type": [
                1
            ],
            "data": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "AssembledByMap": {
            "id": [
                1
            ],
            "assembled": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AssemblyDeletedReason": {},
        "AssignCompanyToUser": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "roles": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AssignCompanyToUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURL": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURLS": {
            "signedUrls": [
                87
            ],
            "__typename": [
                1
            ]
        },
        "AttachRelationsToLoanInput": {
            "ids": [
                12
            ],
            "loanId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "AttachTransactionToCompanyLoanTransactionInput": {
            "transactionId": [
                1
            ],
            "classifications": [
                338
            ],
            "__typename": [
                1
            ]
        },
        "AttemptToClassifyTransactionsInput": {
            "classificationRequests": [
                249
            ],
            "actorContext": [
                26
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupInput": {
            "email": [
                1
            ],
            "accessKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupResponse": {
            "exists": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AwaitedExportResponse": {
            "requestId": [
                1
            ],
            "status": [
                485
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BalanceAdjustment": {
            "value": [
                10
            ],
            "forDate": [
                1
            ],
            "actorType": [
                98
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BalanceLocation": {
            "balance": [
                10
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccount": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                12
            ],
            "source": [
                754
            ],
            "externalSourceId": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "type": [
                125
            ],
            "connectionStatus": [
                101
            ],
            "subtype": [
                123
            ],
            "currency": [
                1
            ],
            "currentBalance": [
                10
            ],
            "institution": [
                718
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "displayName": [
                1
            ],
            "mask": [
                1
            ],
            "connectedAt": [
                398
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "locks": [
                105
            ],
            "rawData": [
                843
            ],
            "closingBalanceLogs": [
                256
            ],
            "balanceAdjustments": [
                95
            ],
            "latestAccountingEventIds": [
                1
            ],
            "transactionsImportStatus": [
                124
            ],
            "deletedByUserAt": [
                398
            ],
            "deletedByUserId": [
                1
            ],
            "createdAt": [
                398
            ],
            "createdBy": [
                1
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "vendor": [
                1583
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                5
            ],
            "lockStatus": [
                108,
                {
                    "year": [
                        38
                    ]
                }
            ],
            "machineValidationData": [
                927
            ],
            "accountValidation": [
                9
            ],
            "markedAsClosedByCustomerAt": [
                398
            ],
            "status": [
                121
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "isManagedByFinaloop": [
                5
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                5
            ],
            "transactionsCount": [
                38,
                {
                    "fromDate": [
                        398
                    ],
                    "untilDate": [
                        398
                    ]
                }
            ],
            "minimumTransactionDate": [
                398,
                {
                    "fromBooksStartTime": [
                        5
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountBalanceAdjustmentActorType": {},
        "BankAccountBalanceInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "balanceDateInISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountClosingBalanceActorType": {},
        "BankAccountConnectionStatus": {},
        "BankAccountEventIdsInput": {
            "bankAccountId": [
                12
            ],
            "eventIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInternalInput": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLock": {
            "type": [
                1
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "lockedAt": [
                398
            ],
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockByTypeInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "lockedAt": [
                398
            ],
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "lockedAt": [
                398
            ],
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "type": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockStatus": {
            "lockedAt": [
                398
            ],
            "minimumAllowedEventTime": [
                398
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwner": {
            "type": [
                110
            ],
            "id": [
                12
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwnerType": {},
        "BankAccountReconciliation": {
            "id": [
                12
            ],
            "bankAccount": [
                97
            ],
            "companyId": [
                1
            ],
            "status": [
                113
            ],
            "transactionsFileLocations": [
                501
            ],
            "statementFileLocations": [
                501
            ],
            "balanceLocation": [
                96
            ],
            "reconciliationDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationStatus": {},
        "BankAccountsByIdsInput": {
            "companyId": [
                12
            ],
            "bankAccountIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountsInput": {
            "companyId": [
                12
            ],
            "sources": [
                754
            ],
            "statuses": [
                121
            ],
            "types": [
                125
            ],
            "subtypes": [
                123
            ],
            "withDisabled": [
                5
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementFileType": {},
        "BankAccountStatementRequest": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "bankAccount": [
                141
            ],
            "type": [
                120
            ],
            "subType": [
                119
            ],
            "customerData": [
                142
            ],
            "finaloopData": [
                146
            ],
            "status": [
                118
            ],
            "fileNames": [
                1
            ],
            "displayedBalance": [
                10
            ],
            "csvUploads": [
                376
            ],
            "balanceId": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                398
            ],
            "lockedStatus": [
                923
            ],
            "originalBankAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementRequestStatus": {},
        "BankAccountStatementRequestSubType": {},
        "BankAccountStatementRequestType": {},
        "BankAccountStatus": {},
        "BankAccountStopImportNewOwner": {
            "id": [
                1
            ],
            "type": [
                110
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountSubtype": {},
        "BankAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                398
            ],
            "stopImportFromDate": [
                398
            ],
            "balanceAtStopImportFromDate": [
                10
            ],
            "newOwner": [
                122
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountType": {},
        "BankConnection": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "institution": [
                718
            ],
            "integrationAccountId": [
                12
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "isTest": [
                5
            ],
            "accounts": [
                127
            ],
            "isRefreshRequired": [
                5
            ],
            "isDisconnected": [
                5
            ],
            "syncStatus": [
                133
            ],
            "impactOnBooksSince": [
                398
            ],
            "deletedAt": [
                398
            ],
            "deletedBy": [
                1
            ],
            "status": [
                132
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccount": {
            "id": [
                12
            ],
            "minimumTransactionDate": [
                398,
                {
                    "fromBooksStartTime": [
                        5
                    ]
                }
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "institution": [
                718
            ],
            "integrationType": [
                754
            ],
            "integrationAccountId": [
                12
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "mask": [
                1
            ],
            "type": [
                125
            ],
            "subtype": [
                123
            ],
            "currentBalance": [
                10
            ],
            "status": [
                121
            ],
            "connectionStatus": [
                129
            ],
            "transactionsImportStatus": [
                130
            ],
            "isManual": [
                5
            ],
            "isRefreshRequired": [
                5
            ],
            "isDisconnected": [
                5
            ],
            "deletedAt": [
                398
            ],
            "deletedBy": [
                1
            ],
            "createdAt": [
                398
            ],
            "createdBy": [
                1
            ],
            "connectedAt": [
                398
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                5
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "currency": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                398
            ],
            "locks": [
                128
            ],
            "isManagedByFinaloop": [
                5
            ],
            "accountValidation": [
                9
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountLock": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountStatus": {},
        "BankConnectionAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                398
            ],
            "stopImportFromDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionsInput": {
            "companyId": [
                12
            ],
            "withDeleted": [
                5
            ],
            "sources": [
                754
            ],
            "statuses": [
                121
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionStatus": {},
        "BankConnectionSyncStatus": {
            "lastSuccessfulTransactionsUpdate": [
                398
            ],
            "lastFailedTransactionsUpdate": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankDetails": {
            "name": [
                1
            ],
            "lastFourDigits": [
                1
            ],
            "type": [
                1
            ],
            "missingBank": [
                5
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequest": {
            "id": [
                12
            ],
            "bankAccount": [
                97
            ],
            "companyId": [
                1
            ],
            "requestType": [
                163
            ],
            "fiscalYear": [
                38
            ],
            "finaloopMessage": [
                1
            ],
            "finaloopMessageSender": [
                1
            ],
            "expectedEndDate": [
                398
            ],
            "customerSelectedDate": [
                398
            ],
            "booksBalanceAtDate": [
                10
            ],
            "customerBalanceByStatement": [
                10
            ],
            "customerConfirmedBooksBalanceAtDate": [
                5
            ],
            "resolutionType": [
                157
            ],
            "irrelevantReason": [
                156
            ],
            "transferredEntity": [
                158
            ],
            "defaultEndDate": [
                398
            ],
            "status": [
                161
            ],
            "isBankAccountValid": [
                5
            ],
            "latestActorId": [
                1
            ],
            "latestActorType": [
                27
            ],
            "statementFiles": [
                1229
            ],
            "deletedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequestFilesStatus": {},
        "BankReconciliationRequestInput": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequestsInput": {
            "ids": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementAccountHolder": {
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementAccountHolderInput": {
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementBankAccount": {
            "id": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "name": [
                1
            ],
            "type": [
                125
            ],
            "subtype": [
                123
            ],
            "institutionName": [
                1
            ],
            "institutionId": [
                1
            ],
            "currency": [
                1
            ],
            "mask": [
                1
            ],
            "source": [
                754
            ],
            "transactionsImportStatus": [
                1444
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementCustomerData": {
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "noCSVFiles": [
                5
            ],
            "noPDFFiles": [
                5
            ],
            "displayedBalance": [
                10
            ],
            "realBalance": [
                10
            ],
            "cycleStart": [
                398
            ],
            "cycleEnd": [
                398
            ],
            "isConfirmed": [
                5
            ],
            "irrelevantReason": [
                1
            ],
            "irrelevantType": [
                834
            ],
            "isMachineReconciled": [
                5
            ],
            "newOwner": [
                1
            ],
            "newOwnerType": [
                110
            ],
            "newOwnerId": [
                1
            ],
            "newOwnerName": [
                1
            ],
            "bankDetails": [
                134
            ],
            "transferDate": [
                398
            ],
            "closedDate": [
                398
            ],
            "relatedMissionId": [
                1
            ],
            "isIntegrationTransfers": [
                5
            ],
            "isClosedThisYear": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementFileSubSystem": {},
        "BankStatementFilesValidationProvider": {},
        "BankStatementFileSystem": {},
        "BankStatementFinaloopData": {
            "comment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsedTransaction": {
            "date": [
                1
            ],
            "description": [
                1
            ],
            "amount": [
                10
            ],
            "runningBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsedTransactionInput": {
            "date": [
                1
            ],
            "description": [
                1
            ],
            "amount": [
                10
            ],
            "runningBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequest": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                151
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                38
            ],
            "requestPayload": [
                843
            ],
            "runStatus": [
                152
            ],
            "rawResponse": [
                843
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "responseTimeMs": [
                10
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequestInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                151
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                38
            ],
            "requestPayload": [
                843
            ],
            "runStatus": [
                152
            ],
            "rawResponse": [
                843
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "responseTimeMs": [
                10
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingRequestsProvider": {},
        "BankStatementParsingRequestsRunStatus": {},
        "BankStatementParsingResult": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankStatementParsingRequestId": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                151
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                38
            ],
            "status": [
                155
            ],
            "institutionName": [
                1
            ],
            "accountName": [
                1
            ],
            "mask": [
                1
            ],
            "openingBalance": [
                10
            ],
            "closingBalance": [
                10
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "isReconciled": [
                5
            ],
            "isReconciledByProvider": [
                5
            ],
            "transactions": [
                147
            ],
            "accountHolders": [
                139
            ],
            "transactionsCount": [
                38
            ],
            "totalTransactionsAmount": [
                10
            ],
            "balanceChange": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingResultsInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankStatementParsingRequestId": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                151
            ],
            "providerModel": [
                1
            ],
            "queryVersion": [
                38
            ],
            "institutionName": [
                1
            ],
            "accountName": [
                1
            ],
            "mask": [
                1
            ],
            "openingBalance": [
                10
            ],
            "closingBalance": [
                10
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "isReconciled": [
                5
            ],
            "isReconciledByProvider": [
                5
            ],
            "transactions": [
                148
            ],
            "accountHolders": [
                140
            ],
            "transactionsCount": [
                38
            ],
            "totalTransactionsAmount": [
                10
            ],
            "balanceChange": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementParsingResultStatus": {},
        "BankStatementReconciliationIrrelevantReason": {},
        "BankStatementReconciliationResolutionType": {},
        "BankStatementReconciliationTransferredEntity": {},
        "BankStatementRequestResolutionInput": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "resolutionType": [
                157
            ],
            "irrelevantReason": [
                156
            ],
            "customerConfirmedBooksBalanceAtDate": [
                5
            ],
            "customerBalanceByStatement": [
                10
            ],
            "customerSelectedDate": [
                398
            ],
            "statementFiles": [
                1395
            ],
            "forceSubmit": [
                5
            ],
            "fileIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementRequestResolutionResponse": {
            "status": [
                161
            ],
            "submissionStatus": [
                162
            ],
            "fileValidationStatus": [
                136
            ],
            "fileIds": [
                1
            ],
            "errors": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementRequestStatus": {},
        "BankStatementRequestSubmissionStatus": {},
        "BankStatementRequestType": {},
        "BankStatementValidationResults": {
            "fileId": [
                12
            ],
            "runStatus": [
                165
            ],
            "failureReason": [
                1
            ],
            "matchConfidence": [
                10
            ],
            "matchedComponents": [
                166
            ],
            "institution": [
                1
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "openingBalance": [
                10
            ],
            "closingBalance": [
                10
            ],
            "accountNumber": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementValidationRunStatus": {},
        "BankStatementVerificationComponents": {},
        "BankTransaction": {
            "id": [
                12
            ],
            "accrual": [
                11
            ],
            "companyId": [
                12
            ],
            "bankAccount": [
                97
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                10
            ],
            "merchant": [
                947
            ],
            "businessEvent": [
                169
            ],
            "classificationMetadata": [
                247
            ],
            "description": [
                1
            ],
            "processedByMachineAt": [
                398
            ],
            "lastProcessedByMachineAt": [
                398
            ],
            "applyingClassificationJobId": [
                1
            ],
            "source": [
                1
            ],
            "sourceSpecificData": [
                843
            ],
            "userMemo": [
                180
            ],
            "attachments": [
                168
            ],
            "classificationVersion": [
                38
            ],
            "deletedAt": [
                398
            ],
            "loan": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionAttachment": {
            "signedUrl": [
                1
            ],
            "url": [
                1
            ],
            "name": [
                1
            ],
            "addedBy": [
                1
            ],
            "createdAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEvent": {
            "classifiedAt": [
                398
            ],
            "classifierType": [
                1
            ],
            "classifiedBy": [
                1
            ],
            "classifications": [
                170
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEventClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                10
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                843
            ],
            "askTheUserResult": [
                82
            ],
            "pairedEntity": [
                1078
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchant": {
            "groupId": [
                12
            ],
            "merchant": [
                947
            ],
            "transactions": [
                167
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchantResult": {
            "groups": [
                171
            ],
            "total": [
                38
            ],
            "nextToken": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationInput": {
            "bankTransactionId": [
                12
            ],
            "merchant": [
                950
            ],
            "classifications": [
                176
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResult": {
            "bankTransaction": [
                167
            ],
            "status": [
                175
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResultStatus": {},
        "BankTransactionClassificationInput": {
            "amount": [
                10
            ],
            "classificationTagId": [
                12
            ],
            "classificationText": [
                12
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "askTheUserResult": [
                843
            ],
            "extraData": [
                843
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionClassifierType": {},
        "BankTransactionCursorInput": {
            "offset": [
                38
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionInput": {
            "companyId": [
                12
            ],
            "transactionId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionMemo": {
            "content": [
                1
            ],
            "updatedAt": [
                398
            ],
            "updatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingInput": {
            "pairedEntityType": [
                1079
            ],
            "pairedEntityId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingResult": {
            "pairedEntityType": [
                1079
            ],
            "pairedEntityId": [
                1
            ],
            "bankTransactions": [
                167
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionResponse": {
            "bankTransactions": [
                167
            ],
            "nextOffset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByMerchantInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "dateRange": [
                400
            ],
            "merchantIds": [
                12
            ],
            "merchantIdsOperator": [
                505
            ],
            "nextToken": [
                12
            ],
            "limit": [
                38
            ],
            "searchTerm": [
                1
            ],
            "amountRange": [
                69
            ],
            "absoluteAmounts": [
                5
            ],
            "moneyDirection": [
                968
            ],
            "searchMerchantsByName": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByPairingsInput": {
            "pairings": [
                181
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsInput": {
            "companyId": [
                12
            ],
            "bankTransactionIds": [
                12
            ],
            "bankAccountIds": [
                12
            ],
            "bankAccountIdsOperator": [
                505
            ],
            "businessEvents": [
                1
            ],
            "businessEventsOperator": [
                505
            ],
            "searchTerm": [
                1
            ],
            "endDate": [
                398
            ],
            "startDate": [
                398
            ],
            "amountLte": [
                10
            ],
            "amountGte": [
                10
            ],
            "checkAgainstOriginalAmount": [
                5
            ],
            "absoluteAmounts": [
                5
            ],
            "source": [
                754
            ],
            "merchantIds": [
                12
            ],
            "searchMerchantsByName": [
                5
            ],
            "merchantIdsOperator": [
                505
            ],
            "withMerchantIdsAsPairedEntities": [
                5
            ],
            "onlyPendingUserInput": [
                5
            ],
            "moneyDirection": [
                968
            ],
            "cursor": [
                178
            ],
            "excludePairedEntityTypes": [
                1079
            ],
            "showOnlySpreadTransactions": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsResponse": {
            "bankTransactions": [
                167
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSort": {
            "closeToAmount": [
                10
            ],
            "closeToDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSummary": {
            "amountInUsd": [
                10
            ],
            "count": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUpload": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "bankAccount": [
                97
            ],
            "fileLocation": [
                191
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                27
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                398
            ],
            "processingLogs": [
                192
            ],
            "status": [
                196
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadFileLocation": {
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLog": {
            "processorType": [
                195
            ],
            "processorId": [
                1
            ],
            "createdAt": [
                398
            ],
            "state": [
                194
            ],
            "metadata": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogInput": {
            "processorType": [
                195
            ],
            "processorId": [
                1
            ],
            "state": [
                194
            ],
            "message": [
                1
            ],
            "metadata": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogState": {},
        "BankTransactionsUploadProcessorType": {},
        "BankTransactionsUploadStatus": {},
        "BankTransactionToLinkInput": {
            "transactionId": [
                12
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionUploadsInput": {
            "companyId": [
                12
            ],
            "bankAccountIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BankTxSaleTransaction": {
            "id": [
                12
            ],
            "integrationInfo": [
                736
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                1445
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BaseAffiliatedEntity": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationAccountRoleAmount": {
            "amount": [
                10
            ],
            "accountRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationData": {
            "method": [
                928
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "entityData": [
                204
            ],
            "booksData": [
                204
            ],
            "status": [
                206
            ],
            "isGreen": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationInventoryBalanceData": {
            "inventory": [
                10
            ],
            "inventoryInProcess": [
                10
            ],
            "inventoryInTransit": [
                10
            ],
            "inventoryInTransitInternal": [
                10
            ],
            "legacyInventory": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationPeriodData": {
            "cogs": [
                201
            ],
            "purchases": [
                201
            ],
            "endOfYearAdjustments": [
                10
            ],
            "inventoryEndingBalance": [
                203
            ],
            "inventoryOpeningBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationResponse": {
            "data": [
                202
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationStatus": {
            "openingBalance": [
                5
            ],
            "cogs": [
                5
            ],
            "methodChangeAdjustments": [
                5
            ],
            "endOfYearAdjustments": [
                5
            ],
            "endingBalance": [
                5
            ],
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Bill": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "billNumber": [
                1
            ],
            "currency": [
                1
            ],
            "amount": [
                10
            ],
            "dueDate": [
                398
            ],
            "createdDate": [
                398
            ],
            "deletedAt": [
                398
            ],
            "vendor": [
                1583
            ],
            "categories": [
                240
            ],
            "balance": [
                10,
                {
                    "input": [
                        210
                    ]
                }
            ],
            "payments": [
                215
            ],
            "indicativePayments": [
                215
            ],
            "credits": [
                211
            ],
            "adjustments": [
                208
            ],
            "refunds": [
                217
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustment": {
            "id": [
                12
            ],
            "type": [
                209
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "actionTime": [
                398
            ],
            "eventTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustmentType": {},
        "BillBalanceInput": {
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BillCredit": {
            "creditDate": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "Billing": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BillingPayment": {
            "on_RecurringPayment": [
                1235
            ],
            "on_OneTimePayment": [
                999
            ],
            "__typename": [
                1
            ]
        },
        "BillItemToReconcile": {
            "companyId": [
                12
            ],
            "billId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BillPayment": {
            "id": [
                12
            ],
            "paymentDate": [
                398
            ],
            "amount": [
                10
            ],
            "feeAmount": [
                10
            ],
            "amountInUSD": [
                10
            ],
            "currency": [
                377
            ],
            "vendor": [
                1583
            ],
            "settlementType": [
                216
            ],
            "voidedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BillPaymentSettlementType": {},
        "BillRefund": {
            "id": [
                12
            ],
            "refundDate": [
                398
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "BillsAgingReportInput": {
            "companyId": [
                12
            ],
            "balanceAsOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "BillsDueDateSummary": {
            "total": [
                459
            ],
            "totalBalanceDue": [
                459
            ],
            "pastDue": [
                459
            ],
            "dueNext7Days": [
                459
            ],
            "dueAfterNext7Days": [
                459
            ],
            "__typename": [
                1
            ]
        },
        "BillsDueDateSummaryInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BooleanResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfiguration": {
            "id": [
                12
            ],
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "accrualConfig": [
                840
            ],
            "cashConfig": [
                840
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfigurationByType": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventCategorizationInput": {
            "classifications": [
                246
            ],
            "shouldApplyAlways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                10
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                843
            ],
            "askTheUserResult": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinition": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "customerDisplayName": [
                1
            ],
            "groups": [
                1
            ],
            "vendorRequired": [
                5
            ],
            "irrelevantForVendor": [
                5
            ],
            "hideForManualClassification": [
                5
            ],
            "forceShowInEventsListings": [
                5
            ],
            "moneyDirection": [
                968
            ],
            "customerDescriptionTemplate": [
                1
            ],
            "isRelevantToAccruals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByGroups": {
            "groups": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByIds": {
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventInput": {
            "classifications": [
                225
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventItemToReconcile": {
            "companyId": [
                12
            ],
            "businessEventId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventRuleType": {},
        "BusinessEventsAccrualsConfig": {
            "id": [
                1
            ],
            "isRelevantToAccruals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CalculateInvoiceInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "companyAddressId": [
                12
            ],
            "customerId": [
                12
            ],
            "shippingAddressId": [
                12
            ],
            "billingAddressId": [
                12
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                398
            ],
            "dueDate": [
                398
            ],
            "taxFixedAmount": [
                10
            ],
            "lineItems": [
                1261
            ],
            "discounts": [
                451
            ],
            "paymentMethodIds": [
                12
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventInput": {
            "eventId": [
                1
            ],
            "inviteeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventResponse": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CancelAccrualInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "canceledAtData": [
                238
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAccrualData": {
            "classificationTagId": [
                12
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1394
            ],
            "residualAmount": [
                10
            ],
            "specificDate": [
                1387
            ],
            "spreadOverTime": [
                1392
            ],
            "customSpread": [
                388
            ],
            "defaultFixedAssetsData": [
                512
            ],
            "customFixedAssetsData": [
                512
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAtDataItem": {
            "canceledAt": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "businessEventType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CategorizeBankTransactionsInput": {
            "companyId": [
                12
            ],
            "categorizations": [
                173
            ],
            "__typename": [
                1
            ]
        },
        "Category": {
            "description": [
                1
            ],
            "amount": [
                10
            ],
            "classificationTag": [
                251
            ],
            "__typename": [
                1
            ]
        },
        "CategoryBreakdown": {
            "category": [
                258
            ],
            "amount": [
                10
            ],
            "costType": [
                330
            ],
            "__typename": [
                1
            ]
        },
        "ChangeProductsHiddenStatusInput": {
            "companyId": [
                12
            ],
            "productStatusChange": [
                1194
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataInput": {
            "companyId": [
                12
            ],
            "chartName": [
                1
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "breakdown": [
                1
            ],
            "timezone": [
                1
            ],
            "comparisonPeriod": [
                1
            ],
            "view": [
                1
            ],
            "stores": [
                1
            ],
            "saleRank": [
                38
            ],
            "quantityView": [
                38
            ],
            "returnRateRank": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataResponse": {
            "title": [
                1
            ],
            "updatedAt": [
                398
            ],
            "category": [
                1
            ],
            "data": [
                397
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CheckAwaitedExportStatusInput": {
            "companyId": [
                12
            ],
            "requestId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                10
            ],
            "description": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadata": {
            "enrichmentRuleType": [
                465
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                231
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                5
            ],
            "actorType": [
                27
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataInput": {
            "enrichmentRuleType": [
                465
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                231
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                5
            ],
            "metadata": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRequestInput": {
            "id": [
                12
            ],
            "merchant": [
                950
            ],
            "businessEvent": [
                229
            ],
            "allowClassificationForVersion": [
                45
            ],
            "classificationMetadata": [
                248
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationResult": {
            "current": [
                167
            ],
            "previous": [
                167
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTag": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "moneyDirection": [
                968
            ],
            "isHidden": [
                5
            ],
            "isRelevantToBills": [
                5
            ],
            "isRelevantToApplyAlways": [
                5
            ],
            "displayNameForReports": [
                1
            ],
            "keywords": [
                1
            ],
            "classification": [
                252
            ],
            "isApplicableForRefund": [
                5
            ],
            "weight": [
                38
            ],
            "reportsDisplayNameForTagSelection": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagClassification": {
            "in": [
                1
            ],
            "out": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsByIdsInput": {
            "ids": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsInput": {
            "forBillsOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ClassifyIntegrationTransferInput": {
            "companyId": [
                12
            ],
            "classifications": [
                743
            ],
            "__typename": [
                1
            ]
        },
        "ClosingBalance": {
            "value": [
                10
            ],
            "forDate": [
                1
            ],
            "actorType": [
                100
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "COGSBreakdownItem": {
            "product": [
                1170
            ],
            "quantity": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "totalCogs": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "CogsCategory": {},
        "COGSEvent": {
            "id": [
                1
            ],
            "groupName": [
                1
            ],
            "period": [
                260
            ],
            "amount": [
                10
            ],
            "salesChannel": [
                1266
            ],
            "lineOfBusinessId": [
                1
            ],
            "memo": [
                1
            ],
            "type": [
                264
            ],
            "source": [
                261
            ],
            "isFullyProcessed": [
                5
            ],
            "costType": [
                330
            ],
            "categoryBreakdown": [
                241
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventPeriod": {
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventSource": {},
        "CogsSource": {},
        "COGSSummaryV2": {
            "groupName": [
                1
            ],
            "type": [
                264
            ],
            "sum": [
                10
            ],
            "salesChannel": [
                1266
            ],
            "realtimeSum": [
                10
            ],
            "manualSum": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "CogsType": {},
        "CommonAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CommonWarehouseTransfersFilterInput": {
            "companyId": [
                1
            ],
            "productIds": [
                1
            ],
            "shippingDate": [
                1633
            ],
            "arrivalDate": [
                1633
            ],
            "warehouse": [
                1607
            ],
            "textSearch": [
                1
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Company": {
            "id": [
                12
            ],
            "billing": [
                284
            ],
            "name": [
                1
            ],
            "userName": [
                1
            ],
            "refferalCode": [
                1
            ],
            "source": [
                843
            ],
            "email": [
                1
            ],
            "isManufacturer": [
                5
            ],
            "phoneNumber": [
                1
            ],
            "address": [
                32
            ],
            "accountingMethod": [
                6
            ],
            "booksStartTime": [
                843
            ],
            "dataRestrictionTime": [
                398
            ],
            "customerSelectedAccountingMethod": [
                385
            ],
            "inventorySettings": [
                785
            ],
            "ordersSettings": [
                1064
            ],
            "generalDetails": [
                843
            ],
            "configuration": [
                286
            ],
            "isTestCompany": [
                5
            ],
            "isArchived": [
                5
            ],
            "createdAtMs": [
                10
            ],
            "createdAtIso": [
                1
            ],
            "canceledAtIso": [
                1
            ],
            "preloadEmail": [
                1
            ],
            "eventsLock": [
                843
            ],
            "eventsLock2": [
                468
            ],
            "legalEntityType": [
                1
            ],
            "taxEntityType": [
                1
            ],
            "trialStartTimeIso": [
                1
            ],
            "trialStartTimeMs": [
                10
            ],
            "trialDurationInDays": [
                38
            ],
            "isPaying": [
                5
            ],
            "fullUiAccess": [
                5
            ],
            "paymentStatus": [
                1
            ],
            "tier": [
                1
            ],
            "tierAtConversion": [
                1
            ],
            "taxPlan": [
                1
            ],
            "stripeCustomerId": [
                12
            ],
            "isOnboardingCompleted": [
                5
            ],
            "onboardingState": [
                993
            ],
            "isParkerReferral": [
                5
            ],
            "estimatedProjectedRevenue": [
                467
            ],
            "calculatedTier": [
                305
            ],
            "hasActiveTrial": [
                5
            ],
            "lockStatus": [
                302
            ],
            "isLiveTestCompany": [
                5
            ],
            "gtmSource": [
                683
            ],
            "fiscalYearStart": [
                288
            ],
            "lockHistory": [
                301
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAccountValidation": {
            "account": [
                1
            ],
            "status": [
                315
            ],
            "value": [
                10
            ],
            "isError": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddress": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isDefault": [
                5
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "state": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddressInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliate": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "type": [
                276
            ],
            "name": [
                1
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "logoUrl": [
                1
            ],
            "holdingsInPercentage": [
                10
            ],
            "isForeignResident": [
                5
            ],
            "balance": [
                10
            ],
            "transactions": [
                275
            ],
            "status": [
                699
            ],
            "affiliatedEntityId": [
                12
            ],
            "latestAccountingEventIds": [
                1
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesByIdsInput": {
            "ids": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesInput": {
            "companyId": [
                12
            ],
            "type": [
                276
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateTransaction": {
            "entityId": [
                12
            ],
            "entityType": [
                1
            ],
            "bankAccountId": [
                1
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                10
            ],
            "type": [
                1
            ],
            "source": [
                754
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateType": {},
        "CompanyArCashDiffsActionsResponse": {
            "accountRole": [
                1
            ],
            "amount": [
                10
            ],
            "isAccrual": [
                5
            ],
            "isCash": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsFixbooksResponse": {
            "eventTime": [
                398
            ],
            "createdAt": [
                398
            ],
            "creatorEmail": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                277
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsInput": {
            "companyId": [
                12
            ],
            "unpaidBalanceAsOf": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsOrdersResponse": {
            "orderId": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "displayName": [
                1
            ],
            "ordersVsBooks": [
                10
            ],
            "ordersBalance": [
                10
            ],
            "booksBalance": [
                10
            ],
            "placedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsResponse": {
            "companyId": [
                12
            ],
            "reportDate": [
                398
            ],
            "orders": [
                280
            ],
            "fixbooks": [
                278
            ],
            "totals": [
                282
            ],
            "lockTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashTotalsResponse": {
            "arCashInBooks": [
                10
            ],
            "unpaidOrders": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArtificialFulfillmentSettingsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanyBilling": {
            "features": [
                487
            ],
            "purchasedOffers": [
                1198
            ],
            "portalUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyByStripeCustomerIdInput": {
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyConfiguration": {
            "isPnlReportReady": [
                5
            ],
            "isBalanceSheetReportFinal": [
                5
            ],
            "includeCCAccountsInCashFlowBeforePayment": [
                5
            ],
            "includeUDFAccountsInCashFlowBeforeDeposit": [
                5
            ],
            "cashFlowStartTimeISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyEventsLockHistory": {
            "year": [
                38
            ],
            "createdAt": [
                398
            ],
            "userEmail": [
                1
            ],
            "type": [
                924
            ],
            "__typename": [
                1
            ]
        },
        "CompanyFiscalYearStart": {
            "day": [
                38
            ],
            "month": [
                38
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatus": {
            "company": [
                267
            ],
            "domains": [
                311
            ],
            "status": [
                315
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatusSortDirection": {},
        "CompanyIdAndStripeCustomerId": {
            "companyId": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIdInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegration": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "integrationAccountId": [
                12
            ],
            "integrationTypeAndIntegrationAccountId": [
                1
            ],
            "data": [
                842
            ],
            "enabled": [
                5
            ],
            "impactOnBooksSince": [
                1
            ],
            "createdAtMs": [
                10
            ],
            "deletedByUserAt": [
                1
            ],
            "deletedByUserId": [
                1
            ],
            "disconnectedAt": [
                1
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationsInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoan": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "type": [
                908
            ],
            "lender": [
                975
            ],
            "currency": [
                377
            ],
            "postedAt": [
                398
            ],
            "direction": [
                879
            ],
            "isShortTerm": [
                5
            ],
            "amount": [
                10
            ],
            "totalAmount": [
                10
            ],
            "outstandingAmount": [
                10
            ],
            "accruedInterest": [
                10
            ],
            "unrecognizedFees": [
                10
            ],
            "prepaymentLiabilityAmount": [
                10
            ],
            "status": [
                905
            ],
            "userActionStatus": [
                912
            ],
            "acknowledgedInternallyAt": [
                398
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                398
            ],
            "attachments": [
                867
            ],
            "workingPaperLink": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lastSeenByClientAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "interestDays": [
                886
            ],
            "repaymentType": [
                899
            ],
            "repaymentFrequency": [
                898
            ],
            "repaymentRate": [
                10
            ],
            "interestRateType": [
                887
            ],
            "interestRate": [
                10
            ],
            "gracePeriod": [
                38
            ],
            "catchUpPeriod": [
                38
            ],
            "catchUpPercent": [
                10
            ],
            "originationFees": [
                10
            ],
            "prepaymentFees": [
                10
            ],
            "recurringDataFrequency": [
                896
            ],
            "instantRepaymentMethod": [
                885
            ],
            "isIndexLinked": [
                5
            ],
            "interestType": [
                888
            ],
            "variableInterestUpdatePeriod": [
                915
            ],
            "variableInterestSpread": [
                10
            ],
            "variableInterestBase": [
                914
            ],
            "compoundFrequency": [
                875
            ],
            "indexType": [
                884
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                461
            ],
            "transactions": [
                167
            ],
            "lastTransaction": [
                167
            ],
            "userFollowUp2": [
                1558
            ],
            "askIfRepaid": [
                5
            ],
            "hideOutdatedWarning": [
                5
            ],
            "deleteOptionMode": [
                297
            ],
            "pendingDelete": [
                5
            ],
            "pendingRestoreAcknowledgement": [
                5
            ],
            "lastUserMoveToProcessingAt": [
                398
            ],
            "dataRequestType": [
                878
            ],
            "isHidden": [
                5
            ],
            "lastEventDate": [
                398
            ],
            "isBillPaymentEventAttached": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoanDeleteOptionMode": {},
        "CompanyLoanResponse": {
            "loan": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoansResponse": {
            "loans": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockConditions": {
            "accountingMethod": [
                1
            ],
            "moneyIn": [
                922
            ],
            "moneyOut": [
                922
            ],
            "reclassificationMoneyIn": [
                922
            ],
            "reclassificationMoneyOut": [
                922
            ],
            "assetsEqualToLiabilitiesAndEquities": [
                922
            ],
            "isPayrollOk": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockHistory": {
            "type": [
                916
            ],
            "userEmail": [
                1
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "createdAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockStatus": {
            "minimumAllowedEventTime": [
                398
            ],
            "lockedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyMainHolderInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CompanySyncBodyInput": {
            "id": [
                12
            ],
            "booksStartTime": [
                398
            ],
            "deletedAt": [
                398
            ],
            "name": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "isTestCompany": [
                5
            ],
            "booksStatus": [
                842
            ],
            "isArchived": [
                5
            ],
            "archivedAtIso": [
                398
            ],
            "createdAt": [
                398
            ],
            "tier": [
                306
            ],
            "trialStartTime": [
                398
            ],
            "trialDurationInDays": [
                38
            ],
            "isPaying": [
                5
            ],
            "eventsLock": [
                842
            ],
            "__typename": [
                1
            ]
        },
        "CompanyTier": {},
        "CompanyTierType": {},
        "CompanyUser": {
            "email": [
                1
            ],
            "roles": [
                1572
            ],
            "name": [
                1
            ],
            "invitationStatus": [
                811
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersArgs": {
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersResponse": {
            "users": [
                307
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidation": {
            "type": [
                316
            ],
            "metricName": [
                313
            ],
            "name": [
                1
            ],
            "status": [
                315
            ],
            "accounts": [
                268
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomain": {
            "type": [
                312
            ],
            "name": [
                1
            ],
            "status": [
                315
            ],
            "validations": [
                310
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomainType": {},
        "CompanyValidationMetricName": {},
        "CompanyValidationSortByValidationInput": {
            "validationType": [
                316
            ],
            "metricName": [
                313
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationStatus": {},
        "CompanyValidationType": {},
        "CompanyWorksheet": {
            "worksheetRows": [
                318
            ],
            "company": [
                267
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRow": {
            "companyId": [
                1
            ],
            "dateForLocking": [
                398
            ],
            "name": [
                1
            ],
            "amount": [
                10
            ],
            "comment": [
                1
            ],
            "signedAt": [
                398
            ],
            "year": [
                38
            ],
            "attachmentUrl": [
                1
            ],
            "lockableEntity": [
                319
            ],
            "periodStart": [
                398
            ],
            "periodEnd": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRowLockableEntity": {
            "on_BankAccount": [
                97
            ],
            "__typename": [
                1
            ]
        },
        "CompleteValidationCycleInput": {
            "validationCycleId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmRoleForPaymentGatewayInput": {
            "companyId": [
                12
            ],
            "paymentGatewayType": [
                1087
            ],
            "__typename": [
                1
            ]
        },
        "Contractor": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "department": [
                443
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "businessName": [
                1
            ],
            "isActive": [
                5
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPayment": {
            "id": [
                1
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "reimbursements": [
                1240
            ],
            "externalSourceId": [
                1
            ],
            "department": [
                443
            ],
            "postedAt": [
                398
            ],
            "currency": [
                1
            ],
            "wage": [
                10
            ],
            "wageInHomeCurrency": [
                10
            ],
            "bonus": [
                10
            ],
            "bonusInHomeCurrency": [
                10
            ],
            "categoryGroup": [
                1126
            ],
            "paymentChecksAmount": [
                10
            ],
            "isPaymentChecksAmountImmaterial": [
                5
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyInput": {
            "companyIds": [
                12
            ],
            "afterBooksStartTimeOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyItem": {
            "companyId": [
                1
            ],
            "contractorPaymentIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentIdsByCompanyResponse": {
            "items": [
                325
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentsInternalInput": {
            "companyId": [
                12
            ],
            "contractorPaymentIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ContractorPaymentsInternalResponse": {
            "contractorPayments": [
                323
            ],
            "__typename": [
                1
            ]
        },
        "CopyBankAccountInput": {
            "sourceBankAccountId": [
                1
            ],
            "newBankAccountId": [
                1
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "status": [
                121
            ],
            "__typename": [
                1
            ]
        },
        "CostType": {},
        "CreateBankAccountInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                754
            ],
            "externalSourceId": [
                1
            ],
            "type": [
                125
            ],
            "subtype": [
                123
            ],
            "connectionStatus": [
                101
            ],
            "lastUpdateTime": [
                398
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "currency": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "displayName": [
                1
            ],
            "overrideFullName": [
                1
            ],
            "mask": [
                1
            ],
            "currentBalance": [
                10
            ],
            "connectedAt": [
                398
            ],
            "rawData": [
                843
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "status": [
                121
            ],
            "createdByActor": [
                27
            ],
            "createdBy": [
                1
            ],
            "createdFromSource": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankAccountStatementForStopImportInput": {
            "bankAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "stopImportFromDate": [
                1
            ],
            "displayedBalance": [
                10
            ],
            "closedDate": [
                1
            ],
            "isConfirmed": [
                5
            ],
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "realBalance": [
                10
            ],
            "newOwner": [
                109
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankTransactionsUploadInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                27
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                398
            ],
            "skipMachineTrigger": [
                5
            ],
            "originFileId": [
                12
            ],
            "fileStatus": [
                196
            ],
            "uploadedOnBankTransactionId": [
                12
            ],
            "fileUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionInput": {
            "companyId": [
                12
            ],
            "paymentOptionIds": [
                12
            ],
            "successUrl": [
                1
            ],
            "cancelUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyAffiliateInput": {
            "companyId": [
                12
            ],
            "name": [
                1
            ],
            "type": [
                276
            ],
            "isForeignResident": [
                5
            ],
            "holdingsInPercentage": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyInput": {
            "email": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "source": [
                842
            ],
            "referralCode": [
                1
            ],
            "isParkerReferral": [
                5
            ],
            "withoutNotification": [
                5
            ],
            "gtmSource": [
                684
            ],
            "appsSelectedInPreOnboarding": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionClassificationInput": {
            "amount": [
                10
            ],
            "classificationTagId": [
                12
            ],
            "classificationText": [
                12
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairingType": [
                1080
            ],
            "askTheUserResult": [
                843
            ],
            "extraData": [
                843
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionMerchantInput": {
            "id": [
                1
            ],
            "type": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanInput": {
            "companyId": [
                12
            ],
            "data": [
                909
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerArgs": {
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                386
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                33
            ],
            "shippingAddress": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleInput": {
            "companyId": [
                12
            ],
            "applyForAlreadyCategorizedTransactions": [
                5
            ],
            "merchant": [
                950
            ],
            "moneyDirection": [
                1373
            ],
            "classification": [
                383
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CreateFileUploadUrlInput": {
            "companyId": [
                1
            ],
            "source": [
                503
            ],
            "fileName": [
                1
            ],
            "featureContext": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalRuleResult": {
            "id": [
                12
            ],
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1592
            ],
            "subTypes": [
                1595
            ],
            "is1099Contractor": [
                5
            ],
            "websiteUrl": [
                1
            ],
            "source": [
                1593
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorRuleInput": {
            "vendorIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateImageUploadUrlInput": {
            "companyId": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionInput": {
            "name": [
                1
            ],
            "preferredProvider": [
                723
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionMappingsInput": {
            "source": [
                722
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateIntegrationTransferRuleInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "companyAffiliateId": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationInput": {
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "unpaidBillsUntil": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanEventDefinitionInput": {
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBalanceTransactionInput": {
            "companyId": [
                12
            ],
            "balanceTransactionId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBillFinancedInput": {
            "companyId": [
                12
            ],
            "billId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanInput": {
            "companyId": [
                12
            ],
            "data": [
                910
            ],
            "merchantRole": [
                276
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoansEventsEffectConfigurationInput": {
            "eventDefinitionId": [
                12
            ],
            "loanType": [
                908
            ],
            "isShortTerm": [
                5
            ],
            "direction": [
                879
            ],
            "minGracePeriod": [
                38
            ],
            "repaymentType": [
                899
            ],
            "lenderId": [
                12
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "accrualConfig": [
                841
            ],
            "cashConfig": [
                841
            ],
            "loanOutstandingAmountAffect": [
                893
            ],
            "loanAmountAffect": [
                866
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "events": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput2": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "eventDefinitionId": [
                12
            ],
            "amount": [
                10
            ],
            "memo": [
                1
            ],
            "postedAt": [
                398
            ],
            "spreadInput": [
                362
            ],
            "recurrenceSettings": [
                1234
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInputEvents": {
            "eventDefinitionId": [
                12
            ],
            "amount": [
                10
            ],
            "memo": [
                1
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventSpreadInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrGetBusinessEventDefinitionInput": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "accrualConfig": [
                841
            ],
            "cashConfig": [
                841
            ],
            "originalActorId": [
                1
            ],
            "originalActorType": [
                27
            ],
            "integrationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "accrualsData": [
                16
            ],
            "categorizations": [
                13
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualResult": {
            "bankTransaction": [
                167
            ],
            "status": [
                18
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreatePhysicalCountAttachmentInput": {
            "name": [
                1
            ],
            "url": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreatePhysicalCountAttachmentResult": {
            "on_PhysicalCountAttachment": [
                1162
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyInput": {
            "source": [
                1189
            ],
            "integrationAccountId": [
                1
            ],
            "companyId": [
                1
            ],
            "displayName": [
                1
            ],
            "sku": [
                1
            ],
            "imageUrl": [
                1
            ],
            "isFractional": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyResponse": {
            "on_CreateProductManuallyError": [
                368
            ],
            "on_CreateProductManuallySuccess": [
                371
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallySuccess": {
            "product": [
                1170
            ],
            "__typename": [
                1
            ]
        },
        "CreateTeamCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CreateUserResponse": {
            "user": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "CreateVendorArgs": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1592
            ],
            "subTypes": [
                1595
            ],
            "is1099Contractor": [
                5
            ],
            "contactEmail": [
                1
            ],
            "source": [
                1593
            ],
            "externalSourceId": [
                1
            ],
            "isPaymentVendor": [
                5
            ],
            "labels": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateWarehouseInput": {
            "companyId": [
                1
            ],
            "source": [
                1629
            ],
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CSVUpload": {
            "name": [
                1
            ],
            "uploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CurrencyCode": {},
        "CurrentlyRunningInventoryDataSyncInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CurrentlyRunningInventoryDataSyncResponse": {
            "totalItems": [
                38
            ],
            "completedItems": [
                38
            ],
            "updatingProducts": [
                1170
            ],
            "__typename": [
                1
            ]
        },
        "Customer": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "companyId": [
                12
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                386
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                381
            ],
            "shippingAddress": [
                381
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddress": {
            "id": [
                12
            ],
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "isShipping": [
                5
            ],
            "isBilling": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddressArgs": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "zip": [
                1
            ],
            "source": [
                386
            ],
            "__typename": [
                1
            ]
        },
        "CustomerCategorizationRuleClassificationInput": {
            "classificationTagId": [
                12
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityType": [
                1079
            ],
            "pairedEntityId": [
                1
            ],
            "pairingType": [
                1080
            ],
            "__typename": [
                1
            ]
        },
        "CustomerNotExistError": {
            "message": [
                1
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "CustomerSelectedAccountingMethod": {},
        "CustomerSource": {},
        "CustomerV2": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "billingAddress": [
                381
            ],
            "shippingAddress": [
                381
            ],
            "addresses": [
                381
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpread": {
            "specificDate": [
                1387
            ],
            "spreadOverTime": [
                1392
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpreadInput": {
            "specificDate": [
                1388
            ],
            "spreadOverTime": [
                1393
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedOwnersDraw": {
            "employeeId": [
                12
            ],
            "employeeName": [
                1
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPaymentCheck": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "employeeId": [
                12
            ],
            "employmentType": [
                1139
            ],
            "categoryGroup": [
                1126
            ],
            "isImmaterial": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPayrollData": {
            "date": [
                398
            ],
            "salariesAndWages": [
                10
            ],
            "bonus": [
                10
            ],
            "commission": [
                10
            ],
            "correctionPayment": [
                10
            ],
            "tips": [
                10
            ],
            "holidayPay": [
                10
            ],
            "sickLeave": [
                10
            ],
            "severance": [
                10
            ],
            "ownersDraw": [
                10
            ],
            "vacationPay": [
                10
            ],
            "ficaEmployee": [
                10
            ],
            "ficaEmployer": [
                10
            ],
            "federalEmployee": [
                10
            ],
            "federalEmployer": [
                10
            ],
            "stateAndLocalEmployee": [
                10
            ],
            "stateAndLocalEmployer": [
                10
            ],
            "savingsAndRetirement401kEmployee": [
                10
            ],
            "savingsAndRetirement401kEmployer": [
                10
            ],
            "savingsAndRetirementOtherEmployee": [
                10
            ],
            "savingsAndRetirementOtherEmployer": [
                10
            ],
            "healthEmployee": [
                10
            ],
            "healthEmployer": [
                10
            ],
            "transportationEmployee": [
                10
            ],
            "transportationEmployer": [
                10
            ],
            "otherBenefitsEmployee": [
                10
            ],
            "otherBenefitsEmployer": [
                10
            ],
            "childSupport": [
                10
            ],
            "otherGarnishments": [
                10
            ],
            "otherDeductions": [
                10
            ],
            "grossPay": [
                10
            ],
            "netPay": [
                10
            ],
            "employerCost": [
                10
            ],
            "employmentType": [
                1139
            ],
            "categoryGroup": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedReimbursement": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "employeeId": [
                12
            ],
            "isClearedByNetPayPayment": [
                5
            ],
            "paymentMethod": [
                1
            ],
            "employmentType": [
                1139
            ],
            "categoryGroup": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByContractor": {
            "contractor": [
                322
            ],
            "payments": [
                10
            ],
            "reimbursements": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByEmployee": {
            "employee": [
                464
            ],
            "netPay": [
                10
            ],
            "grossPay": [
                10
            ],
            "employeeTaxes": [
                10
            ],
            "deductionsAndBenefits": [
                10
            ],
            "reimbursements": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "DangerousTouchLoanInput": {
            "loanId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DataSerie": {
            "label": [
                1
            ],
            "values": [
                10
            ],
            "unitGroup": [
                1
            ],
            "valueType": [
                1582
            ],
            "previous": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Date": {},
        "DateRange": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "DateRangeInput": {
            "from": [
                398
            ],
            "to": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryCogs": {
            "amount": [
                10
            ],
            "quantity": [
                10
            ],
            "monthlyBreakdown": [
                402
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryMonthlyBreakdown": {
            "date": [
                398
            ],
            "quantity": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "DeleteAccrualInput": {
            "bankTransactionId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankAccountsInput": {
            "companyId": [
                1
            ],
            "bankAccountIds": [
                12
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankConnectionInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionAttachmentInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "attachmentUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionsByBankAccountIdsInput": {
            "companyId": [
                12
            ],
            "bankAccountIds": [
                12
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteCompanyAffiliateInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderCapitalInvestmentInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderShareInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInventoryPurchaseInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "lastUpdatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInvoicesResponse": {
            "deleted": [
                12
            ],
            "errors": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanAttachmentInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "attachmentId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanInput": {
            "loanId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanManualAccountingEventsInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "accountingEventIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanRelationshipsInput": {
            "relationshipIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationInput": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansInput": {
            "loans": [
                414
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansResults": {
            "loans": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualAssemblyInput": {
            "companyId": [
                12
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "eventId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventsInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "eventIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupInput": {
            "companyId": [
                12
            ],
            "orderGroupId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "orderGroupId": [
                1
            ],
            "changedBankTransactionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupInput": {
            "companyId": [
                12
            ],
            "paymentGroupId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "paymentGroupId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePendingWarehouseOpeningAttachmentInput": {
            "companyId": [
                12
            ],
            "attachmentId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DeletePhysicalCountAttachmentInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePhysicalCountAttachmentResult": {
            "on_PhysicalCountAttachment": [
                1162
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "DeletePhysicalCountInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePhysicalCountResult": {
            "on_PhysicalCount": [
                1161
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "DeleteProductsInput": {
            "companyId": [
                12
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePurchaseOrderInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteRecipeInput": {
            "companyId": [
                12
            ],
            "recipeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseOpeningBalancesInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Department": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DepreciationMethod": {},
        "DirectLineItem": {
            "id": [
                12
            ],
            "product": [
                1170
            ],
            "quantity": [
                38
            ],
            "vendor": [
                1583
            ],
            "indirectCostsAllocation": [
                712
            ],
            "directCostPerUnit": [
                10
            ],
            "indirectCostPerUnit": [
                10
            ],
            "remainderIndirectCostPerUnit": [
                10
            ],
            "discountCostPerUnit": [
                10
            ],
            "remainderDiscountCostPerUnit": [
                10
            ],
            "landedCostPerUnit": [
                10
            ],
            "remainderLandedCostPerUnit": [
                10
            ],
            "directCostTotal": [
                10
            ],
            "indirectCostTotal": [
                10
            ],
            "landedCostTotal": [
                10
            ],
            "discountCostTotal": [
                10
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DirectLineItemInput": {
            "id": [
                12
            ],
            "productId": [
                1
            ],
            "quantity": [
                38
            ],
            "vendorId": [
                12
            ],
            "directCostPerUnit": [
                10
            ],
            "indirectCostsAllocation": [
                713
            ],
            "indirectCostPerUnit": [
                10
            ],
            "remainderIndirectCostPerUnit": [
                10
            ],
            "discountCostPerUnit": [
                10
            ],
            "remainderDiscountCostPerUnit": [
                10
            ],
            "landedCostPerUnit": [
                10
            ],
            "remainderLandedCostPerUnit": [
                10
            ],
            "directCostTotal": [
                10
            ],
            "indirectCostTotal": [
                10
            ],
            "landedCostTotal": [
                10
            ],
            "discountCostTotal": [
                10
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscardPendingSalesDataAdjustments": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DiscardPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DisconnectConnectionInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                754
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Discount": {
            "id": [
                12
            ],
            "type": [
                452
            ],
            "amount": [
                10
            ],
            "description": [
                1
            ],
            "totalAmount": [
                10
            ],
            "discountType": [
                848
            ],
            "__typename": [
                1
            ]
        },
        "DiscountInput": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "type": [
                452
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscountType": {},
        "DonationCogs": {
            "amount": [
                10
            ],
            "quantity": [
                38
            ],
            "monthlyBreakdown": [
                454
            ],
            "__typename": [
                1
            ]
        },
        "DonationMonthlyBreakdown": {
            "date": [
                398
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "attachmentId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentResult": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoInput": {
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoResult": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DueDateSummary": {
            "count": [
                38
            ],
            "sum": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "DuplicateCheckResult": {},
        "EarlyRepaymentDiscount": {
            "period": [
                38
            ],
            "fee": [
                10
            ],
            "periodType": [
                462
            ],
            "__typename": [
                1
            ]
        },
        "EarlyRepaymentDiscountPeriodType": {},
        "EarlyRepaymentDiscountUpdate": {
            "period": [
                38
            ],
            "fee": [
                10
            ],
            "periodType": [
                462
            ],
            "__typename": [
                1
            ]
        },
        "Employee": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "department": [
                443
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "isOfficer": [
                5
            ],
            "isTerminated": [
                5
            ],
            "jobs": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "EnrichmentRuleType": {},
        "Error": {
            "message": [
                1
            ],
            "on_CustomerNotExistError": [
                384
            ],
            "on_VendorNotExistError": [
                1589
            ],
            "__typename": [
                1
            ]
        },
        "EstimatedProjectedRevenue": {},
        "EventsLock": {
            "minimumAllowedEventTime": [
                398
            ],
            "lockHistory": [
                287
            ],
            "__typename": [
                1
            ]
        },
        "ExcludedSalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoResult": {
            "warehouseMovements": [
                1614
            ],
            "__typename": [
                1
            ]
        },
        "ExportBalanceSheetApiArgs": {
            "companyId": [
                12
            ],
            "isGoogleExport": [
                5
            ],
            "fromTimeIso": [
                398
            ],
            "toTimeIso": [
                398
            ],
            "noDecimals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowApiArgs": {
            "companyId": [
                12
            ],
            "exportArgs": [
                474
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowArgs": {
            "timeRange": [
                1435
            ],
            "fileType": [
                502
            ],
            "groupByTimeWindow": [
                967
            ],
            "includeUDFAccounts": [
                5
            ],
            "includeCCAccounts": [
                5
            ],
            "breakdowns": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownRequest": {
            "companyId": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "type": [
                264
            ],
            "salesChannel": [
                1269
            ],
            "category": [
                258
            ],
            "sort": [
                544
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewRequest": {
            "companyId": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "filters": [
                546
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportInvoicesResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportLedgerAccountTransactionsInput": {
            "companyId": [
                12
            ],
            "exportArgs": [
                488
            ],
            "withBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansCsvInput": {
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                907
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                5
            ],
            "userActionStatuses": [
                912
            ],
            "includeDeleted": [
                5
            ],
            "loanType": [
                908
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansResult": {
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportProductsResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportResponse": {
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportStatus": {},
        "ExternalBalanceInput": {
            "integrationAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "Feature": {},
        "FetchLedgerAccountTransactionsInput": {
            "vendorId": [
                1
            ],
            "categoryId": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "categoryName": [
                1
            ],
            "accountName": [
                1
            ],
            "filterByVendor": [
                5
            ],
            "filterByCategory": [
                5
            ],
            "timeRange": [
                1001
            ],
            "accountingMethod": [
                1
            ],
            "isCashFlow": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsMetrics": {
            "type": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationType": [
                1
            ],
            "metrics": [
                490
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsSingleMetric": {
            "name": [
                1
            ],
            "fifo": [
                10
            ],
            "isValid": [
                5
            ],
            "be": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationCogsSummary": {
            "name": [
                1
            ],
            "fifoCogs": [
                10
            ],
            "isValidCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationInput": {
            "date": [
                398
            ],
            "companyId": [
                12
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductData": {
            "product": [
                1170
            ],
            "warehouse": [
                1601
            ],
            "baseMetrics": [
                499
            ],
            "cogsMetrics": [
                491
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductTotals": {
            "baseMetrics": [
                499
            ],
            "cogsMetrics": [
                491
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductValidationData": {
            "data": [
                493
            ],
            "totals": [
                494
            ],
            "totalBalance": [
                500
            ],
            "fifoCogsBusinessEventsMetrics": [
                489
            ],
            "fifoCogsBusinessEventsMetricsTotal": [
                490
            ],
            "notPropagatedBEIds": [
                1
            ],
            "redundantBEIds": [
                1
            ],
            "isUnitsValid": [
                5
            ],
            "isCogsValid": [
                5
            ],
            "isBusinessEventsValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationResponse": {
            "id": [
                1
            ],
            "validationData": [
                495
            ],
            "reportLatestDate": [
                398
            ],
            "reportPath": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationsListInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationsListResponse": {
            "id": [
                1
            ],
            "date": [
                398
            ],
            "generatedBy": [
                1
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationSummary": {
            "name": [
                1
            ],
            "source": [
                10
            ],
            "sourceCost": [
                10
            ],
            "fifo": [
                10
            ],
            "fifoCost": [
                10
            ],
            "fifoCogs": [
                10
            ],
            "isValidQuantity": [
                5
            ],
            "isValidCost": [
                5
            ],
            "isValidCogs": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationTotalsBalance": {
            "totalIncomingFromSource": [
                10
            ],
            "totalOnHandFromFifo": [
                10
            ],
            "totalCogs": [
                10
            ],
            "totalWaste": [
                10
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FileLocation": {
            "name": [
                1
            ],
            "key": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FileType": {},
        "FileUploadSource": {},
        "FileUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "key": [
                1
            ],
            "bucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FilterOperator": {},
        "FinalizeAskTheUserFlowInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "type": [
                78
            ],
            "snapshot": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "FinalizeAskTheUserFlowResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProduct": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProductInternalInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopTagsInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "isNew": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FinancialReportingStatus": {},
        "FixedAssetsData": {
            "usefulLifeStartDate": [
                398
            ],
            "depreciationMethod": [
                444
            ],
            "residualValue": [
                10
            ],
            "section179Deduction": [
                1299
            ],
            "addDepreciationBonus": [
                29
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FixedAssetsDataInput": {
            "usefulLifeStartDate": [
                1
            ],
            "depreciationMethod": [
                444
            ],
            "residualValue": [
                10
            ],
            "section179Deduction": [
                1300
            ],
            "addDepreciationBonus": [
                30
            ],
            "startDate": [
                1
            ],
            "endDate": [
                1
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FloatRange": {
            "min": [
                10
            ],
            "max": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FulfillLineItemInput": {
            "lineItemId": [
                12
            ],
            "fulfillmentDate": [
                398
            ],
            "quantity": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1603
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentCost": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1255
            ],
            "order": [
                1073
            ],
            "type": [
                518
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentCostType": {},
        "FulfillmentMethod": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "type": [
                521
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "type": [
                521
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodType": {},
        "FulfillmentSetting": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "methods": [
                519
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentSettingInput": {
            "companyId": [
                12
            ],
            "integrationAccountIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentsValidation": {
            "integrationData": [
                1208
            ],
            "adjustments": [
                1208
            ],
            "cogs": [
                1208
            ],
            "source": [
                1208
            ],
            "diff": [
                1208
            ],
            "isValid": [
                5
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateBigValidationInput": {
            "companyId": [
                12
            ],
            "to": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GenerateNoUnitsValidationResult": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfInput": {
            "companyId": [
                1
            ],
            "html": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponseV2": {
            "on_GeneratePdfResponse": [
                532
            ],
            "on_GeneratePdfError": [
                530
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsByTransactionIDsInput": {
            "companyId": [
                12
            ],
            "bankTransactionIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsSpreadInput": {
            "companyId": [
                12
            ],
            "bankTransactionIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetArtificialTransactionsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetAttachmentTargetURLSInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "fileNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementParsingRequestInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "provider": [
                151
            ],
            "queryVersion": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetBankStatementParsingResultsInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "status": [
                155
            ],
            "__typename": [
                1
            ]
        },
        "GetBillsInput": {
            "companyId": [
                12
            ],
            "dateTo": [
                398
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetBillsResponse": {
            "bills": [
                207
            ],
            "totalCount": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownRequest": {
            "companyId": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "type": [
                264
            ],
            "salesChannel": [
                1269
            ],
            "category": [
                258
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "sort": [
                544
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownResponse": {
            "from": [
                398
            ],
            "to": [
                398
            ],
            "type": [
                264
            ],
            "items": [
                257
            ],
            "totalCount": [
                38
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSort": {
            "field": [
                545
            ],
            "direction": [
                1193
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSortField": {},
        "GetCOGSOverviewFilters": {
            "source": [
                262
            ],
            "type": [
                264
            ],
            "costType": [
                330
            ],
            "category": [
                258
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "salesChannel": [
                1269
            ],
            "groupNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewRequest": {
            "companyId": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "filters": [
                546
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewResponse": {
            "from": [
                398
            ],
            "to": [
                398
            ],
            "summary": [
                263
            ],
            "summaryEvents": [
                259
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesByIdsInput": {
            "companyIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesInput": {
            "payingCompaniesOnly": [
                5
            ],
            "tiers": [
                305
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoanInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoansInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgs": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyWorksheetsArgs": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyWorksheetsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerArgs": {
            "searchTerm": [
                1
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerByIdInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerInternal": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerResponse": {
            "on_Customer": [
                380
            ],
            "on_CustomerNotExistError": [
                384
            ],
            "on_Error": [
                466
            ],
            "__typename": [
                1
            ]
        },
        "GetFiscalYerDataInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressInput": {
            "companyId": [
                1
            ],
            "entityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResponse": {
            "fromAddress": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResult": {
            "on_GetFromEmailAddressResponse": [
                565
            ],
            "on_GetFromEmailAddressError": [
                563
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentCostsByIdsInput": {
            "companyId": [
                12
            ],
            "fulfillmentCostIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingsResponse": {
            "settings": [
                734
            ],
            "__typename": [
                1
            ]
        },
        "GetGlobalVendorsInput": {
            "ids": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetHealthStatusInput": {
            "asOfDate": [
                398
            ],
            "createdAt": [
                398
            ],
            "sort": [
                691
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTagsInput": {
            "companyId": [
                12
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransferInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersBackofficeInput": {
            "companyId": [
                12
            ],
            "ids": [
                12
            ],
            "timeRange": [
                752
            ],
            "sort": [
                751
            ],
            "type": [
                753
            ],
            "limit": [
                38
            ],
            "integrationType": [
                754
            ],
            "amountRange": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersInput": {
            "companyId": [
                12
            ],
            "ids": [
                12
            ],
            "timeRange": [
                752
            ],
            "sort": [
                751
            ],
            "type": [
                753
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "integrationType": [
                754
            ],
            "checkAgainstOriginalAmount": [
                5
            ],
            "onlyUnidentified": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceInput": {
            "companyId": [
                12
            ],
            "pointInTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceResponse": {
            "inventoryAccountBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesByIdsInput": {
            "companyId": [
                12
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesExportInput": {
            "filters": [
                580
            ],
            "sort": [
                775
            ],
            "managementMethod": [
                928
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesFilters": {
            "companyId": [
                12
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "text": [
                1
            ],
            "status": [
                779
            ],
            "categoryName": [
                764
            ],
            "purchaseType": [
                782
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesForCompanyInput": {
            "companyId": [
                12
            ],
            "historicalPurchasesOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesInput": {
            "filters": [
                580
            ],
            "offset": [
                38
            ],
            "limit": [
                38
            ],
            "sort": [
                775
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchaseSummaryInput": {
            "companyId": [
                12
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "text": [
                1
            ],
            "status": [
                779
            ],
            "purchaseType": [
                782
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesValidationResult": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsInput": {
            "companyId": [
                12
            ],
            "affiliatedEntityId": [
                12
            ],
            "toDate": [
                398
            ],
            "unpaidBillsUntilDate": [
                398
            ],
            "text": [
                1
            ],
            "fromAmount": [
                10
            ],
            "toAmount": [
                10
            ],
            "itemTypes": [
                791
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsOverviewInput": {
            "companyId": [
                12
            ],
            "affiliatedEntityId": [
                12
            ],
            "toDate": [
                398
            ],
            "unpaidBillsUntilDate": [
                398
            ],
            "text": [
                1
            ],
            "fromAmount": [
                10
            ],
            "toAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorFilterValuesInput": {
            "companyId": [
                12
            ],
            "untilDate": [
                398
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorParamsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceInput": {
            "companyId": [
                12
            ],
            "affiliatedEntityId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "text": [
                1
            ],
            "fromAmount": [
                10
            ],
            "toAmount": [
                10
            ],
            "skipZeroAmount": [
                5
            ],
            "itemTypes": [
                801
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceOverviewInput": {
            "companyId": [
                12
            ],
            "affiliatedEntityId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "text": [
                1
            ],
            "fromAmount": [
                10
            ],
            "toAmount": [
                10
            ],
            "skipZeroAmount": [
                5
            ],
            "itemTypes": [
                801
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsInput": {
            "companyId": [
                12
            ],
            "text": [
                1
            ],
            "untilDate": [
                398
            ],
            "unpaidBillsUntilDate": [
                398
            ],
            "skipZeroRollingBalance": [
                5
            ],
            "negativeRollingBalance": [
                5
            ],
            "fromRollingBalance": [
                10
            ],
            "toRollingBalance": [
                10
            ],
            "sort": [
                806
            ],
            "offset": [
                38
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsOverviewInput": {
            "companyId": [
                12
            ],
            "text": [
                1
            ],
            "untilDate": [
                398
            ],
            "unpaidBillsUntilDate": [
                398
            ],
            "skipZeroRollingBalance": [
                5
            ],
            "negativeRollingBalance": [
                5
            ],
            "fromRollingBalance": [
                10
            ],
            "toRollingBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesAffectingPaymentGroupsInput": {
            "companyId": [
                1
            ],
            "invoiceIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesAffectingPaymentGroupsResponse": {
            "invoicesToPaymentGroups": [
                832
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoiceSettingsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesResult": {
            "invoices": [
                814
            ],
            "__typename": [
                1
            ]
        },
        "GetLatestValidationInput": {
            "companyId": [
                1
            ],
            "type": [
                316
            ],
            "integrationAccountId": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "account": [
                1
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetLatestValidationResult": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "filters": [
                882
            ],
            "page": [
                38
            ],
            "size": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "filters": [
                882
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthResult": {
            "length": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsResult": {
            "events": [
                880
            ],
            "page": [
                38
            ],
            "size": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanRelationshipsResult": {
            "relationships": [
                897
            ],
            "page": [
                38
            ],
            "size": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansByIdInput": {
            "loanIds": [
                12
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansForAllocateInput": {
            "companyId": [
                12
            ],
            "lenderId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansInput": {
            "page": [
                38
            ],
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                907
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                5
            ],
            "includeDeleted": [
                5
            ],
            "userActionStatuses": [
                912
            ],
            "sort": [
                904
            ],
            "loanType": [
                908
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateInput": {
            "companyId": [
                12
            ],
            "loansIds": [
                12
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateResult": {
            "loans": [
                894
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "eventDefinitionId": [
                12
            ],
            "loanInput": [
                610
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleLoanInput": {
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "spreadInput": [
                362
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleResult": {
            "effect": [
                901
            ],
            "memo": [
                1
            ],
            "dailyAccrual": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "GetNonMatchingProductsInput": {
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetOffersInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetOffersResponse": {
            "status": [
                990
            ],
            "offers": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusesInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusInput": {
            "companyId": [
                12
            ],
            "scope": [
                992
            ],
            "step": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrderFilteringBoundsResult": {
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "orderTotalP999": [
                10
            ],
            "maxItemsCount": [
                38
            ],
            "hasVoidedOrders": [
                5
            ],
            "hasNotSalesAndCogsOrders": [
                5
            ],
            "hasUncollectibleOrders": [
                5
            ],
            "hasCogsOnlyOrders": [
                5
            ],
            "hasGiveawayOrders": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersAggregationsInput": {
            "filter": [
                620
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersDateRangeInput": {
            "min": [
                398
            ],
            "max": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersFilter": {
            "productIds": [
                12
            ],
            "tagIds": [
                12
            ],
            "customerIds": [
                12
            ],
            "paymentStatuses": [
                1052
            ],
            "finaloopStatus": [
                1013
            ],
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "integrationAccountIds": [
                1
            ],
            "itemsCount": [
                514
            ],
            "totalAmountInHomeCurrency": [
                514
            ],
            "placedDate": [
                619
            ],
            "firstPaymentDate": [
                619
            ],
            "firstRefundDate": [
                619
            ],
            "firstFulfillmentDate": [
                619
            ],
            "unpaidAsOfDate": [
                398
            ],
            "hasRestockedItem": [
                5
            ],
            "allTransactionsMatched": [
                5
            ],
            "fulfillmentStatuses": [
                1017
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersInput": {
            "companyId": [
                12
            ],
            "filter": [
                620
            ],
            "sort": [
                623
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersResultV2": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSort": {
            "type": [
                625
            ],
            "direction": [
                624
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSortDirection": {},
        "GetOrdersSortType": {},
        "GetPaymentsByIdsInput": {
            "ids": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsFilter": {
            "attachmentIds": [
                12
            ],
            "attributes": [
                1177
            ],
            "searchText": [
                1
            ],
            "sku": [
                1
            ],
            "source": [
                1189
            ],
            "warehouseId": [
                1
            ],
            "dataType": [
                1147
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsInput": {
            "salesChannel": [
                1269
            ],
            "companyId": [
                12
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "endDate": [
                398
            ],
            "startDate": [
                398
            ],
            "sort": [
                630
            ],
            "filter": [
                627
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsOverviewInput": {
            "salesChannel": [
                1269
            ],
            "companyId": [
                12
            ],
            "endDate": [
                398
            ],
            "startDate": [
                398
            ],
            "filter": [
                627
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsSort": {
            "field": [
                631
            ],
            "direction": [
                1193
            ],
            "__typename": [
                1
            ]
        },
        "GetPendingSalesDataAdjustmentsSortField": {},
        "GetPendingWarehouseOpeningBalancesAttachmentsInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "displayDeleted": [
                5
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetPresignedUrlInput": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "year": [
                38
            ],
            "mimeType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetPresignedUrlInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "mimeType": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "GetProductInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsFilter": {
            "searchText": [
                1
            ],
            "sku": [
                1
            ],
            "ids": [
                1
            ],
            "source": [
                1
            ],
            "withoutCostOnly": [
                5
            ],
            "withNegativeOnHandOnly": [
                5
            ],
            "attributes": [
                1177
            ],
            "canEditCurrentAppliedCost": [
                5
            ],
            "onlyPrimaryProducts": [
                5
            ],
            "warehouseId": [
                1
            ],
            "asOfDate": [
                398
            ],
            "startDate": [
                398
            ],
            "notMatchingProducts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsInput": {
            "companyId": [
                12
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "sort": [
                638
            ],
            "filter": [
                636
            ],
            "extendedList": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSort": {
            "field": [
                639
            ],
            "direction": [
                1193
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSortField": {},
        "GetPurchaseOrderInput": {
            "companyId": [
                12
            ],
            "purchaseOrderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersInput": {
            "companyId": [
                12
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                5
            ],
            "searchField": [
                1
            ],
            "productIds": [
                1
            ],
            "status": [
                1207
            ],
            "fromInProgressDate": [
                398
            ],
            "toInProgressDate": [
                398
            ],
            "fromLandedCostTotal": [
                10
            ],
            "toLandedCostTotal": [
                10
            ],
            "sortField": [
                1206
            ],
            "sortDirection": [
                1386
            ],
            "showHistorical": [
                5
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersOverviewInput": {
            "companyId": [
                12
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                5
            ],
            "searchField": [
                1
            ],
            "productIds": [
                1
            ],
            "fromInProgressDate": [
                398
            ],
            "toInProgressDate": [
                398
            ],
            "fromLandedCostTotal": [
                10
            ],
            "toLandedCostTotal": [
                10
            ],
            "showHistorical": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetReadyValidationInput": {
            "companyId": [
                12
            ],
            "validationId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetReadyValidationResponse": {
            "url": [
                1
            ],
            "isGreen": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetRefundsByIdsInput": {
            "ids": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleAggregationsInput": {
            "companyId": [
                12
            ],
            "filter": [
                653
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleDateRangeInput": {
            "min": [
                398
            ],
            "max": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleFloatRangeInput": {
            "min": [
                10
            ],
            "max": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleIntRangeInput": {
            "min": [
                38
            ],
            "max": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsFilter": {
            "ids": [
                12
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsInput": {
            "companyId": [
                12
            ],
            "filter": [
                650
            ],
            "sort": [
                656
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelTemplatesInput": {
            "filter": [
                650
            ],
            "sort": [
                656
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesFilter": {
            "ids": [
                12
            ],
            "customerIds": [
                12
            ],
            "excludeCustomerIds": [
                12
            ],
            "withDeleted": [
                5
            ],
            "withoutVoided": [
                5
            ],
            "withoutDrafts": [
                5
            ],
            "salesChannelName": [
                1
            ],
            "search": [
                1
            ],
            "sources": [
                1283
            ],
            "dueInDays": [
                649
            ],
            "total": [
                648
            ],
            "balance": [
                648
            ],
            "netSales": [
                648
            ],
            "postedAt": [
                647
            ],
            "currencies": [
                377
            ],
            "excludeCurrencies": [
                377
            ],
            "paymentMethodIds": [
                12
            ],
            "productIds": [
                12
            ],
            "unpaidAsOfDate": [
                398
            ],
            "statuses": [
                1284
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesInput": {
            "companyId": [
                12
            ],
            "filter": [
                653
            ],
            "sort": [
                656
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "balanceAsOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesResult": {
            "sales": [
                1255
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSort": {
            "type": [
                658
            ],
            "direction": [
                657
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSortDirection": {},
        "GetSalesSortType": {},
        "GetSellingFeesByIdsInput": {
            "companyId": [
                12
            ],
            "sellingFeeIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetShippingCostsByIdsInput": {
            "companyId": [
                12
            ],
            "shippingCostIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetTax1099VendorGroupsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetValidationCycleInput": {
            "executionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetValidationTemplatesInput": {
            "validationType": [
                316
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorResponse": {
            "on_Vendor": [
                1583
            ],
            "on_VendorNotExistError": [
                1589
            ],
            "on_Error": [
                466
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorsInput": {
            "companyId": [
                12
            ],
            "ids": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalanceOverviewInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                12
            ],
            "missingData": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "attachmentIds": [
                12
            ],
            "productSource": [
                1189
            ],
            "productAttributes": [
                1177
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalancesInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "productSource": [
                1189
            ],
            "productAttributes": [
                1177
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                12
            ],
            "missingData": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "attachmentIds": [
                12
            ],
            "sort": [
                1621
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesDefaultsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesInput": {
            "companyId": [
                12
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransferInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransfersInput": {
            "filters": [
                266
            ],
            "status": [
                1661
            ],
            "sort": [
                1660
            ],
            "offset": [
                38
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayCogs": {
            "amount": [
                10
            ],
            "quantity": [
                38
            ],
            "monthlyBreakdown": [
                674
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayMonthlyBreakdown": {
            "date": [
                398
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleExistByVendorAndMoneyDirectionInput": {
            "vendorId": [
                1
            ],
            "moneyDirection": [
                968
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleInput": {
            "vendorId": [
                1
            ],
            "metadata": [
                843
            ],
            "outcome": [
                843
            ],
            "conditions": [
                843
            ],
            "logicalOperator": [
                1254
            ],
            "updatedAtMS": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "GlobalVendorMappingRule": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectionLinkInput": {
            "state": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectLinkResponse": {
            "link": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectStatusResponse": {
            "isConnected": [
                5
            ],
            "email": [
                1
            ],
            "sharedFolderUrl": [
                1
            ],
            "sharedFolderName": [
                1
            ],
            "accessToken": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeArgs": {
            "companyId": [
                12
            ],
            "code": [
                1
            ],
            "scope": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GTMSource": {
            "utms": [
                685
            ],
            "identifiers": [
                842
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceInput": {
            "utms": [
                686
            ],
            "identifiers": [
                842
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtms": {
            "firstUtms": [
                687
            ],
            "lastUtms": [
                687
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtmsInput": {
            "firstUtms": [
                688
            ],
            "lastUtms": [
                688
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtms": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtmsInput": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginArgs": {
            "source": [
                842
            ],
            "invitationCode": [
                1
            ],
            "gtmSource": [
                684
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginResponse": {
            "acceptedInviteCompanyInfo": [
                0
            ],
            "wasCompanyCreated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HealthStatusSortType": {
            "validation": [
                314
            ],
            "direction": [
                290
            ],
            "__typename": [
                1
            ]
        },
        "Holder": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestment": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "holder": [
                271
            ],
            "amount": [
                10
            ],
            "bankTransactionId": [
                1
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsByIdsInput": {
            "ids": [
                12
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "HolderShare": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "holder": [
                271
            ],
            "type": [
                1333
            ],
            "series": [
                1
            ],
            "amount": [
                10
            ],
            "parValue": [
                10
            ],
            "quantity": [
                38
            ],
            "bankTransactionId": [
                1
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesByIdsInput": {
            "ids": [
                12
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "HolderStatus": {},
        "HolderTransaction": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "holder": [
                271
            ],
            "bankTransaction": [
                167
            ],
            "type": [
                703
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsByIdsInput": {
            "ids": [
                12
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionType": {},
        "ImageUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "iconUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateArgs": {
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IncludedFinaloopSalesChannelItemReconciliation": {
            "salesChannel": [
                839
            ],
            "data": [
                708
            ],
            "summary": [
                1230
            ],
            "productItemSoldData": [
                1367
            ],
            "productStatus": [
                1363
            ],
            "unknownSkuQuantity": [
                38
            ],
            "unknownSkuZeroPriceQuantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "IncludedShopifySalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "salesChannel": [
                839
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1230
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchAnalytics": {
            "origin": [
                710
            ],
            "analytics": [
                711
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchOrigin": {
            "on_Shipment": [
                1334
            ],
            "on_WarehouseOpeningBalance": [
                1615
            ],
            "on_RecipeWithBreakdown": [
                1222
            ],
            "on_ManualAssemblyWithBreakdown": [
                933
            ],
            "on_WarehouseTransferWithBreakdown": [
                1664
            ],
            "__typename": [
                1
            ]
        },
        "IncomingInventoryAnalytics": {
            "assembled": [
                10
            ],
            "incoming": [
                10
            ],
            "fulfilled": [
                10
            ],
            "warehouseTransfers": [
                10
            ],
            "adjustments": [
                10
            ],
            "restocked": [
                10
            ],
            "onHand": [
                10
            ],
            "landedCostPerUnit": [
                10
            ],
            "onHandCost": [
                10
            ],
            "unavailable": [
                10
            ],
            "unavailableByAssemblies": [
                10
            ],
            "unavailableByWarehouseTransfersOut": [
                10
            ],
            "netDepleted": [
                10
            ],
            "openingBalanceQuantity": [
                10
            ],
            "openingBalanceCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocation": {
            "id": [
                12
            ],
            "indirectLineItem": [
                714
            ],
            "allocatedCostPerUnit": [
                10
            ],
            "remainderAllocationCostPerUnit": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocationInput": {
            "indirectLineItemId": [
                12
            ],
            "allocatedCostPerUnit": [
                10
            ],
            "remainderAllocationCostPerUnit": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItem": {
            "id": [
                12
            ],
            "type": [
                716
            ],
            "cost": [
                10
            ],
            "vendor": [
                1583
            ],
            "isDefaultVendor": [
                5
            ],
            "hasAutomaticAllocation": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemInput": {
            "id": [
                12
            ],
            "type": [
                716
            ],
            "cost": [
                10
            ],
            "vendorId": [
                12
            ],
            "isDefaultVendor": [
                5
            ],
            "hasAutomaticAllocation": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemType": {},
        "InitiateTotalArCashDiffsReportInput": {
            "unpaidBalanceAsOf": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "Institution": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                723
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "affiliatedEntityId": [
                12
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMapping": {
            "id": [
                12
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingResponse": {
            "mappings": [
                719
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingsInput": {
            "source": [
                722
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingSourceType": {},
        "InstitutionProviders": {},
        "InstitutionsInput": {
            "name": [
                1
            ],
            "searchTerm": [
                1
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionsResponse": {
            "institutions": [
                718
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionVendorID": {
            "vendorId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCompanyAddressInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionAccount": {
            "integrationType": [
                754
            ],
            "connectedAt": [
                398
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "impactOnBooksSince": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionsFilter": {
            "companyId": [
                12
            ],
            "connectionTypes": [
                730
            ],
            "integrationTypes": [
                754
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionType": {},
        "IntegrationCustomerAddressInput": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCustomerInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationDiscountInput": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "type": [
                452
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSetting": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "source": [
                754
            ],
            "methods": [
                519
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSettingsInput": {
            "integrationAccountId": [
                1
            ],
            "methods": [
                520
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationInfo": {
            "accountId": [
                12
            ],
            "accountName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleLineItemInput": {
            "id": [
                12
            ],
            "quantity": [
                10
            ],
            "unitPrice": [
                10
            ],
            "type": [
                1263
            ],
            "currency": [
                377
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                12
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                451
            ],
            "taxRate": [
                10
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleTransaction": {
            "id": [
                12
            ],
            "integrationInfo": [
                736
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                1445
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSource": {},
        "IntegrationTransactionInput": {
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfer": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "amount": [
                10
            ],
            "amountInUsd": [
                10
            ],
            "currency": [
                1
            ],
            "eventTime": [
                398
            ],
            "deletedAt": [
                398
            ],
            "integrationType": [
                754
            ],
            "pairedBankTransaction": [
                167
            ],
            "pairedCompanyAffiliate": [
                271
            ],
            "type": [
                753
            ],
            "classification": [
                742
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "on_Payout": [
                1124
            ],
            "on_Topup": [
                1439
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassification": {
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                843
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                745
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassificationInput": {
            "integrationTransferId": [
                12
            ],
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                843
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                746
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferGroup": {
            "groupId": [
                12
            ],
            "integrationTransfers": [
                741
            ],
            "payer": [
                748
            ],
            "payee": [
                748
            ],
            "isProcessing": [
                5
            ],
            "action": [
                1455
            ],
            "type": [
                753
            ],
            "tooltipText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntity": {
            "id": [
                12
            ],
            "type": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityInput": {
            "id": [
                12
            ],
            "type": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityType": {},
        "IntegrationTransferParty": {
            "on_UnknownIntegrationTransferBank": [
                1457
            ],
            "on_UnknownIntegrationTransferAccount": [
                1456
            ],
            "on_Vendor": [
                1583
            ],
            "on_BankAccount": [
                97
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferRule": {
            "id": [
                12
            ],
            "integrationType": [
                754
            ],
            "companyAffiliate": [
                271
            ],
            "integrationAccountId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersByCompanyAffiliateInput": {
            "companyId": [
                12
            ],
            "companyAffiliateId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersSort": {
            "closeToAmount": [
                10
            ],
            "closeToDate": [
                398
            ],
            "byDate": [
                1386
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferTimeRangeInput": {
            "from": [
                398
            ],
            "to": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferType": {},
        "IntegrationType": {},
        "InternalTransferCandidatesInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "allowNotExactMatch": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "internalTransferCandidatesV2Input": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "rangePercentageAmount": [
                10
            ],
            "postedAt": [
                400
            ],
            "allowNotExactDateMatch": [
                5
            ],
            "sort": [
                188
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "InventoryBatch": {
            "action": [
                1602
            ],
            "quantity": [
                38
            ],
            "directCost": [
                10
            ],
            "indirectCosts": [
                972
            ],
            "__typename": [
                1
            ]
        },
        "InventoryInitStatus": {
            "areProductsLoaded": [
                5
            ],
            "isSalesDataLoaded": [
                5
            ],
            "isDataReconciled": [
                5
            ],
            "hasPurchaseOrders": [
                5
            ],
            "hasWarehouseOpeningBalances": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchase": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                772
            ],
            "sourceEntity": [
                773
            ],
            "bankTransactionId": [
                12
            ],
            "billId": [
                12
            ],
            "amazonShippingInBusinessEventId": [
                12
            ],
            "payrollId": [
                12
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                766
            ],
            "dates": [
                399
            ],
            "affiliatedEntity": [
                42
            ],
            "currency": [
                1
            ],
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "historicalPurchaseAffectingMethodId": [
                12
            ],
            "categories": [
                762
            ],
            "items": [
                768
            ],
            "paymentStatus": [
                770
            ],
            "canceledAt": [
                398
            ],
            "lastUpdatedAt": [
                398
            ],
            "attachments": [
                760
            ],
            "paidAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategory": {
            "serialNumber": [
                38
            ],
            "categoryName": [
                764
            ],
            "purchaseType": [
                782
            ],
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "classificationTag": [
                251
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryInput": {
            "serialNumber": [
                38
            ],
            "categoryName": [
                764
            ],
            "purchaseType": [
                782
            ],
            "amount": [
                10
            ],
            "classificationTagId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryName": {},
        "InventoryPurchaseCategorySummary": {
            "categoryName": [
                764
            ],
            "purchasedAmountInHomeCurrency": [
                10
            ],
            "incidentalsAmountInHomeCurrency": [
                10
            ],
            "receivedAmountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseDirection": {},
        "InventoryPurchaseInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                766
            ],
            "dates": [
                400
            ],
            "vendorId": [
                12
            ],
            "affiliatedEntityId": [
                12
            ],
            "currency": [
                1
            ],
            "amount": [
                10
            ],
            "categories": [
                763
            ],
            "items": [
                769
            ],
            "paymentStatus": [
                770
            ],
            "lastUpdatedAt": [
                398
            ],
            "attachments": [
                761
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItem": {
            "amount": [
                10
            ],
            "shipmentDate": [
                398
            ],
            "receivedDate": [
                398
            ],
            "purchaseDate": [
                398
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItemInput": {
            "amount": [
                10
            ],
            "shipmentDate": [
                398
            ],
            "receivedDate": [
                398
            ],
            "purchaseDate": [
                398
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasePaymentStatus": {},
        "InventoryPurchasesExportResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseSource": {},
        "InventoryPurchaseSourceEntity": {
            "on_BankTransaction": [
                167
            ],
            "on_Bill": [
                207
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesResponse": {
            "purchases": [
                759
            ],
            "hasNext": [
                5
            ],
            "totalCount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSort": {
            "field": [
                776
            ],
            "direction": [
                777
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSortByField": {},
        "InventoryPurchasesSortDirection": {},
        "InventoryPurchasesSummaryResponse": {
            "summary": [
                765
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseStatus": {},
        "InventoryPurchasesValidationResult": {
            "checkTime": [
                398
            ],
            "expectedInProcessBalance": [
                10
            ],
            "expectedInTransitBalance": [
                10
            ],
            "actualInProcessBalance": [
                10
            ],
            "actualInTransitBalance": [
                10
            ],
            "clearingAccountBalance": [
                10
            ],
            "missingExpectedPurchaseIds": [
                12
            ],
            "unexpectedPurchaseIds": [
                12
            ],
            "missingExpectedBusinessEventsForPurchaseIds": [
                12
            ],
            "unexpectedBusinessEventIds": [
                12
            ],
            "status": [
                781
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesValidationStatus": {},
        "InventoryPurchaseType": {},
        "InventoryPurchaseUpdateOutOfDateError": {
            "inventoryPurchase": [
                759
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseValidationResultResponse": {
            "lastValidationResultCreatedAt": [
                398
            ],
            "result": [
                780
            ],
            "__typename": [
                1
            ]
        },
        "InventorySettings": {
            "companyId": [
                12
            ],
            "managementMethod": [
                1
            ],
            "rangedManagementMethod": [
                1210
            ],
            "isEligibleForNoClosingBalancePurchaseBased": [
                5
            ],
            "openingBalance": [
                10
            ],
            "latestOpeningBalanceBusinessEventId": [
                1
            ],
            "closingBalances": [
                843
            ],
            "latestClosingBalanceBusinessEventIds": [
                1
            ],
            "realtimeCogsStatus": [
                1213
            ],
            "hasUserReviewedProducts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryUsageType": {},
        "InventoryVendor": {
            "affiliatedEntity": [
                42
            ],
            "rollingBalance": [
                10
            ],
            "unpaidBills": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorAdjustment": {
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "bill": [
                207
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItem": {
            "amount": [
                10
            ],
            "balance": [
                10
            ],
            "paidAmount": [
                10
            ],
            "source": [
                790
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemSource": {
            "on_InventoryPurchase": [
                759
            ],
            "on_InventoryVendorPayment": [
                798
            ],
            "on_InventoryVendorCredit": [
                794
            ],
            "on_InventoryVendorAdjustment": [
                788
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemType": {},
        "InventoryVendorBillsOverviewResponse": {
            "billsAmount": [
                10
            ],
            "billPayments": [
                10
            ],
            "billCredits": [
                10
            ],
            "billAdjustments": [
                10
            ],
            "billsPurchasesAmount": [
                10
            ],
            "billsIncidentalPurchasesAmount": [
                10
            ],
            "asOfDate": [
                796
            ],
            "beforeUnitsTracking": [
                796
            ],
            "count": [
                38
            ],
            "minAmount": [
                10
            ],
            "maxAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillsResponse": {
            "hasNext": [
                5
            ],
            "count": [
                38
            ],
            "items": [
                789
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorCredit": {
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "bill": [
                207
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorFilterValuesResponse": {
            "maxRollingBalance": [
                10
            ],
            "minRollingBalance": [
                10
            ],
            "maxUnpaidBills": [
                10
            ],
            "minUnpaidBills": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorOverviewItem": {
            "amount": [
                10
            ],
            "payments": [
                10
            ],
            "credits": [
                10
            ],
            "adjustments": [
                10
            ],
            "purchasesAmount": [
                10
            ],
            "incidentalPurchasesAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorParamsResponse": {
            "inventoryVendorBillsCount": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorPayment": {
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "bill": [
                207
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItem": {
            "amount": [
                10
            ],
            "rollingBalance": [
                10
            ],
            "source": [
                800
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemSource": {
            "on_PurchaseOrder": [
                1199
            ],
            "on_InventoryPurchase": [
                759
            ],
            "on_WarehouseTransfer": [
                1634
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemTypes": {},
        "InventoryVendorRollingBalanceOverviewResponse": {
            "affiliatedEntity": [
                42
            ],
            "purchaseOrdersBalance": [
                10
            ],
            "warehouseTransfersBalance": [
                10
            ],
            "paymentsBalance": [
                10
            ],
            "billsBalance": [
                10
            ],
            "count": [
                38
            ],
            "minAmount": [
                10
            ],
            "maxAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceResponse": {
            "hasNext": [
                5
            ],
            "items": [
                799
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsOverviewResponse": {
            "count": [
                38
            ],
            "negativeRollingBalanceCount": [
                38
            ],
            "rollingBalance": [
                10
            ],
            "unpaidBills": [
                10
            ],
            "unpaidBillsOpeningBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsResponse": {
            "vendors": [
                787
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSort": {
            "field": [
                807
            ],
            "direction": [
                1386
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSortField": {},
        "InventoryVendorValidationResult": {
            "totalsResult": [
                810
            ],
            "vendorsResults": [
                809
            ],
            "nonMatchingUnpaidBills": [
                978
            ],
            "emptyVendorInventoryPurchases": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationResultItem": {
            "affiliatedEntity": [
                42
            ],
            "rollingBalanceTotalAmount": [
                10
            ],
            "rollingBalanceListAmount": [
                10
            ],
            "rollingBalanceOverviewAmount": [
                10
            ],
            "unpaidBillsTotalAmount": [
                10
            ],
            "unpaidBillsListAmount": [
                10
            ],
            "unpaidBillsOverviewAmount": [
                10
            ],
            "rollingBalancePassed": [
                5
            ],
            "unpaidBillsPassed": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationTotalsResult": {
            "rollingBalanceOverviewAmount": [
                10
            ],
            "rollingBalanceListAmount": [
                10
            ],
            "rollingBalanceBooksAmount": [
                10
            ],
            "rollingBalanceDbAmount": [
                10
            ],
            "unpaidBillsOverviewAmount": [
                10
            ],
            "unpaidBillsIncidentalsAmount": [
                10
            ],
            "unpaidBillsBooksAmount": [
                10
            ],
            "unpaidBillsListAmount": [
                10
            ],
            "unpaidBillsDbAmount": [
                10
            ],
            "rollingBalancePassed": [
                5
            ],
            "unpaidBillsPassed": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InvitationStatus": {},
        "InviteUserArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InviteUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Invoice": {
            "id": [
                12
            ],
            "dueDate": [
                398
            ],
            "dueInDays": [
                38
            ],
            "companyAddress": [
                269
            ],
            "currentPdf": [
                823
            ],
            "pdfs": [
                823
            ],
            "paymentMethod": [
                1116
            ],
            "paymentMethods": [
                1116
            ],
            "saleType": [
                1
            ],
            "companyId": [
                12
            ],
            "source": [
                1283
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                377
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1284
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "publishedAt": [
                398
            ],
            "lineItems": [
                1260
            ],
            "shipping": [
                1345
            ],
            "discounts": [
                450
            ],
            "taxes": [
                1424
            ],
            "taxFixedAmount": [
                10
            ],
            "adjustments": [
                1257
            ],
            "payments": [
                1286
            ],
            "credits": [
                1286
            ],
            "refunds": [
                1286
            ],
            "paymentIndications": [
                1286
            ],
            "refundIndications": [
                1286
            ],
            "customer": [
                387
            ],
            "customerId": [
                12
            ],
            "shippingAddress": [
                381
            ],
            "billingAddress": [
                381
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "accounting": [
                4
            ],
            "voidedAt": [
                398
            ],
            "voidedBy": [
                1256
            ],
            "summary": [
                1285
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceAdjustmentInput": {
            "invoiceId": [
                12
            ],
            "companyId": [
                12
            ],
            "adjustmentId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "balanceAsOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroup": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "invoices": [
                1097
            ],
            "bankTransactions": [
                1096
            ],
            "expenses": [
                1091
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupByIdResponse": {
            "paymentGroup": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsByIdsResponse": {
            "paymentGroups": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsResponse": {
            "paymentGroups": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                398
            ],
            "unclearedBalances": [
                822
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupUnclearedBalance": {
            "paymentGroup": [
                817
            ],
            "unclearedBalance": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdf": {
            "id": [
                12
            ],
            "location": [
                1
            ],
            "createdAt": [
                398
            ],
            "sentAt": [
                398
            ],
            "issuedAt": [
                398
            ],
            "isDraft": [
                5
            ],
            "isVoided": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfInput": {
            "companyId": [
                12
            ],
            "invoiceId": [
                12
            ],
            "invoicePdfId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfSentInput": {
            "companyId": [
                12
            ],
            "invoicePdfId": [
                12
            ],
            "invoiceId": [
                12
            ],
            "sentAtStr": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundInput": {
            "invoiceId": [
                12
            ],
            "companyId": [
                12
            ],
            "refundId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundOrAdjustmentInput": {
            "invoiceId": [
                12
            ],
            "companyId": [
                12
            ],
            "refundId": [
                12
            ],
            "adjustmentId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesAggregations": {
            "invoicesCount": [
                38
            ],
            "invoicesAverageValue": [
                10
            ],
            "invoicesNetSales": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesBalance": {
            "balance": [
                10
            ],
            "count": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceSettings": {
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesInput": {
            "companyId": [
                12
            ],
            "balanceAsOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceToPaymentGroups": {
            "invoiceId": [
                1
            ],
            "paymentGroups": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "IPaymentGroup": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "on_InvoicePaymentGroup": [
                817
            ],
            "on_OrderPaymentGroup": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "IrrelevantType": {},
        "IsCompanyFulfillmentSettingsAppliedInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "IsGlobalRuleExistResult": {
            "exists": [
                5
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserResponse": {
            "isRegisteredUser": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ItemReconciliationFinaloopSalesChannel": {
            "displayName": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActions": {
            "firstAccount": [
                23
            ],
            "secondAccount": [
                23
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActionsInput": {
            "firstAccount": [
                24
            ],
            "secondAccount": [
                24
            ],
            "__typename": [
                1
            ]
        },
        "JSON": {},
        "JSONObject": {},
        "LastAccountingReportSyncInput": {
            "companyId": [
                12
            ],
            "reportType": [
                1251
            ],
            "__typename": [
                1
            ]
        },
        "LastAccountingReportSyncResponse": {
            "status": [
                1250
            ],
            "exportedAt": [
                398
            ],
            "fileUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LastBigValidationInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "lenderFromEntityInput": {
            "companyId": [
                12
            ],
            "entityId": [
                12
            ],
            "entityType": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "LineItemDiscountType": {},
        "LinkedBankTransaction": {
            "bankTransaction": [
                167
            ],
            "amount": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "LinkedOrder": {
            "order": [
                1073
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupInput": {
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "invoices": [
                1094
            ],
            "bankTransactions": [
                1089
            ],
            "expenses": [
                1092
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupResponse": {
            "paymentGroup": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupInput": {
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "orders": [
                1068
            ],
            "bankTransactions": [
                197
            ],
            "expenses": [
                1022
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupResponse": {
            "orderGroup": [
                1018
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupInput": {
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "orders": [
                1103
            ],
            "bankTransactions": [
                1089
            ],
            "expenses": [
                1092
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupResponse": {
            "paymentGroup": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "ListCompaniesInput": {
            "withArchivedCompanies": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ListPhysicalCountInput": {
            "companyId": [
                1
            ],
            "toDate": [
                398
            ],
            "warehouseId": [
                1
            ],
            "containsAnySku": [
                1
            ],
            "adjustmentAmountMin": [
                10
            ],
            "adjustmentAmountMax": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ListPhysicalCountResult": {
            "on_PhysicalCountList": [
                1164
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "ListReadyValidationsInput": {
            "companyId": [
                12
            ],
            "type": [
                316
            ],
            "__typename": [
                1
            ]
        },
        "Loan": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "latestActorType": [
                27
            ],
            "latestActorId": [
                1
            ],
            "type": [
                908
            ],
            "lender": [
                975
            ],
            "currency": [
                377
            ],
            "postedAt": [
                398
            ],
            "direction": [
                879
            ],
            "internalNote": [
                1
            ],
            "isShortTerm": [
                5
            ],
            "amount": [
                10
            ],
            "totalAmount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "outstandingAmount": [
                10
            ],
            "accruedInterest": [
                10
            ],
            "unrecognizedFees": [
                10
            ],
            "prepaymentLiabilityAmount": [
                10
            ],
            "status": [
                905
            ],
            "userActionStatus": [
                912
            ],
            "treatmentStatus": [
                907
            ],
            "acknowledgedInternallyAt": [
                398
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                398
            ],
            "lastManuallyHandledAt": [
                398
            ],
            "lastManuallyHandledBy": [
                1
            ],
            "attachments": [
                867
            ],
            "createdBy": [
                1
            ],
            "createdById": [
                1
            ],
            "workingPaperLink": [
                1
            ],
            "internalOwner": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lastSeenByClientAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "restoredAt": [
                398
            ],
            "directionSource": [
                895
            ],
            "merchantSource": [
                895
            ],
            "postedAtSource": [
                895
            ],
            "amountSource": [
                895
            ],
            "interestDays": [
                886
            ],
            "repaymentType": [
                899
            ],
            "repaymentFrequency": [
                898
            ],
            "repaymentRate": [
                10
            ],
            "interestRateType": [
                887
            ],
            "interestRate": [
                10
            ],
            "gracePeriod": [
                38
            ],
            "catchUpPeriod": [
                38
            ],
            "catchUpPercent": [
                10
            ],
            "originationFees": [
                10
            ],
            "prepaymentFees": [
                10
            ],
            "dataRequestType": [
                878
            ],
            "recurringDataFrequency": [
                896
            ],
            "instantRepaymentMethod": [
                885
            ],
            "isIndexLinked": [
                5
            ],
            "interestType": [
                888
            ],
            "variableInterestUpdatePeriod": [
                915
            ],
            "variableInterestSpread": [
                10
            ],
            "variableInterestBase": [
                914
            ],
            "compoundFrequency": [
                875
            ],
            "indexType": [
                884
            ],
            "modifiedFields": [
                1
            ],
            "manualEventsGroup": [
                890
            ],
            "billPaymentEvents": [
                870
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                461
            ],
            "userFollowUp2": [
                1558
            ],
            "pendingDelete": [
                5
            ],
            "pendingRestoreAcknowledgement": [
                5
            ],
            "lastUserMoveToProcessingAt": [
                398
            ],
            "isHidden": [
                5
            ],
            "firstEventDate": [
                398
            ],
            "isBillPaymentEventAttached": [
                5
            ],
            "accountingEvents": [
                862
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountingEvent": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "memo": [
                1
            ],
            "businessEventActionsConfiguration": [
                222
            ],
            "customTags": [
                843
            ],
            "latestActorType": [
                27
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "businessEventData": [
                872
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountRoleEffectDirection": {},
        "LoanAffiliatedTransactionEvent": {
            "id": [
                12
            ],
            "bankTransaction": [
                167
            ],
            "companyAffiliate": [
                271
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LoanAlreadyExistsResult": {
            "loan": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "LoanAmountEffect": {},
        "LoanAttachment": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "createdAt": [
                398
            ],
            "type": [
                868
            ],
            "__typename": [
                1
            ]
        },
        "LoanAttachmentType": {},
        "LoanBillEvent": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "LoanBillPaymentEvent": {
            "id": [
                12
            ],
            "bill": [
                207
            ],
            "paymentId": [
                12
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEvent": {
            "id": [
                12
            ],
            "postedAt": [
                398
            ],
            "eventType": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                843
            ],
            "integrationAccountId": [
                1
            ],
            "amount": [
                10
            ],
            "isMigrationAdjustment": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEventData": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "LoanComment": {
            "id": [
                12
            ],
            "text": [
                1
            ],
            "createdAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LoanCommentInput": {
            "id": [
                12
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCompoundFrequency": {},
        "LoanCreationFailureResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCreationResult": {
            "on_LoanUpdatedResult": [
                911
            ],
            "on_LoanCreationFailureResult": [
                876
            ],
            "on_LoanAlreadyExistsResult": [
                865
            ],
            "on_LoanTransactionProcessedOnlineResult": [
                906
            ],
            "__typename": [
                1
            ]
        },
        "LoanDataRequestType": {},
        "LoanDirection": {},
        "LoanEvent": {
            "on_LoanManualEvent": [
                890
            ],
            "on_BankTransaction": [
                167
            ],
            "on_LoanBillEvent": [
                869
            ],
            "on_LoanBusinessEvent": [
                871
            ],
            "on_LoanBillPaymentEvent": [
                870
            ],
            "on_Payout": [
                1124
            ],
            "on_Topup": [
                1439
            ],
            "on_LoanAffiliatedTransactionEvent": [
                864
            ],
            "on_IntegrationTransfer": [
                741
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventDefinition": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "isSpreadable": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventFilter": {},
        "LoanEventRecurrence": {},
        "LoanIndexType": {},
        "LoanInstantRepaymentMethod": {},
        "LoanInterestDays": {},
        "LoanInterestRateType": {},
        "LoanInterestType": {},
        "LoanLenderType": {},
        "LoanManualEvent": {
            "id": [
                12
            ],
            "eventDefinition": [
                881
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "memo": [
                1
            ],
            "recurrenceSettings": [
                1233
            ],
            "spreadData": [
                891
            ],
            "latestActorType": [
                27
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "effect": [
                901
            ],
            "accountingEvents": [
                862
            ],
            "__typename": [
                1
            ]
        },
        "LoanManualEventSpreadData": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LoanMerchantInput": {
            "id": [
                12
            ],
            "type": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "LoanOutstandingAmountAffect": {},
        "LoanOutstandingAmountOnDate": {
            "loanId": [
                12
            ],
            "outstandingAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "LoanPropertySource": {},
        "LoanRecurringDataFrequency": {},
        "LoanRelationship": {
            "id": [
                12
            ],
            "isManuallyAttached": [
                5
            ],
            "event": [
                880
            ],
            "__typename": [
                1
            ]
        },
        "LoanRepaymentFrequency": {},
        "LoanRepaymentType": {},
        "LoansByIdsResponse": {
            "loans": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "LoansEventsEffectConfiguration": {
            "id": [
                12
            ],
            "event": [
                881
            ],
            "loanType": [
                908
            ],
            "isShortTerm": [
                5
            ],
            "direction": [
                879
            ],
            "minGracePeriod": [
                38
            ],
            "repaymentType": [
                899
            ],
            "lender": [
                975
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "businessEventActionsConfiguration": [
                222
            ],
            "loanOutstandingAmountAffect": [
                893
            ],
            "loanAmountAffect": [
                866
            ],
            "latestActorType": [
                27
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LoanSortDirection": {},
        "LoansResponse": {
            "loans": [
                861
            ],
            "page": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "LoansSort": {
            "field": [
                1
            ],
            "direction": [
                902
            ],
            "__typename": [
                1
            ]
        },
        "LoanStatus": {},
        "LoanTransactionProcessedOnlineResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanTreatmentStatus": {},
        "LoanType": {},
        "LoanUpdateBasicData": {
            "lenderId": [
                1
            ],
            "userActionStatus": [
                912
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "status": [
                905
            ],
            "hideOutdatedWarning": [
                5
            ],
            "type": [
                908
            ],
            "pendingDelete": [
                5
            ],
            "userFollowUp2": [
                1559
            ],
            "direction": [
                879
            ],
            "dataRequestType": [
                878
            ],
            "recurringDataFrequency": [
                896
            ],
            "isShortTerm": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdateData": {
            "status": [
                905
            ],
            "userActionStatus": [
                912
            ],
            "lastManuallyHandledAt": [
                398
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "internalOwner": [
                1
            ],
            "postedAtSource": [
                895
            ],
            "amountSource": [
                895
            ],
            "isShortTerm": [
                5
            ],
            "type": [
                908
            ],
            "direction": [
                879
            ],
            "directionSource": [
                895
            ],
            "interestRateType": [
                887
            ],
            "interestRate": [
                10
            ],
            "dataRequestType": [
                878
            ],
            "interestType": [
                888
            ],
            "isIndexLinked": [
                5
            ],
            "gracePeriod": [
                38
            ],
            "interestDays": [
                886
            ],
            "originationFees": [
                10
            ],
            "prepaymentFees": [
                10
            ],
            "repaymentFrequency": [
                898
            ],
            "repaymentType": [
                899
            ],
            "lenderId": [
                1
            ],
            "lenderType": [
                889
            ],
            "merchantSource": [
                895
            ],
            "variableInterestUpdatePeriod": [
                915
            ],
            "variableInterestSpread": [
                10
            ],
            "variableInterestBase": [
                914
            ],
            "compoundFrequency": [
                875
            ],
            "recurringDataFrequency": [
                896
            ],
            "comment": [
                874
            ],
            "unrecognizedFees": [
                10
            ],
            "repaymentRate": [
                10
            ],
            "catchUpPeriod": [
                38
            ],
            "catchUpPercent": [
                10
            ],
            "instantRepaymentMethod": [
                885
            ],
            "indexType": [
                884
            ],
            "currency": [
                377
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                463
            ],
            "userFollowUp2": [
                1559
            ],
            "internalNote": [
                1
            ],
            "lastUserMoveToProcessingAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdatedResult": {
            "loans": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "LoanUserActionStatus": {},
        "LoanUserFollowUpType": {},
        "LoanVariableInterestBase": {},
        "LoanVariableInterestUpdatePeriod": {},
        "LockAction": {},
        "LockBankAccountInput": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "LockBankAccountInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyBankInput": {
            "companyId": [
                1
            ],
            "bankName": [
                1
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyInput": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyV2Input": {
            "companyId": [
                1
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "LockConditionStatus": {},
        "LockedStatus": {
            "year": [
                38
            ],
            "lockedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LockType": {},
        "MachineClassificationInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "MachineClassificationJob": {
            "finishedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "MachineValidationData": {
            "madePrivateAt": [
                398
            ],
            "lastValidAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ManagementMethod": {},
        "ManualAssembly": {
            "id": [
                1
            ],
            "product": [
                1170
            ],
            "date": [
                398
            ],
            "warehouse": [
                1601
            ],
            "recipe": [
                1214
            ],
            "unitsToAssemble": [
                38
            ],
            "displayName": [
                1
            ],
            "serialNumber": [
                38
            ],
            "deletedReason": [
                84
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "manualAssembly": [
                929
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyBreakdown": {
            "origin": [
                933
            ],
            "analytics": [
                711
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyDuplicateError": {
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyWithBreakdown": {
            "assembly": [
                929
            ],
            "warehouse": [
                1601
            ],
            "date": [
                398
            ],
            "breakdown": [
                1215
            ],
            "__typename": [
                1
            ]
        },
        "ManualCompanyLoanCreationResult": {
            "loan": [
                296
            ],
            "__typename": [
                1
            ]
        },
        "ManualLoanCreationResult": {
            "loan": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "ManualTransactionsAttachToLoanResult": {
            "loan": [
                296
            ],
            "transactions": [
                167
            ],
            "__typename": [
                1
            ]
        },
        "ManualTxInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "MarkIntegrationTransfersAsDiscrepancyInput": {
            "ids": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedResponse": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleResponse": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersInput": {
            "companyId": [
                12
            ],
            "orderMarks": [
                946
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrderType": {},
        "MarkOrderWithoutFinancialEventsInput": {
            "companyId": [
                12
            ],
            "orderId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MarkSingleOrderInput": {
            "orderId": [
                12
            ],
            "markType": [
                944
            ],
            "__typename": [
                1
            ]
        },
        "Merchant": {
            "on_Vendor": [
                1583
            ],
            "on_Customer": [
                380
            ],
            "on_CompanyAffiliate": [
                271
            ],
            "on_Institution": [
                718
            ],
            "__typename": [
                1
            ]
        },
        "MerchantBankTransactionStatsMerchantKeyInput": {
            "id": [
                12
            ],
            "type": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "MerchantCategorizationInput": {
            "merchant": [
                950
            ],
            "shouldApplyAlways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "MerchantInput": {
            "id": [
                12
            ],
            "type": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "MerchantKey": {
            "id": [
                12
            ],
            "type": [
                956
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStats": {
            "merchantsCount": [
                38
            ],
            "transactionsCount": [
                38
            ],
            "transactionsSum": [
                10
            ],
            "uncategorizedTransactionsCount": [
                38
            ],
            "uncategorizedTransactionsSum": [
                10
            ],
            "transactionBusinessEvents": [
                1
            ],
            "uncategorizedTransactionBusinessEvents": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStatsInput": {
            "companyId": [
                12
            ],
            "merchants": [
                948
            ],
            "moneyDirection": [
                1373
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByIdsInput": {
            "companyId": [
                12
            ],
            "merchantIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByKeysInput": {
            "keys": [
                951
            ],
            "__typename": [
                1
            ]
        },
        "MerchantType": {},
        "MeResponse": {
            "user": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "MergedProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "lineOfBusinessId": [
                1
            ],
            "string": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedWithProducts": [
                1170
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsInput": {
            "companyId": [
                1
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedFinaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2": {
            "representativeId": [
                1
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Input": {
            "companyId": [
                1
            ],
            "mergeInput": [
                960
            ],
            "splitInput": [
                1390
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Response": {
            "on_MergeProductsV2Result": [
                963
            ],
            "on_MergeProductsValidationError": [
                964
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Result": {
            "splitResult": [
                1170
            ],
            "mergeResult": [
                1170
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeResult": {},
        "MergeTax1099VendorsInput": {
            "companyId": [
                12
            ],
            "vendorIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "MetricRangeGroupBy": {},
        "MoneyDirection": {},
        "MoveBankAccountUploadsToAnotherBankAccountInput": {
            "companyId": [
                12
            ],
            "sourceBankAccountId": [
                12
            ],
            "targetBankAccountId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "Movement": {
            "stockSituation": [
                757
            ],
            "direction": [
                971
            ],
            "batches": [
                757
            ],
            "unavailableQuantity": [
                38
            ],
            "action": [
                1602
            ],
            "__typename": [
                1
            ]
        },
        "MovementDirection": {},
        "MovementIndirectCost": {
            "type": [
                973
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "MovementIndirectCostType": {},
        "Mutation": {
            "cancelAccrual": [
                11,
                {
                    "input": [
                        236,
                        "CancelAccrualInput!"
                    ]
                }
            ],
            "createOrUpdateAccrual": [
                365,
                {
                    "input": [
                        364,
                        "CreateOrUpdateAccrualInput!"
                    ]
                }
            ],
            "deleteAccrual": [
                11,
                {
                    "input": [
                        403,
                        "DeleteAccrualInput!"
                    ]
                }
            ],
            "updateBankAccountClosingBalance": [
                97,
                {
                    "input": [
                        1473,
                        "UpdateBankAccountClosingBalanceInput!"
                    ]
                }
            ],
            "createBankAccountBalanceAdjustment": [
                97,
                {
                    "input": [
                        1472,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "deleteBankAccountBalanceAdjustment": [
                97,
                {
                    "input": [
                        1472,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "setBankAccountsLatestEventIds": [
                97,
                {
                    "input": [
                        1308,
                        "SetBankAccountsLatestEventIdsInput!"
                    ]
                }
            ],
            "createBankAccount": [
                97,
                {
                    "input": [
                        331,
                        "CreateBankAccountInput!"
                    ]
                }
            ],
            "copyBankAccount": [
                97,
                {
                    "input": [
                        329,
                        "CopyBankAccountInput!"
                    ]
                }
            ],
            "updateBankAccount": [
                97,
                {
                    "input": [
                        1474,
                        "UpdateBankAccountInput!"
                    ]
                }
            ],
            "replaceBankAccountLocks": [
                97,
                {
                    "input": [
                        1246,
                        "ReplaceBankAccountLocksInput!"
                    ]
                }
            ],
            "replaceBankAccountLocksByType": [
                97,
                {
                    "input": [
                        1245,
                        "ReplaceBankAccountLocksByTypeInput!"
                    ]
                }
            ],
            "updateBankAccountsMergeStatus": [
                1414,
                {
                    "input": [
                        1475,
                        "UpdateBankAccountsMergeStatusInput!"
                    ]
                }
            ],
            "lockBankAccount": [
                97,
                {
                    "input": [
                        917,
                        "LockBankAccountInput!"
                    ]
                }
            ],
            "lockBankAccountV2": [
                97,
                {
                    "input": [
                        918,
                        "LockBankAccountInputV2!"
                    ]
                }
            ],
            "createInstitution": [
                718,
                {
                    "input": [
                        349,
                        "CreateInstitutionInput!"
                    ]
                }
            ],
            "updateInstitution": [
                718,
                {
                    "input": [
                        1495,
                        "UpdateInstitutionInput!"
                    ]
                }
            ],
            "createInstitutionMapping": [
                719,
                {
                    "input": [
                        350,
                        "CreateInstitutionMappingsInput!"
                    ]
                }
            ],
            "updateInstitutionMapping": [
                719,
                {
                    "input": [
                        1496,
                        "UpdateInstitutionMappingInput!"
                    ]
                }
            ],
            "uploadBankStatement": [
                1535,
                {
                    "input": [
                        1534,
                        "UploadBankStatementInput!"
                    ]
                }
            ],
            "updateBankReconciliationRequestMessage": [
                135,
                {
                    "input": [
                        1476,
                        "UpdateBankReconciliationRequestMessageInput!"
                    ]
                }
            ],
            "upsertBankStatementParsingRequest": [
                149,
                {
                    "input": [
                        150,
                        "BankStatementParsingRequestInput!"
                    ]
                }
            ],
            "upsertBankStatementParsingResults": [
                153,
                {
                    "input": [
                        154,
                        "BankStatementParsingResultsInput!"
                    ]
                }
            ],
            "updateBankStatementParsingResultStatus": [
                153,
                {
                    "input": [
                        1477,
                        "UpdateBankStatementParsingResultStatus!"
                    ]
                }
            ],
            "stopImportingDataForBankAccount": [
                97,
                {
                    "input": [
                        1396,
                        "StopImportingDataForBankAccountInput!"
                    ]
                }
            ],
            "stopImportingDataForBankAccountNew": [
                97,
                {
                    "input": [
                        1397,
                        "StopImportingDataForBankAccountNewInput!"
                    ]
                }
            ],
            "createBankAccountStatementForStopImport": [
                117,
                {
                    "input": [
                        332,
                        "CreateBankAccountStatementForStopImportInput!"
                    ]
                }
            ],
            "deleteBankAccounts": [
                5,
                {
                    "input": [
                        404,
                        "DeleteBankAccountsInput!"
                    ]
                }
            ],
            "updateBankAccountReconciliation": [
                111,
                {
                    "input": [
                        1488,
                        "UpdatedBankAccountReconciliation!"
                    ]
                }
            ],
            "submitBankReconciliationRequestResolution": [
                160,
                {
                    "input": [
                        159,
                        "BankStatementRequestResolutionInput!"
                    ]
                }
            ],
            "categorizeBankTransactions": [
                174,
                {
                    "input": [
                        239,
                        "CategorizeBankTransactionsInput!"
                    ]
                }
            ],
            "createCustomerCategorizationRule": [
                343,
                {
                    "input": [
                        342,
                        "CreateCustomerCategorizationRuleInput!"
                    ]
                }
            ],
            "updateBankTransactionMemo": [
                167,
                {
                    "input": [
                        1478,
                        "UpdateBankTransactionMemoInput!"
                    ]
                }
            ],
            "addBankTransactionAttachment": [
                167,
                {
                    "input": [
                        28,
                        "AddBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "deleteBankTransactionAttachment": [
                167,
                {
                    "input": [
                        406,
                        "DeleteBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "finalizeAskTheUserFlow": [
                507,
                {
                    "input": [
                        506,
                        "FinalizeAskTheUserFlowInput!"
                    ]
                }
            ],
            "askTheUserNotificationService": [
                80,
                {
                    "input": [
                        79,
                        "AskTheUserNotificationInput!"
                    ]
                }
            ],
            "scheduleAtuTransactionsResultProcessing": [
                1293,
                {
                    "input": [
                        1292,
                        "ScheduleAtuTransactionsResultProcessingInput!"
                    ]
                }
            ],
            "createGlobalVendorMappingRule": [
                677,
                {
                    "input": [
                        347,
                        "CreateGlobalVendorRuleInput!"
                    ]
                }
            ],
            "createBankTransactionsUpload": [
                190,
                {
                    "input": [
                        333,
                        "CreateBankTransactionsUploadInput!"
                    ]
                }
            ],
            "upsertBankTransactionLocalRule": [
                372,
                {
                    "input": [
                        1536,
                        "UpsertBankTransactionLocalRuleInput!"
                    ]
                }
            ],
            "createGlobalRule": [
                345,
                {
                    "input": [
                        676,
                        "GlobalRuleInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactions": [
                250,
                {
                    "input": [
                        91,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactionsWithPairedEntities": [
                250,
                {
                    "input": [
                        91,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsByBankAccountIds": [
                5,
                {
                    "input": [
                        407,
                        "DeleteBankTransactionsByBankAccountIdsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsInternal": [
                47,
                {
                    "input": [
                        46,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "restoreBankTransactionsInternal": [
                47,
                {
                    "input": [
                        46,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "moveBankAccountUploadsToAnotherBankAccount": [
                190,
                {
                    "input": [
                        969,
                        "MoveBankAccountUploadsToAnotherBankAccountInput!"
                    ]
                }
            ],
            "appendProcessingLogToBankTransactionsUpload": [
                190,
                {
                    "input": [
                        72,
                        "AppendProcessingLogToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "submitTransactionsFileToBankTransactionsUpload": [
                190,
                {
                    "input": [
                        1409,
                        "SubmitTransactionsFileToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "clearExpiredDoItLaterResults": [
                41
            ],
            "processCheckoutWebhookEvents": [
                5,
                {
                    "args": [
                        1169,
                        "ProcessCheckoutWebhookEventsInput!"
                    ]
                }
            ],
            "syncStripeOffers": [
                5,
                {
                    "args": [
                        1422,
                        "SyncStripeOffersInput!"
                    ]
                }
            ],
            "syncStripeTier1Offer": [
                5,
                {
                    "args": [
                        1423,
                        "SyncStripeTier1Offer!"
                    ]
                }
            ],
            "createCheckoutSession": [
                335,
                {
                    "args": [
                        334,
                        "CreateCheckoutSessionInput!"
                    ]
                }
            ],
            "pollCheckoutSessionStatus": [
                267,
                {
                    "args": [
                        1165,
                        "PollCheckoutSessionStatusInput!"
                    ]
                }
            ],
            "createOrGetBusinessEventDefinition": [
                222,
                {
                    "input": [
                        363,
                        "CreateOrGetBusinessEventDefinitionInput!"
                    ]
                }
            ],
            "updateBooksStartTime": [
                1411,
                {
                    "args": [
                        1479,
                        "UpdateBooksStartTimeArgs!"
                    ]
                }
            ],
            "createFileUploadUrl": [
                504,
                {
                    "input": [
                        344,
                        "CreateFileUploadUrlInput!"
                    ]
                }
            ],
            "updateCompanyAccountingMethod": [
                267,
                {
                    "args": [
                        1482,
                        "UpdateCompanyAccountingMethodArgs!"
                    ]
                }
            ],
            "updateEstimatedProjectedRevenue": [
                267,
                {
                    "args": [
                        1492,
                        "UpdateEstimatedProjectedRevenueInput!"
                    ]
                }
            ],
            "toggleOrdersZeroNetBalance": [
                1411,
                {
                    "args": [
                        1436,
                        "ToggleOrdersZeroNetBalanceInput!"
                    ]
                }
            ],
            "toggleRecognizeZeroNetOrdersAsGiveaways": [
                5,
                {
                    "args": [
                        1437,
                        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput!"
                    ]
                }
            ],
            "createImageUploadUrl": [
                704,
                {
                    "input": [
                        348,
                        "CreateImageUploadUrlInput!"
                    ]
                }
            ],
            "setSettings": [
                1411,
                {
                    "args": [
                        1329,
                        "SetSettingsArgs!"
                    ]
                }
            ],
            "upsertCompanyAddress": [
                269,
                {
                    "input": [
                        1537
                    ]
                }
            ],
            "deleteCompanyAddress": [
                5,
                {
                    "input": [
                        270,
                        "CompanyAddressInput!"
                    ]
                }
            ],
            "updateOnboardingStatus": [
                994,
                {
                    "input": [
                        1513,
                        "UpdateOnboardingStatusInput!"
                    ]
                }
            ],
            "setFiscalYearStart": [
                5,
                {
                    "input": [
                        1309,
                        "SetFiscalYearStartInput!"
                    ]
                }
            ],
            "createCompany": [
                267,
                {
                    "args": [
                        337,
                        "CreateCompanyInput!"
                    ]
                }
            ],
            "updateCompanyPayingStatus": [
                267,
                {
                    "args": [
                        1484,
                        "UpdateCompanyPayingStatusInput!"
                    ]
                }
            ],
            "syncCompany": [
                5,
                {
                    "args": [
                        1418,
                        "SyncCompanyInput!"
                    ]
                }
            ],
            "lockCompany": [
                302,
                {
                    "args": [
                        920,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "unlockCompany": [
                302,
                {
                    "args": [
                        920,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "lockCompanyV2": [
                302,
                {
                    "args": [
                        921,
                        "LockCompanyV2Input!"
                    ]
                }
            ],
            "unlockCompanyV2": [
                302,
                {
                    "args": [
                        1460,
                        "UnlockCompanyV2Input!"
                    ]
                }
            ],
            "removeDataRestriction": [
                267,
                {
                    "args": [
                        1242,
                        "RemoveDataRestrictionInput!"
                    ]
                }
            ],
            "validationCycle": [
                1576,
                {
                    "input": [
                        662,
                        "GetValidationCycleInput!"
                    ]
                }
            ],
            "completeValidationCycle": [
                5,
                {
                    "input": [
                        320,
                        "CompleteValidationCycleInput!"
                    ]
                }
            ],
            "sendValidationNotification": [
                5,
                {
                    "input": [
                        1307,
                        "SendValidationNotificationInput!"
                    ]
                }
            ],
            "storeValidation": [
                5,
                {
                    "input": [
                        1404,
                        "StoreValidationInput!"
                    ]
                }
            ],
            "createCompanyAffiliate": [
                271,
                {
                    "input": [
                        336,
                        "CreateCompanyAffiliateInput!"
                    ]
                }
            ],
            "updateCompanyAffiliate": [
                271,
                {
                    "input": [
                        1483,
                        "UpdateCompanyAffiliateInput!"
                    ]
                }
            ],
            "deleteCompanyAffiliate": [
                271,
                {
                    "input": [
                        408,
                        "DeleteCompanyAffiliateInput!"
                    ]
                }
            ],
            "upsertHolderShare": [
                696,
                {
                    "input": [
                        1542,
                        "UpsertHolderShareInput!"
                    ]
                }
            ],
            "deleteHolderShare": [
                696,
                {
                    "input": [
                        410,
                        "DeleteHolderShareInput!"
                    ]
                }
            ],
            "upsertHolderCapitalInvestment": [
                693,
                {
                    "input": [
                        1541,
                        "UpsertHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "deleteHolderCapitalInvestment": [
                693,
                {
                    "input": [
                        409,
                        "DeleteHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "addRoleToAffiliatedEntity": [
                36,
                {
                    "input": [
                        34,
                        "AddRoleToAffiliatedEntityInput!"
                    ]
                }
            ],
            "deleteBankConnection": [
                5,
                {
                    "input": [
                        405,
                        "DeleteBankConnectionInput!"
                    ]
                }
            ],
            "disconnectPayrollConnection": [
                5,
                {
                    "input": [
                        449,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "disconnectConnection": [
                5,
                {
                    "input": [
                        449,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "confirmRoleForPaymentGateway": [
                5,
                {
                    "input": [
                        321,
                        "ConfirmRoleForPaymentGatewayInput!"
                    ]
                }
            ],
            "unclassifyIntegrationTransfers": [
                741,
                {
                    "input": [
                        1449,
                        "UnclassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "markIntegrationTransfersAsDiscrepancy": [
                741,
                {
                    "input": [
                        938,
                        "MarkIntegrationTransfersAsDiscrepancyInput!"
                    ]
                }
            ],
            "classifyIntegrationTransfer": [
                741,
                {
                    "input": [
                        255,
                        "ClassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "createIntegrationTransferRule": [
                749,
                {
                    "input": [
                        351,
                        "CreateIntegrationTransferRuleInput!"
                    ]
                }
            ],
            "scheduleAtuIntegrationTransfersResultProcessing": [
                1291,
                {
                    "input": [
                        1290,
                        "ScheduleAtuIntegrationTransfersResultProcessingInput!"
                    ]
                }
            ],
            "refreshSkuAmbiguity": [
                5,
                {
                    "input": [
                        1238,
                        "RefreshSkuAmbiguityInput!"
                    ]
                }
            ],
            "updateAppliedCostFromIntegration": [
                5,
                {
                    "input": [
                        1471,
                        "UpdateAppliedCostFromIntegrationInput!"
                    ]
                }
            ],
            "triggerCOGSFromScratch": [
                5,
                {
                    "input": [
                        1448,
                        "TriggerCOGSFromScratchInput!"
                    ]
                }
            ],
            "syncInventoryIntegrationFulfillmentSetting": [
                5,
                {
                    "input": [
                        1420,
                        "SyncInventoryIntegrationFulfillmentSettingInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromTransactions": [
                5,
                {
                    "input": [
                        1228,
                        "ReconcileInventoryPurchasesFromTransactionsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBills": [
                5,
                {
                    "input": [
                        1225,
                        "ReconcileInventoryPurchasesFromBillsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBusinessEvents": [
                5,
                {
                    "input": [
                        1226,
                        "ReconcileInventoryPurchasesFromBusinessEventsInput!"
                    ]
                }
            ],
            "reconcileAllPayrollInventoryPurchases": [
                5,
                {
                    "input": [
                        1224,
                        "ReconcileAllPayrollInventoryPurchasesInput!"
                    ]
                }
            ],
            "reconcileAllContractorPaymentInventoryPurchases": [
                5,
                {
                    "input": [
                        1223,
                        "ReconcileAllContractorPaymentInventoryPurchasesInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSku": [
                5,
                {
                    "input": [
                        1355,
                        "ShopifyItemsValidationBlacklistSkuInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSalesChannel": [
                5,
                {
                    "input": [
                        1354,
                        "ShopifyItemsValidationBlacklistSalesChannelInput!"
                    ]
                }
            ],
            "markOrderWithoutFinancialEvents": [
                5,
                {
                    "input": [
                        945,
                        "MarkOrderWithoutFinancialEventsInput!"
                    ]
                }
            ],
            "markOrdersWithoutFinancialEvents": [
                38,
                {
                    "input": [
                        67,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "generateFifoValidation": [
                5,
                {
                    "input": [
                        492,
                        "FifoValidationInput!"
                    ]
                }
            ],
            "generateNoUnitsValidation": [
                528,
                {
                    "input": [
                        527,
                        "GenerateNoUnitsValidationInput!"
                    ]
                }
            ],
            "generateBigValidation": [
                205,
                {
                    "input": [
                        525,
                        "GenerateBigValidationInput!"
                    ]
                }
            ],
            "amazonValidationAdjustSkuQuantities": [
                5,
                {
                    "input": [
                        66,
                        "AmazonValidationAdjustSkuQuantitiesInput!"
                    ]
                }
            ],
            "createInventoryVendorValidation": [
                353,
                {
                    "input": [
                        352,
                        "CreateInventoryVendorValidationInput!"
                    ]
                }
            ],
            "changeProductsHiddenStatus": [
                5,
                {
                    "input": [
                        242,
                        "ChangeProductsHiddenStatusInput!"
                    ]
                }
            ],
            "createProductManually": [
                370,
                {
                    "input": [
                        369,
                        "CreateProductManuallyInput!"
                    ]
                }
            ],
            "createWarehouse": [
                1606,
                {
                    "input": [
                        375,
                        "CreateWarehouseInput!"
                    ]
                }
            ],
            "deleteManualAssembly": [
                5,
                {
                    "input": [
                        421,
                        "DeleteManualAssemblyInput!"
                    ]
                }
            ],
            "deletePurchaseOrder": [
                5,
                {
                    "input": [
                        438,
                        "DeletePurchaseOrderInput!"
                    ]
                }
            ],
            "deleteRecipe": [
                5,
                {
                    "input": [
                        439,
                        "DeleteRecipeInput!"
                    ]
                }
            ],
            "deleteWarehouse": [
                5,
                {
                    "input": [
                        440,
                        "DeleteWarehouseInput!"
                    ]
                }
            ],
            "mergeProductsV2": [
                962,
                {
                    "input": [
                        961,
                        "MergeProductsV2Input!"
                    ]
                }
            ],
            "setProductAdjustments": [
                1170,
                {
                    "input": [
                        1321,
                        "SetProductAdjustmentsInput!"
                    ]
                }
            ],
            "setWarehouseOpeningBalance": [
                1615,
                {
                    "input": [
                        1332,
                        "SetWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "updateManualAssembly": [
                1508,
                {
                    "input": [
                        1507,
                        "UpdateManualAssemblyInput!"
                    ]
                }
            ],
            "updateProduct": [
                1170,
                {
                    "input": [
                        1521,
                        "UpdateProductInput!"
                    ]
                }
            ],
            "updateProductFractional": [
                1170,
                {
                    "input": [
                        1520,
                        "UpdateProductFractionalInput!"
                    ]
                }
            ],
            "updatePurchaseOrder": [
                1199,
                {
                    "input": [
                        1202,
                        "PurchaseOrderInput!"
                    ]
                }
            ],
            "updateRecipe": [
                1523,
                {
                    "input": [
                        1522,
                        "UpdateRecipeInput!"
                    ]
                }
            ],
            "updateWarehouse": [
                1601,
                {
                    "input": [
                        1531,
                        "UpdateWarehouseInput!"
                    ]
                }
            ],
            "updateWarehousesDefaults": [
                1628,
                {
                    "input": [
                        1532,
                        "UpdateWarehousesDefaultsInput!"
                    ]
                }
            ],
            "generatePdf": [
                532,
                {
                    "input": [
                        531,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "generatePdfV2": [
                533,
                {
                    "input": [
                        531,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "sendPoEmail": [
                1306,
                {
                    "input": [
                        1304,
                        "SendPoEmailInput!"
                    ]
                }
            ],
            "setInventoryUsages": [
                5,
                {
                    "input": [
                        1312,
                        "SetInventoryUsagesInput!"
                    ]
                }
            ],
            "updateInventoryPurchase": [
                1498,
                {
                    "input": [
                        767,
                        "InventoryPurchaseInput!"
                    ]
                }
            ],
            "deleteInventoryPurchase": [
                783,
                {
                    "input": [
                        411,
                        "DeleteInventoryPurchaseInput!"
                    ]
                }
            ],
            "schedulePurchasesValidation": [
                5,
                {
                    "input": [
                        1294,
                        "SchedulePurchasesValidationInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalance": [
                1155,
                {
                    "input": [
                        1317,
                        "SetPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "deleteWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        441,
                        "DeleteWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "discardPendingWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        448,
                        "DiscardPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "applyPendingWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        74,
                        "ApplyPendingWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalanceWithCsv": [
                1156,
                {
                    "input": [
                        1319,
                        "SetPendingWarehouseOpeningBalancesWithCsvInput!"
                    ]
                }
            ],
            "deletePendingWarehouseOpeningBalanceAttachment": [
                5,
                {
                    "input": [
                        432,
                        "DeletePendingWarehouseOpeningAttachmentInput!"
                    ]
                }
            ],
            "updateWarehouseTransfer": [
                1634,
                {
                    "input": [
                        1533,
                        "UpdateWarehouseTransferInput!"
                    ]
                }
            ],
            "deleteWarehouseTransfer": [
                5,
                {
                    "input": [
                        442,
                        "DeleteWarehouseTransferInput!"
                    ]
                }
            ],
            "setPendingSalesDataAdjustments": [
                1151,
                {
                    "input": [
                        1315,
                        "SetPendingSalesDataAdjustmentsInput!"
                    ]
                }
            ],
            "discardPendingSalesDataAdjustments": [
                5,
                {
                    "input": [
                        447,
                        "DiscardPendingSalesDataAdjustments!"
                    ]
                }
            ],
            "upsertPhysicalCount": [
                1549,
                {
                    "input": [
                        1548,
                        "UpsertPhysicalCountInput!"
                    ]
                }
            ],
            "deletePhysicalCount": [
                436,
                {
                    "input": [
                        435,
                        "DeletePhysicalCountInput!"
                    ]
                }
            ],
            "ackPhysicalCount": [
                22,
                {
                    "input": [
                        21,
                        "AckPhysicalCountInput!"
                    ]
                }
            ],
            "updateLoan": [
                935,
                {
                    "input": [
                        1497,
                        "UpdateInternalLoanInput!"
                    ]
                }
            ],
            "updateLoanAttachments": [
                935,
                {
                    "input": [
                        1502,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createLoan": [
                935,
                {
                    "input": [
                        357,
                        "CreateLoanInput!"
                    ]
                }
            ],
            "createLoanFromTransaction": [
                877,
                {
                    "input": [
                        1446,
                        "TransactionUpdatedInput!"
                    ]
                }
            ],
            "createLoanFromBalanceTransaction": [
                877,
                {
                    "input": [
                        355,
                        "CreateLoanFromBalanceTransactionInput!"
                    ]
                }
            ],
            "createLoanFromBillFinanced": [
                5,
                {
                    "input": [
                        356,
                        "CreateLoanFromBillFinancedInput!"
                    ]
                }
            ],
            "acknowledgeLoansChanges": [
                19,
                {
                    "input": [
                        20,
                        "AcknowledgeLoansChangesInput!"
                    ]
                }
            ],
            "simulateLoanFromTransaction2": [
                1376,
                {
                    "input": [
                        1382,
                        "SimulateTransactionUpdatedInput2!"
                    ]
                }
            ],
            "deleteLoans": [
                420,
                {
                    "input": [
                        419,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousForceDeleteLoans": [
                420,
                {
                    "input": [
                        419,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousTouchLoan": [
                5,
                {
                    "input": [
                        396,
                        "DangerousTouchLoanInput!"
                    ]
                }
            ],
            "deleteLoanRelationships": [
                5,
                {
                    "input": [
                        416,
                        "DeleteLoanRelationshipsInput!"
                    ]
                }
            ],
            "deleteManualLoanEvent": [
                1511,
                {
                    "input": [
                        422,
                        "DeleteManualLoanEventInput!"
                    ]
                }
            ],
            "deleteManualLoanEvents": [
                1512,
                {
                    "input": [
                        423,
                        "DeleteManualLoanEventsInput!"
                    ]
                }
            ],
            "createManualLoanEvent2": [
                1511,
                {
                    "input": [
                        360,
                        "CreateManualLoanEventInput2!"
                    ]
                }
            ],
            "updateManualLoanEvent2": [
                1511,
                {
                    "input": [
                        1510,
                        "UpdateManualLoanEventInput2!"
                    ]
                }
            ],
            "deleteLoanManualAccountingEvents": [
                1512,
                {
                    "input": [
                        415,
                        "DeleteLoanManualAccountingEventsInput!"
                    ]
                }
            ],
            "updateLoanManualAccountingEvent": [
                1511,
                {
                    "input": [
                        1504,
                        "UpdateLoanManualAccountingEventInput!"
                    ]
                }
            ],
            "deleteLoansEventsEffectConfiguration": [
                418,
                {
                    "input": [
                        417,
                        "DeleteLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoansEventsEffectConfiguration": [
                901,
                {
                    "input": [
                        358,
                        "CreateLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoanEventDefinition": [
                881,
                {
                    "input": [
                        354,
                        "CreateLoanEventDefinitionInput!"
                    ]
                }
            ],
            "attachRelationsToLoan": [
                5,
                {
                    "input": [
                        89,
                        "AttachRelationsToLoanInput!"
                    ]
                }
            ],
            "deleteCompanyLoanAttachment": [
                934,
                {
                    "input": [
                        413,
                        "DeleteLoanAttachmentInput!"
                    ]
                }
            ],
            "updateCompanyLoanAttachments": [
                934,
                {
                    "input": [
                        1502,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createCompanyLoan": [
                934,
                {
                    "input": [
                        340,
                        "CreateCompanyLoanInput!"
                    ]
                }
            ],
            "updateCompanyLoan": [
                934,
                {
                    "input": [
                        1503,
                        "UpdateLoanInput!"
                    ]
                }
            ],
            "deleteLoan": [
                420,
                {
                    "input": [
                        414,
                        "DeleteLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToExistingLoan": [
                936,
                {
                    "input": [
                        1552,
                        "UserAttachTransactionsToExistingLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToNewLoan": [
                936,
                {
                    "input": [
                        1554,
                        "UserAttachTransactionsToNewLoanInput!"
                    ]
                }
            ],
            "lenderFromEntity": [
                975,
                {
                    "input": [
                        847,
                        "lenderFromEntityInput!"
                    ]
                }
            ],
            "updateOrderEntities": [
                1410,
                {
                    "input": [
                        1514,
                        "UpdateOrderEntitiesInput!"
                    ]
                }
            ],
            "deleteOrderGroupUponTransactionChange": [
                427,
                {
                    "input": [
                        426,
                        "DeleteOrderGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "initiateTotalArCashDiffsReport": [
                1410,
                {
                    "input": [
                        717,
                        "InitiateTotalArCashDiffsReportInput!"
                    ]
                }
            ],
            "updateCompanyArCashDiffsInBucket": [
                1410,
                {
                    "input": [
                        279,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "toggleZeroNetBalance": [
                1410,
                {
                    "input": [
                        1438,
                        "ToggleZeroNetBalanceInput!"
                    ]
                }
            ],
            "setOrderFinaloopNote": [
                1075,
                {
                    "input": [
                        1313,
                        "SetOrderFinaloopNoteInput!"
                    ]
                }
            ],
            "setOrderFinaloopTags": [
                1075,
                {
                    "input": [
                        1314,
                        "SetOrderFinaloopTagsInput!"
                    ]
                }
            ],
            "setFulfillmentSettings": [
                1311,
                {
                    "input": [
                        1310,
                        "SetFulfillmentSettingsInput!"
                    ]
                }
            ],
            "markOrders": [
                5,
                {
                    "input": [
                        943
                    ]
                }
            ],
            "linkOrderGroup": [
                854,
                {
                    "input": [
                        853,
                        "LinkOrderGroupInput!"
                    ]
                }
            ],
            "updateOrderGroup": [
                1516,
                {
                    "input": [
                        1515,
                        "UpdateOrderGroupInput!"
                    ]
                }
            ],
            "deleteOrderGroup": [
                425,
                {
                    "input": [
                        424,
                        "DeleteOrderGroupInput!"
                    ]
                }
            ],
            "voidOrders": [
                1599,
                {
                    "input": [
                        1598,
                        "VoidOrdersInput!"
                    ]
                }
            ],
            "unvoidOrders": [
                1470,
                {
                    "input": [
                        1469,
                        "UnvoidOrdersInput!"
                    ]
                }
            ],
            "markOrdersAsUncollectible": [
                942,
                {
                    "input": [
                        941,
                        "MarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "markOrdersAsOvercollected": [
                940,
                {
                    "input": [
                        939,
                        "MarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "unMarkOrdersAsUncollectible": [
                1464,
                {
                    "input": [
                        1463,
                        "UnMarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "unMarkOrdersAsOvercollected": [
                1462,
                {
                    "input": [
                        1461,
                        "UnMarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "deletePaymentGroupUponTransactionChange": [
                431,
                {
                    "input": [
                        430,
                        "DeletePaymentGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "linkOrderPaymentGroup": [
                856,
                {
                    "input": [
                        855,
                        "LinkOrderPaymentGroupInput!"
                    ]
                }
            ],
            "updateOrderPaymentGroup": [
                1518,
                {
                    "input": [
                        1517,
                        "UpdateOrderPaymentGroupInput!"
                    ]
                }
            ],
            "deleteOrderPaymentGroup": [
                429,
                {
                    "input": [
                        428,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "linkInvoicePaymentGroup": [
                852,
                {
                    "input": [
                        851,
                        "LinkInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "updateInvoicePaymentGroup": [
                1500,
                {
                    "input": [
                        1499,
                        "UpdateInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "deleteInvoicePaymentGroup": [
                429,
                {
                    "input": [
                        428,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "updateEmployeesOfficerStatus": [
                5,
                {
                    "input": [
                        1491,
                        "UpdateEmployeesOfficerStatusInput!"
                    ]
                }
            ],
            "updatePayrollSettings": [
                5,
                {
                    "input": [
                        1519,
                        "UpdatePayrollSettingsInput!"
                    ]
                }
            ],
            "createPeriodicPartnerReport": [
                1158,
                {
                    "input": [
                        1159,
                        "PeriodicPartnerReportInput!"
                    ]
                }
            ],
            "scheduleAccountingReportExport": [
                1289,
                {
                    "args": [
                        1288,
                        "ScheduleAccountingReportExportInput!"
                    ]
                }
            ],
            "upsertIntegrationCustomer": [
                387,
                {
                    "input": [
                        1543,
                        "UpsertIntegrationCustomerArgs!"
                    ]
                }
            ],
            "upsertIntegrationInvoice": [
                814,
                {
                    "input": [
                        1544,
                        "UpsertIntegrationInvoiceInput!"
                    ]
                }
            ],
            "sendSalesToReconcilerByTypeAndDate": [
                5,
                {
                    "input": [
                        1265,
                        "SalesByTypeAndDateInput!"
                    ]
                }
            ],
            "forceDeleteInvoices": [
                412,
                {
                    "input": [
                        1282,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "refreshInvoiceCalculatedFields": [
                814,
                {
                    "input": [
                        1259,
                        "SaleInternalInput!"
                    ]
                }
            ],
            "refreshManyInvoicesCalculatedFields": [
                814,
                {
                    "input": [
                        1282,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "createCustomer": [
                380,
                {
                    "createCustomerArgs": [
                        341,
                        "CreateCustomerArgs!"
                    ]
                }
            ],
            "updateCustomer": [
                380,
                {
                    "updateCustomerArgs": [
                        1487,
                        "UpdateCustomerArgs!"
                    ]
                }
            ],
            "upsertCustomerAddress": [
                381,
                {
                    "input": [
                        1538,
                        "UpsertCustomerAddressArgs!"
                    ]
                }
            ],
            "upsertCustomerV2": [
                387,
                {
                    "input": [
                        1539,
                        "UpsertCustomerV2Args!"
                    ]
                }
            ],
            "upsertInvoice": [
                814,
                {
                    "input": [
                        1545,
                        "UpsertInvoiceInput!"
                    ]
                }
            ],
            "voidInvoice": [
                814,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "unvoidInvoice": [
                814,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoiceAdjustment": [
                814,
                {
                    "input": [
                        815,
                        "InvoiceAdjustmentInput!"
                    ]
                }
            ],
            "markAsUncollectible": [
                814,
                {
                    "input": [
                        937,
                        "ManualTxInput!"
                    ]
                }
            ],
            "addRefundToInvoice": [
                814,
                {
                    "input": [
                        937,
                        "ManualTxInput!"
                    ]
                }
            ],
            "deleteInvoiceRefund": [
                814,
                {
                    "input": [
                        826,
                        "InvoiceRefundInput!"
                    ]
                }
            ],
            "upsertPaymentMethod": [
                1116,
                {
                    "input": [
                        1547
                    ]
                }
            ],
            "deletePaymentMethod": [
                5,
                {
                    "input": [
                        1117,
                        "PaymentMethodInput!"
                    ]
                }
            ],
            "generateInvoicePDF": [
                12,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "markInvoicePdfAsSent": [
                1,
                {
                    "input": [
                        825,
                        "InvoicePdfSentInput!"
                    ]
                }
            ],
            "sendInvoiceEmail": [
                814,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "issueInvoice": [
                814,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoice": [
                5,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "upsertSalesChannel": [
                1268,
                {
                    "input": [
                        1550,
                        "UpsertSalesChannelInput!"
                    ]
                }
            ],
            "upsertInvoiceSettings": [
                830,
                {
                    "input": [
                        1546,
                        "UpsertInvoiceSettingsInput!"
                    ]
                }
            ],
            "updateUserRolesForCompany": [
                1528,
                {
                    "args": [
                        1527,
                        "UpdateUserRolesForCompanyArgs!"
                    ]
                }
            ],
            "inviteUser": [
                813,
                {
                    "args": [
                        812,
                        "InviteUserArgs!"
                    ]
                }
            ],
            "referUser": [
                1237,
                {
                    "args": [
                        1236,
                        "ReferUserArgs!"
                    ]
                }
            ],
            "removeUser": [
                1244,
                {
                    "args": [
                        1243,
                        "RemoveUserArgs!"
                    ]
                }
            ],
            "acceptInvitation": [
                3,
                {
                    "args": [
                        2,
                        "AcceptInvitationArgs!"
                    ]
                }
            ],
            "handleUserLogin": [
                690,
                {
                    "args": [
                        689,
                        "HandleUserLoginArgs!"
                    ]
                }
            ],
            "updateUserDetails": [
                1526,
                {
                    "args": [
                        1525,
                        "UpdateUserDetailsArgs!"
                    ]
                }
            ],
            "setGoogleToken": [
                682,
                {
                    "args": [
                        681,
                        "GoogleTokenCodeArgs!"
                    ]
                }
            ],
            "disconnectGoogleAccount": [
                682
            ],
            "switchCompany": [
                1416,
                {
                    "args": [
                        1415,
                        "SwitchCompanyArgs!"
                    ]
                }
            ],
            "updateSharedGoogleDriveFolder": [
                1411,
                {
                    "args": [
                        1524,
                        "UpdateSharedGoogleDriveFolder!"
                    ]
                }
            ],
            "assignCompanyToUser": [
                86,
                {
                    "args": [
                        85,
                        "AssignCompanyToUser!"
                    ]
                }
            ],
            "impersonate": [
                706,
                {
                    "args": [
                        705,
                        "ImpersonateArgs!"
                    ]
                }
            ],
            "setUserName": [
                1331,
                {
                    "args": [
                        1330,
                        "SetUserName!"
                    ]
                }
            ],
            "createVendor": [
                1583,
                {
                    "createVendorArgs": [
                        374,
                        "CreateVendorArgs!"
                    ]
                }
            ],
            "recordTax1099VendorGroupInput": [
                5,
                {
                    "input": [
                        1232,
                        "RecordTax1099VendorGroupInput!"
                    ]
                }
            ],
            "mergeTax1099Vendors": [
                5,
                {
                    "input": [
                        966,
                        "MergeTax1099VendorsInput!"
                    ]
                }
            ],
            "splitTax1099VendorGroups": [
                5,
                {
                    "input": [
                        1391,
                        "SplitTax1099VendorGroupsInput!"
                    ]
                }
            ],
            "createGlobalVendor": [
                1583,
                {
                    "input": [
                        346,
                        "CreateGlobalVendorInput!"
                    ]
                }
            ],
            "updateCompanyVendorExternalSourceId": [
                1583,
                {
                    "args": [
                        1486,
                        "UpdateCompanyVendorExternalSourceIdVendorInput!"
                    ]
                }
            ],
            "upsertCompanyVendor": [
                1583,
                {
                    "args": [
                        1529,
                        "UpdateVendorInput!"
                    ]
                }
            ],
            "updateGlobalVendorLogo": [
                1583,
                {
                    "input": [
                        1494,
                        "UpdateGlobalVendorLogoInput!"
                    ]
                }
            ],
            "upsertGlobalVendor": [
                1583,
                {
                    "args": [
                        1540,
                        "UpsertGlobalVendorInput!"
                    ]
                }
            ],
            "unsignWorksheetRow": [
                318,
                {
                    "args": [
                        1467,
                        "UnsignWorksheetRowInput!"
                    ]
                }
            ],
            "signWorksheetRow": [
                318,
                {
                    "args": [
                        1371,
                        "SignWorksheetRowInput!"
                    ]
                }
            ],
            "storeDateForLocking": [
                318,
                {
                    "args": [
                        1399,
                        "StoreDateForLockingInput!"
                    ]
                }
            ],
            "getPresignedUploadUrl": [
                1166,
                {
                    "args": [
                        633,
                        "GetPresignedUrlInput!"
                    ]
                }
            ],
            "updateComments": [
                318,
                {
                    "args": [
                        1480,
                        "UpdateCommentsInput!"
                    ]
                }
            ],
            "unsignWorksheetRowV2": [
                318,
                {
                    "args": [
                        1468,
                        "UnsignWorksheetRowInputV2!"
                    ]
                }
            ],
            "signWorksheetRowV2": [
                318,
                {
                    "args": [
                        1372,
                        "SignWorksheetRowInputV2!"
                    ]
                }
            ],
            "storeDateForLockingV2": [
                318,
                {
                    "args": [
                        1400,
                        "StoreDateForLockingInputV2!"
                    ]
                }
            ],
            "getPresignedUploadUrlV2": [
                1166,
                {
                    "args": [
                        634,
                        "GetPresignedUrlInputV2!"
                    ]
                }
            ],
            "updateCommentsV2": [
                318,
                {
                    "args": [
                        1481,
                        "UpdateCommentsInputV2!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "NewLender": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "affiliatedEntity": [
                42
            ],
            "name": [
                1
            ],
            "supportedLoanTypes": [
                908
            ],
            "defaultLoanType": [
                908
            ],
            "isShortTerm": [
                5
            ],
            "supportedFollowUpTypes": [
                913
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchedSku": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "quantity": [
                38
            ],
            "zeroPriceQuantity": [
                38
            ],
            "restocks": [
                38
            ],
            "giveaway": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingProductsResponse": {
            "ambiguousSkuCount": [
                38
            ],
            "notFoundProductSkuCount": [
                38
            ],
            "notFoundProductSkus": [
                979
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingUnpaidBill": {
            "billId": [
                1
            ],
            "booksAmount": [
                10
            ],
            "inventoryPurchaseAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "NotFoundProductSkus": {
            "shopName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsDailyProductValidationResult": {
            "skuValidations": [
                1384
            ],
            "diff": [
                1208
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsIntegrationValidation": {
            "isValid": [
                5
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "totalCogsAmount": [
                10
            ],
            "totalBusinessEventsAmount": [
                10
            ],
            "dailyCogsType": [
                1
            ],
            "businessEventType": [
                1
            ],
            "isYoung": [
                5
            ],
            "businessEventId": [
                1
            ],
            "dailyCogsId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsOverviewValidationResult": {
            "isValid": [
                5
            ],
            "isYoung": [
                5
            ],
            "integrationValidations": [
                981
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationInput": {
            "companyId": [
                12
            ],
            "validationId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationListItem": {
            "id": [
                1
            ],
            "createdAt": [
                398
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationResponse": {
            "on_NoUnitsValidationResult": [
                987
            ],
            "on_NoUnitsValidationError": [
                983
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationResult": {
            "isValid": [
                5
            ],
            "dailyProductValidationResult": [
                980
            ],
            "overviewValidationResult": [
                982
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "error": [
                1
            ],
            "diff": [
                1208
            ],
            "__typename": [
                1
            ]
        },
        "NoUnitsValidationsListInput": {
            "companyId": [
                12
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "validationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Offer": {
            "tier": [
                991
            ],
            "payment": [
                213
            ],
            "features": [
                487
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "couponDiscount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "OffersStatus": {},
        "OfferTier": {},
        "OnboardingScope": {},
        "OnBoardingState": {
            "signupCompleted": [
                5
            ],
            "currentPage": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatus": {
            "companyId": [
                12
            ],
            "scope": [
                992
            ],
            "step": [
                1
            ],
            "status": [
                996
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatuses": {
            "hasReportedCOGSEvents": [
                5
            ],
            "hasClassifiedPurchases": [
                5
            ],
            "hasConfirmedOpeningBalance": [
                5
            ],
            "inventoryInitStatus": [
                758
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStepStatus": {},
        "OnDemandAssemblyAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnDemandAssemblyBreakdown": {
            "origin": [
                1222
            ],
            "analytics": [
                711
            ],
            "__typename": [
                1
            ]
        },
        "OneTimePayment": {
            "id": [
                12
            ],
            "price": [
                10
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "OpeningBalanceAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "averageCost": [
                10
            ],
            "isFromUserInput": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OptionalTimeRangeInput": {
            "fromUTC": [
                398
            ],
            "toUTC": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAccount": {
            "entity": [
                1003
            ],
            "account": [
                1003
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAmount": {
            "amount": [
                10
            ],
            "accountRole": [
                1
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                1002
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationsResponse": {
            "rows": [
                1004
            ],
            "otherBalances": [
                1045
            ],
            "__typename": [
                1
            ]
        },
        "OrderActionType": {},
        "OrderAdjustment": {
            "id": [
                12
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                1008
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderAdjustmentType": {},
        "OrderAggregationEntry": {
            "type": [
                1010
            ],
            "value": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "OrderAggregationType": {},
        "OrderEntity": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderEntityType": {},
        "OrderFinaloopStatus": {},
        "OrderFulfillment": {
            "id": [
                12
            ],
            "fulfilledAt": [
                398
            ],
            "fulfillmentLineItems": [
                1015
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentLineItem": {
            "id": [
                12
            ],
            "lineItemId": [
                12
            ],
            "quantity": [
                38
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentSource": {},
        "OrderFulfillmentStatus": {},
        "OrderGroup": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "orders": [
                850
            ],
            "bankTransactions": [
                849
            ],
            "expenses": [
                1021
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdInput": {
            "orderGroupId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdResponse": {
            "orderGroup": [
                1018
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpense": {
            "id": [
                12
            ],
            "expenseType": [
                1023
            ],
            "amount": [
                70
            ],
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseInput": {
            "expenseType": [
                1023
            ],
            "amount": [
                10
            ],
            "vendorId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseType": {},
        "OrderGroupLink": {
            "orderGroupId": [
                12
            ],
            "amountInHomeCurrency": [
                10
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsInput": {
            "companyId": [
                12
            ],
            "orderGroupIds": [
                12
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsResponse": {
            "orderGroups": [
                1018
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsFilter": {
            "postedAt": [
                400
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                1027
            ],
            "sort": [
                1030
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsResponse": {
            "orderGroups": [
                1018
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSort": {
            "type": [
                1032
            ],
            "direction": [
                1031
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSortDirection": {},
        "OrderGroupsSortType": {},
        "OrderGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                12
            ],
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                398
            ],
            "totalUnclearedBalance": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceInput": {
            "companyId": [
                12
            ],
            "orderGroupIds": [
                12
            ],
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                398
            ],
            "unclearedBalances": [
                1037
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupUnclearedBalance": {
            "orderGroup": [
                1018
            ],
            "unclearedBalance": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "OrderIdInput": {
            "companyId": [
                12
            ],
            "orderId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderInternalInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemsFulfillmentV2": {
            "id": [
                12
            ],
            "trackingInfoRecords": [
                842
            ],
            "externalWarehouseId": [
                1
            ],
            "voidedAt": [
                398
            ],
            "fulfilledAt": [
                398
            ],
            "fulfilledQuantity": [
                38
            ],
            "voidedBy": [
                27
            ],
            "source": [
                1016
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemStatus": {},
        "OrderItemStatusWithOverride": {
            "status": [
                1041
            ],
            "isOverridden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemType": {},
        "OrderItemV2": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "actionType": [
                1006
            ],
            "type": [
                1043
            ],
            "amount": [
                70
            ],
            "taxAmount": [
                10
            ],
            "taxAmountInHomeCurrency": [
                10
            ],
            "discountAmount": [
                10
            ],
            "discountAmountInHomeCurrency": [
                10
            ],
            "exchangeRateOverride": [
                10
            ],
            "taxExchangeRateOverride": [
                10
            ],
            "discountExchangeRateOverride": [
                10
            ],
            "taxes": [
                842
            ],
            "externalSourceId": [
                1
            ],
            "postedAt": [
                398
            ],
            "externalProductId": [
                1
            ],
            "warehouse": [
                1601
            ],
            "product": [
                1170
            ],
            "sku": [
                1
            ],
            "quantity": [
                38
            ],
            "unitPrice": [
                10
            ],
            "isRestocked": [
                5
            ],
            "fulfillments": [
                1040
            ],
            "originalFulfillmentId": [
                1
            ],
            "voided": [
                842
            ],
            "isFulfillmentOverridden": [
                5
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "status": [
                1042
            ],
            "lineItemId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderOtherAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                1003
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroup": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "orders": [
                1098
            ],
            "bankTransactions": [
                1096
            ],
            "expenses": [
                1091
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupByIdResponse": {
            "paymentGroup": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsByIdsResponse": {
            "paymentGroups": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsResponse": {
            "paymentGroups": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                398
            ],
            "unclearedBalances": [
                1051
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupUnclearedBalance": {
            "paymentGroup": [
                1046
            ],
            "unclearedBalance": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentStatus": {},
        "OrderReconciliationRow": {
            "title": [
                1
            ],
            "saleAmount": [
                10
            ],
            "taxAmount": [
                10
            ],
            "discountAmount": [
                10
            ],
            "shippingAmount": [
                10
            ],
            "refundAmount": [
                10
            ],
            "additionalTaxesAmount": [
                10
            ],
            "totalAmount": [
                10
            ],
            "netAmount": [
                10
            ],
            "subRows": [
                1053
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationRowType": {},
        "OrderReconciliationsInput": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "integrationAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationsResponse": {
            "booksRows": [
                1053
            ],
            "excludedRows": [
                1053
            ],
            "adjustedRows": [
                1053
            ],
            "shopifyRow": [
                1053
            ],
            "dateRange": [
                399
            ],
            "currency": [
                1
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestock": {
            "id": [
                12
            ],
            "postedAt": [
                398
            ],
            "restockLineItems": [
                1058
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestockLineItem": {
            "id": [
                12
            ],
            "quantity": [
                38
            ],
            "lineItemId": [
                12
            ],
            "sku": [
                1
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsResponse": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettings": {
            "void": [
                1597
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsResponse": {
            "settings": [
                1061
            ],
            "__typename": [
                1
            ]
        },
        "OrdersSettings": {
            "isZeroNetOrdersSupported": [
                5
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "shopifyStoreV2StartDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderStatus": {},
        "OrderStatusWithOverride": {
            "status": [
                1065
            ],
            "isOverridden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderTag": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderToLinkInput": {
            "orderId": [
                12
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "OrderTotalSummary": {
            "subtotal": [
                70
            ],
            "shippingIncome": [
                70
            ],
            "discounts": [
                70
            ],
            "netSale": [
                70
            ],
            "taxes": [
                70
            ],
            "additionalTaxes": [
                70
            ],
            "refunds": [
                70
            ],
            "fxAdjustment": [
                70
            ],
            "netOrder": [
                70
            ],
            "paid": [
                10
            ],
            "refunded": [
                10
            ],
            "netPaid": [
                10
            ],
            "uncollectible": [
                10
            ],
            "overCollected": [
                10
            ],
            "orderBalance": [
                10
            ],
            "balanceAsOf": [
                398
            ],
            "balanceAsOfAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionMatchV2": {
            "id": [
                12
            ],
            "amount": [
                70
            ],
            "payment": [
                1403
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionType": {},
        "OrderTransactionV2": {
            "id": [
                12
            ],
            "amount": [
                70
            ],
            "exchangeRateOverride": [
                10
            ],
            "type": [
                1071
            ],
            "postedAt": [
                398
            ],
            "matches": [
                1070
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "isManual": [
                5
            ],
            "voided": [
                842
            ],
            "authorizationCode": [
                1
            ],
            "isArtificiallyMatched": [
                5
            ],
            "matchedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "items": [
                1044
            ],
            "transactions": [
                1072
            ],
            "orderGroupLinks": [
                1024
            ],
            "orderAdjustments": [
                1007
            ],
            "totalSummary": [
                1069
            ],
            "displayName": [
                1
            ],
            "currency": [
                1
            ],
            "source": [
                1
            ],
            "placedAt": [
                398
            ],
            "firstPaymentDate": [
                398
            ],
            "fullyPaidDate": [
                398
            ],
            "firstRefundDate": [
                398
            ],
            "firstFulfillmentDate": [
                398
            ],
            "latestFinancialDate": [
                398
            ],
            "salesChannel": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "customer": [
                387
            ],
            "isZeroNetOrder": [
                5
            ],
            "integrationTags": [
                1
            ],
            "finaloopTags": [
                1067
            ],
            "finaloopNote": [
                1
            ],
            "integrationNote": [
                1
            ],
            "isChannelProductSalesAffecting": [
                5
            ],
            "isChannelAccountsAffecting": [
                5
            ],
            "isProductSalesAffecting": [
                5
            ],
            "isAccountsAffecting": [
                5
            ],
            "paymentStatus": [
                1052
            ],
            "fulfillmentStatus": [
                1017
            ],
            "balanceInHomeCurrency": [
                10
            ],
            "voidedAt": [
                398
            ],
            "shippingAddress": [
                381
            ],
            "billingAddress": [
                381
            ],
            "store": [
                1398
            ],
            "status": [
                1066
            ],
            "integrationExternalUrl": [
                1
            ],
            "fulfillments": [
                1014
            ],
            "restocks": [
                1057
            ],
            "paymentGroupLinks": [
                1095
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2NotFound": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2Result": {
            "on_OrderV2": [
                1073
            ],
            "on_OrderV2NotFound": [
                1074
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCycle": {
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "openingBalance": [
                10
            ],
            "purchasesSum": [
                10
            ],
            "cogsSum": [
                10
            ],
            "cogsAdjustmentsSum": [
                10
            ],
            "closingBalance": [
                10
            ],
            "warehouseTransfersSum": [
                10
            ],
            "physicalCountAdjustment": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCyclesInput": {
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PairedEntity": {
            "on_Bill": [
                207
            ],
            "on_Payout": [
                1124
            ],
            "on_Topup": [
                1439
            ],
            "on_BankTransaction": [
                167
            ],
            "on_CompanyAffiliate": [
                271
            ],
            "on_IntegrationTransfer": [
                741
            ],
            "__typename": [
                1
            ]
        },
        "PairedEntityType": {},
        "PairingType": {},
        "Payment": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "source": [
                754
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "capturedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PaymentCheck": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "amountInHomeCurrency": [
                10
            ],
            "contractor": [
                322
            ],
            "employee": [
                464
            ],
            "matchedBankTransaction": [
                167
            ],
            "isImmaterial": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentChecksInput": {
            "companyId": [
                12
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentDetails": {
            "id": [
                12
            ],
            "price": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGateway": {},
        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGatewayType": {},
        "PaymentGroup": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupBankTransactionToLinkInput": {
            "transactionId": [
                12
            ],
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupByIdInput": {
            "paymentGroupId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpense": {
            "id": [
                12
            ],
            "expenseType": [
                1093
            ],
            "amount": [
                70
            ],
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseInput": {
            "expenseType": [
                1093
            ],
            "amount": [
                10
            ],
            "vendorId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseType": {},
        "PaymentGroupInvoiceToLinkInput": {
            "invoiceId": [
                12
            ],
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLink": {
            "paymentGroupId": [
                12
            ],
            "amount": [
                10
            ],
            "amountInHomeCurrency": [
                10
            ],
            "currency": [
                377
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedBankTransaction": {
            "bankTransaction": [
                167
            ],
            "bankTransactionPostedAt": [
                398
            ],
            "amount": [
                70
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedInvoice": {
            "invoice": [
                814
            ],
            "amountInHomeCurrency": [
                10
            ],
            "currency": [
                377
            ],
            "amount": [
                10
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedOrder": {
            "order": [
                1073
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceInput": {
            "companyId": [
                1
            ],
            "invoiceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceResponse": {
            "paymentGroupLinks": [
                1095
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksInput": {
            "companyId": [
                1
            ],
            "saleId": [
                1
            ],
            "paymentGroupType": [
                1115
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksResponse": {
            "paymentGroupLinks": [
                1095
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupOrderToLinkInput": {
            "orderId": [
                12
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsInput": {
            "companyId": [
                12
            ],
            "paymentGroupIds": [
                12
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsResponse": {
            "paymentGroups": [
                833
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsFilter": {
            "postedAt": [
                400
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                1106
            ],
            "sort": [
                1108
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSort": {
            "type": [
                1110
            ],
            "direction": [
                1109
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSortDirection": {},
        "PaymentGroupsSortType": {},
        "PaymentGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                12
            ],
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                398
            ],
            "totalUnclearedBalance": [
                70
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsUnclearedBalanceInput": {
            "companyId": [
                12
            ],
            "paymentGroupIds": [
                12
            ],
            "asOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupTransaction": {
            "id": [
                12
            ],
            "integrationInfo": [
                736
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                1445
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "paymentGroup": [
                1088
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupType": {},
        "PaymentMethod": {
            "id": [
                12
            ],
            "type": [
                1118
            ],
            "isDefault": [
                5
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodType": {},
        "PaymentOptionInput": {
            "stripeId": [
                1
            ],
            "price": [
                10
            ],
            "recurrence": [
                1122
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorInput": {
            "companyId": [
                12
            ],
            "vendorId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorResponse": {
            "processor": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentRecurrence": {},
        "PaymentsByEntityInput": {
            "companyId": [
                12
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "Payout": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "amount": [
                10
            ],
            "amountInUsd": [
                10
            ],
            "currency": [
                1
            ],
            "eventTime": [
                398
            ],
            "deletedAt": [
                398
            ],
            "integrationType": [
                754
            ],
            "pairedBankTransaction": [
                167
            ],
            "type": [
                753
            ],
            "classification": [
                742
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                271
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Payroll": {
            "id": [
                12
            ],
            "company": [
                267
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "postedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategory": {},
        "PayrollCategoryMapping": {
            "on_PayrollDepartmentCategoryMapping": [
                1137
            ],
            "on_PayrollEmployeeCategoryMapping": [
                1138
            ],
            "on_PayrollContractorCategoryMapping": [
                1134
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingInput": {
            "id": [
                12
            ],
            "type": [
                1144
            ],
            "category": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingMode": {},
        "PayrollConnection": {
            "integrationAccountId": [
                12
            ],
            "integrationType": [
                754
            ],
            "integrationAccountName": [
                1
            ],
            "importStatus": [
                1131
            ],
            "disconnectedAt": [
                398
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatus": {
            "contractorPayments": [
                1132
            ],
            "contractors": [
                1132
            ],
            "departments": [
                1132
            ],
            "employees": [
                1132
            ],
            "payrolls": [
                1132
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatusData": {
            "lastUpdateTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PayrollContractorCategoryMapping": {
            "contractor": [
                322
            ],
            "category": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyAggregate": {
            "id": [
                12
            ],
            "currency": [
                1
            ],
            "postedAt": [
                398
            ],
            "source": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "unallocatedHealthBenefitsContribution": [
                10
            ],
            "unallocatedCompanyTaxes": [
                10
            ],
            "unallocatedDonationDeductions": [
                10
            ],
            "pension401k": [
                10
            ],
            "deletedAt": [
                398
            ],
            "paymentChecks": [
                391
            ],
            "reimbursements": [
                393
            ],
            "ownersDraw": [
                390
            ],
            "taxDebitAmount": [
                10
            ],
            "netPayDebitAmount": [
                10
            ],
            "dailyAggregates": [
                392
            ],
            "unpaidAmountsViaProvider": [
                1465
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyDataInput": {
            "companyId": [
                12
            ],
            "payrollIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDepartmentCategoryMapping": {
            "department": [
                443
            ],
            "category": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmployeeCategoryMapping": {
            "employee": [
                464
            ],
            "category": [
                1126
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmploymentType": {},
        "PayrollIdsByCompanyInput": {
            "companyIds": [
                12
            ],
            "afterBooksStartTimeOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PayrollIdsByCompanyItem": {
            "companyId": [
                1
            ],
            "payrollIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PayrollIdsByCompanyResponse": {
            "items": [
                1141
            ],
            "__typename": [
                1
            ]
        },
        "PayrollItemToReconcile": {
            "companyId": [
                12
            ],
            "payrollId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PayrollMappableType": {},
        "PayrollSettings": {
            "withCategoryMapping": [
                5
            ],
            "categoryMappingMode": [
                1129
            ],
            "categoryMappings": [
                1127
            ],
            "__typename": [
                1
            ]
        },
        "PayrollSettingsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PendingProductAdjustmentsDataType": {},
        "PendingSalesDataAdjustments": {
            "salesChannel": [
                1266
            ],
            "date": [
                398
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "warehouseId": [
                1
            ],
            "warehouse": [
                1601
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsItem": {
            "productId": [
                1
            ],
            "product": [
                1170
            ],
            "pendingSalesDataAdjustments": [
                1148
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsOverviewResponse": {
            "count": [
                38
            ],
            "pendingCount": [
                38
            ],
            "missingDataCount": [
                38
            ],
            "lastPendingChange": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PendingSalesDataAdjustmentsResponse": {
            "items": [
                1149
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalance": {
            "id": [
                12
            ],
            "warehouse": [
                1601
            ],
            "quantity": [
                38
            ],
            "costPerUnit": [
                10
            ],
            "date": [
                398
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalanceAttachment": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "date": [
                398
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "createdAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "pendingChangesCount": [
                38
            ],
            "summaryData": [
                1154
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesAttachmentSummary": {
            "totalCount": [
                38
            ],
            "validCount": [
                38
            ],
            "invalidCount": [
                38
            ],
            "errorCount": [
                38
            ],
            "errorItems": [
                1616
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesResponse": {
            "items": [
                1618
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesWithCsvResponse": {
            "attachmentId": [
                12
            ],
            "summaryData": [
                1154
            ],
            "__typename": [
                1
            ]
        },
        "PeriodDates": {
            "periodStart": [
                398
            ],
            "periodEnd": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "PeriodicPartnerReport": {
            "company": [
                267
            ],
            "partner": [
                1249
            ],
            "createdAt": [
                398
            ],
            "latestActorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PeriodicPartnerReportInput": {
            "companyId": [
                12
            ],
            "partnerId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "Permission": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PhysicalCount": {
            "id": [
                1
            ],
            "amount": [
                10
            ],
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "companyId": [
                1
            ],
            "deletedAt": [
                398
            ],
            "attachments": [
                1162
            ],
            "diff": [
                10
            ],
            "lastAcknowledgedDiff": [
                10
            ],
            "closingBalance": [
                10
            ],
            "lastAcknowledgedClosingBalance": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "PhysicalCountAttachment": {
            "id": [
                1
            ],
            "physicalCountId": [
                1
            ],
            "name": [
                1
            ],
            "url": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PhysicalCountError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PhysicalCountList": {
            "physicalCounts": [
                1161
            ],
            "__typename": [
                1
            ]
        },
        "PollCheckoutSessionStatusInput": {
            "checkoutSessionId": [
                1
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "PresignedUrl": {
            "url": [
                1
            ],
            "presignedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentInput": {
            "companyId": [
                12
            ],
            "filePathInBucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentResult": {
            "isSuccessful": [
                5
            ],
            "id": [
                12
            ],
            "vendor": [
                1583
            ],
            "categories": [
                240
            ],
            "date": [
                398
            ],
            "dueDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ProcessCheckoutWebhookEventsInput": {
            "webhookEvent": [
                842
            ],
            "__typename": [
                1
            ]
        },
        "Product": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "price": [
                10
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "on_AmazonProduct": [
                54
            ],
            "on_MergedProduct": [
                958
            ],
            "on_ShopifyProduct": [
                1360
            ],
            "on_TikTokProduct": [
                1433
            ],
            "on_UserManualProduct": [
                1567
            ],
            "on_WalmartProduct": [
                1600
            ],
            "__typename": [
                1
            ]
        },
        "ProductAnalyticsOverview": {
            "inTransit": [
                10
            ],
            "incoming": [
                10
            ],
            "netDepleted": [
                10
            ],
            "onHand": [
                10
            ],
            "costPerUnit": [
                10
            ],
            "onHandCost": [
                10
            ],
            "unavailable": [
                10
            ],
            "openingBalanceCost": [
                10
            ],
            "openingBalanceQuantity": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCost": {
            "asOfDate": [
                398
            ],
            "appliedCost": [
                10
            ],
            "source": [
                1173
            ],
            "isLocked": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCostSource": {},
        "ProductAppliedCostWithDepletedCount": {
            "asOfDate": [
                398
            ],
            "appliedCost": [
                10
            ],
            "source": [
                1173
            ],
            "depletedCount": [
                38
            ],
            "isLocked": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownResult": {
            "manualAssemblies": [
                931
            ],
            "onDemandAssemblies": [
                998
            ],
            "__typename": [
                1
            ]
        },
        "ProductAttributesTypes": {},
        "ProductDataTimeRangeInput": {
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustment": {
            "id": [
                1
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsDeadInventory": [
                10
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustments": {
            "adjustments": [
                1179
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsDonated": [
                38
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustments": {
            "adjustments": [
                1181
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsGiveaway": [
                38
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustments": {
            "adjustments": [
                1183
            ],
            "__typename": [
                1
            ]
        },
        "ProductPurchases": {
            "purchaseOrder": [
                1199
            ],
            "__typename": [
                1
            ]
        },
        "Products2Result": {
            "products": [
                1170
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustment": {
            "salesChannel": [
                1266
            ],
            "date": [
                398
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustments": {
            "adjustments": [
                1187
            ],
            "__typename": [
                1
            ]
        },
        "ProductSource": {},
        "ProductsOverviewInput": {
            "companyId": [
                12
            ],
            "filter": [
                636
            ],
            "__typename": [
                1
            ]
        },
        "ProductsOverviewResponse": {
            "totalOnHandInventory": [
                10
            ],
            "totalNegativeOnHand": [
                10
            ],
            "productsWithoutCostCount": [
                38
            ],
            "totalProducts": [
                38
            ],
            "totalHidden": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ProductsResult": {
            "totalProducts": [
                38
            ],
            "productsWithoutCostCount": [
                38
            ],
            "totalOnHandInventory": [
                10
            ],
            "totalNegativeOnHand": [
                38
            ],
            "totalHidden": [
                38
            ],
            "products": [
                1170
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductsSortDirection": {},
        "ProductStatusChangeInput": {
            "productId": [
                1
            ],
            "isHidden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationData": {
            "skuData": [
                1383
            ],
            "ambiguousSkus": [
                976
            ],
            "unknownSkus": [
                976
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationDataInput": {
            "companyId": [
                12
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isUIOperation": [
                5
            ],
            "timeRange": [
                1178
            ],
            "__typename": [
                1
            ]
        },
        "ProductWarehousesAnalyticsOverview": {
            "warehouse": [
                1601
            ],
            "analytics": [
                1171
            ],
            "__typename": [
                1
            ]
        },
        "PurchasedOffer": {
            "purchasedAt": [
                398
            ],
            "tier": [
                991
            ],
            "features": [
                487
            ],
            "price": [
                10
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "recurrence": [
                1122
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrder": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                398
            ],
            "inProgressDate": [
                398
            ],
            "vendor": [
                1583
            ],
            "directLineItems": [
                445
            ],
            "indirectLineItems": [
                714
            ],
            "shipments": [
                1334
            ],
            "directCostTotal": [
                10
            ],
            "indirectCostTotal": [
                10
            ],
            "landedCostTotal": [
                10
            ],
            "landedCostBreakdown": [
                1203
            ],
            "discountCostTotal": [
                10
            ],
            "status": [
                1207
            ],
            "publishedEventIds": [
                1
            ],
            "deletedAt": [
                398
            ],
            "isDeleted": [
                5
            ],
            "serialNumber": [
                38
            ],
            "notes": [
                1
            ],
            "attachments": [
                1200
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachment": {
            "id": [
                12
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachmentInput": {
            "id": [
                1
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                398
            ],
            "vendorId": [
                12
            ],
            "directLineItems": [
                446
            ],
            "indirectLineItems": [
                715
            ],
            "shipments": [
                1342
            ],
            "directCostTotal": [
                10
            ],
            "indirectCostTotal": [
                10
            ],
            "landedCostTotal": [
                10
            ],
            "discountCostTotal": [
                10
            ],
            "inProgressDate": [
                398
            ],
            "notes": [
                1
            ],
            "attachments": [
                1201
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderLandedCostBreakdown": {
            "inTransit": [
                10
            ],
            "inProcess": [
                10
            ],
            "received": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverview": {
            "status": [
                1207
            ],
            "landedCostTotal": [
                10
            ],
            "totalPurchaseOrders": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverviewV2": {
            "maxLandedCostTotal": [
                10
            ],
            "purchaseOrderOverview": [
                1204
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrdersSortField": {},
        "PurchaseOrderStatus": {},
        "QuantityAndCost": {
            "quantity": [
                38
            ],
            "cost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "Query": {
            "accrualsSpreadByTransactionIDs": [
                17,
                {
                    "input": [
                        535,
                        "GetAccrualsSpreadInput!"
                    ]
                }
            ],
            "getAccrualsByTransactionIDs": [
                11,
                {
                    "input": [
                        534,
                        "GetAccrualsByTransactionIDsInput!"
                    ]
                }
            ],
            "reportedBusinessBankTransactionsAccount": [
                97,
                {
                    "input": [
                        1248,
                        "ReportedBusinessBankTransactionsAccountInput!"
                    ]
                }
            ],
            "bankAccountInternal": [
                97,
                {
                    "input": [
                        104,
                        "BankAccountInternalInput!"
                    ]
                }
            ],
            "institutionVendorsIds": [
                726,
                {
                    "vendorId": [
                        12
                    ]
                }
            ],
            "institutionMappings": [
                720,
                {
                    "input": [
                        721
                    ]
                }
            ],
            "institutionById": [
                718,
                {
                    "id": [
                        12,
                        "ID!"
                    ]
                }
            ],
            "bankStatementParsingRequest": [
                149,
                {
                    "input": [
                        538,
                        "GetBankStatementParsingRequestInput!"
                    ]
                }
            ],
            "bankStatementParsingResults": [
                153,
                {
                    "input": [
                        539,
                        "GetBankStatementParsingResultsInput!"
                    ]
                }
            ],
            "bankAccount": [
                97,
                {
                    "input": [
                        103,
                        "BankAccountInput!"
                    ]
                }
            ],
            "bankAccounts": [
                97,
                {
                    "input": [
                        115,
                        "BankAccountsInput!"
                    ]
                }
            ],
            "bankAccountsByIds": [
                97,
                {
                    "input": [
                        114,
                        "BankAccountsByIdsInput!"
                    ]
                }
            ],
            "bankAccountReconciliations": [
                111,
                {
                    "input": [
                        112,
                        "BankAccountReconciliationsInput!"
                    ]
                }
            ],
            "bankAccountBalance": [
                10,
                {
                    "input": [
                        99,
                        "BankAccountBalanceInput!"
                    ]
                }
            ],
            "institutions": [
                725,
                {
                    "input": [
                        724
                    ]
                }
            ],
            "bankReconciliationRequest": [
                135,
                {
                    "input": [
                        137,
                        "BankReconciliationRequestInput!"
                    ]
                }
            ],
            "bankReconciliationRequests": [
                135,
                {
                    "input": [
                        138,
                        "BankReconciliationRequestsInput!"
                    ]
                }
            ],
            "bankTransaction": [
                167,
                {
                    "getBankTransactionInput": [
                        179,
                        "BankTransactionInput!"
                    ]
                }
            ],
            "bankTransactions": [
                183,
                {
                    "input": [
                        186,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "bankTransactionsByMerchant": [
                172,
                {
                    "bankTransactionsByMerchantInput": [
                        184,
                        "BankTransactionsByMerchantInput!"
                    ]
                }
            ],
            "searchMerchants": [
                947,
                {
                    "input": [
                        1296,
                        "SearchMerchantsInput!"
                    ]
                }
            ],
            "searchVendorsTransactionsPriority": [
                1298,
                {
                    "input": [
                        1297,
                        "SearchVendorsTransactionPriorityInput!"
                    ]
                }
            ],
            "classificationTags": [
                251,
                {
                    "input": [
                        254
                    ]
                }
            ],
            "classificationTagsByIds": [
                251,
                {
                    "input": [
                        253,
                        "ClassificationTagsByIdsInput!"
                    ]
                }
            ],
            "internalTransferCandidates": [
                183,
                {
                    "input": [
                        755,
                        "InternalTransferCandidatesInput!"
                    ]
                }
            ],
            "internalTransferCandidatesV2": [
                187,
                {
                    "input": [
                        756,
                        "internalTransferCandidatesV2Input!"
                    ]
                }
            ],
            "unidentifiedBankTransactionsGroups": [
                1452,
                {
                    "input": [
                        1453,
                        "UnidentifiedBankTransactionsGroupsInput!"
                    ]
                }
            ],
            "merchantsBankTransactionStats": [
                952,
                {
                    "input": [
                        953,
                        "MerchantsBankTransactionStatsInput!"
                    ]
                }
            ],
            "bankTransactionsSummary": [
                189,
                {
                    "input": [
                        186,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "merchantsByIds": [
                947,
                {
                    "input": [
                        954,
                        "MerchantsByIdsInput!"
                    ]
                }
            ],
            "suggestBankTransactionClassification": [
                1413,
                {
                    "input": [
                        1412,
                        "SuggestBankTransactionClassificationInput!"
                    ]
                }
            ],
            "lastMachineClassificationJob": [
                926,
                {
                    "input": [
                        925,
                        "MachineClassificationInput!"
                    ]
                }
            ],
            "merchantsByKeys": [
                947,
                {
                    "input": [
                        955,
                        "MerchantsByKeysInput!"
                    ]
                }
            ],
            "bankTransactionUploads": [
                190,
                {
                    "input": [
                        198,
                        "BankTransactionUploadsInput!"
                    ]
                }
            ],
            "bankTransactionsByPairings": [
                182,
                {
                    "input": [
                        185,
                        "BankTransactionsByPairingsInput!"
                    ]
                }
            ],
            "isGlobalRuleExistByVendorAndMoneyDirection": [
                836,
                {
                    "input": [
                        675,
                        "GlobalRuleExistByVendorAndMoneyDirectionInput!"
                    ]
                }
            ],
            "companyByStripeCustomerId": [
                267,
                {
                    "args": [
                        285,
                        "CompanyByStripeCustomerIdInput!"
                    ]
                }
            ],
            "stripeCustomerIdsByCompanyIds": [
                1408,
                {
                    "args": [
                        1407,
                        "StripeCustomerIdsByCompanyIdsInput!"
                    ]
                }
            ],
            "offers": [
                614,
                {
                    "args": [
                        613,
                        "GetOffersInput!"
                    ]
                }
            ],
            "billsByIds": [
                207,
                {
                    "input": [
                        12,
                        "[ID!]!"
                    ]
                }
            ],
            "billsDueDateSummary": [
                219,
                {
                    "input": [
                        220,
                        "BillsDueDateSummaryInput!"
                    ]
                }
            ],
            "processBillContent": [
                1168,
                {
                    "input": [
                        1167,
                        "ProcessBillContentInput!"
                    ]
                }
            ],
            "bills": [
                541,
                {
                    "input": [
                        540,
                        "GetBillsInput!"
                    ]
                }
            ],
            "billsAgingReportCSV": [
                94,
                {
                    "args": [
                        218,
                        "BillsAgingReportInput!"
                    ]
                }
            ],
            "businessEventDefinitions": [
                226
            ],
            "businessEventDefinitionsByIds": [
                226,
                {
                    "input": [
                        228,
                        "BusinessEventDefinitionsByIds!"
                    ]
                }
            ],
            "businessEventDefinitionsByGroups": [
                226,
                {
                    "input": [
                        227,
                        "BusinessEventDefinitionsByGroups!"
                    ]
                }
            ],
            "businessEventActionsConfigurations": [
                222
            ],
            "businessEventActionsConfigurationByType": [
                222,
                {
                    "input": [
                        223,
                        "BusinessEventActionsConfigurationByType!"
                    ]
                }
            ],
            "classificationBusinessEventDefinitions": [
                226
            ],
            "businessEventsAccrualsConfig": [
                232
            ],
            "company": [
                267,
                {
                    "args": [
                        551,
                        "GetCompanyInput!"
                    ]
                }
            ],
            "getCompanyAddresses": [
                269,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getCompanyDefaultAddress": [
                269,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "signedDownloadUrl": [
                1,
                {
                    "args": [
                        1370,
                        "SignedDownloadUrlInput!"
                    ]
                }
            ],
            "onboardingStatus": [
                994,
                {
                    "input": [
                        616,
                        "GetOnboardingStatusInput!"
                    ]
                }
            ],
            "listCompanies": [
                267,
                {
                    "args": [
                        857
                    ]
                }
            ],
            "getCompanies": [
                267,
                {
                    "args": [
                        550
                    ]
                }
            ],
            "getCompaniesByIds": [
                267,
                {
                    "args": [
                        549,
                        "GetCompaniesByIdsInput!"
                    ]
                }
            ],
            "healthStatus": [
                289,
                {
                    "input": [
                        571,
                        "GetHealthStatusInput!"
                    ]
                }
            ],
            "getLatestValidation": [
                598,
                {
                    "input": [
                        597,
                        "GetLatestValidationInput!"
                    ]
                }
            ],
            "validationTemplates": [
                1581,
                {
                    "input": [
                        663,
                        "GetValidationTemplatesInput!"
                    ]
                }
            ],
            "listReadyValidations": [
                1212,
                {
                    "input": [
                        860,
                        "ListReadyValidationsInput!"
                    ]
                }
            ],
            "getReadyValidation": [
                644,
                {
                    "input": [
                        643,
                        "GetReadyValidationInput!"
                    ]
                }
            ],
            "companyMainHolder": [
                271,
                {
                    "input": [
                        303,
                        "CompanyMainHolderInput!"
                    ]
                }
            ],
            "companyAffiliatesByIds": [
                271,
                {
                    "input": [
                        273,
                        "CompanyAffiliatesByIdsInput!"
                    ]
                }
            ],
            "affiliatedEntityById": [
                42,
                {
                    "input": [
                        43,
                        "AffiliatedEntityByIdInput!"
                    ]
                }
            ],
            "holderTransactionsByIds": [
                700,
                {
                    "input": [
                        701,
                        "HolderTransactionsByIdsInput!"
                    ]
                }
            ],
            "holderSharesByIds": [
                696,
                {
                    "input": [
                        697,
                        "HolderSharesByIdsInput!"
                    ]
                }
            ],
            "holderCapitalInvestmentsByIds": [
                693,
                {
                    "input": [
                        694,
                        "HolderCapitalInvestmentsByIdsInput!"
                    ]
                }
            ],
            "companyAffiliates": [
                271,
                {
                    "input": [
                        274,
                        "CompanyAffiliatesInput!"
                    ]
                }
            ],
            "companyAffiliate": [
                271,
                {
                    "input": [
                        272,
                        "CompanyAffiliateInput!"
                    ]
                }
            ],
            "holderShares": [
                696,
                {
                    "input": [
                        698,
                        "HolderSharesInput!"
                    ]
                }
            ],
            "holderCapitalInvestments": [
                693,
                {
                    "input": [
                        695,
                        "HolderCapitalInvestmentsInput!"
                    ]
                }
            ],
            "holderTransactions": [
                700,
                {
                    "input": [
                        702,
                        "HolderTransactionsInput!"
                    ]
                }
            ],
            "companyIntegration": [
                293,
                {
                    "input": [
                        294,
                        "CompanyIntegrationInput!"
                    ]
                }
            ],
            "companyIntegrations": [
                293,
                {
                    "input": [
                        295,
                        "CompanyIntegrationsInput!"
                    ]
                }
            ],
            "bankConnections": [
                126,
                {
                    "input": [
                        131,
                        "BankConnectionsInput!"
                    ]
                }
            ],
            "payrollConnections": [
                1130,
                {
                    "input": [
                        1133,
                        "PayrollConnectionsInput!"
                    ]
                }
            ],
            "integrationConnectionAccounts": [
                728,
                {
                    "input": [
                        729,
                        "IntegrationConnectionsFilter!"
                    ]
                }
            ],
            "integrationTransfersByCompanyAffiliate": [
                741,
                {
                    "input": [
                        750,
                        "IntegrationTransfersByCompanyAffiliateInput!"
                    ]
                }
            ],
            "integrationTransfersBackoffice": [
                741,
                {
                    "input": [
                        574,
                        "GetIntegrationTransfersBackofficeInput!"
                    ]
                }
            ],
            "integrationTransfer": [
                741,
                {
                    "input": [
                        573,
                        "GetIntegrationTransferInput!"
                    ]
                }
            ],
            "integrationTransfers": [
                741,
                {
                    "input": [
                        575,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "groupedIntegrationTransfers": [
                744,
                {
                    "input": [
                        575,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "finaloopProductInternal": [
                508,
                {
                    "input": [
                        509,
                        "FinaloopProductInternalInput!"
                    ]
                }
            ],
            "inventoryPurchasesForCompany": [
                759,
                {
                    "input": [
                        581,
                        "GetInventoryPurchasesForCompanyInput!"
                    ]
                }
            ],
            "inventoryPurchasesByIds": [
                759,
                {
                    "input": [
                        578,
                        "GetInventoryPurchasesByIdsInput!"
                    ]
                }
            ],
            "explainFifo": [
                471,
                {
                    "input": [
                        470,
                        "ExplainFifoInput!"
                    ]
                }
            ],
            "getCOGSBreakdown2": [
                543,
                {
                    "input": [
                        542,
                        "GetCOGSBreakdownRequest!"
                    ]
                }
            ],
            "exportCOGSBreakdown": [
                476,
                {
                    "input": [
                        475,
                        "ExportCOGSBreakdownRequest!"
                    ]
                }
            ],
            "getCOGSOverview2": [
                548,
                {
                    "input": [
                        547,
                        "GetCOGSOverviewRequest!"
                    ]
                }
            ],
            "exportCOGSOverview": [
                478,
                {
                    "input": [
                        477,
                        "ExportCOGSOverviewRequest!"
                    ]
                }
            ],
            "product": [
                1170,
                {
                    "input": [
                        635,
                        "GetProductInput!"
                    ]
                }
            ],
            "productAssembliesInventoryBreakdown": [
                1176,
                {
                    "input": [
                        1175,
                        "ProductAssembliesBreakdownInput!"
                    ]
                }
            ],
            "productsCsv": [
                483,
                {
                    "input": [
                        637,
                        "GetProductsInput!"
                    ]
                }
            ],
            "products2": [
                1186,
                {
                    "input": [
                        637,
                        "GetProductsInput!"
                    ]
                }
            ],
            "productsOverview": [
                1191,
                {
                    "input": [
                        1190,
                        "ProductsOverviewInput!"
                    ]
                }
            ],
            "purchaseOrder": [
                1199,
                {
                    "input": [
                        640,
                        "GetPurchaseOrderInput!"
                    ]
                }
            ],
            "purchaseOrders": [
                1199,
                {
                    "input": [
                        641,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2": [
                1199,
                {
                    "input": [
                        641,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2Total": [
                38,
                {
                    "input": [
                        641,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersOverview": [
                1204,
                {
                    "input": [
                        642,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "purchaseOrdersOverviewV2": [
                1205,
                {
                    "input": [
                        642,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "warehouses": [
                1624,
                {
                    "input": [
                        670,
                        "GetWarehousesInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryOverview": [
                1613,
                {
                    "input": [
                        1612,
                        "WarehouseIncomingInventoryOverviewInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryBreakdown": [
                1611,
                {
                    "input": [
                        1610,
                        "WarehouseIncomingInventoryBreakdownInput!"
                    ]
                }
            ],
            "warehouseDefaults": [
                1628,
                {
                    "input": [
                        669,
                        "GetWarehousesDefaultsInput!"
                    ]
                }
            ],
            "salesChannels": [
                1266,
                {
                    "input": [
                        1277,
                        "SalesChannelsInput!"
                    ]
                }
            ],
            "currentlyRunningInventoryDataSync": [
                379,
                {
                    "input": [
                        378,
                        "CurrentlyRunningInventoryDataSyncInput!"
                    ]
                }
            ],
            "inventoryAccountBalance": [
                577,
                {
                    "input": [
                        576,
                        "GetInventoryAccountBalanceInput!"
                    ]
                }
            ],
            "getOnboardingStatuses": [
                995,
                {
                    "input": [
                        615,
                        "GetOnboardingStatusesInput!"
                    ]
                }
            ],
            "nonMatchedProducts": [
                977,
                {
                    "input": [
                        612,
                        "GetNonMatchingProductsInput!"
                    ]
                }
            ],
            "getFromEmailAddress": [
                566,
                {
                    "input": [
                        564,
                        "GetFromEmailAddressInput!"
                    ]
                }
            ],
            "inventoryPurchases": [
                774,
                {
                    "input": [
                        582,
                        "GetInventoryPurchasesInput!"
                    ]
                }
            ],
            "inventoryPurchasesExport": [
                771,
                {
                    "input": [
                        579,
                        "GetInventoryPurchasesExportInput!"
                    ]
                }
            ],
            "inventoryPurchasesSummary": [
                778,
                {
                    "input": [
                        583,
                        "GetInventoryPurchaseSummaryInput!"
                    ]
                }
            ],
            "inventoryPurchasesValidationResult": [
                784,
                {
                    "input": [
                        584,
                        "GetInventoryPurchasesValidationResult!"
                    ]
                }
            ],
            "inventoryOverviewCycles": [
                1076,
                {
                    "input": [
                        1077,
                        "OverviewCyclesInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliation": [
                1362,
                {
                    "input": [
                        1347,
                        "ShopifyItemsReconciliationInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrders": [
                1353,
                {
                    "input": [
                        1352,
                        "ShopifyItemsReconciliationOrdersInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrdersDiff": [
                1350,
                {
                    "input": [
                        1349,
                        "ShopifyItemsReconciliationOrdersDiffInput!"
                    ]
                }
            ],
            "amazonProductsReconciliation": [
                55,
                {
                    "input": [
                        67,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "amazonAvailableValidationData": [
                51,
                {
                    "input": [
                        50,
                        "AmazonAvailableValidationDataInput!"
                    ]
                }
            ],
            "amazonOrdersDiff": [
                53,
                {
                    "input": [
                        67,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "fifoValidation": [
                496,
                {
                    "input": [
                        492,
                        "FifoValidationInput!"
                    ]
                }
            ],
            "fifoValidationsList": [
                498,
                {
                    "input": [
                        497,
                        "FifoValidationsListInput!"
                    ]
                }
            ],
            "lastBigValidation": [
                205,
                {
                    "input": [
                        846,
                        "LastBigValidationInput!"
                    ]
                }
            ],
            "inventoryVendorsOverview": [
                804,
                {
                    "input": [
                        592,
                        "GetInventoryVendorsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendors": [
                805,
                {
                    "input": [
                        591,
                        "GetInventoryVendorsInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalance": [
                803,
                {
                    "input": [
                        589,
                        "GetInventoryVendorRollingBalanceInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalanceOverview": [
                802,
                {
                    "input": [
                        590,
                        "GetInventoryVendorRollingBalanceOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorBills": [
                793,
                {
                    "input": [
                        585,
                        "GetInventoryVendorBillsInput!"
                    ]
                }
            ],
            "inventoryVendorBillsOverview": [
                792,
                {
                    "input": [
                        586,
                        "GetInventoryVendorBillsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorFilterValues": [
                795,
                {
                    "input": [
                        587,
                        "GetInventoryVendorFilterValuesInput!"
                    ]
                }
            ],
            "inventoryVendorParams": [
                797,
                {
                    "input": [
                        588,
                        "GetInventoryVendorParamsInput!"
                    ]
                }
            ],
            "warehouseOpeningBalanceOverview": [
                1619,
                {
                    "input": [
                        667,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseOpeningBalances": [
                1623,
                {
                    "input": [
                        668,
                        "GetWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "pendingWarehouseOpeningBalancesAttachments": [
                1153,
                {
                    "input": [
                        632,
                        "GetPendingWarehouseOpeningBalancesAttachmentsInput!"
                    ]
                }
            ],
            "getWarehouseOpeningBalancesCsvTemplate": [
                1620,
                {
                    "input": [
                        667,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseTransfersOverview": [
                1656,
                {
                    "input": [
                        266,
                        "CommonWarehouseTransfersFilterInput!"
                    ]
                }
            ],
            "warehouseTransfers": [
                1657,
                {
                    "input": [
                        672,
                        "GetWarehouseTransfersInput!"
                    ]
                }
            ],
            "warehouseTransfer": [
                1634,
                {
                    "input": [
                        671,
                        "GetWarehouseTransferInput!"
                    ]
                }
            ],
            "pendingSalesDataAdjustments": [
                1151,
                {
                    "input": [
                        628,
                        "GetPendingSalesDataAdjustmentsInput!"
                    ]
                }
            ],
            "pendingSalesDataAdjustmentsOverview": [
                1150,
                {
                    "input": [
                        629,
                        "GetPendingSalesDataAdjustmentsOverviewInput!"
                    ]
                }
            ],
            "listPhysicalCount": [
                859,
                {
                    "input": [
                        858,
                        "ListPhysicalCountInput!"
                    ]
                }
            ],
            "loans": [
                903,
                {
                    "input": [
                        606,
                        "GetLoansInput!"
                    ]
                }
            ],
            "getMatchingEffectRule": [
                611,
                {
                    "input": [
                        609,
                        "GetMatchingEffectRuleInput!"
                    ]
                }
            ],
            "loansEventsEffectConfiguration": [
                901
            ],
            "getLoansById": [
                900,
                {
                    "input": [
                        604,
                        "GetLoansByIdInput!"
                    ]
                }
            ],
            "exportLoans": [
                482,
                {
                    "input": [
                        481,
                        "ExportLoansCsvInput!"
                    ]
                }
            ],
            "getLoanEvents": [
                602,
                {
                    "input": [
                        599,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanRelationships": [
                603,
                {
                    "input": [
                        599,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanEventsLength": [
                601,
                {
                    "input": [
                        600,
                        "GetLoanEventsLengthInput!"
                    ]
                }
            ],
            "accountRoles": [
                1
            ],
            "loanEventDefinitions": [
                881
            ],
            "getLoansUpdatedBy": [
                1
            ],
            "knownLenders": [
                975
            ],
            "companyLoans": [
                299,
                {
                    "input": [
                        553,
                        "GetCompanyLoansInput!"
                    ]
                }
            ],
            "companyLoan": [
                298,
                {
                    "input": [
                        552,
                        "GetCompanyLoanInput!"
                    ]
                }
            ],
            "getAttachmentTargetURLS": [
                88,
                {
                    "input": [
                        537,
                        "GetAttachmentTargetURLSInput!"
                    ]
                }
            ],
            "downloadAttachment": [
                456,
                {
                    "input": [
                        455,
                        "DownloadAttachmentInput!"
                    ]
                }
            ],
            "getLoansOutstandingAmountOnDate": [
                608,
                {
                    "input": [
                        607,
                        "GetLoansOutstandingAmountOnDateInput!"
                    ]
                }
            ],
            "companyArCashDiffs": [
                281,
                {
                    "input": [
                        279,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "orderReconciliations": [
                1056,
                {
                    "input": [
                        1055,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "orderAccountingReconciliations": [
                1005,
                {
                    "input": [
                        1055,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "shopifyReportDateLimits": [
                1365,
                {
                    "input": [
                        1364,
                        "ShopifyReportDateLimitsInput!"
                    ]
                }
            ],
            "shopifyReconciliationData": [
                1056,
                {
                    "input": [
                        1055,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "paymentProcessorByCompanyAndVendor": [
                1121,
                {
                    "input": [
                        1120,
                        "PaymentProcessorByCompanyAndVendorInput!"
                    ]
                }
            ],
            "vendorByPaymentGateway": [
                1588,
                {
                    "input": [
                        1587,
                        "VendorByPaymentGatewayInput!"
                    ]
                }
            ],
            "salesChannelsByCompanyAndIntegration": [
                1276,
                {
                    "input": [
                        1275,
                        "SalesChannelsByCompanyAndIntegrationInput!"
                    ]
                }
            ],
            "paymentGatewaysByCompanyIntegrationAndSalesChannel": [
                1,
                {
                    "input": [
                        1086,
                        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput!"
                    ]
                }
            ],
            "getOrdersV2": [
                622,
                {
                    "input": [
                        621,
                        "GetOrdersInput!"
                    ]
                }
            ],
            "getOrdersAggregations": [
                1009,
                {
                    "input": [
                        618,
                        "GetOrdersAggregationsInput!"
                    ]
                }
            ],
            "getOrderById": [
                1075,
                {
                    "input": [
                        1038,
                        "OrderIdInput!"
                    ]
                }
            ],
            "getFinaloopTags": [
                1067,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getIntegrationTags": [
                1067,
                {
                    "input": [
                        572,
                        "GetIntegrationTagsInput!"
                    ]
                }
            ],
            "getFinaloopPaymentGateways": [
                1,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderSalesChannels": [
                1,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderFilteringBounds": [
                617,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getFulfillmentSettings": [
                569,
                {
                    "input": [
                        568,
                        "GetFulfillmentSettingInput!"
                    ]
                }
            ],
            "isCompanyFulfillmentSettingsApplied": [
                5,
                {
                    "input": [
                        835,
                        "IsCompanyFulfillmentSettingsAppliedInput!"
                    ]
                }
            ],
            "ordersByIds": [
                1060,
                {
                    "input": [
                        1059,
                        "OrdersByIdsInput!"
                    ]
                }
            ],
            "orderGroupById": [
                1020,
                {
                    "input": [
                        1019,
                        "OrderGroupByIdInput!"
                    ]
                }
            ],
            "orderGroupsByIds": [
                1026,
                {
                    "input": [
                        1025,
                        "OrderGroupsByIdsInput!"
                    ]
                }
            ],
            "orderGroups": [
                1029,
                {
                    "input": [
                        1028,
                        "OrderGroupsInput!"
                    ]
                }
            ],
            "orderGroupsUnclearedBalance": [
                1036,
                {
                    "input": [
                        1035,
                        "OrderGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderGroupsTotalUnclearedBalance": [
                1034,
                {
                    "input": [
                        1033,
                        "OrderGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderSettings": [
                1063,
                {
                    "input": [
                        1062,
                        "OrderSettingsInput!"
                    ]
                }
            ],
            "paymentGroupLinks": [
                1102,
                {
                    "input": [
                        1101,
                        "PaymentGroupLinksInput!"
                    ]
                }
            ],
            "getInvoicesAffectingPaymentGroups": [
                594,
                {
                    "input": [
                        593,
                        "GetInvoicesAffectingPaymentGroupsInput!"
                    ]
                }
            ],
            "getPaymentGroupsByIds": [
                1105,
                {
                    "input": [
                        1104,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroupById": [
                1047,
                {
                    "input": [
                        1090,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "orderPaymentGroupsByIds": [
                1048,
                {
                    "input": [
                        1104,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroups": [
                1049,
                {
                    "input": [
                        1107,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "orderPaymentGroupsUnclearedBalance": [
                1050,
                {
                    "input": [
                        1113,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderPaymentGroupsTotalUnclearedBalance": [
                1112,
                {
                    "input": [
                        1111,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupById": [
                818,
                {
                    "input": [
                        1090,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsByIds": [
                819,
                {
                    "input": [
                        1104,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "invoicePaymentGroups": [
                820,
                {
                    "input": [
                        1107,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsUnclearedBalance": [
                821,
                {
                    "input": [
                        1113,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsTotalUnclearedBalance": [
                1112,
                {
                    "input": [
                        1111,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "artificialTransactionsByMonth": [
                76,
                {
                    "input": [
                        536,
                        "GetArtificialTransactionsInput!"
                    ]
                }
            ],
            "paymentsByIds": [
                1081,
                {
                    "input": [
                        626,
                        "GetPaymentsByIdsInput!"
                    ]
                }
            ],
            "refundsByIds": [
                1239,
                {
                    "input": [
                        645,
                        "GetRefundsByIdsInput!"
                    ]
                }
            ],
            "paymentChecks": [
                1082,
                {
                    "input": [
                        1083,
                        "PaymentChecksInput!"
                    ]
                }
            ],
            "payrollDailyAggregatesByEmploymentTypeAndCategory": [
                1135,
                {
                    "input": [
                        1136,
                        "PayrollDailyDataInput!"
                    ]
                }
            ],
            "payrollIdsByCompany": [
                1142,
                {
                    "input": [
                        1140,
                        "PayrollIdsByCompanyInput!"
                    ]
                }
            ],
            "contractorPaymentsInternal": [
                328,
                {
                    "input": [
                        327,
                        "ContractorPaymentsInternalInput!"
                    ]
                }
            ],
            "contractorPaymentIdsByCompany": [
                326,
                {
                    "input": [
                        324,
                        "ContractorPaymentIdsByCompanyInput!"
                    ]
                }
            ],
            "paymentsByEmployee": [
                395,
                {
                    "input": [
                        1123,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "paymentsByContractor": [
                394,
                {
                    "input": [
                        1123,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "payrollSettings": [
                1145,
                {
                    "input": [
                        1146,
                        "PayrollSettingsInput!"
                    ]
                }
            ],
            "validations": [
                1578,
                {
                    "args": [
                        1580,
                        "ValidationsArgs!"
                    ]
                }
            ],
            "periodicPartnerReports": [
                1158
            ],
            "periodicReportsPartners": [
                1249
            ],
            "exportCashFlow": [
                484,
                {
                    "args": [
                        473,
                        "ExportCashFlowApiArgs!"
                    ]
                }
            ],
            "exportBalanceSheet": [
                94,
                {
                    "args": [
                        472,
                        "ExportBalanceSheetApiArgs!"
                    ]
                }
            ],
            "ledgerAccountTransactionsCSV": [
                94,
                {
                    "args": [
                        480,
                        "ExportLedgerAccountTransactionsInput!"
                    ]
                }
            ],
            "checkExportStatus": [
                94,
                {
                    "args": [
                        245,
                        "CheckAwaitedExportStatusInput!"
                    ]
                }
            ],
            "syncReportDefinition": [
                1289,
                {
                    "args": [
                        1421,
                        "SyncReportInput!"
                    ]
                }
            ],
            "lastAccountingReportSync": [
                845,
                {
                    "args": [
                        844,
                        "LastAccountingReportSyncInput!"
                    ]
                }
            ],
            "chartData": [
                244,
                {
                    "args": [
                        243,
                        "ChartDataInput!"
                    ]
                }
            ],
            "customerInternal": [
                380,
                {
                    "args": [
                        560,
                        "GetCustomerInternal!"
                    ]
                }
            ],
            "sellingFeesByIds": [
                1301,
                {
                    "input": [
                        659,
                        "GetSellingFeesByIdsInput!"
                    ]
                }
            ],
            "fulfillmentCostsByIds": [
                517,
                {
                    "input": [
                        567,
                        "GetFulfillmentCostsByIdsInput!"
                    ]
                }
            ],
            "shippingCostsByIds": [
                1343,
                {
                    "input": [
                        660,
                        "GetShippingCostsByIdsInput!"
                    ]
                }
            ],
            "getCustomers": [
                380,
                {
                    "getCustomerArgs": [
                        558,
                        "GetCustomerArgs!"
                    ]
                }
            ],
            "getCustomerById": [
                561,
                {
                    "id": [
                        12,
                        "ID!"
                    ]
                }
            ],
            "getCustomerByIds": [
                380,
                {
                    "ids": [
                        12,
                        "[ID!]!"
                    ]
                }
            ],
            "searchCustomers": [
                387,
                {
                    "input": [
                        1295,
                        "SearchCustomersInput!"
                    ]
                }
            ],
            "getCustomerV2ById": [
                387,
                {
                    "input": [
                        559,
                        "GetCustomerByIdInput!"
                    ]
                }
            ],
            "sale": [
                1255,
                {
                    "input": [
                        1258,
                        "SaleInput!"
                    ]
                }
            ],
            "getSales": [
                655,
                {
                    "input": [
                        654,
                        "GetSalesInput!"
                    ]
                }
            ],
            "invoice": [
                814,
                {
                    "input": [
                        816,
                        "InvoiceInput!"
                    ]
                }
            ],
            "getInvoices": [
                596,
                {
                    "input": [
                        654,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoicePdfUrl": [
                1,
                {
                    "input": [
                        824,
                        "InvoicePdfInput!"
                    ]
                }
            ],
            "getPaymentMethods": [
                1116,
                {
                    "input": [
                        292,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "calculateInvoice": [
                814,
                {
                    "input": [
                        233,
                        "CalculateInvoiceInput!"
                    ]
                }
            ],
            "getInvoicesCsv": [
                479,
                {
                    "input": [
                        654,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoiceAggregations": [
                828,
                {
                    "input": [
                        646,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "getInvoicesBalance": [
                829,
                {
                    "input": [
                        646,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "invoicesAgingReportCSV": [
                94,
                {
                    "args": [
                        654,
                        "GetSalesInput!"
                    ]
                }
            ],
            "salesChannel": [
                1268,
                {
                    "input": [
                        1258,
                        "SaleInput!"
                    ]
                }
            ],
            "getSalesChannels": [
                1268,
                {
                    "input": [
                        651,
                        "GetSalesChannelsInput!"
                    ]
                }
            ],
            "getSalesChannelTemplates": [
                1280,
                {
                    "input": [
                        652,
                        "GetSalesChannelTemplatesInput!"
                    ]
                }
            ],
            "invoiceSettings": [
                830,
                {
                    "input": [
                        595,
                        "GetInvoiceSettingsInput!"
                    ]
                }
            ],
            "getInvoiceExamplePdfUrl": [
                1,
                {
                    "input": [
                        831,
                        "InvoicesInput!"
                    ]
                }
            ],
            "me": [
                957
            ],
            "isInvitationCodeForRegisteredUser": [
                838,
                {
                    "args": [
                        837,
                        "IsInvitationCodeForRegisteredUserArgs!"
                    ]
                }
            ],
            "companyUsers": [
                309,
                {
                    "args": [
                        308
                    ]
                }
            ],
            "userInvitationByCode": [
                1563,
                {
                    "args": [
                        1562,
                        "UserInvitationArgs!"
                    ]
                }
            ],
            "userReferralLink": [
                1571,
                {
                    "args": [
                        1570
                    ]
                }
            ],
            "userInvitationsByEmail": [
                1565,
                {
                    "args": [
                        1564,
                        "UserInvitationsByEmailArgs!"
                    ]
                }
            ],
            "userRoles": [
                1573
            ],
            "auth0UserLookup": [
                93,
                {
                    "args": [
                        92,
                        "Auth0UserLookupInput!"
                    ]
                }
            ],
            "googleConnectStatus": [
                680
            ],
            "googleConnectionLink": [
                679,
                {
                    "input": [
                        678
                    ]
                }
            ],
            "calendlyEventDetails": [
                235,
                {
                    "args": [
                        234,
                        "CalendlyEventInput!"
                    ]
                }
            ],
            "userNameFromReferralCode": [
                1569,
                {
                    "args": [
                        1568,
                        "UserNameFromReferralCodeInput!"
                    ]
                }
            ],
            "userByEmail": [
                1556,
                {
                    "args": [
                        1555,
                        "UserByEmailArgs!"
                    ]
                }
            ],
            "usersByEmails": [
                1575,
                {
                    "args": [
                        1574,
                        "UsersByEmailsArgs!"
                    ]
                }
            ],
            "vendors": [
                1583,
                {
                    "input": [
                        1591,
                        "VendorsInput!"
                    ]
                }
            ],
            "vendor": [
                665,
                {
                    "input": [
                        664,
                        "GetVendorInput!"
                    ]
                }
            ],
            "vendorsByIds": [
                1583,
                {
                    "input": [
                        666,
                        "GetVendorsInput!"
                    ]
                }
            ],
            "vendorsBySubtype": [
                1583,
                {
                    "companyId": [
                        12,
                        "ID!"
                    ],
                    "subTypes": [
                        1595,
                        "[VendorSubType!]!"
                    ]
                }
            ],
            "tax1099VendorGroups": [
                1430,
                {
                    "input": [
                        661,
                        "GetTax1099VendorGroupsInput!"
                    ]
                }
            ],
            "institutionVendors": [
                1583
            ],
            "vendorById": [
                1583,
                {
                    "input": [
                        1585,
                        "VendorByIdInput!"
                    ]
                }
            ],
            "vendorByAffiliatedEntityId": [
                1583,
                {
                    "input": [
                        1584,
                        "VendorByAffiliatedEntityIdInput!"
                    ]
                }
            ],
            "vendorByName": [
                1583,
                {
                    "input": [
                        1586,
                        "VendorByNameInput!"
                    ]
                }
            ],
            "globalVendorsByIds": [
                1583,
                {
                    "input": [
                        570,
                        "GetGlobalVendorsInput!"
                    ]
                }
            ],
            "vendorsByIntegrationType": [
                1583,
                {
                    "input": [
                        1590,
                        "VendorsByIntegrationTypeInput!"
                    ]
                }
            ],
            "getCompanyWorksheets": [
                317,
                {
                    "args": [
                        556,
                        "GetCompanyWorksheetsArgs!"
                    ]
                }
            ],
            "getCompanyLockConditions": [
                300,
                {
                    "args": [
                        554,
                        "GetCompanyLockConditionsArgs!"
                    ]
                }
            ],
            "getCompanyWorksheetsV2": [
                317,
                {
                    "args": [
                        557,
                        "GetCompanyWorksheetsArgsV2!"
                    ]
                }
            ],
            "getCompanyLockConditionsV2": [
                300,
                {
                    "args": [
                        555,
                        "GetCompanyLockConditionsArgsV2!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethod": {
            "managementMethod": [
                928
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethodInput": {
            "managementMethod": [
                928
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ReadyValidation": {
            "id": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "generatedAt": [
                398
            ],
            "generatedBy": [
                1
            ],
            "isGreen": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RealtimeCogsStatus": {
            "isAmazonEnabled": [
                5
            ],
            "isShopifyEnabled": [
                5
            ],
            "isWalmartEnabled": [
                5
            ],
            "isTikTokEnabled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Recipe": {
            "id": [
                1
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "isAssembledOnDemand": [
                5
            ],
            "product": [
                1170
            ],
            "warehouse": [
                1601
            ],
            "assemblyCost": [
                10
            ],
            "components": [
                1218
            ],
            "managementMethod": [
                1220
            ],
            "displayName": [
                1
            ],
            "deletedReason": [
                84
            ],
            "manualAssemblyId": [
                1
            ],
            "totalAssembled": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "RecipeBreakdown": {
            "quantity": [
                10
            ],
            "wasteQuantity": [
                10
            ],
            "costPerUnit": [
                10
            ],
            "product": [
                1170
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundError": {
            "data": [
                1217
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundErrorData": {
            "recipe": [
                1214
            ],
            "product": [
                1170
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponent": {
            "product": [
                1170
            ],
            "warehouse": [
                1601
            ],
            "quantity": [
                10
            ],
            "wasteQuantity": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponentInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "quantity": [
                10
            ],
            "wasteQuantity": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "RecipeManagementMethod": {},
        "RecipeOverlappingError": {
            "recipes": [
                1214
            ],
            "__typename": [
                1
            ]
        },
        "RecipeWithBreakdown": {
            "recipe": [
                1214
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "warehouse": [
                1601
            ],
            "breakdown": [
                1215
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileAllContractorPaymentInventoryPurchasesInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileAllPayrollInventoryPurchasesInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBillsInput": {
            "billItemsToReconcile": [
                214
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBusinessEventsInput": {
            "businessEventItemsToReconcile": [
                230
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromPayrollsInput": {
            "payrollItemsToReconcile": [
                1143
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromTransactionsInput": {
            "transactionItemsToReconcile": [
                1441
            ],
            "__typename": [
                1
            ]
        },
        "ReconciliationRequestStatementFile": {
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                116
            ],
            "status": [
                136
            ],
            "__typename": [
                1
            ]
        },
        "ReconciliationSummary": {
            "totalInReport": [
                38
            ],
            "isGreen": [
                5
            ],
            "inBooks": [
                38
            ],
            "unfulfilledInBooks": [
                38
            ],
            "voided": [
                38
            ],
            "restocked": [
                38
            ],
            "excluded": [
                38
            ],
            "totalInDb": [
                38
            ],
            "fulfilled": [
                38
            ],
            "fulfilledNotSold": [
                38
            ],
            "soldNotFulfilled": [
                38
            ],
            "zeroNet": [
                38
            ],
            "zeroNetFulfilled": [
                38
            ],
            "zeroNetNotSold": [
                38
            ],
            "zeroNetNotFulfilled": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099VendorGroupIdentifierInput": {
            "id": [
                12
            ],
            "type": [
                1431
            ],
            "isGroup": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099VendorGroupInput": {
            "companyId": [
                12
            ],
            "identifiers": [
                1231
            ],
            "isDone": [
                5
            ],
            "disqualifyReason": [
                1426
            ],
            "resetDisqualifyReason": [
                5
            ],
            "file": [
                1429
            ],
            "resetFile": [
                5
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettings": {
            "recurrence": [
                883
            ],
            "totalRecurrences": [
                38
            ],
            "cutOffDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettingsInput": {
            "recurrence": [
                883
            ],
            "totalRecurrences": [
                38
            ],
            "cutOffDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "RecurringPayment": {
            "yearly": [
                1084
            ],
            "monthly": [
                1084
            ],
            "yearlyDiscount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ReferUserArgs": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "referUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RefreshSkuAmbiguityInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Refund": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "source": [
                754
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "amount": [
                10
            ],
            "currency": [
                1
            ],
            "givenAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "Reimbursement": {
            "id": [
                1
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "postedAt": [
                398
            ],
            "amount": [
                10
            ],
            "isClearedByNetPayPayment": [
                5
            ],
            "employee": [
                464
            ],
            "contractor": [
                322
            ],
            "contractorPayment": [
                323
            ],
            "payroll": [
                1125
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "RelatedParty": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "RemoveDataRestrictionInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserArgs": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksByTypeInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "locks": [
                106
            ],
            "lockType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "locks": [
                107
            ],
            "__typename": [
                1
            ]
        },
        "Report": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReportedBusinessBankTransactionsAccountInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ReportsPartner": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReportSyncStatus": {},
        "ReportType": {},
        "RestockingAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1603
            ],
            "fulfillmentDate": [
                398
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Role": {},
        "RootConditionOperator": {},
        "Sale": {
            "id": [
                12
            ],
            "saleType": [
                1
            ],
            "companyId": [
                12
            ],
            "source": [
                1283
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                377
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1284
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "publishedAt": [
                398
            ],
            "lineItems": [
                1260
            ],
            "shipping": [
                1345
            ],
            "discounts": [
                450
            ],
            "taxes": [
                1424
            ],
            "taxFixedAmount": [
                10
            ],
            "adjustments": [
                1257
            ],
            "payments": [
                1286
            ],
            "credits": [
                1286
            ],
            "refunds": [
                1286
            ],
            "paymentIndications": [
                1286
            ],
            "refundIndications": [
                1286
            ],
            "customer": [
                387
            ],
            "customerId": [
                12
            ],
            "shippingAddress": [
                381
            ],
            "billingAddress": [
                381
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "voidedAt": [
                398
            ],
            "voidedBy": [
                1256
            ],
            "summary": [
                1285
            ],
            "__typename": [
                1
            ]
        },
        "SaleActor": {},
        "SaleAdjustment": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                40
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "SaleInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "balanceAsOfDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "SaleInternalInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItem": {
            "id": [
                12
            ],
            "quantity": [
                10
            ],
            "unitPrice": [
                10
            ],
            "currency": [
                377
            ],
            "type": [
                1263
            ],
            "integrationInfo": [
                736
            ],
            "product": [
                1170
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                450
            ],
            "shipping": [
                1345
            ],
            "taxes": [
                1424
            ],
            "subtotal": [
                10
            ],
            "totalAmount": [
                10
            ],
            "salesChannel": [
                1268
            ],
            "salesChannelId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemInput": {
            "id": [
                12
            ],
            "quantity": [
                10
            ],
            "unitPrice": [
                10
            ],
            "type": [
                1263
            ],
            "currency": [
                377
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                12
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                451
            ],
            "taxRate": [
                10
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemTaxInfo": {
            "taxableAmount": [
                10
            ],
            "taxRate": [
                10
            ],
            "totalTaxAmount": [
                10
            ],
            "isTaxInclusive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemType": {},
        "SaleOrder": {
            "id": [
                12
            ],
            "saleType": [
                1
            ],
            "companyId": [
                12
            ],
            "source": [
                1283
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                377
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1284
            ],
            "postedAt": [
                398
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "publishedAt": [
                398
            ],
            "lineItems": [
                1260
            ],
            "shipping": [
                1345
            ],
            "discounts": [
                450
            ],
            "taxes": [
                1424
            ],
            "taxFixedAmount": [
                10
            ],
            "adjustments": [
                1257
            ],
            "payments": [
                1286
            ],
            "credits": [
                1286
            ],
            "refunds": [
                1286
            ],
            "paymentIndications": [
                1286
            ],
            "refundIndications": [
                1286
            ],
            "customer": [
                387
            ],
            "customerId": [
                12
            ],
            "shippingAddress": [
                381
            ],
            "billingAddress": [
                381
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "accounting": [
                4
            ],
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "voidedAt": [
                398
            ],
            "voidedBy": [
                1256
            ],
            "summary": [
                1285
            ],
            "__typename": [
                1
            ]
        },
        "SalesByTypeAndDateInput": {
            "saleType": [
                1287
            ],
            "companyId": [
                12
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannel": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelDailySalesData": {
            "salesChannel": [
                1266
            ],
            "salesData": [
                1274
            ],
            "soldSkusSummary": [
                1278
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInfo": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isCogsAffecting": [
                5
            ],
            "isSalesAffecting": [
                5
            ],
            "financialReportingStatus": [
                511
            ],
            "periods": [
                1271
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInput": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelMonthlyBreakdown": {
            "date": [
                398
            ],
            "summary": [
                1279
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelPeriod": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "financialReportingStatus": [
                511
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelPeriodInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "financialReportingStatus": [
                511
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesCogs": {
            "hasAdjustments": [
                5
            ],
            "salesChannel": [
                1266
            ],
            "summary": [
                1279
            ],
            "monthlyBreakdown": [
                1270
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesData": {
            "date": [
                398
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "unitsGiveaway": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationInput": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationResponse": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSalesSummary": {
            "sku": [
                1
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "unitsGiveaway": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSummary": {
            "sku": [
                1
            ],
            "fulfilled": [
                38
            ],
            "restocked": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelTemplate": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "isCogsAffecting": [
                5
            ],
            "isSalesAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesData": {
            "recipes": [
                1214
            ],
            "assembledByRecipeMap": [
                83
            ],
            "salesChannels": [
                1273
            ],
            "deadInventory": [
                401
            ],
            "giveaway": [
                673
            ],
            "donation": [
                453
            ],
            "waste": [
                1665
            ],
            "warehouseSalesData": [
                1625
            ],
            "salesAdjustments": [
                1188
            ],
            "deadInventoryAdjustments": [
                1180
            ],
            "giveawayAdjustments": [
                1184
            ],
            "donationAdjustments": [
                1182
            ],
            "appliedCosts": [
                1174
            ],
            "__typename": [
                1
            ]
        },
        "SalesInternalInput": {
            "ids": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SaleSource": {},
        "SaleStatus": {},
        "SaleTotals": {
            "subtotal": [
                70
            ],
            "subtotalAfterItemDiscounts": [
                70
            ],
            "totalBeforeTaxes": [
                70
            ],
            "total": [
                70
            ],
            "shipping": [
                70
            ],
            "payments": [
                70
            ],
            "refunds": [
                70
            ],
            "credits": [
                70
            ],
            "adjustments": [
                70
            ],
            "taxes": [
                70
            ],
            "globalDiscounts": [
                70
            ],
            "globalPlatformDiscounts": [
                70
            ],
            "globalSellerDiscounts": [
                70
            ],
            "itemDiscounts": [
                70
            ],
            "totalDiscounts": [
                70
            ],
            "unpaidBalance": [
                70
            ],
            "balanceAsOf": [
                398
            ],
            "balanceAsOfAmount": [
                70
            ],
            "latestFinancialTimestamp": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "SaleTransaction": {
            "id": [
                12
            ],
            "integrationInfo": [
                736
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "type": [
                1445
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "on_BankTxSaleTransaction": [
                199
            ],
            "on_IntegrationSaleTransaction": [
                738
            ],
            "on_PaymentGroupTransaction": [
                1114
            ],
            "__typename": [
                1
            ]
        },
        "SaleType": {},
        "ScheduleAccountingReportExportInput": {
            "companyId": [
                12
            ],
            "reportType": [
                1251
            ],
            "isEnabled": [
                5
            ],
            "byMonth": [
                5
            ],
            "fractions": [
                5
            ],
            "withMerchantsBreakdown": [
                5
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "includeCCAccounts": [
                5
            ],
            "includeUDFAccounts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAccountingReportExportResponse": {
            "reportType": [
                1251
            ],
            "isEnabled": [
                5
            ],
            "byMonth": [
                5
            ],
            "fractions": [
                5
            ],
            "withMerchantsBreakdown": [
                5
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "syncMostUpdatedReport": [
                5
            ],
            "includeCCAccounts": [
                5
            ],
            "includeUDFAccounts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingInput": {
            "companyId": [
                12
            ],
            "integrationTransferIds": [
                12
            ],
            "askTheUserResult": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingInput": {
            "companyId": [
                12
            ],
            "transactionIds": [
                12
            ],
            "askTheUserResult": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SchedulePurchasesValidationInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SearchCustomersInput": {
            "companyId": [
                12
            ],
            "searchTerm": [
                1
            ],
            "source": [
                386
            ],
            "limit": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "SearchMerchantsInput": {
            "companyId": [
                12
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionPriorityInput": {
            "companyId": [
                12
            ],
            "searchTerm": [
                1
            ],
            "merchantType": [
                956
            ],
            "subType": [
                1595
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionsPriorityResult": {
            "vendors": [
                1583
            ],
            "counts": [
                1594
            ],
            "__typename": [
                1
            ]
        },
        "Section179Deduction": {
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "Section179DeductionInput": {
            "date": [
                1
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SellingFee": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1255
            ],
            "order": [
                1073
            ],
            "type": [
                1302
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SellingFeeType": {},
        "SendPoEmailError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "to": [
                1
            ],
            "from": [
                1
            ],
            "cc": [
                1
            ],
            "bcc": [
                1
            ],
            "subject": [
                1
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResult": {
            "on_SendPoEmailResponse": [
                1305
            ],
            "on_SendPoEmailError": [
                1303
            ],
            "__typename": [
                1
            ]
        },
        "SendValidationNotificationInput": {
            "caption": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "validationTemplateId": [
                1
            ],
            "slackChannel": [
                1
            ],
            "type": [
                312
            ],
            "__typename": [
                1
            ]
        },
        "SetBankAccountsLatestEventIdsInput": {
            "bankAccountEventIds": [
                102
            ],
            "__typename": [
                1
            ]
        },
        "SetFiscalYearStartInput": {
            "companyId": [
                12
            ],
            "month": [
                38
            ],
            "day": [
                38
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsInput": {
            "companyId": [
                12
            ],
            "settings": [
                735
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsResponse": {
            "settings": [
                734
            ],
            "__typename": [
                1
            ]
        },
        "SetInventoryUsagesInput": {
            "companyId": [
                12
            ],
            "usageTypes": [
                786
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopNoteInput": {
            "companyId": [
                12
            ],
            "orderId": [
                12
            ],
            "finaloopNote": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopTagsInput": {
            "companyId": [
                12
            ],
            "orderId": [
                12
            ],
            "finaloopTags": [
                510
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsInput": {
            "companyId": [
                12
            ],
            "salesChannel": [
                1269
            ],
            "periodEndDate": [
                398
            ],
            "items": [
                1316
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingSalesDataAdjustmentsItemInput": {
            "productId": [
                1
            ],
            "pendingId": [
                1
            ],
            "date": [
                398
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "warehouseId": [
                1
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "items": [
                1318
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesItemInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sourceId": [
                1
            ],
            "pendingId": [
                1
            ],
            "quantity": [
                38
            ],
            "costPerUnit": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesWithCsvInput": {
            "companyId": [
                12
            ],
            "date": [
                398
            ],
            "url": [
                1
            ],
            "filename": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentOperation": {},
        "SetProductAdjustmentsInput": {
            "companyId": [
                12
            ],
            "finaloopProductId": [
                1
            ],
            "salesDataAdjustments": [
                1327
            ],
            "deadInventoryAdjustments": [
                1324
            ],
            "giveawayAdjustments": [
                1326
            ],
            "donationAdjustments": [
                1325
            ],
            "appliedCosts": [
                1323
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentsResponse": {
            "appliedCosts": [
                1172
            ],
            "newDeadInventoryAdjustments": [
                1179
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAppliedCostInput": {
            "operation": [
                1320
            ],
            "asOfDate": [
                398
            ],
            "appliedCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDeadInventoryAdjustmentInput": {
            "id": [
                1
            ],
            "operation": [
                1320
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsDeadInventory": [
                10
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDonationAdjustmentInput": {
            "operation": [
                1320
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsDonated": [
                38
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductGiveawayAdjustmentInput": {
            "operation": [
                1320
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                398
            ],
            "unitsGiveaway": [
                38
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductSalesDataAdjustmentInput": {
            "operation": [
                1320
            ],
            "salesChannel": [
                1269
            ],
            "date": [
                398
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPurchaseOrderBusinessEventInput": {
            "companyId": [
                12
            ],
            "purchaseOrderId": [
                1
            ],
            "publishedEventIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetSettingsArgs": {
            "companyId": [
                1
            ],
            "rangedManagementMethod": [
                1211
            ],
            "__typename": [
                1
            ]
        },
        "SetUserName": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetUserNameResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SetWarehouseOpeningBalanceInput": {
            "warehouseId": [
                1
            ],
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "quantity": [
                38
            ],
            "costPerUnit": [
                10
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ShareType": {},
        "Shipment": {
            "id": [
                12
            ],
            "shipmentDate": [
                398
            ],
            "arrival": [
                1336
            ],
            "contents": [
                1340
            ],
            "displayName": [
                1
            ],
            "vendor": [
                1583
            ],
            "notes": [
                1
            ],
            "attachments": [
                1338
            ],
            "purchaseOrder": [
                1199
            ],
            "cost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "purchaseOrder": [
                1199
            ],
            "directCost": [
                10
            ],
            "indirectCosts": [
                972
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrival": {
            "arrivalDate": [
                398
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrivalInput": {
            "arrivalDate": [
                398
            ],
            "warehouseId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachment": {
            "id": [
                12
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContents": {
            "id": [
                12
            ],
            "lineItem": [
                445
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContentsInput": {
            "lineItemId": [
                12
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentInput": {
            "id": [
                12
            ],
            "shipmentDate": [
                398
            ],
            "arrival": [
                1337
            ],
            "contents": [
                1341
            ],
            "displayName": [
                1
            ],
            "vendorId": [
                12
            ],
            "notes": [
                1
            ],
            "attachments": [
                1339
            ],
            "__typename": [
                1
            ]
        },
        "ShippingCost": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "sale": [
                1255
            ],
            "order": [
                1073
            ],
            "type": [
                1344
            ],
            "externalId": [
                1
            ],
            "externalName": [
                1
            ],
            "amount": [
                10
            ],
            "currency": [
                377
            ],
            "amountInHomeCurrency": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShippingCostType": {},
        "ShippingLineItem": {
            "id": [
                12
            ],
            "quantity": [
                10
            ],
            "unitPrice": [
                10
            ],
            "currency": [
                377
            ],
            "type": [
                1263
            ],
            "description": [
                1
            ],
            "discounts": [
                450
            ],
            "taxes": [
                1424
            ],
            "subtotal": [
                10
            ],
            "totalAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyCsvBalance": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "balances": [
                1368
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationInput": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "integrationAccountId": [
                1
            ],
            "companyId": [
                12
            ],
            "isRegenerateFromScratch": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrderData": {
            "orderId": [
                1
            ],
            "url": [
                1
            ],
            "shopifySalesChannel": [
                1
            ],
            "salesChannel": [
                1
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffInput": {
            "companyId": [
                12
            ],
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResult": {
            "ordersData": [
                1351
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResultData": {
            "orderId": [
                12
            ],
            "reportQuantity": [
                38
            ],
            "entitiesQuantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersInput": {
            "startTime": [
                398
            ],
            "endTime": [
                398
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                12
            ],
            "sku": [
                1
            ],
            "itemType": [
                1369
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersResponse": {
            "ordersData": [
                1348
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSalesChannelInput": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSkuInput": {
            "companyId": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderSourceData": {
            "financialStatus": [
                1
            ],
            "fulfillmentStatus": [
                1
            ],
            "gqlId": [
                1
            ],
            "orderValidationData": [
                1357
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationData": {
            "sales": [
                1359
            ],
            "refunds": [
                1358
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationRefund": {
            "id": [
                1
            ],
            "refundAmount": [
                10
            ],
            "giftCardAmount": [
                10
            ],
            "giftCardDiscountAmount": [
                10
            ],
            "shippingAmount": [
                10
            ],
            "taxAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationSale": {
            "id": [
                1
            ],
            "saleAmount": [
                10
            ],
            "giftCardAmount": [
                10
            ],
            "giftCardDiscountAmount": [
                10
            ],
            "taxAmount": [
                10
            ],
            "dutyAmount": [
                10
            ],
            "discountAmount": [
                10
            ],
            "shippingAmount": [
                10
            ],
            "tipAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "productUrl": [
                1
            ],
            "productVariantId": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProductsValidationData": {
            "integrationAccountId": [
                1
            ],
            "data": [
                1366
            ],
            "reportEndTime": [
                398
            ],
            "reportPath": [
                1
            ],
            "summary": [
                1230
            ],
            "productItemSoldData": [
                1367
            ],
            "productStatus": [
                1363
            ],
            "unknownSkuQuantity": [
                38
            ],
            "unknownSkuZeroPriceQuantity": [
                38
            ],
            "recognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "isNotArtificiallyFulfilled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProductsValidationResponse": {
            "validationId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReconciliationProductsStatus": {
            "unitsSoldDiff": [
                38
            ],
            "unitsRestockedDiff": [
                38
            ],
            "unitsGiveawayDiff": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsInput": {
            "integrationAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsResponse": {
            "dateRange": [
                399
            ],
            "failureType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationData": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "excludedSalesChannelsData": [
                469
            ],
            "includedSalesChannelsData": [
                707
            ],
            "summary": [
                1230
            ],
            "productItemSoldData": [
                1367
            ],
            "productStatus": [
                1363
            ],
            "unknownSkuQuantity": [
                38
            ],
            "unknownSkuZeroPriceQuantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationProductItemSoldData": {
            "unitsSold": [
                38
            ],
            "unitsSoldWithZeroPrice": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "unitsGiveaway": [
                38
            ],
            "ambiguousSkuQuantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyTimezoneBalance": {
            "timezone": [
                1
            ],
            "grossAmount": [
                10
            ],
            "netAmount": [
                10
            ],
            "taxAmount": [
                10
            ],
            "discountAmount": [
                10
            ],
            "shippingAmount": [
                10
            ],
            "refundAmount": [
                10
            ],
            "totalAmount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyValidationItemType": {},
        "SignedDownloadUrlInput": {
            "companyId": [
                1
            ],
            "s3Url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignWorksheetRowInput": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "name": [
                1
            ],
            "amount": [
                10
            ],
            "comment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "amount": [
                10
            ],
            "comment": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "SimpleMoneyDirection": {},
        "SimulatedLoanFailure": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanIrrelevantEvent": {
            "event": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanSummary": {
            "on_SimulatedLoanIrrelevantEvent": [
                1375
            ],
            "on_SimulatedLoanUpdate": [
                1377
            ],
            "on_SimulatedLoanFailure": [
                1374
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdate": {
            "createdLoan": [
                1378
            ],
            "deletedLoan": [
                1379
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateCreate": {
            "type": [
                908
            ],
            "isShortTerm": [
                5
            ],
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "lender": [
                975
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateDelete": {
            "loanId": [
                1
            ],
            "type": [
                908
            ],
            "isShortTerm": [
                5
            ],
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "lender": [
                975
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                10
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput": {
            "companyId": [
                12
            ],
            "transactionId": [
                1
            ],
            "businessEvent": [
                1
            ],
            "merchantId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput2": {
            "companyId": [
                12
            ],
            "transactionId": [
                1
            ],
            "classifications": [
                1380
            ],
            "merchantId": [
                1
            ],
            "merchantType": [
                889
            ],
            "__typename": [
                1
            ]
        },
        "SkuValidationData": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "unitsSold": [
                38
            ],
            "unitsRestocked": [
                38
            ],
            "unitsGiveaway": [
                38
            ],
            "unitsSoldWithZeroPrice": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "SkuValidations": {
            "sku": [
                1
            ],
            "sales": [
                524
            ],
            "restocks": [
                524
            ],
            "deadInventory": [
                39
            ],
            "donations": [
                39
            ],
            "giveaways": [
                39
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SortByMerchants": {},
        "SortDirection": {},
        "SpecificDate": {
            "date": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SpecificDateInput": {
            "date": [
                1
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsInput": {
            "companyId": [
                1
            ],
            "finaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsV2": {
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitTax1099VendorGroupsInput": {
            "companyId": [
                12
            ],
            "groupIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTime": {
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTimeInput": {
            "fromDate": [
                1
            ],
            "toDate": [
                1
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "SpreadType": {},
        "StatementFile": {
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                116
            ],
            "__typename": [
                1
            ]
        },
        "StopImportingDataForBankAccountInput": {
            "bankAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "stopImportFromDate": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StopImportingDataForBankAccountNewInput": {
            "bankAccountId": [
                12
            ],
            "companyId": [
                12
            ],
            "stopImportFromDate": [
                1
            ],
            "newOwner": [
                109
            ],
            "__typename": [
                1
            ]
        },
        "Store": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "homeCurrency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "StoreDateForLockingInput": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "name": [
                1
            ],
            "dateForLocking": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "StoreDateForLockingInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "dateForLocking": [
                398
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "StoreOrderLineItemType": {},
        "StoreOrderTransactionMatchType": {},
        "StorePaymentV2": {
            "id": [
                12
            ],
            "source": [
                754
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationInput": {
            "validationTemplateId": [
                1
            ],
            "validationId": [
                1
            ],
            "validationCycleId": [
                1
            ],
            "result": [
                1405
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationResultDataInput": {
            "companyId": [
                1
            ],
            "domain": [
                312
            ],
            "type": [
                316
            ],
            "integrationAccountId": [
                1
            ],
            "account": [
                1
            ],
            "accountDisplayName": [
                1
            ],
            "isError": [
                5
            ],
            "metrics": [
                1406
            ],
            "dataUrl": [
                1
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "generatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StoreValidationResultDataMeticItemInput": {
            "metricName": [
                313
            ],
            "value": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsByCompanyIdsInput": {
            "companyIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsResponse": {
            "companyAndStripeIds": [
                291
            ],
            "__typename": [
                1
            ]
        },
        "SubmitTransactionsFileToBankTransactionsUploadInput": {
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "processorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationResult": {
            "isSuccessful": [
                5
            ],
            "tag": [
                251
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuspectedBankAccountDuplication": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "bankAccountId1": [
                1
            ],
            "bankAccountId2": [
                1
            ],
            "liveConnectionBankAccountId": [
                1
            ],
            "readyForMergingAt": [
                398
            ],
            "hubspotNotificationSentAt": [
                398
            ],
            "duplicateCheckStartedAt": [
                398
            ],
            "duplicateCheckCompletedAt": [
                398
            ],
            "duplicateCheckResult": [
                460
            ],
            "mergeStartedAt": [
                398
            ],
            "mergeCompletedAt": [
                398
            ],
            "mergeResult": [
                965
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "mergedBy": [
                27
            ],
            "mergedById": [
                1
            ],
            "totalMergedTransactionsChunks": [
                38
            ],
            "currentlyMergedTransactionsChunks": [
                38
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyArgs": {
            "companyId": [
                1
            ],
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SyncCompanyEventName": {},
        "SyncCompanyInput": {
            "eventName": [
                1417
            ],
            "body": [
                304
            ],
            "__typename": [
                1
            ]
        },
        "SyncGtmSourceInput": {
            "companyId": [
                12
            ],
            "gtmSource": [
                684
            ],
            "__typename": [
                1
            ]
        },
        "SyncInventoryIntegrationFulfillmentSettingInput": {
            "companyId": [
                12
            ],
            "source": [
                1189
            ],
            "isEnabled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SyncReportInput": {
            "companyId": [
                12
            ],
            "reportType": [
                1251
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeOffersInput": {
            "subscriptionProductId": [
                1
            ],
            "transitionFeeProductId": [
                1
            ],
            "catchupProductId": [
                1
            ],
            "couponCode": [
                1
            ],
            "isStripeTest": [
                5
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "tier": [
                991
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeTier1Offer": {
            "yearlySubscriptionPriceId": [
                1
            ],
            "monthlySubscriptionPriceId": [
                1
            ],
            "transitionFeePriceId": [
                1
            ],
            "catchupPriceId": [
                1
            ],
            "isStripeTest": [
                5
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "couponCode": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax": {
            "id": [
                12
            ],
            "amount": [
                10
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099Vendor": {
            "vendor": [
                1583
            ],
            "type": [
                1431
            ],
            "totalPayments": [
                10
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorDisqualifyReason": {},
        "Tax1099VendorGroup": {
            "id": [
                12
            ],
            "groupName": [
                1
            ],
            "isGroup": [
                5
            ],
            "type": [
                1431
            ],
            "vendors": [
                1425
            ],
            "totalPayments": [
                10
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "disqualifyReason": [
                1426
            ],
            "file": [
                1428
            ],
            "markedAsDoneAt": [
                398
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupFile": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupFileInput": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupsByType": {
            "type": [
                1431
            ],
            "vendorGroups": [
                1427
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorType": {},
        "TaxInfo": {
            "amount": [
                10
            ],
            "description": [
                1
            ],
            "isTaxInclusive": [
                5
            ],
            "createdAt": [
                398
            ],
            "updatedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "TikTokProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "TimeRange": {
            "fromUTC": [
                398
            ],
            "toUTC": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "TimeRangeInput": {
            "fromUTC": [
                398
            ],
            "toUTC": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "ToggleOrdersZeroNetBalanceInput": {
            "companyId": [
                1
            ],
            "isZeroNetOrdersSupported": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput": {
            "companyId": [
                12
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ToggleZeroNetBalanceInput": {
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "Topup": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "amount": [
                10
            ],
            "amountInUsd": [
                10
            ],
            "currency": [
                1
            ],
            "eventTime": [
                398
            ],
            "deletedAt": [
                398
            ],
            "integrationType": [
                754
            ],
            "pairedBankTransaction": [
                167
            ],
            "type": [
                753
            ],
            "classification": [
                742
            ],
            "integrationAccountId": [
                12
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                271
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionDescription": {
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionItemToReconcile": {
            "companyId": [
                12
            ],
            "transactionId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "TransactionKind": {},
        "TransactionPaymentType": {},
        "TransactionsImportStatus": {
            "stopImportFromDate": [
                1
            ],
            "changedBy": [
                1
            ],
            "changedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionType": {},
        "TransactionUpdatedInput": {
            "companyId": [
                12
            ],
            "transactionId": [
                1
            ],
            "actorType": [
                27
            ],
            "__typename": [
                1
            ]
        },
        "TransitionFeeInput": {
            "stripeId": [
                1
            ],
            "price": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "TriggerCOGSFromScratchInput": {
            "companyId": [
                12
            ],
            "managementMethod": [
                928
            ],
            "productIds": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnclassifyIntegrationTransferInput": {
            "ids": [
                12
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UnconnectedAccount": {
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionParty": {
            "on_BankAccount": [
                97
            ],
            "on_UnknownTransactionBank": [
                1459
            ],
            "on_UnknownTransactionAccount": [
                1458
            ],
            "on_UnconnectedAccount": [
                1450
            ],
            "on_Vendor": [
                1583
            ],
            "on_TransactionDescription": [
                1440
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroup": {
            "transactions": [
                167
            ],
            "payer": [
                1451
            ],
            "payee": [
                1451
            ],
            "groupId": [
                12
            ],
            "isProcessing": [
                5
            ],
            "action": [
                1455
            ],
            "tooltipText": [
                1
            ],
            "type": [
                1454
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupsInput": {
            "companyId": [
                12
            ],
            "fromDate": [
                398
            ],
            "toDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupType": {},
        "UnidentifiedTransfersGroupAction": {},
        "UnknownIntegrationTransferAccount": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownIntegrationTransferBank": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionAccount": {
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionBank": {
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "UnlockCompanyV2Input": {
            "companyId": [
                1
            ],
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedResponse": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleResponse": {
            "orders": [
                1073
            ],
            "__typename": [
                1
            ]
        },
        "UnpaidAmountsViaProvider": {
            "type": [
                1466
            ],
            "categoryGroup": [
                1126
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "UnpaidAmountsViaProviderType": {},
        "UnsignWorksheetRowInput": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnsignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersResponse": {
            "orders": [
                1073
            ],
            "failedIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UpdateAppliedCostFromIntegrationInput": {
            "appliedCosts": [
                73
            ],
            "companyId": [
                12
            ],
            "source": [
                1
            ],
            "impactOnBooksSince": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountBalanceAdjustmentInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "balanceAdjustment": [
                10
            ],
            "balanceAdjustmentDate": [
                1
            ],
            "actorType": [
                98
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountClosingBalanceInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "closingBalance": [
                10
            ],
            "closingBalanceDate": [
                1
            ],
            "actorType": [
                100
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountInput": {
            "companyId": [
                12
            ],
            "id": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "currentBalance": [
                10
            ],
            "lastUpdateTime": [
                398
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "rawData": [
                843
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "status": [
                121
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountsMergeStatusInput": {
            "companyId": [
                12
            ],
            "suspectedDuplicationId": [
                12
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankReconciliationRequestMessageInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankStatementParsingResultStatus": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bankStatementFileId": [
                1
            ],
            "status": [
                155
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankTransactionMemoInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBooksStartTimeArgs": {
            "companyId": [
                1
            ],
            "booksStartTime": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCommentsInput": {
            "companyId": [
                1
            ],
            "year": [
                38
            ],
            "name": [
                1
            ],
            "comment": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCommentsInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "comment": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "periodDates": [
                1157
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAccountingMethodArgs": {
            "companyId": [
                1
            ],
            "accountingMethod": [
                385
            ],
            "flow": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAffiliateInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "name": [
                1
            ],
            "isForeignResident": [
                5
            ],
            "holdingsInPercentage": [
                10
            ],
            "status": [
                699
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyPayingStatusInput": {
            "companyId": [
                1
            ],
            "isPaying": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyTierInput": {
            "companyId": [
                1
            ],
            "tier": [
                305
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyVendorExternalSourceIdVendorInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCustomerArgs": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                386
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                33
            ],
            "shippingAddress": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedBankAccountReconciliation": {
            "id": [
                12
            ],
            "companyId": [
                1
            ],
            "transactionsFileNames": [
                1
            ],
            "statementFileNames": [
                1
            ],
            "balance": [
                10
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedLoans": {
            "loans": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusChange": {
            "employeeId": [
                12
            ],
            "isOfficer": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusInput": {
            "companyId": [
                12
            ],
            "changes": [
                1490
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEstimatedProjectedRevenueInput": {
            "companyId": [
                1
            ],
            "estimatedProjectedRevenue": [
                467
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogo": {
            "id": [
                12
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogoInput": {
            "id": [
                12
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionInput": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                723
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionMappingInput": {
            "id": [
                12
            ],
            "source": [
                722
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInternalLoanInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "data": [
                910
            ],
            "merchantRole": [
                276
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryPurchaseResponse": {
            "on_InventoryPurchase": [
                759
            ],
            "on_InventoryPurchaseUpdateOutOfDateError": [
                783
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "invoices": [
                1094
            ],
            "bankTransactions": [
                1089
            ],
            "expenses": [
                1092
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupResponse": {
            "paymentGroup": [
                817
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentInput": {
            "id": [
                12
            ],
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "type": [
                868
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentsInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "attachments": [
                1501
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "data": [
                909
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanManualAccountingEventInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "accountingEventId": [
                12
            ],
            "amount": [
                10
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInput": {
            "loans": [
                1506
            ],
            "data": [
                910
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInputLoan": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyInput": {
            "companyId": [
                12
            ],
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "unitsToAssemble": [
                38
            ],
            "recipe": [
                1522
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyResult": {
            "on_ManualAssembly": [
                929
            ],
            "on_RecipeCirclesFoundError": [
                1216
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "loanEventId": [
                12
            ],
            "eventDefinitionId": [
                12
            ],
            "amount": [
                10
            ],
            "memo": [
                1
            ],
            "postedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput2": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "loanEventId": [
                12
            ],
            "eventDefinitionId": [
                12
            ],
            "amount": [
                10
            ],
            "memo": [
                1
            ],
            "postedAt": [
                398
            ],
            "recurrenceSettings": [
                1234
            ],
            "spreadInput": [
                362
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventResult": {
            "loan": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventsResult": {
            "loan": [
                861
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOnboardingStatusInput": {
            "companyId": [
                12
            ],
            "scope": [
                992
            ],
            "step": [
                1
            ],
            "status": [
                996
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderEntitiesInput": {
            "entityType": [
                1012
            ],
            "companyId": [
                1
            ],
            "entityIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "orders": [
                1068
            ],
            "bankTransactions": [
                197
            ],
            "expenses": [
                1022
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupResponse": {
            "orderGroup": [
                1018
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "postedAt": [
                398
            ],
            "orders": [
                1103
            ],
            "bankTransactions": [
                1089
            ],
            "expenses": [
                1092
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupResponse": {
            "paymentGroup": [
                1046
            ],
            "__typename": [
                1
            ]
        },
        "UpdatePayrollSettingsInput": {
            "companyId": [
                12
            ],
            "withCategoryMapping": [
                5
            ],
            "categoryMappingMode": [
                1129
            ],
            "categoryMappings": [
                1128
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductFractionalInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "isFractional": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeInput": {
            "companyId": [
                12
            ],
            "id": [
                1
            ],
            "from": [
                398
            ],
            "to": [
                398
            ],
            "isAssembledOnDemand": [
                5
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "assemblyCost": [
                10
            ],
            "components": [
                1219
            ],
            "managementMethod": [
                1220
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeResult": {
            "on_Recipe": [
                1214
            ],
            "on_RecipeCirclesFoundError": [
                1216
            ],
            "on_RecipeOverlappingError": [
                1221
            ],
            "__typename": [
                1
            ]
        },
        "UpdateSharedGoogleDriveFolder": {
            "companyId": [
                12
            ],
            "folderId": [
                12
            ],
            "folderName": [
                1
            ],
            "folderUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsArgs": {
            "id": [
                12
            ],
            "email": [
                1
            ],
            "name": [
                1
            ],
            "auth0Id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsResponse": {
            "user": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateVendorInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "source": [
                1593
            ],
            "name": [
                1
            ],
            "logoUrl": [
                1
            ],
            "labels": [
                1
            ],
            "subTypes": [
                1595
            ],
            "is1099Contractor": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "contactEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseFieldsInput": {
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDefault": [
                5
            ],
            "isActive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "updateFields": [
                1530
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehousesDefaultsInput": {
            "companyId": [
                12
            ],
            "defaults": [
                1627
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "serialNumber": [
                38
            ],
            "displayName": [
                1
            ],
            "transit": [
                1663
            ],
            "lineItems": [
                1651
            ],
            "costLineItems": [
                1646
            ],
            "attachments": [
                1639
            ],
            "notes": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementInput": {
            "companyId": [
                12
            ],
            "bankAccountId": [
                12
            ],
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                116
            ],
            "system": [
                145
            ],
            "subSystem": [
                143
            ],
            "validationProvider": [
                144
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementResponse": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "bankAccount": [
                97
            ],
            "providerValidationResults": [
                164
            ],
            "__typename": [
                1
            ]
        },
        "UpsertBankTransactionLocalRuleInput": {
            "companyId": [
                12
            ],
            "bankTransactionId": [
                12
            ],
            "classification": [
                176
            ],
            "moneyDirection": [
                1373
            ],
            "merchant": [
                950
            ],
            "shouldAlwaysApplyMerchant": [
                5
            ],
            "shouldAlwaysApplyClassification": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCompanyAddressInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "isDefault": [
                5
            ],
            "deactivatedAt": [
                398
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerAddressArgs": {
            "id": [
                1
            ],
            "customerId": [
                1
            ],
            "companyId": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "state": [
                1
            ],
            "stateCode": [
                1
            ],
            "source": [
                386
            ],
            "integrationAccountId": [
                1
            ],
            "isBilling": [
                5
            ],
            "isShipping": [
                5
            ],
            "deletedAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerV2Args": {
            "id": [
                1
            ],
            "affiliatedEntityId": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                386
            ],
            "integrationAccountId": [
                1
            ],
            "billingAddress": [
                382
            ],
            "shippingAddress": [
                382
            ],
            "billingIsSameAsShipping": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpsertGlobalVendorInput": {
            "id": [
                12
            ],
            "name": [
                1
            ],
            "source": [
                1593
            ],
            "websiteUrl": [
                1
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderCapitalInvestmentInput": {
            "id": [
                12
            ],
            "holderId": [
                12
            ],
            "companyId": [
                12
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderShareInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "holderId": [
                12
            ],
            "type": [
                1333
            ],
            "series": [
                1
            ],
            "amount": [
                10
            ],
            "parValue": [
                10
            ],
            "quantity": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationCustomerArgs": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                386
            ],
            "integrationAccountId": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationInvoiceInput": {
            "companyId": [
                12
            ],
            "companyAddress": [
                727
            ],
            "shippingAddress": [
                731
            ],
            "billingAddress": [
                731
            ],
            "customer": [
                732
            ],
            "payments": [
                740
            ],
            "refunds": [
                740
            ],
            "paymentIndications": [
                740
            ],
            "refundIndications": [
                740
            ],
            "externalSourceId": [
                12
            ],
            "externalSourceUrl": [
                1
            ],
            "source": [
                739
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                398
            ],
            "dueDate": [
                398
            ],
            "taxFixedAmount": [
                10
            ],
            "lineItems": [
                737
            ],
            "discounts": [
                733
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "voidedAt": [
                398
            ],
            "deletedAt": [
                398
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "companyAddressId": [
                12
            ],
            "customerId": [
                12
            ],
            "shippingAddressId": [
                12
            ],
            "billingAddressId": [
                12
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                398
            ],
            "dueDate": [
                398
            ],
            "taxFixedAmount": [
                10
            ],
            "lineItems": [
                1261
            ],
            "discounts": [
                451
            ],
            "paymentMethodIds": [
                12
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "sendInvoice": [
                5
            ],
            "source": [
                1283
            ],
            "currency": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceSettingsInput": {
            "companyId": [
                12
            ],
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "UpsertPaymentMethodInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "type": [
                1118
            ],
            "isDefault": [
                5
            ],
            "deletedAt": [
                398
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertPhysicalCountInput": {
            "id": [
                1
            ],
            "amount": [
                10
            ],
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "companyId": [
                1
            ],
            "deletedAt": [
                398
            ],
            "diff": [
                10
            ],
            "lastAcknowledgedDiff": [
                10
            ],
            "closingBalance": [
                10
            ],
            "lastAcknowledgedClosingBalance": [
                10
            ],
            "attachments": [
                366
            ],
            "__typename": [
                1
            ]
        },
        "UpsertPhysicalCountResult": {
            "on_PhysicalCount": [
                1161
            ],
            "on_PhysicalCountError": [
                1163
            ],
            "__typename": [
                1
            ]
        },
        "UpsertSalesChannelInput": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "financialReportingStatus": [
                511
            ],
            "periods": [
                1272
            ],
            "__typename": [
                1
            ]
        },
        "User": {
            "id": [
                12
            ],
            "email": [
                1
            ],
            "auth0Id": [
                1
            ],
            "name": [
                1
            ],
            "companies": [
                1557
            ],
            "permissions": [
                1160
            ],
            "globalRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToExistingLoanInput": {
            "companyId": [
                12
            ],
            "loanId": [
                12
            ],
            "transactions": [
                90
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanData": {
            "lenderId": [
                1
            ],
            "amount": [
                10
            ],
            "postedAt": [
                398
            ],
            "direction": [
                879
            ],
            "isShortTerm": [
                5
            ],
            "type": [
                908
            ],
            "userActionStatus": [
                912
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanInput": {
            "companyId": [
                12
            ],
            "transactions": [
                90
            ],
            "merchant": [
                892
            ],
            "newLoanData": [
                1553
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailResponse": {
            "user": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "UserCompanies": {
            "company": [
                267
            ],
            "roles": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUp": {
            "type": [
                913
            ],
            "data": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUpInput": {
            "type": [
                913
            ],
            "data": [
                843
            ],
            "__typename": [
                1
            ]
        },
        "userIdInput": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitation": {
            "id": [
                12
            ],
            "email": [
                1
            ],
            "companyId": [
                12
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                398
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationResponse": {
            "userInvitation": [
                1566
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailResponse": {
            "userInvitations": [
                1566
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationWithCompany": {
            "id": [
                12
            ],
            "email": [
                1
            ],
            "company": [
                267
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                398
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "UserManualProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UserNameFromReferralCodeInput": {
            "referralCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserNameFromReferralCodeResponse": {
            "userName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserReferralLinkArgs": {
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserReferralLinkResponse": {
            "referralLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserRole": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserRolesResponse": {
            "userRoles": [
                1572
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsArgs": {
            "emails": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsResponse": {
            "users": [
                1551
            ],
            "__typename": [
                1
            ]
        },
        "ValidationCycle": {
            "id": [
                1
            ],
            "status": [
                1577
            ],
            "executionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ValidationCycleStatus": {},
        "ValidationResult": {
            "companyId": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "accountRoles": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "resultSet": [
                1579
            ],
            "__typename": [
                1
            ]
        },
        "ValidationResultSet": {
            "createdAt": [
                398
            ],
            "sampleDate": [
                398
            ],
            "sampleValue": [
                10
            ],
            "currentValue": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "ValidationsArgs": {
            "integrationType": [
                1
            ],
            "accountRole": [
                1
            ],
            "resultsLimit": [
                38
            ],
            "payingCompaniesOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ValidationTemplate": {
            "id": [
                1
            ],
            "domain": [
                312
            ],
            "type": [
                316
            ],
            "year": [
                38
            ],
            "isEnabled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ValueType": {},
        "Vendor": {
            "id": [
                12
            ],
            "integrationType": [
                754
            ],
            "name": [
                1
            ],
            "companyId": [
                12
            ],
            "externalSourceId": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "logoUrl": [
                1
            ],
            "source": [
                1593
            ],
            "labels": [
                1
            ],
            "contactEmail": [
                1
            ],
            "is1099Contractor": [
                5
            ],
            "type": [
                1596
            ],
            "subType": [
                1595
            ],
            "affiliatedEntityId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VendorByAffiliatedEntityIdInput": {
            "affiliatedEntityId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VendorByIdInput": {
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VendorByNameInput": {
            "name": [
                1
            ],
            "companyId": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayInput": {
            "paymentGateway": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayResponse": {
            "vendor": [
                1583
            ],
            "__typename": [
                1
            ]
        },
        "VendorNotExistError": {
            "message": [
                1
            ],
            "id": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VendorsByIntegrationTypeInput": {
            "integrationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorsInput": {
            "searchTerm": [
                1
            ],
            "limit": [
                38
            ],
            "companyId": [
                12
            ],
            "subTypes": [
                1595
            ],
            "__typename": [
                1
            ]
        },
        "VendorsNotificationInfoInput": {
            "subTypes": [
                1595
            ],
            "purchaseOrderId": [
                1
            ],
            "purchaseOrderGroup": [
                1
            ],
            "logrocketSessionUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorSource": {},
        "VendorsTransactionsCount": {
            "id": [
                1
            ],
            "amount": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "VendorSubType": {},
        "VendorType": {},
        "VoidOrderSettings": {
            "restrictedPaymentGateways": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersInput": {
            "companyId": [
                12
            ],
            "orderIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersResponse": {
            "orders": [
                1073
            ],
            "failedIds": [
                12
            ],
            "__typename": [
                1
            ]
        },
        "WalmartProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                10
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1266
            ],
            "appliedCosts": [
                1172
            ],
            "dailySalesData": [
                1267,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                38
            ],
            "avgCostPerUnit": [
                10
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "walmartId": [
                1
            ],
            "productUrl": [
                1
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1281,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1214,
                {
                    "timeRange": [
                        1178,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1170
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1171,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1197,
                {
                    "input": [
                        71
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Warehouse": {
            "id": [
                12
            ],
            "companyId": [
                12
            ],
            "name": [
                1
            ],
            "source": [
                1629
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDeletable": [
                5
            ],
            "isActive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "on_AdjustmentRestockingAction": [
                37
            ],
            "on_CommonAction": [
                265
            ],
            "on_FulfillmentAction": [
                516
            ],
            "on_ManualAssemblyAction": [
                930
            ],
            "on_OnDemandAssemblyAction": [
                997
            ],
            "on_OpeningBalanceAction": [
                1000
            ],
            "on_RestockingAction": [
                1252
            ],
            "on_ShipmentAction": [
                1335
            ],
            "on_WarehouseTransferArrivalAction": [
                1636
            ],
            "on_WarehouseTransferShippingAction": [
                1654
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseActionSalesChannel": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateError": {
            "message": [
                1
            ],
            "type": [
                1605
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateErrorTypeEnum": {},
        "WarehouseCreateResult": {
            "on_Warehouse": [
                1601
            ],
            "on_WarehouseCreateError": [
                1604
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilter": {
            "type": [
                1608
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilterType": {},
        "WarehouseIncomingInventoryBreakdownFiltersInput": {
            "hasUnavailable": [
                5
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sort": [
                1632
            ],
            "limit": [
                38
            ],
            "offset": [
                38
            ],
            "filters": [
                1609
            ],
            "date": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownResult": {
            "incomingBatches": [
                709
            ],
            "totalBatches": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewInput": {
            "companyId": [
                12
            ],
            "productId": [
                1
            ],
            "date": [
                398
            ],
            "fromDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewResult": {
            "warehouse": [
                1601
            ],
            "analytics": [
                711
            ],
            "inTransit": [
                38
            ],
            "openingBalance": [
                1615
            ],
            "totalBatches": [
                38
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseMovements": {
            "warehouse": [
                1601
            ],
            "movements": [
                970
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalance": {
            "id": [
                12
            ],
            "warehouse": [
                1601
            ],
            "product": [
                1170
            ],
            "quantity": [
                38
            ],
            "costPerUnit": [
                10
            ],
            "date": [
                398
            ],
            "isMissingUserInput": [
                5
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItem": {
            "rowNumber": [
                38
            ],
            "cellNumber": [
                38
            ],
            "errorType": [
                1617
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItemType": {},
        "WarehouseOpeningBalanceItem": {
            "product": [
                1170
            ],
            "warehouseOpeningBalance": [
                1615
            ],
            "pendingWarehouseOpeningBalance": [
                1152
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceOverviewResponse": {
            "count": [
                38
            ],
            "pendingCount": [
                38
            ],
            "missingDataCount": [
                38
            ],
            "totalCost": [
                10
            ],
            "minQuantity": [
                10
            ],
            "maxQuantity": [
                10
            ],
            "minCostPerUnit": [
                10
            ],
            "maxCostPerUnit": [
                10
            ],
            "lastPendingChange": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalancesCsvTemplateResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSort": {
            "field": [
                1622
            ],
            "direction": [
                1386
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSortField": {},
        "WarehouseOpeningBalancesResponse": {
            "items": [
                1618
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseResult": {
            "data": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSalesData": {
            "warehouse": [
                1601
            ],
            "salesChannels": [
                1273
            ],
            "deadInventory": [
                401
            ],
            "giveaway": [
                673
            ],
            "donation": [
                453
            ],
            "waste": [
                1665
            ],
            "salesAdjustments": [
                1188
            ],
            "deadInventoryAdjustments": [
                1180
            ],
            "giveawayAdjustments": [
                1184
            ],
            "donationAdjustments": [
                1182
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaults": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsInput": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsResult": {
            "companyId": [
                12
            ],
            "defaults": [
                1626
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSource": {},
        "WarehousesProductPurchasesBreakdownSortDirection": {},
        "WarehousesProductPurchasesBreakdownSortField": {},
        "WarehousesProductPurchasesBreakdownSortInput": {
            "field": [
                1631
            ],
            "direction": [
                1630
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTimeRangeInput": {
            "startDate": [
                398
            ],
            "endDate": [
                398
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfer": {
            "id": [
                12
            ],
            "serialNumber": [
                38
            ],
            "displayName": [
                1
            ],
            "transit": [
                1662
            ],
            "lineItems": [
                1648
            ],
            "costLineItems": [
                1645
            ],
            "attachments": [
                1638
            ],
            "status": [
                1661
            ],
            "notes": [
                1
            ],
            "deletedAt": [
                398
            ],
            "productsCost": [
                10
            ],
            "transferCost": [
                10
            ],
            "totalCost": [
                10
            ],
            "isHistorical": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrival": {
            "date": [
                398
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1634
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalInput": {
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBatchHistoryItem": {
            "action": [
                1602
            ],
            "landedCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownBatch": {
            "quantity": [
                10
            ],
            "history": [
                1640
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownItem": {
            "action": [
                1602
            ],
            "quantity": [
                10
            ],
            "landedCost": [
                10
            ],
            "batches": [
                1641
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocation": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                10
            ],
            "lastUnitCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocationInput": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                10
            ],
            "lastUnitCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItem": {
            "id": [
                1
            ],
            "type": [
                1647
            ],
            "amount": [
                10
            ],
            "vendor": [
                1583
            ],
            "isManuallyAllocated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemInput": {
            "id": [
                1
            ],
            "type": [
                1647
            ],
            "amount": [
                10
            ],
            "vendorId": [
                1
            ],
            "isManuallyAllocated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemType": {},
        "WarehouseTransferLineItem": {
            "id": [
                1
            ],
            "product": [
                1170
            ],
            "quantity": [
                38
            ],
            "lineItemIdx": [
                38
            ],
            "costAllocations": [
                1643
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoCost": {
            "directCost": [
                10
            ],
            "indirectCosts": [
                1650
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoIndirectCost": {
            "type": [
                716
            ],
            "amount": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemInput": {
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "quantity": [
                38
            ],
            "lineItemIdx": [
                38
            ],
            "costAllocations": [
                1644
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferOverviewItem": {
            "status": [
                1661
            ],
            "count": [
                38
            ],
            "totalCost": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShipping": {
            "date": [
                398
            ],
            "warehouse": [
                1601
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingAction": {
            "quantity": [
                38
            ],
            "id": [
                1
            ],
            "date": [
                398
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1634
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingInput": {
            "date": [
                398
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersOverview": {
            "items": [
                1652
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersResponse": {
            "warehouseTransfers": [
                1634
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersSortDirection": {},
        "WarehouseTransfersSortField": {},
        "WarehouseTransfersSortInput": {
            "field": [
                1659
            ],
            "direction": [
                1658
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferStatus": {},
        "WarehouseTransferTransit": {
            "shipping": [
                1653
            ],
            "arrival": [
                1635
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferTransitInput": {
            "shipping": [
                1655
            ],
            "arrival": [
                1637
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferWithBreakdown": {
            "warehouseTransfer": [
                1634
            ],
            "date": [
                398
            ],
            "items": [
                1642
            ],
            "__typename": [
                1
            ]
        },
        "WasteCogs": {
            "amount": [
                10
            ],
            "quantity": [
                10
            ],
            "monthlyBreakdown": [
                1666
            ],
            "__typename": [
                1
            ]
        },
        "WasteMonthlyBreakdown": {
            "date": [
                398
            ],
            "quantity": [
                10
            ],
            "__typename": [
                1
            ]
        }
    }
}