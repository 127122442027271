import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';

interface FinalMetricValueProps {
  value?: number;
  isLoading: boolean;
}

export const FinalMetricValue = ({ value, isLoading }: FinalMetricValueProps) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Current Value:
        </Typography>
        {isLoading && <CircularProgress color="inherit" size={20} />}
        {value || 0}
      </CardContent>
    </Card>
  );
};
