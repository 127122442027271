import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { useSelectedCompany } from '../../common/hooks/useSelectedCompany';
import { ExistingPdfMissions } from './components/ExistingPdfMissions';
import { NewPdfMission } from './components/NewPdfMission';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const PDFMissions = () => {
  const { selectedCompany } = useSelectedCompany();
  if (!selectedCompany) return <>First select company in the menu</>;

  return (
    <Container>
      <NewPdfMission />
      <Divider />
      <ExistingPdfMissions />
    </Container>
  );
};
