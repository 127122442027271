import axios from 'axios';

let url = '';

export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchQuestionnaireResults = async (companyId: string): Promise<any> => {
  if (!url.length) {
    return;
  }

  return await axios.get<any>(`${url}/company/${companyId}/questionnaires/results`);
};
