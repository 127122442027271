import { useQuery } from 'react-query';
import { ClassificationTag, getBillsClassificationTags } from '../../../services/classificationTagsApiClient';

export interface UseClassificationTagsResult {
  classificationTags?: ClassificationTag[] | null;
  isLoading: boolean;
}

export function useBillsClassificationTags(getToken: () => Promise<string>): UseClassificationTagsResult {
  const { data, isLoading } = useQuery('get-bills-classification-tags', () => getBillsClassificationTags(getToken), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  return { classificationTags: data?.tags || [], isLoading };
}
