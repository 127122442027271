import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import 'moment-timezone';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthListener } from './AuthContext';
import { Routes } from './Routes';
import { AppHeader, SideBar } from './components/Navigation';
import { APP_THEME } from './config';
import { EnvironmentProvider } from './environmentContext/environmentContext';

moment.tz.setDefault('UTC');

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
  height: 100%;
  box-sizing: content-box;
  overflow: hidden;
`;

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 1em;
`;

const globalStyles = css`
  html,
  body,
  #root {
    height: 100%;
  }
  #root {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
`;

const history = createBrowserHistory();

const onRedirectCallback = (appState?: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const queryClient = new QueryClient();

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      <AuthListener>
        <ThemeProvider theme={APP_THEME}>
          <Global styles={globalStyles} />
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <EnvironmentProvider>
              <BrowserRouter>
                <LocalizationProvider
                  dateLibInstance={moment}
                  dateAdapter={AdapterMoment}>
                  <AppHeader />
                  <Container>
                    <SideBar />
                    <PageContent>
                      <Routes />
                    </PageContent>
                  </Container>
                </LocalizationProvider>
              </BrowserRouter>
            </EnvironmentProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </AuthListener>
    </Auth0Provider>
  );
}

export default App;
