import { useContext } from 'react';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { CompanyQuickbooksAccount } from '../../../types/CompanyQuickbooksAccount';
import { useMutation, useQueryClient } from 'react-query';
import { GET_COMPANY_QB_ACCOUNTS_QUERY } from './useGetCompanyQbAccounts';
import { useAuth0 } from '@auth0/auth0-react';

export const useUpdateCompanyQbAccountsMutation = (companyId: string) => {
  const services = useContext(environmentContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (accounts: CompanyQuickbooksAccount[]) =>
      services.CompanyQbAccountsApiClient.updateAccounts(companyId, accounts, getAccessTokenSilently),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_COMPANY_QB_ACCOUNTS_QUERY);
      },
    },
  );
};
