import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { formatMoney } from '../../../common/utils/format-money';
import { useLoans } from '../hooks/useLoans';
import { lenders } from '../services/lenders-provider';
import { LoanDirection, LoanResponse, LoanStatus } from '../types';
import { LoanAgreements } from './LoanAgreements';

const LoanContainer = styled.div<{ loanStatus: LoanStatus }>`
  display: flex;
  flex-direction: row;
  border: 2px solid ${(_) => getLoanBorderColor(_.loanStatus)};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 650px;
  padding: 15px;
  gap: 15px;
`;

const getLoanBorderColor = (status: LoanStatus) => {
  switch (status) {
    case LoanStatus.approved:
      return 'lightgreen';
    case LoanStatus.actionRequired:
      return 'orange';
    default:
    case LoanStatus.processing:
      return 'lightgray';
  }
};

const LoanSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 5px;
  flex: 1;
`;

const TupleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  label:first-of-type {
    color: gray;
    text-decoration: underline;
    flex-shrink: 0;
  }
`;

const Comment = styled.textarea`
  border: 1px solid lightgray;
  min-width: 300px;
  min-height: 100px;
`;

const Tuple = ({ label, value }: { label: string; value?: any }) => (
  <TupleContainer>
    <label>{label}:</label>
    {value}
  </TupleContainer>
);

export const LoanComponent = ({ loan }: { loan: LoanResponse }) => {
  const { useUpsertLoan, useDeleteLoan } = useLoans();
  const { hadErrorSavingLoan, isSaving, savedSuccessfully, upsertLoan } = useUpsertLoan(loan.id);
  const { hasErrorDeleting, isDeleting, deletedSuccessfully, deleteLoan } = useDeleteLoan(loan.id);
  const { user } = useAuth0();
  const [newStatus, setNewStatus] = useState<LoanStatus>(loan.status);
  const [comment, setComment] = useState(loan.comment?.text || '');

  const onSaveClick = async () => {
    await upsertLoan({
      ...loan,
      comment: !comment
        ? null
        : {
            author: user?.email || 'backoffice',
            dateMs: Date.now(),
            text: comment,
          },
      status: newStatus,
    });
  };

  const onDeleteClick = async () => {
    if (window.confirm('Are you sure you want to delete this loan?')) {
      await deleteLoan(loan.id);
    }
  };

  return (
    <LoanContainer loanStatus={loan.status}>
      <LoanSection>
        <Tuple label="Company" value={loan.companyName} />
        <Tuple
          label="Commencement date"
          value={loan.commencementDateMs ? moment(loan.commencementDateMs).format('MM-DD-yyyy') : ''}
        />
        <Tuple label="Amount" value={loan.amount ? formatMoney(loan.amount) : ''} />
        <Tuple label="Lender" value={lenders.find((_) => _.id === loan.lenderId)?.name} />
        {loan.unlistedLenderName && (
          <Tuple
            label={loan.loanDirection === LoanDirection.given ? 'Lendee' : 'Lender name'}
            value={loan.unlistedLenderName}
          />
        )}
        {loan.includesRelatedParty !== undefined && (
          <Tuple label="Related party" value={loan.includesRelatedParty?.toString()} />
        )}
        <Tuple label="Added by" value={loan.initiatedBy} />
        <Tuple label="Last updated at" value={moment(loan.updatedAtMs).format('DD-MM-yyyy HH:mm')} />
        <Tuple label="Updated by" value={loan.updatedBy} />
        {loan.loanDirection && <Tuple label="Direction" value={loan.loanDirection} />}
        <Tuple label="Loan id" value={loan.id} />
      </LoanSection>
      <LoanSection>
        <Tuple label="Agreements" value={<LoanAgreements loan={loan} />} />
        {loan.isWithoutAgreements && <Tuple label="Without agreements" value={true} />}
        <Tuple
          label="Status"
          value={
            <select
              value={newStatus}
              onChange={(_) => {
                setNewStatus(_.target.value as any);
              }}
            >
              {[LoanStatus.processing, LoanStatus.actionRequired, LoanStatus.approved].map((_, index) => (
                <option key={index}>{_}</option>
              ))}
            </select>
          }
        />
        <Tuple label="Comment" value={<Comment value={comment} onChange={(_) => setComment(_.target.value)} />} />
        <Button variant="outlined" fullWidth={false} onClick={onSaveClick}>
          save loan
        </Button>
        <Button variant="outlined" onClick={onDeleteClick}>
          delete loan
        </Button>
        {hadErrorSavingLoan && <div>failed to save loan</div>}
        {isSaving && <div>saving...</div>}
        {savedSuccessfully && <div>saved</div>}
        {hasErrorDeleting && <div>failed to delete loan</div>}
        {isDeleting && <div>deleting...</div>}
        {deletedSuccessfully && <div>deleted</div>}
      </LoanSection>
    </LoanContainer>
  );
};
