import { useQuery, gqlClient } from './hooks';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer, Table, TableRow, TableCell, Box, TableHead, TableBody, Button } from '@mui/material';
import { Fragment, useContext } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { AmazonProductsValidation } from '../../../__generated_amazon_products_gql__';
import ReactJson from 'react-json-view';
import * as reactQuery from 'react-query';
import { environmentContext } from '../../../environmentContext/environmentContext';
import lodash from 'lodash';

const Status = ({ ok }: { ok: boolean }) => {
  return ok ? (
    <CheckIcon style={{ color: 'green' }}></CheckIcon>
  ) : (
    <PriorityHighIcon style={{ color: 'red' }}></PriorityHighIcon>
  );
};

const RerunValidation = ({
  userToken,
  companyId,
  integrationAccountId,
  setValidationData,
}: {
  userToken: string;
  companyId: string;
  integrationAccountId: string;
  setValidationData: (data: any) => any;
}) => {
  const { currentEnv } = useContext(environmentContext);

  const client = gqlClient(currentEnv, userToken);
  const { isLoading, data, mutate } = reactQuery.useMutation(
    [
      'runValidation',
      {
        companyId,
        integrationAccountId,
      },
    ],
    () => {
      return client.mutation({
        runValidation: [
          {
            args: {
              companyId,
              integrationAccountId,
            },
          },
          {
            company: {
              companyId: true,
              companyName: true,
            },
            isAmazonEnabled: true,
            isOk: true,
            integrationAccountId: true,
            date: true,
            validationDetails: true,
          },
        ],
      });
    },
    {
      onSuccess: (newData) => setValidationData(newData.runValidation),
    },
  );

  return (
    <Button disabled={isLoading} onClick={() => mutate()}>
      Rerun
    </Button>
  );
};

const AmazonProductsValidationsList = ({
  userToken,
  validations: validationsData,
}: {
  userToken: string;
  validations: AmazonProductsValidation[];
}) => {
  const [validations, setValidations] = useState(validationsData);
  const [showDetailsIdx, setShowDetailsIdx] = useState<number | null>(null);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Integration Account Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Events</TableCell>
            <TableCell>Products</TableCell>
            <TableCell>RT-COGS enabled</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lodash
            .sortBy(validations, (x) => (x.isAmazonEnabled ? -1 : 1))
            .map((v, idx) => {
              const rowSelector = {
                companyId: v.company.companyId,
                integrationAccountId: v.integrationAccountId,
              };
              return (
                <Fragment>
                  <TableRow>
                    <TableCell>{`${v.company.companyName} / ${v.company.companyId}`}</TableCell>
                    <TableCell>{v.integrationAccountId}</TableCell>
                    <TableCell>{v.date}</TableCell>
                    <TableCell>
                      <Status ok={v.isOk} />
                    </TableCell>
                    <TableCell>
                      <Status ok={v.areProductsSynced} />
                    </TableCell>
                    <TableCell>
                      <Status ok={v.isAmazonEnabled} />
                    </TableCell>
                    <TableCell>
                      <RerunValidation
                        userToken={userToken}
                        {...rowSelector}
                        setValidationData={(data) => setValidations(data)}
                      />
                    </TableCell>
                    <TableCell>
                      {showDetailsIdx === idx ? (
                        <ValidationDetails userToken={userToken} validation={v} />
                      ) : (
                        <Button onClick={() => setShowDetailsIdx(idx)}>Details</Button>
                      )}
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ValidationDetails = ({ userToken, validation }: { userToken: string; validation: AmazonProductsValidation }) => {
  const validationsData = useQuery(
    {
      getAmazonProductsValidations: [
        {
          args: {
            companyId: validation.company.companyId,
            integrationAccountId: validation.integrationAccountId,
          },
        },
        {
          validationDetails: true,
        },
      ],
    },
    userToken,
  );

  const data = validationsData.data?.getAmazonProductsValidations;
  const item = data && data[0];
  return <ReactJson collapsed={1} src={{ data: item?.validationDetails }} />;
};

const AmazonProductsValidationWrapper = ({ userToken }: { userToken: string }) => {
  const validationsData = useQuery(
    {
      getAmazonProductsValidations: {
        isOk: true,
        company: {
          companyName: true,
          companyId: true,
          __typename: true,
        },
        isAmazonEnabled: true,
        integrationAccountId: true,
        areProductsSynced: true,
        date: true,
        __typename: true,
      },
    },
    userToken,
  );

  if (validationsData.isLoading) {
    return <Box>Loading product validation data</Box>;
  }

  const validations = validationsData.data?.getAmazonProductsValidations;
  if (!validations) {
    return <Box>Empty validations, something is wrong</Box>;
  }

  return <AmazonProductsValidationsList validations={validations} userToken={userToken} />;
};

export default () => {
  const [userToken, setUserToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    getAccessTokenSilently().then((token) => setUserToken(token));
  });

  if (!userToken) {
    return <Box>Authorizing</Box>;
  }

  return <AmazonProductsValidationWrapper userToken={userToken} />;
};
