import { useContext, useEffect, useState } from 'react';

import { FetchHookState } from './types/FetchHookState';
import { environmentContext } from '../../../environmentContext/environmentContext';
import { MetricsValidationStatusForCompany } from '../../../types/MetricsValidationStatusForCompany';

export type UseFetchMetricsStatusForCompanyState = FetchHookState<MetricsValidationStatusForCompany[]>;

const defaultState: UseFetchMetricsStatusForCompanyState = {
  data: [],
  loading: true,
};
export const useFetchMetricsValidationStatus = (integrationType?: string) => {
  const [fetchHookState, setFetchHookState] = useState<UseFetchMetricsStatusForCompanyState>(defaultState);
  const services = useContext(environmentContext);
  useEffect(() => {
    if (!integrationType) return;
    setFetchHookState(defaultState);
    services.ValidationApiClient.fetchMetricsValidationStatus(integrationType)
      .then((data) => {
        setFetchHookState({
          data,
          loading: false,
        });
      })
      .catch((error) => {
        setFetchHookState({
          data: [],
          error,
          loading: false,
        });
      });
  }, [services, integrationType]);
  return fetchHookState;
};
