import { useCallback, useEffect, useMemo, useState } from 'react';
export const usePagination = () => {
  const [pages, setPages] = useState<(any | undefined)[]>([]);
  const [currentPageId, setCurrentPageId] = useState(1);
  const [missingPage, setMissingPage] = useState(false);
  const nextPage = useCallback(() => {
    if (pages.length - 1 === currentPageId) {
      setMissingPage(true);
      return;
    }
    setCurrentPageId(currentPageId + 1);
  }, [pages, currentPageId]);
  const previousPage = useCallback(() => {
    if (currentPageId === 0) {
      return;
    }
    setCurrentPageId(currentPageId - 1);
  }, [currentPageId]);
  const appendPage = useCallback(
    (newPage: any) => {
      setPages((pages) => [...pages, newPage]);
      setMissingPage(false);
    },
    [setPages],
  );
  const clearPages = useCallback(() => {
    setPages([]);
    setCurrentPageId(1);
  }, [setPages]);
  useEffect(() => setCurrentPageId(() => pages.length - 1), [setCurrentPageId, pages]);
  const currentPage = useMemo(() => pages[currentPageId], [pages, currentPageId]);
  const hasPrevious = useMemo(() => currentPageId > 0, [currentPageId]);
  return {
    missingPage,
    nextPage,
    previousPage,
    currentPage,
    appendPage,
    hasPrevious,
    clearPages,
  };
};
