import axios from 'axios';
import { MatchingReportForAccount } from '../types/MatchingReportForAccount';

let url = '';
export const setUrl = (newUrl: string) => (url = newUrl);

export const fetchMatchingReport = async (integrationType: string): Promise<MatchingReportForAccount[]> => {
  if (!url.length) {
    return [];
  }

  const result = await axios.get<MatchingReportForAccount[]>(`${url}/report/${integrationType.toUpperCase()}`);

  return result.data;
};
