import { useCallback, useEffect, useState } from 'react';

const ALERT_TIMEOUT = 8000;

export const useDispatchBusinessEventsStatus = () => {
  const [dispatchError, setDispatchError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (showSuccess) {
      const timeout = setTimeout(() => {
        setShowSuccess(false);
      }, ALERT_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [showSuccess]);

  useEffect(() => {
    if (dispatchError) {
      const timeout = setTimeout(() => {
        setDispatchError(null);
      }, ALERT_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [dispatchError]);

  const onDispatchSuccess = useCallback(() => {
    setDispatchError(null);
    setShowSuccess(true);
  }, []);

  const onDispatchError = useCallback((e) => {
    setShowSuccess(false);
    if (typeof e === 'string') {
      setDispatchError(e);
    } else {
      setDispatchError(e?.message || 'Error saving business event');
    }
  }, []);

  return { showSuccess, dispatchError, onDispatchError, onDispatchSuccess, setDispatchError };
};
